import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';

import {ContainerFlex, Image} from '@EcommerceShopify/styles';
import qrIcon from '@images/qrcodeNav.svg';
import clearIcon from '@images/close.svg';
import searchIcon from '@components/Branches/Images/AppMagnifierIcon.svg';
import {SearchInput} from '@Customer/Styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {INVENTORY_PAGE} from '@components/InventoryManagement/constants';
import {InventoryFiltersProps} from '@components/InventoryManagement/interfaces';
import useDebounce from '@/hooks/useDebounce';
import PriceInputDropdown from '@components/InventoryManagement/PriceInputDropdown';

const InventoryFilters = ({
    onSearch,
    onPriceRangeSelect,
    contractStatuses,
    setSelectedStatus,
    selectedStatus,
    branchesOptions,
    setSelectedBranch,
    selectedBranch,
}: InventoryFiltersProps) => {
    const {register, watch, reset} = useForm({
        defaultValues: {searchTerm: ''},
    });
    const searchTerm = watch('searchTerm');
    const debouncedSearch = useDebounce(searchTerm, 300);

    useEffect(() => {
        if (debouncedSearch.length >= 3) {
            onSearch(debouncedSearch);
        }
        if (debouncedSearch.length === 0) {
            onSearch('');
        }
    }, [debouncedSearch, onSearch]);

    const handleClearInput = () => {
        reset({searchTerm: ''});
    };

    const getInputIcon = () => (searchTerm.length > 0 ? clearIcon : searchIcon);

    return (
        <>
            <ContainerFlex Align="baseline" Padding="0" Height="2.5rem" Gap="1rem">
                <SearchInput
                    Height="2.6rem"
                    Position="none"
                    FontSize="1rem"
                    PlaceholderColor="#A1A5AA"
                    Padding="0 0 0 1rem"
                    WBoxShadow=""
                >
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={INVENTORY_PAGE.SEARCH}
                        {...register('searchTerm')}
                    />
                    <ContainerFlex
                        Justify="flex-end"
                        Align="center"
                        Gap="0.5rem"
                        Padding="0.5rem 1rem"
                    >
                        <Image src={qrIcon} alt="icon-qr" Width="1.5rem" Height="1.5rem" />
                        <Image
                            src={getInputIcon()}
                            alt="icon-magnifier"
                            Cursor="pointer"
                            Width="1.5rem"
                            Height="1.5rem"
                            onClick={handleClearInput}
                        />
                    </ContainerFlex>
                </SearchInput>
                <SelectGeneral
                    value={selectedBranch}
                    width="13.938rem"
                    isSearchable={false}
                    options={branchesOptions}
                    onChange={setSelectedBranch}
                />
            </ContainerFlex>
            <ContainerFlex Gap="1rem">
                <PriceInputDropdown onPriceRangeSelect={onPriceRangeSelect} />
                <SelectGeneral
                    value={selectedStatus}
                    placeholder={INVENTORY_PAGE.STATUS}
                    isSearchable={false}
                    options={contractStatuses}
                    noOptionsMessage={() => INVENTORY_PAGE.NO_OPTIONS}
                    onChange={setSelectedStatus}
                />
            </ContainerFlex>
        </>
    );
};

export default InventoryFilters;
