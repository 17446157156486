import axios from 'axios';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';
import {GET_BRANCH_STORAGE_BYCOMPANY} from '@components/InventoryManagement/Redux/types';
import {ContractStatusesResponse} from '@components/InventoryManagement/Redux/interfaces';
import {GetContractStatusTypes} from '@/components/InventoryManagement/Redux/Actions/GetContractStatusesTypes';

const fetchBranchStorageStart = () => ({
    type: GET_BRANCH_STORAGE_BYCOMPANY.START,
});

const fetchBranchStorageSuccess = (result: ContractStatusesResponse) => ({
    type: GET_BRANCH_STORAGE_BYCOMPANY.SUCCESS,
    payload: result,
});

const fetchBranchStorageError = () => ({
    type: GET_BRANCH_STORAGE_BYCOMPANY.ERROR,
});

export const FetchBranchStorageByCompanyId = (
    token: string,
    companyId: number,
    fetchStorages: boolean
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(fetchBranchStorageStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchStorageByCompanyId}`,
                {
                    headers,
                    params: {companyId},
                }
            );
            dispatch(fetchBranchStorageSuccess(response.data.data));
            if (fetchStorages) dispatch(GetContractStatusTypes(token));
        } catch (error) {
            dispatch(fetchBranchStorageError());
        }
    };
};
