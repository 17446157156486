import React, {useRef, useState} from 'react';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    IAdditionalDocumentsProps,
    IFileErrors,
    ImageCharge,
} from '@components/PersonalLoans/interfaces';
import ArrowUpMenu from '@images/arrowUpIcon.svg';
import ArrowDownMenu from '@images/arrowDownIcon.svg';
import * as content from '@components/PersonalLoans/styles';
import {compressImage, isSupportedFileType, toCamelCase} from '@/hooks/currentFormatUtils';
import AdditionalDocumentTitle from '@components/PersonalLoans/PersonalLoansAdditionalDocument/AdditionalDocumentTitle';
import NoFileUploader from '@components/PersonalLoans/PersonalLoansAdditionalDocument/NoFileUploader';
import PreviewFileUploader from '@components/PersonalLoans/PersonalLoansAdditionalDocument/PreviewFileUploader';
import {fileErrorsInitialState, UPLOADDROPDATA} from '@components/PersonalLoans/constants';

const PersonalLoansAdditiionalDocument = ({
    register,
    errors,
    documentsInfo,
    setDocumentsInfo,
    additionalDocument,
}: IAdditionalDocumentsProps) => {
    const stateKey = toCamelCase(additionalDocument.documentName);
    const registerName = 'name' + stateKey;
    const [showDocument, setShowDocument] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileErrors, setFileErrors] = useState<IFileErrors>(fileErrorsInitialState);

    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                callback(reader.result as string);
            }
        };
    };

    const handleFileProcessing = async (file: File) => {
        if (file.type === UPLOADDROPDATA.PDF_FORMAT) {
            convertToBase64(file, (base64) => {
                setDocumentsInfo((prevState) => ({
                    ...prevState,
                    [stateKey]: {fileName: file.name, formFile: base64},
                }));
            });
        } else {
            try {
                const compressedFile = await compressImage(file);
                convertToBase64(compressedFile, (base64) => {
                    setDocumentsInfo((prevState) => ({
                        ...prevState,
                        [stateKey]: {fileName: compressedFile.name, formFile: base64},
                    }));
                });
                setFileErrors(fileErrorsInitialState);
            } catch (error) {
                setFileErrors({...fileErrors, validImageFormat: true});
            }
        }
    };

    const handleIncomeFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileErrors(fileErrorsInitialState);
        if (!event.target.files) return;
        const file = event.target.files[0];
        const fileSizeMB = file?.size / 1024 / 1024;
        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }
        if (fileSizeMB <= 4 && isSupportedFileType(file)) {
            handleFileProcessing(file);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setFileErrors(fileErrorsInitialState);
        const file = event.dataTransfer.files[0];
        const fileSizeMB = file?.size / 1024 / 1024;
        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }
        handleFileProcessing(file);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <ContainerFlex
            {...content.ContainerFlexUpload}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <ContainerFlex
                Justify="space-between"
                Gap="0.5rem"
                onClick={() => setShowDocument(!showDocument)}
                Cursor="pointer"
            >
                <AdditionalDocumentTitle
                    title={additionalDocument.documentName}
                    documentFile={documentsInfo[stateKey] as ImageCharge | null}
                />
                <Image
                    alt="icon-arrow"
                    src={showDocument ? ArrowUpMenu : ArrowDownMenu}
                    Cursor="pointer"
                />
            </ContainerFlex>
            {showDocument &&
                (documentsInfo[stateKey] ? (
                    <PreviewFileUploader
                        documentFile={documentsInfo[stateKey] as ImageCharge}
                        fileErrors={fileErrors}
                        handleIncomeFileChange={handleIncomeFileChange}
                        fileInputRef={fileInputRef}
                        registerName={registerName}
                        errors={errors}
                        register={register}
                    />
                ) : (
                    <NoFileUploader
                        fileErrors={fileErrors}
                        fileInputRef={fileInputRef}
                        handleIncomeFileChange={handleIncomeFileChange}
                    />
                ))}
        </ContainerFlex>
    );
};

export default PersonalLoansAdditiionalDocument;
