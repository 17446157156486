import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {InterceptorApp} from '@/components/Login/Interceptor/Interceptor';
import {AccoutVerification} from '@/components/Login/Operational/AccountVerification';
import {LoginOperational} from '@/components/Login/Operational/index';
import {NewPassword} from '@/components/Login/PasswordForgotAdmin/ConfirmPass/NewPasswd';
import {AdminContact} from '@/components/LoginClient/SubContainers/AdminContact';
import {LoginAdministrative} from '@Login/Administrative/LoginAdministrative';
import {PrivateRoute} from '@routers/PrivateRoute';
import {PublicRoute} from '@routers/PublicRoute';

import {routers} from '@/appRoute';
import {GetRouter} from '@/components/AdminNavbar/Redux/Actions/loginTypeRoute';
import {Elements} from '@/components/CashFlowActions/elemens';
import {CreditQuoter} from '@/components/CreditQuoter/CreditQuoter';
import {GlobalHeader} from '@/components/HomePublicClient/GlobalHeaderAdd';
import {ClientNavBar} from '@/components/HomePublicClient/HomeClientHeader';
import HomePublic from '@/components/HomePublicClient/HomePublic';
import {PlataformSelection} from '@/components/Login/Administrative/PlataformSelextion';
import {AccountContainer} from '@/components/Login/MarketPlaceLogin/AccountContainer';
import {LoginClient} from '@/components/Login/MarketPlaceLogin/LoginClient';
import {VerifyMailPassword} from '@/components/Login/PasswordForgotAdmin/VerifyMailPassword';
import {CustomerPanelClient} from '@/components/MarketCustomerPanel/Modulos/CustomerPanelClient';
import {ResetAccountCode} from '@/components/MarketForgottenLogin/ResetAccountCode';
import {ResetAcountContainer} from '@/components/MarketForgottenLogin/ResetAccountContainer';
import {ResetPasswordClient} from '@/components/MarketForgottenLogin/ResetPasswordClient';
import {SuccesfulPassword} from '@/components/MarketForgottenLogin/SuccessfulConfirmation';
import {StatusPayment} from '@/components/MyAccount/MyCredit/CreditDetails/StatusPayment';
import {PublicGlobalQuoter} from '@/components/PublicGlobalQuoter/PublicGlobalQuoter';
import {QuickPayment} from '@/components/QuickPayment/QuickPayment';
import {SignUp} from '@/components/SignUp';
import {DashboardRoutes} from '@/routers/DashboardRoutes';
import {AccountContainerLogin} from '@components/MarketAccessLogin/AccountContainerLogin';
import {HOME_PATH} from './constants';

export const AppRouter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isHome = location.pathname.toLowerCase() === HOME_PATH;
    const token = useSelector((state) => state.getUsersValidation.userData?.token);
    InterceptorApp(dispatch, token);

    useEffect(() => {
        if (
            (location.pathname.includes('CreditDetails') ||
                location.pathname.includes('PersonalLoans')) &&
            !token
        ) {
            const targetPath = '/login/MarketUser';
            navigate(targetPath, {replace: true});
            dispatch(GetRouter(targetPath));
        }
    }, [location.pathname, token, navigate, dispatch]);
    return (
        <div>
            {isHome && !token && <ClientNavBar />}
            {isHome && !token && <GlobalHeader />}

            <Routes>
                {!token && <Route path={routers.Home} element={<HomePublic />} />}
                {!token && <Route element={<PublicGlobalQuoter />} path={routers.LoanQuoter} />}
                {!token && <Route element={<CreditQuoter />} path={routers.CreditQuoter} />}
                <Route
                    path="Login/*"
                    element={
                        <PublicRoute>
                            <Routes>
                                <Route
                                    element={<AccoutVerification />}
                                    path="/AccountVerification"
                                />
                                <Route element={<LoginAdministrative />} path="/" />
                                <Route
                                    element={<PlataformSelection />}
                                    path="/platform-selection"
                                />

                                <Route element={<LoginClient />} path="/MarketClient" />
                                <Route element={<AccountContainer />} path="/MarketValide" />
                                <Route
                                    element={<CustomerPanelClient />}
                                    path="/CustomerMarketClient"
                                />
                                <Route element={<Elements />} path="/ButtonActions" />
                                <Route element={<ResetAcountContainer />} path="ResetAccount" />
                                <Route element={<ResetAccountCode />} path="RestoredPassword" />
                                <Route
                                    element={<ResetPasswordClient />}
                                    path="RestoredNewPassword"
                                />
                                <Route element={<SuccesfulPassword />} path="PasswordSouccess" />

                                <Route element={<LoginOperational />} path="/Employee" />
                                <Route
                                    element={<AccountContainerLogin />}
                                    path={routers.AccountContainerLogin}
                                />
                                <Route element={<VerifyMailPassword />} path="/ForgotPassword" />
                                <Route element={<NewPassword />} path="/ResetAdminPassword/:user" />
                                <Route element={<AdminContact />} path="/AdminContact" />
                                <Route element={<QuickPayment />} path="/QuickPayment" />
                                <Route element={<StatusPayment />} path="/StatusPayment" />
                            </Routes>
                        </PublicRoute>
                    }
                />

                <Route
                    path="SignUp"
                    element={
                        <PublicRoute>
                            <SignUp />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <DashboardRoutes />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </div>
    );
};
