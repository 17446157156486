import React from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, Controller, useForm, FormProvider} from 'react-hook-form';
import {schemaCustomPrice} from '@components/InventoryManagement/yupInventoryFilter';

import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {ContainerForm, ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import {PrefixWrapper, StyledInput} from '@General/Atoms/LabeledInput/styles';
import {INVENTORY_PAGE, PRICE_RANGES} from '@components/InventoryManagement/constants';
import arrowIcon from '@images/arrowDark.svg';
import {PriceRangeFilterProps} from '@components/InventoryManagement/interfaces';
import {LabelStyle} from '@components/InventoryManagement/styles';

const PriceRangeFilter = ({onSelect}: PriceRangeFilterProps) => {
    const getErrors = (...messages: (string | undefined)[]): string[] =>
        Array.from(new Set(messages.filter((msg): msg is string => Boolean(msg))));

    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaCustomPrice),
        defaultValues: {
            customFrom: null,
            customTo: null,
        },
    });

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = formMethods;

    const onSubmit = (values: FieldValues) => {
        const {customFrom, customTo} = values;

        if (!customFrom && !customTo) {
            return;
        }

        const range = {
            from: customFrom ? parseFloat(customFrom) : null,
            to: customTo ? parseFloat(customTo) : null,
            type: PRICE_RANGES.CUSTOM,
        };

        const rangeString = [
            customFrom
                ? customTo
                    ? `${PRICE_RANGES.FROM} ${numberToCurrency(customFrom)}`
                    : `${INVENTORY_PAGE.FROM} ${numberToCurrency(customFrom)}`
                : '',
            customTo
                ? customFrom
                    ? `${PRICE_RANGES.TO} ${numberToCurrency(customTo)}`
                    : `${INVENTORY_PAGE.TO} ${numberToCurrency(customTo)}`
                : '',
        ]
            .filter(Boolean)
            .join(' ');

        onSelect(rangeString, range);
    };

    return (
        <FormProvider {...formMethods}>
            <ContainerForm
                Width="18.75rem"
                Gap="0.5rem"
                Padding="0.5rem"
                Justify="start"
                Align="flex-end"
                Self="center"
            >
                <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem">
                    <ContainerFlex Gap="0.25rem" Justify="start" Align="center">
                        <Text {...LabelStyle}>{INVENTORY_PAGE.FROM}</Text>
                    </ContainerFlex>
                    <ContainerFlex Position="relative">
                        <PrefixWrapper>$</PrefixWrapper>
                        <Controller
                            name="customFrom"
                            control={control}
                            render={({field}) => (
                                <StyledInput
                                    {...field}
                                    {...register('customFrom')}
                                    type="number"
                                    hasPrefix={true}
                                    error={!!errors.customFrom?.message}
                                />
                            )}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem">
                    <ContainerFlex Gap="0.25rem" Justify="start" Align="center">
                        <Text {...LabelStyle}>{INVENTORY_PAGE.TO}</Text>
                    </ContainerFlex>
                    <ContainerFlex Position="relative">
                        <PrefixWrapper>$</PrefixWrapper>
                        <Controller
                            name={'customTo'}
                            control={control}
                            render={({field}) => (
                                <StyledInput
                                    {...field}
                                    {...register('customTo')}
                                    type="number"
                                    hasPrefix={true}
                                    error={!!errors.customTo?.message}
                                />
                            )}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <Image
                    src={arrowIcon}
                    alt="arrow"
                    Cursor="pointer"
                    Width="1.5rem"
                    Height="1.5rem"
                    Padding="0.5rem 0"
                    onClick={handleSubmit(onSubmit)}
                />
            </ContainerForm>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.285rem">
                {getErrors(
                    errors.customFrom?.message?.toString(),
                    errors.customTo?.message?.toString()
                ).map((error, index) => (
                    <Text
                        key={index}
                        Color="#FF6357"
                        Padding="0 0.8rem"
                        FontSize="0.75rem"
                        oFlow="visible"
                    >
                        {error.toString()}
                    </Text>
                ))}
            </ContainerFlex>
        </FormProvider>
    );
};

export default PriceRangeFilter;
