import React, {useState} from 'react';
import collapsedIcon from '@/images/arrowDownIcon.svg';
import expandIcon from '@/images/arrowUpIcon.svg';
import {
    AccordionSideBarStyled,
    AccordionSummarySideBarStyled,
    AccordionDetailsSideBarStyled,
} from '@/components/SideBarMenu/Accordion/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IAccordionSideBarMenu} from '@/components/SideBarMenu/Accordion/interfaces';
import {useNavigate} from 'react-router-dom';

const AccordionSideBarMenu: React.FC<IAccordionSideBarMenu> = ({
    summaryTitle,
    summaryIcon,
    children,
    hasItems,
    route,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleTextClick = () => {
        if (route) {
            if (route !== '#') {
                navigate(route);
                if (hasItems) setExpanded(!expanded);
            } else {
                setExpanded(!expanded);
            }
        }
    };
    return (
        <AccordionSideBarStyled expanded={expanded}>
            <AccordionSummarySideBarStyled
                expandedIcon={expandIcon}
                collapsedIcon={collapsedIcon}
                expanded={expanded}
                handlerExpanded={setExpanded}
                hasItems={hasItems || false}
            >
                <ContainerFlex Justify="start" Align="center" Width="100%" Gap="8px">
                    <ContainerFlex Width="20px" Height="20px" Justify="center" Align="center">
                        <Image src={summaryIcon} width="24px" height="24px" alt="expanded-icon" />
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Height="100%"
                        Width="100%"
                        Gap="4px"
                        Align="start"
                        Justify="center"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#5A5AFF"
                            Cursor="pointer"
                            onClick={() => handleTextClick()}
                        >
                            {summaryTitle}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </AccordionSummarySideBarStyled>
            <AccordionDetailsSideBarStyled>{children}</AccordionDetailsSideBarStyled>
        </AccordionSideBarStyled>
    );
};

export default AccordionSideBarMenu;
