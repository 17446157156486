import axios from 'axios';
import {URL} from '@config/constants';
import {SEARCH} from '@Quoter/Redux/Types/Types';
import StringUtils from '@GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {IResultQuoterSearch, ISearQuoter} from '@Quoter/inferfaces';

export const searchQuoter = () => ({type: SEARCH.INITIAL});
export const searchQuoterLoadReset = () => ({type: SEARCH.LOAD_RESET});
export const searchQuoterSuccess = (result: {data: IResultQuoterSearch[]}) => {
    return {
        type: SEARCH.SUCCESS,
        search: result.data,
    };
};
export const searchQuoterError = () => ({type: SEARCH.ERROR});
export const resetSearch = () => ({type: SEARCH.RESET});

export const SearchQuoterProduct = (token: string, filters: ISearQuoter, signal?: AbortSignal) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(searchQuoter());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SearchQuoter}${queryString}`,
                {headers, signal}
            );
            dispatch(searchQuoterSuccess(response.data));
        } catch (error) {
            dispatch(searchQuoterError());
        }
    };
};
