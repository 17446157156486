import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import LoanList from '@components/GlobalQuoter/LoanList';
import QuoterContainer from '@components/GlobalQuoter/QuoterContainer';
import BannerQuoter from '@components/CreditQuoter/BannerQuoter';
import {OfferCreditsBanner} from '@components/PublicGlobalQuoter/OfferCreditsBanner';

const GlobalQuoter = () => {
    return (
        <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
            <QuoterContainer />
            <LoanList isPublicQuoter={false} />
            <OfferCreditsBanner />
            <BannerQuoter />
        </ContainerFlex>
    );
};

export default GlobalQuoter;
