import React from 'react';

import useFormatDate from '@/hooks/useFormatDate';
import Tooltip from '@mui/material/Tooltip';
import {Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {RenewalExtendProps} from '@components/ShoppingCart/interface';
import {ENDORSEMENTS} from '@components/ShoppingCart/constants';
import helpIcon from '@images/help.svg';
import RenewalForm from '@components/ShoppingCart/RenewalMenuOption/RenewalForm';

const RenewalExtend: React.FC<RenewalExtendProps> = ({endorsement, onExit}) => {
    return (
        <>
            <FlexPanel
                Padding="0.5rem 1rem"
                Align="flex-start"
                Justify="flex-start"
                Self="stretch"
                Gap="1rem"
                Border="1px solid #E8E9EA"
                Background="#FFFFFF"
            >
                <FlexPanel FlexDir="column" Align="flex-start" Gap="0.25rem">
                    <Text
                        Align="center"
                        Gap="0.25rem"
                        Color="#1D1E20"
                        FontSize="1rem"
                        FontWeight="700"
                        LetterSpacing="0.019rem"
                    >
                        {ENDORSEMENTS.NEXT_PAYMENT_DATE}
                    </Text>
                    <FlexPanel
                        Height="2.5rem"
                        Padding="0.5rem 0"
                        Align="center"
                        Gap="1rem"
                        Self="stretch"
                        Justify="flex-start"
                    >
                        <Text
                            Color="#2A2C2F"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                            Width="100%"
                        >
                            {endorsement.extension?.nextPaymentDate
                                ? useFormatDate(endorsement.extension.nextPaymentDate)
                                : useFormatDate(endorsement.nextPaymentDate)}
                        </Text>
                        <Tooltip title={ENDORSEMENTS.NEXTPAYMENT_TOOLTIP}>
                            <Image
                                src={helpIcon}
                                alt="help"
                                Cursor="pointer"
                                Width="1.5rem"
                                Height="1.5rem"
                            />
                        </Tooltip>
                    </FlexPanel>
                </FlexPanel>
            </FlexPanel>
            <RenewalForm endorsement={endorsement} onFinish={onExit} />
        </>
    );
};
export default RenewalExtend;
