import React, {useState, useCallback, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    TableContainer,
    TableHeaderRow,
    TableBody,
    TableCell,
} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image} from '@EcommerceShopify/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    PAWNBROKER_LIST,
    RESIZE,
    SORT_IDENTIFIERS,
    Sorting,
} from '@components/InventoryManagement/constants';
import {InventoryProductsListProps} from '@components/InventoryManagement/interfaces';
import {InventoryProductItem} from '@components/InventoryManagement/Redux/interfaces';
import InventoryTableRow from '@components/InventoryManagement/InventoryProductListRow';
import ListColumnHeader from '@components/InventoryManagement/ListColumnHeader';
import sortIcon from '@images/ListSortArrows.svg';
import sortAscIcon from '@images/ListSortArrowsAsc.svg';
import sortDescIcon from '@images/ListSortArrowsDesc.svg';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';

const InventoryProductsList = ({inventory, onPageChange}: InventoryProductsListProps) => {
    const productsData = useSelector((state: RootState) => {
        return state.GetProductsForInventory.data;
    });
    const inventoryProducts = productsData?.data;
    const [openOption, setOpenOption] = useState<InventoryProductItem | null>(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [inventoryData, setIventoryData] = useState(inventoryProducts.items);

    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState('');

    const [isSmallView, setIsSmallView] = useState(false);

    const handleResize = () => {
        setIsSmallView(window.innerWidth <= 670);
    };

    useEffect(() => {
        window.addEventListener(RESIZE, handleResize);
        handleResize();

        return () => {
            window.removeEventListener(RESIZE, handleResize);
        };
    }, []);

    const handleSort = useCallback(
        (column: string) => {
            const newDirection =
                sortColumn === column && sortDirection === Sorting.Asc ? Sorting.Desc : Sorting.Asc;
            setSortColumn(column);
            setSortDirection(newDirection);
        },
        [sortColumn, sortDirection]
    );

    const renderColSortIcon = useCallback(
        (column: string) => {
            const isActive = sortColumn === column;
            const iconMap = {
                [Sorting.Asc]: sortAscIcon,
                [Sorting.Desc]: sortDescIcon,
            };
            const icon = isActive ? iconMap[sortDirection] : sortIcon;

            return (
                <Image
                    src={icon}
                    alt={`sort-${isActive ? sortDirection : ''}`}
                    Width="0.875rem"
                    Height="0.875rem"
                    Cursor="pointer"
                    onClick={() => handleSort(column)}
                />
            );
        },
        [sortColumn, sortDirection, handleSort]
    );

    const handleSelectAll = () => {
        setIsAllSelected((prevIsAllSelected) => {
            const newIsAllSelected = !prevIsAllSelected;
            return newIsAllSelected;
        });
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => ({
                ...item,
                selected: !isAllSelected,
            }));
            return newInventoryData;
        });
    };

    const handleItemSelect = (id: number) => {
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => {
                if (item.itemId === id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            });
            const allSelected = newInventoryData.every((item) => item.selected);
            setIsAllSelected(allSelected);
            return newInventoryData;
        });
    };

    const handlePageChange = (newPage: number) => {
        if (onPageChange) onPageChange(newPage);
    };

    const handleOptionClick = (item: InventoryProductItem) =>
        setOpenOption((prevItem) => (prevItem === item ? null : item));

    useEffect(() => {
        if (inventoryData) {
            const allSelected = inventoryData.every((item) => item.selected);
            setIsAllSelected(allSelected);
        }
    }, [inventoryData]);

    return (
        <ContainerFlex
            FlexDir="column"
            Padding="1.5rem"
            Justify="start"
            Align="flex-start"
            Radius="1.5rem"
            Gap="1rem"
            backG="#FFF"
        >
            <TableContainer>
                <TableHeaderRow
                    borderBottom="1px solid #E8E9EA"
                    gridColumns="1.125rem 15.625rem repeat(4, minmax(5rem, 1fr)) 1.5rem"
                    gap="1rem"
                >
                    <TableCell className="mediaPersist">
                        <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
                    </TableCell>
                    {isSmallView ? null : (
                        <>
                            <ListColumnHeader label={PAWNBROKER_LIST.PRODUCT} />
                            <ListColumnHeader
                                label={PAWNBROKER_LIST.LOAN}
                                renderSortIcon={() =>
                                    renderColSortIcon(SORT_IDENTIFIERS.loanAmount)
                                }
                            />
                            <ListColumnHeader label={PAWNBROKER_LIST.CONTRACT} />
                            <ListColumnHeader label={PAWNBROKER_LIST.EXPIRYDATE} />
                            <ListColumnHeader label={PAWNBROKER_LIST.CONTARCT_STATUS} />
                            <TableCell />
                        </>
                    )}
                </TableHeaderRow>
                <TableBody overflowY="scroll">
                    {inventoryData &&
                        inventoryData.length > 0 &&
                        inventoryData.map((inventory) => (
                            <InventoryTableRow
                                key={inventory.itemId}
                                inventory={inventory}
                                handleItemSelect={handleItemSelect}
                                handleItemMenuClick={handleOptionClick}
                                isMenuOpen={openOption === inventory}
                            />
                        ))}
                </TableBody>
            </TableContainer>
            {productsData && productsData.totalItems >= productsData.pageSize && (
                <ContainerFlex Gap="0.5rem" Height="2.5rem">
                    <Pagination
                        pageNumber={productsData.pageNumber ?? 0}
                        totalPages={productsData.totalPages ?? 1}
                        totalItems={productsData.totalItems ?? 0}
                        pageSize={productsData.pageSize ?? 0}
                        onPageChange={productsData.totalItems > 0 ? handlePageChange : () => {}}
                    />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default InventoryProductsList;
