export const CREATE_ACCOUNT_DESCRIPTION =
    'Crea una cuenta o inicia sesión en Ataskate y encuentra todas las opciones de préstamos personalizadas.';

export const PERSONAL_SALES_BANNER = {
    TITLE: 'Ofertas personalizadas',
    DESCRIPTION:
        'Crea una cuenta en Ataskate o inicia sesión y encuentra todas las opciones de préstamos personalizadas que tenemos para ti.',
    BTN_LOGIN: 'Ingresar',
};

export const OFFER_CREDITS_BANNER = {
    TITLE: '¿Quieres ofrecer créditos?',
    DESCRIPTION: 'Se parte de Ataskate Créditos y empieza a ofrecer tus servicios',
    OFFER_BTN: 'Ofrecer créditos',
};
