import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerGrid, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';
import ageIcon from '@/components/CreditQuoter/icons/AgeIcon.svg';
import depositMoneyIcon from '@/components/CreditQuoter/icons/depositMoneyIcon.svg';
import paymentMethodIcon from '@/components/CreditQuoter/icons/paymentMethodIcon.svg';
import {ITypeMethod, IDisbursement} from '@components/CreditQuoter/interfaces';

const RequirementsQuoter = () => {
    const QuoterCompaniesLoans = useSelector((state: RootState) => {
        return state.GetQuoterCompaniesLoansAditionalInf?.dataQuoter;
    });
    return (
        <ContainerFlex
            Width="100%"
            Height="300px"
            Padding="40px 60px"
            backG="#fff"
            Gap="32px"
            Justify="center"
            Align="center"
            FlexDir="column"
        >
            <Text FontWeight="500" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.25rem">
                {CREDIT_QUOTER.REQUIREMENTS.TITLE}
            </Text>
            <ContainerGrid Width="100%" Height="152px" GridColumns="repeat(3,1fr)">
                <ContainerFlex FlexDir="column" Justify="start" Align="center" Gap="16px">
                    <ContainerFlex Width="68px" Justify="center" Align="center">
                        <Image
                            Radius="50%"
                            Width="68px"
                            Height="68px"
                            src={ageIcon}
                            Background="#D0F6FF"
                            alt="eighteen-plus-icon"
                        />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {CREDIT_QUOTER.REQUIREMENTS.AGE_TITLE}
                        </Text>
                        {QuoterCompaniesLoans?.ageMin !== null &&
                            QuoterCompaniesLoans?.ageMax !== null && (
                                <Text
                                    FontWeight="400"
                                    Color="#2A2C2F"
                                    FontFamily="Nunito"
                                    FontSize="1.0rem"
                                >
                                    {`${CREDIT_QUOTER.REQUIREMENTS.AGE_SUBTITLE} ${QuoterCompaniesLoans?.ageMin} ${CREDIT_QUOTER.REQUIREMENTS.AGE_EVELYN} ${QuoterCompaniesLoans?.ageMax} ${CREDIT_QUOTER.REQUIREMENTS.AGE_TIME}`}
                                </Text>
                            )}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="center"
                    Align="center"
                    Br="1px solid #E8E9EA"
                    Bl="1px solid #E8E9EA"
                    Gap="16px"
                >
                    <ContainerFlex Width="68px" Justify="center" Align="center">
                        <Image
                            Radius="50%"
                            Width="68px"
                            Height="68px"
                            src={paymentMethodIcon}
                            Background="#D0F6FF"
                            alt="coins-icon"
                        />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {CREDIT_QUOTER.REQUIREMENTS.PAYMENT_METHOD_TITLE}
                        </Text>
                        <ContainerFlex FlexWrap="wrap" Gap="4px">
                            {QuoterCompaniesLoans?.typeMethods?.map(
                                (item: ITypeMethod, index: number) => (
                                    <Text
                                        FontWeight="400"
                                        Color="#2A2C2F"
                                        FontFamily="Nunito"
                                        FontSize="1.0rem"
                                        key={index}
                                    >
                                        {item.namePayment}
                                        {index <
                                            QuoterCompaniesLoans.typeMethods.length -
                                                CREDIT_QUOTER.REQUIREMENTS.ONE &&
                                            CREDIT_QUOTER.REQUIREMENTS.SPACE}
                                    </Text>
                                )
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="16px">
                    <ContainerFlex Width="68px" Justify="center" Align="center">
                        <Image
                            Radius="50%"
                            Width="68px"
                            Height="68px"
                            src={depositMoneyIcon}
                            Background="#D0F6FF"
                            alt="cash-icon"
                        />
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {CREDIT_QUOTER.REQUIREMENTS.DEPOSIT_TITLE}
                        </Text>
                        <ContainerFlex FlexWrap="wrap" Gap="4px">
                            {QuoterCompaniesLoans?.disbursements?.map(
                                (item: IDisbursement, index: number) => (
                                    <Text
                                        FontWeight="400"
                                        Color="#2A2C2F"
                                        FontFamily="Nunito"
                                        FontSize="1.0rem"
                                        key={index}
                                    >
                                        {item.disbursementName}
                                        {index <
                                            QuoterCompaniesLoans.disbursements.length -
                                                CREDIT_QUOTER.REQUIREMENTS.ONE &&
                                            CREDIT_QUOTER.REQUIREMENTS.SPACE}
                                    </Text>
                                )
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerGrid>
        </ContainerFlex>
    );
};

export default RequirementsQuoter;
