import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {GetTreeUser} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/GetBranchTreeAction';
import {SearchFilterAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/FilterOfUser';
import {BranchAssignmentTree} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignmentTree';
import {
    BranchItem,
    SearchItem,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import BranchTable from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchTable';
import arrow from '@images/ArrowLeftIcone.svg';
import GetStoragesAction from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/GetStoragesAction';
import {IUserBranchAssignment} from '@components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';
import {getLevelTreeBranch} from '@components/NewUserEmployee/Redux/Actions/GetLevelTreeBranchActions';

export const UserBranchAssignment = ({
    checkedStorages,
    setCheckedStorages,
    checkedOptions,
    setCheckedOptions,
    isCreate,
}: IUserBranchAssignment) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const Loadings = useSelector((state: RootState) => state.UserProfileAdmin.loading);
    const companyId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.companyId
    );
    const isloading = useSelector((state: RootState) => state.GetLevelBranchUser.loading);
    const [branchData, setBranchData] = useState<BranchItem[]>([]);
    const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
    const [showTable, setShowTable] = useState(true);

    const handleSearchResult = useCallback((results: SearchItem[]) => {
        setSearchResults(results);
    }, []);

    useEffect(() => {
        if (token && employeeId && companyId) {
            dispatch(GetTreeUser(token, employeeId));
            dispatch(GetStoragesAction(employeeId, token));
            dispatch(getLevelTreeBranch(token, companyId));
        }
    }, []);

    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <ContainerFlex Justify="start">
                <Text FontSize="1.5rem" FontWeight="700">
                    {BRANCH_ASSIGNMENT.TITLE}
                </Text>
            </ContainerFlex>

            {isloading || Loadings ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex
                        FlexDir="column"
                        Padding="16px"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Gap="16px"
                        Radius="16px"
                    >
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                                {BRANCH_ASSIGNMENT.TITLE_TWO}
                            </Text>
                            <Text FontWeight="400" Color="#2A2C2F">
                                {BRANCH_ASSIGNMENT.INDICATION}
                            </Text>
                        </ContainerFlex>

                        <SearchFilterAssignment onSearchResult={handleSearchResult} />

                        <BranchAssignmentTree
                            checkedOptions={checkedOptions}
                            setCheckedOptions={setCheckedOptions}
                            setBranchData={setBranchData}
                            searchResults={searchResults}
                            checkedStorages={checkedStorages}
                            setCheckedStorages={setCheckedStorages}
                            isCreate={isCreate}
                        />
                        <ContainerFlex onClick={() => setShowTable(!showTable)} Cursor="pointer">
                            <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700">
                                {BRANCH_ASSIGNMENT.OPEN_TABLE}
                            </Text>
                            <Image
                                src={arrow}
                                alt="open-icon"
                                Transform={showTable ? 'rotate(0.25turn)' : 'rotate(0.75turn)'}
                            />
                        </ContainerFlex>
                        {showTable && (
                            <BranchTable
                                checkedOptions={checkedOptions}
                                setCheckedOptions={setCheckedOptions}
                                branchData={branchData}
                                checkedStorages={checkedStorages}
                                setCheckedStorages={setCheckedStorages}
                                isCreate={isCreate}
                            />
                        )}
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};
