import {
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,
} from '@components/SignUp/Redux/types';

const initialState = {
    loading: false,
    error: null,
    data: null,
    succeeded: false,
    message: null,
    token: null,
    tokenExpiration: null,
};

export const verifyCodeAdmin = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_CODE_REQUEST:
            return {...state, loading: true, error: null};
        case VERIFY_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                succeeded: action.payload.succeeded,
                message: action.payload.message,
                data: action.payload.data,
                token: action.payload.token,
                tokenExpiration: action.payload.tokenExpiration,
            };
        case VERIFY_CODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errors || action.payload.message,
                message: null,
            };
        default:
            return state;
    }
};
