import React from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import LoanPaymentInfo from '@components/GlobalQuoter/LoanPaymentInfo';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import arrowRBlue from '@images/arrowRBlue.svg';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {IMAGE_ALT, QUOTE_DATA_LABELS} from '@/components/GlobalQuoter/TempConstants';
import {createRequestLoan} from '@/components/PersonalLoans/Redux/Actions/PersonalLoanRquestSummaryActions';
import {useNavigate} from 'react-router-dom';
import {ICompaniesOffert, IQuoterProps} from '@/components/GlobalQuoter/interface';
import {routers} from '@/appRoute';
import CompanyAvatarDefault from '@/components/CreditQuoter/CompanyAvatarDefault';

const QuoterData: React.FC<IQuoterProps> = ({isExpressQuoter, isPublicQuoter}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const {quoterCompleteList, filterList, error} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );
    const {parsedAmount, accountPayments} = useSelector(
        (state: RootState) => state.globalQuoterFormReducer
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const activeLoansList = filterList.length > 0 ? filterList : quoterCompleteList;
    const scrollToSectionLoanList = () => {
        const section = document.getElementById(QUOTE_DATA_LABELS.SCROLL_ID);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };
    const logoContainer = quoterCompleteList
        .slice(0, 3)
        .map((companyOffer: ICompaniesOffert, index: number) => {
            return companyOffer.companyLogo ? (
                <Image
                    key={index}
                    src={companyOffer.companyLogo}
                    alt={IMAGE_ALT.SMALL_CIRCLE_LOGO}
                    Margin={`0px 0px 0px ${index === 0 ? '0px' : '-16px'}`}
                    Cursor="pointer"
                    Width="28px"
                    Height="28px"
                    Radius="50%"
                />
            ) : (
                <CompanyAvatarDefault
                    companyName={companyOffer.companyName}
                    color={companyOffer.companyAvatarColor}
                    Width="28px"
                    Height="28px"
                    Margin={`0px 0px 0px ${index === 0 ? '0px' : '-16px'}`}
                />
            );
        });

    const onApplyNow = () => {
        const firstCompany = activeLoansList[0];
        if (token && accountPayments && parsedAmount && firstCompany) {
            const data = {
                amountRequested: parsedAmount,
                frequencyTermId: firstCompany.frequencyTermCreditId,
                periodAcount: accountPayments,
                companyId: firstCompany.companyId,
            };
            dispatch(createRequestLoan(token, data, navigate));
        }
    };

    const handlerRequest = () => {
        if (token) {
            onApplyNow();
        } else {
            navigate(`login${routers.AccountContainerLogin}`);
        }
    };

    const handlerLoans = () => {
        if (isExpressQuoter) {
            navigate(routers.LoanQuoter);
        } else {
            scrollToSectionLoanList();
        }
    };

    return (
        <ContainerFlex
            Width="100%"
            MinW={isExpressQuoter ? '362px' : '490px'}
            backG="#FAFAFA"
            Radius="16px"
            Padding={isExpressQuoter ? '16px' : '24px'}
            Gap="16px"
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
        >
            <LoanPaymentInfo isExpressQuoter={isExpressQuoter} isPublicQuoter={isPublicQuoter} />
            {!error && (
                <>
                    <ButtonGenerals
                        Padding="8px 16px"
                        Radius="32px"
                        HBackG="none"
                        Width="100%"
                        MHeight="48px"
                        Cursor="pointer"
                        onClick={handlerRequest}
                    >
                        {QUOTE_DATA_LABELS.REQUEST_NOW}
                    </ButtonGenerals>
                    <ButtonGenerals
                        Padding="8px 16px"
                        Radius="32px"
                        HBackG="none"
                        Width="100%"
                        BackGC="#F0F0FF"
                        MHeight="44px"
                        onClick={handlerLoans}
                        Cursor="pointer"
                        disabled={quoterCompleteList.length === 0}
                    >
                        <ContainerFlex Gap="8px" Cursor="pointer">
                            <ContainerFlex
                                Width="64px"
                                Justify="flex-start"
                                Align="flex-start"
                                Cursor="pointer"
                            >
                                {logoContainer}
                                {quoterCompleteList.length > 3 && (
                                    <ContainerFlex
                                        Margin="0px 0px 0px -16px"
                                        Border="0.5px solid #E8E9EA"
                                        Radius="100px"
                                        backG="#FFFFFF"
                                        Width="28px"
                                        Height="28px"
                                        ZIndex="1"
                                        Cursor="pointer"
                                    >
                                        <Text
                                            Color="#54575C"
                                            FontFamily="Nunito"
                                            FontSize="0.875rem"
                                            Cursor="pointer"
                                        >
                                            {quoterCompleteList.length - 3}+
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                            <Text
                                Color="#5A5AFF"
                                FontWeight="700"
                                FontFamily="Nunito"
                                LetterSpacing="0.3px"
                                Cursor="pointer"
                            >
                                {QUOTE_DATA_LABELS.SEE_LOANS}
                            </Text>
                            <Image
                                src={arrowRBlue}
                                Transform={`rotate(${isExpressQuoter ? '180' : '270'}deg)`}
                                alt={IMAGE_ALT.DOWN_ARROW}
                                Cursor="pointer"
                            />
                        </ContainerFlex>
                    </ButtonGenerals>
                </>
            )}
        </ContainerFlex>
    );
};

export default QuoterData;
