import {
    RESET_VISUAL_FORM_VALUES,
    SET_VISUAL_FORM_VALUES,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {IVisualGlobalQuoterForm} from '@/components/GlobalQuoter/interface';

export const setVisualFormValues = (payload: IVisualGlobalQuoterForm) => {
    return {
        type: SET_VISUAL_FORM_VALUES,
        payload,
    };
};

export const resetVisualFormValues = () => {
    return {
        type: RESET_VISUAL_FORM_VALUES,
    };
};
