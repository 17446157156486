import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {ModalActionProps} from '@components/LoansProducts/interfaces';
import {
    LOAN_PRODUCT_PER_PAGE,
    MODALINACTIVE,
    STATUS_PRODUCTS,
} from '@components/LoansProducts/constants';
import {ActiveDesactiveTermCreditStatus} from '@components/LoansProducts/Redux/Actions/ActiveDesactiveTermCredit';
import {getProductsCustomerUser} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import {getAllTotalAvailables} from '@components/LoansProducts/Redux/Actions/getPersonalCreditProductsActions';

export const ModalActionLoans = ({setShowActionModal}: ModalActionProps) => {
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const enterpriceId = useSelector((state: RootState) => {
        return Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId);
    });
    const {selectedCredit, errorData} = useSelector((state: RootState) => {
        return state.ActiveDesactiveTermCredit;
    });
    const {activeStatus, page} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const dispatch = useDispatch();

    const InactiveTermCredits = async () => {
        try {
            if (selectedCredit) {
                const newStatus =
                    selectedCredit.active === STATUS_PRODUCTS.ACTIVE
                        ? STATUS_PRODUCTS.INACTIVE
                        : STATUS_PRODUCTS.ACTIVE;

                await dispatch(
                    ActiveDesactiveTermCreditStatus(
                        {termCreditId: selectedCredit?.termId, statusTermId: newStatus},
                        token,
                        setShowActionModal
                    )
                );

                await dispatch(
                    getProductsCustomerUser(
                        token,
                        enterpriceId,
                        activeStatus,
                        LOAN_PRODUCT_PER_PAGE,
                        page
                    )
                );
                await dispatch(getAllTotalAvailables(token, enterpriceId));
            }
        } catch (error) {
            return errorData;
        }
    };

    return (
        <Modal Justify="center" Align="center">
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Width="600px"
                Height="256px"
                Padding="1.5rem"
                Border="1px solid #E8E9EA"
                backG="#FFFFFF"
                Radius="1.5rem"
                Gap="1.5rem"
            >
                <Text
                    Color="#2A2C2F"
                    FontSize="1.5rem"
                    FontWeight="700"
                    oFlow="normal"
                    wSpace="normal"
                >
                    {MODALINACTIVE.TITLE}
                </Text>
                <Text Color="#000000" oFlow="normal" wSpace="normal">
                    {MODALINACTIVE.DESCRIPTION}
                </Text>
                <ContainerFlex
                    AlignContent="end"
                    Justify="center"
                    Gap="1rem"
                    Height="2.5rem"
                    MarginTop="0.75rem"
                >
                    <ButtonGenerals
                        Width="11.938rem"
                        SecondaryButton="#FFFFFF"
                        FontWeight="700"
                        type="button"
                        onClick={() => {
                            setShowActionModal(false);
                        }}
                    >
                        {MODALINACTIVE.CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals
                        Width="11.938rem"
                        FontWeight="700"
                        type="button"
                        onClick={async () => {
                            await InactiveTermCredits();
                        }}
                    >
                        {MODALINACTIVE.INACTIVE}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
