import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import ProductSpecifications from '@components/InventoryManagement/ProductSpecifications';
import ProductHeader from '@components/InventoryManagement/ProductHeader';
import ProductAccesories from '@components/InventoryManagement/ProductAccesories';
import ProductReviews from '@components/InventoryManagement/ProductReviews';
import ProductPrice from '@components/InventoryManagement/ProductPrice';
import ProductDescription from '@components/InventoryManagement/ProductDescription';
import ProductCharacteristics from '@components/InventoryManagement/ProductCharacteristics';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';

const ProductPaigeDescription: React.FC = () => {
    return (
        <ContainerFlex Align="start" Justify="start" Gap="1.5rem" FlexDir="Column">
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="5rem"
                FlexDir="Column"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
            >
                <ProductHeader />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="3.75rem"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
            >
                <ProductReviews />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="3.875rem"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
            >
                <ProductPrice />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="1.5rem"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
            >
                <ProductCharacteristics />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="2rem"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
            >
                <ButtonGeneral
                    color="#5A5AFF"
                    backGround="#FFFFFF"
                    hText="#5A5AFF"
                    cursor="pointer"
                    border="1px solid #5A5AFF"
                    hBackG="#E5E5FF"
                    width="100%"
                    height="2rem"
                    text={
                        <>
                            <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="1rem">
                                {INVENTORY_PRODUCT_DETAIL.ADD_TO_CART}
                            </Text>
                        </>
                    }
                />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Bt="1px solid #E8E9EA"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
                PaddingT="1.5rem"
            >
                <ProductSpecifications />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Bt="1px solid #E8E9EA"
                PaddingL="1.5rem"
                PaddingR="1.5rem"
                PaddingT="1.5rem"
            >
                <ProductAccesories />
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Bt="1px solid #E8E9EA"
                Bb="1px solid #E8E9EA"
            >
                <ProductDescription />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ProductPaigeDescription;
