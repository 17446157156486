import {
    BranchStorageState,
    GetBranchStorageActionPayload,
} from '@components/InventoryManagement/interfaces';
import {
    GET_BRANCH_STORAGES,
    GET_BRANCH_STORAGES_ERROR,
    GET_BRANCH_STORAGES_SUCCESS,
} from '@components/InventoryManagement/Redux/types';

const initialState: BranchStorageState = {
    storages: [],
    error: null,
    loading: false,
};

export const getBranchStorages = (
    state = initialState,
    action: GetBranchStorageActionPayload
): BranchStorageState => {
    switch (action.type) {
        case GET_BRANCH_STORAGES:
            return {...state, loading: true, error: null};
        case GET_BRANCH_STORAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                storages: action.response.data,
            };
        case GET_BRANCH_STORAGES_ERROR:
            return {...state, loading: false, error: action.error};
        default:
            return state;
    }
};

export default getBranchStorages;
