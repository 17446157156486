import React, {useState} from 'react';
import {ClickAwayListener} from '@mui/material';

import {Text, Input} from '@EcommerceShopify/styles';
import {INVENTORY_PAGE, PRICE_RANGES} from '@components/InventoryManagement/constants';
import {PriceInputDropdownProps, PriceRange} from '@components/InventoryManagement/interfaces';
import PriceRangeFilter from '@components/InventoryManagement/PriceRangeFilter';
import {
    renewalMenuStyles,
    Dropdown,
    Container,
    DropdownContainer,
} from '@components/InventoryManagement/styles';

const PriceInputDropdown = ({onPriceRangeSelect}: PriceInputDropdownProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleInputClick = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const priceRangeMap: Record<string, {from: number | null; to: number | null}> = {
        [PRICE_RANGES.TO_500]: {from: 0, to: PRICE_RANGES.TO_500_VALUE},
        [PRICE_RANGES.FROM_501_TO_1000]: {
            from: PRICE_RANGES.FROM_501_VALUE,
            to: PRICE_RANGES.TO_1000_VALUE,
        },
        [PRICE_RANGES.MORE_THAN_1000]: {from: PRICE_RANGES.FROM_1001_VALUE, to: null},
    };

    const handleOptionClick = (option: string, customRange?: PriceRange) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);

        if (customRange) {
            const type = customRange.to === null ? PRICE_RANGES.EQUAL_OR_MORE_THAN : option;
            onPriceRangeSelect({...customRange, type});
        } else {
            const range = priceRangeMap[option] || {from: null, to: null};
            onPriceRangeSelect({...range, type: option});
        }
    };

    const priceRanges = [
        PRICE_RANGES.TO_500,
        PRICE_RANGES.FROM_501_TO_1000,
        PRICE_RANGES.MORE_THAN_1000,
    ];

    return (
        <Container>
            <Input
                type="text"
                readOnly
                value={selectedOption || ''}
                placeholder={INVENTORY_PAGE.PRICE}
                onClick={handleInputClick}
            />
            <DropdownContainer>
                {isDropdownOpen && (
                    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
                        <Dropdown>
                            {priceRanges.map((range) => (
                                <Text
                                    key={range}
                                    {...renewalMenuStyles}
                                    onClick={() => handleOptionClick(range)}
                                >
                                    {range}
                                </Text>
                            ))}
                            <PriceRangeFilter onSelect={handleOptionClick} />
                        </Dropdown>
                    </ClickAwayListener>
                )}
            </DropdownContainer>
        </Container>
    );
};

export default PriceInputDropdown;
