import React, {useEffect, useState, useRef} from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    SelectStyled,
    ChipStyled,
    DropdownContainer,
    SelectAllWrapper,
    SelectAllText,
    DropdownOption,
    SelectScoreStyle,
    ChipText,
} from '@components/PersonalLoans/NewLoanProduct/StyledScore';
import ArrowSmall from '@images/chevronBottom.svg';
import Close from '@images/closeDark.svg';
import CheckboxScore from '@components/PersonalLoans/NewLoanProduct/CheckboxScore';
import {COLORMAP, ROWLIST, colorMap} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ICreditScoreSelectProps} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';

const DropdownIndicator = ({onClick}: {onClick: () => void}) => (
    <Image src={ArrowSmall} onClick={onClick} Cursor="pointer" Width="24px" Height="24px" />
);

const CreditScore: React.FC<ICreditScoreSelectProps> = ({
    control,
    resetField,
    initialSelectedRows,
}) => {
    const [selectedRows, setSelectedRows] =
        useState<{id: number; name: string; description: string}[]>(initialSelectedRows);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const getColor = (id: number): string => {
        return colorMap[id] || COLORMAP.DEFAULT;
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedRows(ROWLIST);
        } else {
            setSelectedRows([]);
        }
    };

    const isAllSelected = ROWLIST.length > 0 && selectedRows.length === ROWLIST.length;
    const isSomeSelected = selectedRows.length > 0 && selectedRows.length < ROWLIST.length;

    useEffect(() => {
        resetField('creditScore', {defaultValue: selectedRows});
    }, [selectedRows]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <Controller
            name="creditScore"
            control={control}
            render={({field: {onChange, value, ...field}}) => (
                <SelectScoreStyle>
                    <SelectStyled>
                        <ContainerFlex
                            Justify="start"
                            Height="32px"
                            Gap="8px"
                            FlexWrap="wrap"
                            OverFlowY="scroll"
                        >
                            {selectedRows.map((row) => (
                                <ChipStyled key={row.id}>
                                    <ChipText>{row.name}</ChipText>
                                    <Image
                                        src={Close}
                                        alt="close-icon"
                                        width="20px"
                                        height="20px"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSelectedRows(
                                                selectedRows.filter(
                                                    (selectedRow) => selectedRow.id !== row.id
                                                )
                                            );
                                        }}
                                    />
                                </ChipStyled>
                            ))}
                        </ContainerFlex>
                        <DropdownIndicator onClick={toggleDropdown} />
                    </SelectStyled>
                    {isDropdownOpen && (
                        <DropdownContainer ref={dropdownRef}>
                            <SelectAllWrapper>
                                <CheckboxScore
                                    checked={isAllSelected}
                                    indeterminate={isSomeSelected}
                                    onChange={handleSelectAllClick}
                                />
                                <SelectAllText>Todos los clientes</SelectAllText>
                            </SelectAllWrapper>
                            {ROWLIST.map((row) => (
                                <DropdownOption key={row.id}>
                                    <ContainerFlex Width="16px" />
                                    <CheckboxScore
                                        checked={selectedRows.some(
                                            (selectedRow) => selectedRow.id === row.id
                                        )}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedRows([...selectedRows, row]);
                                            } else {
                                                setSelectedRows(
                                                    selectedRows.filter(
                                                        (selectedRow) => selectedRow.id !== row.id
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                    <ContainerFlex Justify="start" Align="start" FlexDir="column">
                                        <Text FontWeight="700" Color={getColor(row.id)}>
                                            {row.name}
                                        </Text>
                                        <Text FontSize="0.75rem" Color={getColor(row.id)}>
                                            {row.description}
                                        </Text>
                                    </ContainerFlex>
                                </DropdownOption>
                            ))}
                        </DropdownContainer>
                    )}
                </SelectScoreStyle>
            )}
        />
    );
};

export default CreditScore;
