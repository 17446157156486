import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    QUOTER_OTHER_LOANS_COMPANIES_START,
    QUOTER_OTHER_LOANS_COMPANIES_ERROR,
    QUOTER_OTHER_LOANS_COMPANIES_SUCCESS,
} from '@components/CreditQuoter/Redux/Types/Types';
import StringUtils from '@/GenericScripts/utils';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';

export const fetchQuoterOthersLoansCompaniesStart = () => {
    return {
        type: QUOTER_OTHER_LOANS_COMPANIES_START,
    };
};

export const fetchQuoterOthersLoansCompaniesSuccess = (result: AxiosResponse) => {
    return {
        type: QUOTER_OTHER_LOANS_COMPANIES_SUCCESS,
        payload: result.data,
    };
};

export const fetchQuoterOthersLoansCompaniesError = (error: AxiosError) => {
    return {
        type: QUOTER_OTHER_LOANS_COMPANIES_ERROR,
        error,
    };
};

export const GetQuoterOthersLoansCompaniesList = (
    token: string | undefined,
    filters: {companyId: number}
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchQuoterOthersLoansCompaniesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetQuoterOthersLoansCompanies}${queryString}`,
                {headers}
            );
            dispatch(fetchQuoterOthersLoansCompaniesSuccess(response));
        } catch (error) {
            dispatch(fetchQuoterOthersLoansCompaniesError(error as AxiosError));
        }
    };
};
