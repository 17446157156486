import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    POST_SEND_PASSWORD_EMAIL,
    POST_SEND_PASSWORD_EMAIL_SUCCESS,
    POST_SEND_PASSWORD_EMAIL_ERROR,
} from '@components/UserProfileResetPassword/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const PostSendPasswordEmail = () => {
    return {
        type: POST_SEND_PASSWORD_EMAIL,
    };
};

export const PostSendPasswordEmailSuccess = (result: AxiosResponse) => {
    return {
        type: POST_SEND_PASSWORD_EMAIL_SUCCESS,
        payload: result,
    };
};

export const PostSendPasswordEmailError = (error: AxiosError) => {
    return {
        type: POST_SEND_PASSWORD_EMAIL_ERROR,
        payload: error,
    };
};

export const PostSendPasswordEmailAction = (data: {Email: string}, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(PostSendPasswordEmail());
        const params = {
            Email: data.Email.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostSendPasswordEmail}?${queryString}`;
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(PostSendPasswordEmailSuccess(response));
        } catch (error) {
            dispatch(PostSendPasswordEmailError(error as AxiosError));
        }
    };
};

export default PostSendPasswordEmailAction;
