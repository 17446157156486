export const PASSWORD_USER_REQUEST = 'PASSWORD_USER_REQUEST';
export const PASSWORD_USER_SUCCESS = 'PASSWORD_USER_SUCCESS';
export const PASSWORD_USER_ERROR = 'PASSWORD_USER_ERROR';

export const AUTO_PASSWORD_USER_REQUEST = 'AUTO_PASSWORD_USER_REQUEST ';
export const AUTO_PASSWORD_USER_SUCCESS = 'AUTO_PASSWORD_USER_SUCCESS';
export const AUTO_PASSWORD_USER_ERROR = 'AUTO_PASSWORD_USER_ERROR';

export const POST_SEND_PASSWORD_EMAIL = 'POST_SEND_PASSWORD_EMAIL';
export const POST_SEND_PASSWORD_EMAIL_SUCCESS = 'POST_SEND_PASSWORD_EMAIL_SUCCESS';
export const POST_SEND_PASSWORD_EMAIL_ERROR = 'POST_SEND_PASSWORD_EMAIL_ERROR';
