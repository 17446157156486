import React, {useState, useEffect} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@/components/Quoter/styles';
import {useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {getAllNation} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/getAllNationsActions';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {SelectLadaProps} from '@components/Quoter/CoownerBeneficiary/interface';

export const SelectLada: React.FC<SelectLadaProps> = ({
    handleNationSelected,
    withoutToken = false,
    selectedLada,
    haveError = false,
}) => {
    const [showList, setShowList] = useState(false);
    const [nationSelected, setNationSelected] = useState<IGetAllNations>();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const getAllNationsData: IGetAllNations[] = useSelector(
        (state: RootState) => state.getAllNations.data
    );

    const getAllNations = () => {
        if (withoutToken) {
            dispatch(getAllNation());
        } else {
            dispatch(getAllNation(token));
        }
    };
    useEffect(() => {
        if (getAllNationsData.length === 0) getAllNations();
        if (selectedLada) {
            const selectedNation = getAllNationsData.find((nation) => nation.lada === selectedLada);
            setNationSelected(selectedNation);
            handleNationSelected(selectedNation!);
        }
    }, [selectedLada]);

    const NationsList = () => {
        setShowList(!showList);
    };

    const selectNation = (nation: IGetAllNations) => {
        setNationSelected(nation);
        setShowList(false);
        handleNationSelected(nation);
    };
    useEffect(() => {
        const selectedNation = getAllNationsData.find((nation) => nation.lada === '+52');
        if (selectedNation) {
            setNationSelected(selectedNation);
            handleNationSelected(selectedNation);
        }
    }, [getAllNationsData]);

    return (
        <ContainerFlex backG="#FFF" FlexDir="column" Align="start" Width="auto" Position="relative">
            <ContainerFlex FlexDir="column">
                <ContainerFlex
                    Gap="0.5rem"
                    Radius="32px 0px 0px 32px"
                    backG="#F4F5F5"
                    Width="auto"
                    Padding={haveError ? '6px' : '0.5rem'}
                    Height="auto"
                    Bt={haveError ? 'solid 1px #A82424' : 'none'}
                    Bl={haveError ? 'solid 1px #A82424' : 'none'}
                    Bb={haveError ? 'solid 1px #A82424' : 'none'}
                >
                    <Image
                        Radius="50%"
                        width="28px"
                        height="28px"
                        src={
                            getAllNationsData.length > 0
                                ? nationSelected
                                    ? nationSelected.urlFlag
                                    : getAllNationsData[0].urlFlag
                                : ''
                        }
                    />
                    <Text Color="#54575C" FontSize="1rem">
                        {getAllNationsData.length > 0
                            ? nationSelected
                                ? nationSelected.lada
                                : getAllNationsData[0].lada
                            : ''}
                    </Text>
                    <IconSvg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        Cursor="pointer"
                        onClick={() => NationsList()}
                    >
                        <path
                            d="M4.75 8.08329L5.91667 6.91663L9.75 10.75L13.5833 6.91663L14.75 8.08329L9.75 13.0833L4.75 8.08329Z"
                            fill="#0D166B"
                        />
                    </IconSvg>
                </ContainerFlex>
                {showList && (
                    <ContainerFlex
                        backG="#FFF"
                        OverFlowY="scroll"
                        FlexDir="column"
                        Position="absolute"
                        MaxH="100vh"
                        PositionTop="85%"
                        ZIndex="2"
                        Justify="start"
                        Height="80px"
                        Gap="1rem"
                    >
                        {getAllNationsData?.map((nation: IGetAllNations, index: number) => (
                            <ContainerFlex
                                key={index}
                                onClick={() => selectNation(nation)}
                                Cursor="pointer"
                                PaddingL="0.8rem"
                                Justify="flex-start"
                            >
                                <Image
                                    Radius="50%"
                                    width="28px"
                                    height="28px"
                                    src={nation?.urlFlag}
                                />
                                <Text Color="#54575C" FontSize="1rem" Cursor="pointer">
                                    {nation?.lada}
                                </Text>
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
