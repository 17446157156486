import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Star from '@/images/star.svg';
import Branch from '@/images/branch.svg';
import CheckList from '@/images/checkList.svg';
import {getDaysDifference} from '@/hooks/useFormatDate';
import {Contract, InventoryPledge} from '@components/InventoryManagement/Redux/interfaces';
import {ScoreStyle} from '@Shopify/ListClient/interfaces';

export const BreadInventory: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Inicio'},
    {to: '#/Inventory', label: 'Inventario'},
];

export const INVENTORY_PAGE = {
    TITLE: 'Inventario',
    ACTIONS: 'Acciones',
    SEARCH: 'Busca en el inventario',
    PRICE: 'Precio',
    STATUS: 'Estado',
    NO_OPTIONS: 'Sin opciones',
    FROM: 'Desde',
    TO: 'Hasta',
    TEMP_TEXT: 'Tabular temporal',
};

export const OPERATIONS = {
    TABS_TITLE: ['Comercial', 'Prendario'],
};

export const INVENTORY_COMMERCIAL = {
    PROMOTIONS: 'Promociones',
    NEWEST: 'Lo más nuevo',
    AGING: 'Envejecimiento',
};

export const INVENTORY_PAWNBROKER = {
    PRODUCTS: 'Productos',
    CONTRACTS: 'Contratos',
};

export const PAWNBROKER_LIST = {
    PRODUCT: 'Producto',
    LOAN: 'Préstamo',
    CONTRACT: 'Contrato',
    LOCATION: 'Ubicación',
    EXPIRYDATE: 'Vencimiento',
    CONTARCT_STATUS: 'Estado del contrato',
};

export const COMMERCIAL_LIST = {
    PRODUCT: 'Producto',
    PRICE: 'Precio',
    STATUS: 'Estado',
    SKU: 'SKU',
    ADDED: 'Agregado',
};

export const Sorting = {
    Asc: 'asc',
    Desc: 'desc',
};

export const RESIZE = 'resize';

export const SORT_IDENTIFIERS = {
    loanAmount: 'loanAmount',
    location: 'location',
};

export const INVENTORY_PRODUCT = {
    PERCENT_OFF: '% OFF',
};

export const PRICE_ERRORS = {
    INVALID_RANGE: 'El rango ingresado es inválido.',
    REQUIRED: 'Ingresa al menos un valor',
    PROVIDE_NUMBER: 'Proporcione un número',
    FROM_NUMBER: '"Desde" debe ser un número',
    TO_NUMBER: '"Hasta" debe ser un número',
    MIN_VALUE: 'El valor mínimo es 1.',
    MAX_VALUE: 'El valor máximo es 1,000,000.',
};

export const PRICE_RANGES = {
    TO_500: 'Hasta $500',
    FROM_501_TO_1000: 'De $501 a $1,000',
    MORE_THAN_1000: 'Más de $1,000',
    EQUAL_OR_MORE_THAN: 'Igual o más de',
    CUSTOM: 'Personalizado',
    FROM: 'De',
    TO: 'a',
    TO_500_VALUE: 500,
    FROM_501_VALUE: 501,
    TO_1000_VALUE: 1000,
    FROM_1001_VALUE: 1001,
    NO_RANGE: 'Todos los precios',
};

export const PRODUCT_CARD_ATTRIBUTES = {
    SET_ASIDE: 'Aparta desde $',
    ADD_BUTTON: 'Agregar al carrito',
    CONSERVATION_ICON: Star,
    CONSERVATION_ALT: 'conservation-icon',
    BRANCH_ICON: Branch,
    BRANCH_ALT: 'branch-icon',
    STATUS_ICON: CheckList,
    STATUS_ALT: 'star-icon',
};

export const INVENTORY_PLEDGE_STATUS = {
    IN_EFFECT: 'Vigente',
    EXPIRED: 'Vencido/ Reactivación',
    DISCHARGED: 'Desempeñado',
    EXPIRES: 'Vence',
    STATUS: 'Estado',
};

export const CONTRACT_PLEDGE_STATUS = {
    IN_EFFECT: 'Vigente',
    EXPIRED: 'Vencido/ Reactivación',
    DISCHARGED: 'Desempeñado',
    EXPIRES: 'Vence',
    LAST_ENDORSEMENT: 'Último refrendo',
};

export const PRODUCT_OPTIONS = {
    EXTEND: 'Extensión',
    PASS_TO_CAPITAL: 'Abono a capital',
    DISCHARGE: 'Desempeño',
};

export const checkExpiredElapsed = (expiryDate: string) => {
    const today = new Date();
    return getDaysDifference(today, expiryDate);
};

export const PRODUCT_CARD = {
    RENEW: 'Refrendar',
    REACTIVATE: 'Reactivar',
    DETAILS: 'Ver detalles',
};

export const VIEW_TYPE = {
    TABLE: 'table',
    LIST: 'list',
};

export const productCardsByPage = 20;
export const listMaxWidth = 400;

export const INVENTORY_PRODUCT_DETAIL = {
    BACK: 'Inventario',
    TAB_SECTIONS: ['Página de producto', 'Detalles e historial'],
    PRODUCT_CONDITION: 'Nuevo',
    PRODUCT_TITLE: 'Apple iPhone 15 Pro Max (128GB) - Blanco',
    EDIT: 'Editar',
    SOLD_BY: 'Vendido por: ',
    SOLD_BY_NAME: 'Multiapoyo',
    STARS: '4.7',
    SHOW_COMMENTS: 'Ver calificaciones',
    WAS_PRICE: '$27,598',
    NOW_PRICE: '$23,999',
    PRODUCT_CHARACTERISTICS: 'Memoria interna: ',
    PRODUCT_CHARACTERISTICS_VALUE: '128 GB',
    ADD_TO_CART: 'Agregar al carrito',
    PRODUCT_SPECIFICATIONS: 'Especificaciones del producto',
    PRODUCT_SPECIFICATIONS_ITEMS: [
        {
            title: 'Fabricante',
            value: 'Apple',
        },
        {
            title: 'Memoria RAM',
            value: '6 GB',
        },
        {
            title: 'Almacenamiento',
            value: '128 GB',
        },
        {
            title: 'Color',
            value: 'Negro',
        },
        {
            title: 'Resolución',
            value: '1920 x 1080 px',
        },
        {
            title: 'Año de lanzamiento',
            value: '2023',
        },
    ],
    TEMPORARY_TAB_CONTENT: 'Sin contenido temporal',
    PRODUCT_ACCESORIES: 'Accesorios Incluídos',
    PRODUCT_ACCESORIES_ITEM: ['Cable', 'Cargador'],
    PRODUCT_DESCRIPTION: 'Descripción',
    PRODUCT_DESCRIPTION_VALUE_1: `
    iPhone 15 Pro Max. Forjado en titanio y equipado con el revolucionario chip A17 Pro, un Botón de Acción personalizable y el sistema de cámaras más potente en un iPhone.
    `,
    PRODUCT_DESCRIPTION_VALUE_2: `
        FORJADO EN TITANIO — El iPhone 15 Pro Max tiene un diseño resistente y ligero, con titanio de calidad aeroespacial y parte posterior de vidrio mate texturizado. Frente de Ceramic Shield, más duro que el vidrio más duro de cualquier smartphone. Y resistencia a las salpicaduras, al agua y al polvo.
    `,
    PRODUCT_DESCRIPTION_VALUE_3: `
     PANTALLA AVANZADA — La pantalla Super Retina XDR de 6.7 pulgadas con ProMotion aumenta la frecuencia de actualización hasta 120 Hz cuando necesitas el máximo rendimiento gráfico. La Dynamic Island muestra alertas y notificaciones de forma más discreta. Y la tecnología True Tone ajusta el balance de blancos para que las imágenes se vean más naturales en cualquier iluminación.
    `,
    SHOW_MORE: 'Ver descripción completa',
};

export const PRODUCT_CONDITION = {
    TITLE: 'Estado del producto',
    RADIO_OPTIONS: [
        {
            id: 1,
            value: 'new',
            label: 'Nuevo',
        },
        {
            id: 2,
            value: 'excelent',
            label: 'Excelente',
        },
        {
            id: 3,
            value: 'good',
            label: 'Buen estado',
        },
        {
            id: 4,
            value: 'regular',
            label: 'Regular',
        },
    ],
    CANCEL_BUTTON: 'Cancelar',
    SAVE_BUTTON: 'Guardar',
};

export const ERROR_PRODUCT_CONDITION = {
    REQUIRED: 'Este campo es requerido',
};

export const PRODUCT_PRICE = {
    TITLE: 'Precio de venta',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_BUTTON: 'Guardar',
    WAS_PRICE_VALUE: 27598,
    VALUATION: 'Avalúo',
    VALUATION_VALUE: 13000,
    PAID_RENEWAL: 'Refrendos pagados',
    PAID_RENEWAL_VALUE: 4,
};

export const ERROR_PRODUCT_PRICE = {
    REQUIRED: 'Este campo es requerido',
    MAX_VALUE: {
        MESSAGE: 'Máximo 1.000.000.000',
        VALUE: 1000000000,
    },
    MIN_VALUE: {
        MESSAGE: 'Mínimo 1',
        VALUE: 1,
    },
};

export const PRODUCT_CHARACTERISTICS = {
    TITLE: 'Editar propiedad',
    RADIO_OPTIONS: [
        {
            id: 1,
            value: '128',
            label: '128 GB',
        },
        {
            id: 2,
            value: '256',
            label: '256 GB',
        },
    ],
    CANCEL_BUTTON: 'Cancelar',
    SAVE_BUTTON: 'Guardar',
};

export const ERROR_PRODUCT_CHARACTERISTICS = {
    REQUIRED: 'Este campo es requerido',
};

export const DUMMY_THUMBNAIL = {
    image: 'https://via.placeholder.com/150',
    thumbnailCount: 4,
};

export const INVENTORY_LOCATION = {
    TITLE: 'Ubicaciones de inventario',
    SUB_TITLE: 'Tipos de Almacenamientos',
    SUB_TITLE_LEVEL: 'Nivel',
    ARTICLES: 'Artículos',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    HOME: 'Inicio',
    NOT_ITEM_SELECTED: 'Seleccionar alguna de las ubicaciones',
    STORAGES: [
        {
            id: 1,
            name: 'Almacen 1',
            storageList: [
                {
                    id: 2,
                    name: 'Bóveda 1',
                    quantity: 20,
                    parentId: 1,
                    levels: [
                        {
                            id: 3,
                            name: 'Fila 1',
                            quantity: 20,
                            parentId: 2,
                            products: [
                                {id: 1, name: 'Cajon 1', quantity: 10, parentId: 3},
                                {id: 2, name: 'Cajon 2', quantity: 30, parentId: 3},
                                {id: 3, name: 'Cajon 31', quantity: 110, parentId: 3},
                            ],
                        },
                        {
                            id: 4,
                            name: 'Fila 2',
                            quantity: 20,
                            parentId: 2,
                            products: [
                                {id: 4, name: 'Cajon 1', quantity: 10, parentId: 4},
                                {id: 5, name: 'Cajon 2', quantity: 30, parentId: 4},
                                {id: 6, name: 'Cajon 31', quantity: 110, parentId: 4},
                            ],
                        },
                        {
                            id: 5,
                            name: 'Fila 3',
                            quantity: 20,
                            parentId: 2,
                            products: [
                                {id: 7, name: 'Cajon 1', quantity: 10, parentId: 5},
                                {id: 8, name: 'Cajon 2', quantity: 30, parentId: 5},
                                {id: 9, name: 'Cajon 31', quantity: 110, parentId: 5},
                            ],
                        },
                    ],
                },
                {
                    id: 6,
                    name: 'Bóveda 2',
                    quantity: 40,
                    parentId: 1,
                    levels: [
                        {
                            id: 7,
                            name: 'Fila 1',
                            quantity: 15,
                            parentId: 6,
                            products: [
                                {id: 10, name: 'Pichonera 4', quantity: 50, parentId: 7},
                                {id: 11, name: 'Pichonera 5', quantity: 25, parentId: 7},
                            ],
                        },
                        {
                            id: 8,
                            name: 'Fila 2',
                            quantity: 25,
                            parentId: 6,
                            products: [
                                {id: 12, name: 'Pichonera 6', quantity: 35, parentId: 8},
                                {id: 13, name: 'Pichonera 7', quantity: 45, parentId: 8},
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            name: 'Almacen 2',
            storageList: [
                {
                    id: 9,
                    name: 'Bóveda 1',
                    quantity: 50,
                    parentId: 2,
                    levels: [
                        {
                            id: 10,
                            name: 'Fila 1',
                            quantity: 20,
                            parentId: 9,
                            products: [
                                {id: 14, name: 'E8', quantity: 10, parentId: 10},
                                {id: 15, name: 'E9', quantity: 40, parentId: 10},
                            ],
                        },
                        {
                            id: 11,
                            name: 'Fila 2',
                            quantity: 30,
                            parentId: 9,
                            products: [{id: 16, name: 'E10', quantity: 50, parentId: 11}],
                        },
                    ],
                },
            ],
        },
    ],
};

export const CONTRACT_INVENTORY_LIST = {
    CONTRACT: 'Contrato',
    CLIENT: 'Cliente',
    VALUE: 'Avalúo',
    LOAN: 'Préstamo',
    EXPIRYDATE: 'Vencimiento',
    CONTRACT_STATUS: 'Estado del contrato',
};

export const CONTRACT_SORT_IDENTIFIERS = {
    client: 'client',
    location: 'location',
};

export const mapInventoryProduct = (contract: Contract): InventoryPledge => {
    const datCarShop = contract.datCarShopInventoryContracts;
    return {
        itemId: contract.contractNumber,
        contractNumber: contract.contractNumber,
        datCarShopInventory: {
            renewal: datCarShop.renewal,
            performance: datCarShop.performance,
            iva: datCarShop.iva,
            moratoriumAmount: datCarShop.moratoriumAmount,
            amountStorage: datCarShop.amountStorage,
            amountInterest: datCarShop.amountInterest,
            insuranceAmount: datCarShop.insuranceAmount,
            amountReposition: datCarShop.amountReposition,
            amountRepositionIVA: datCarShop.amountRepositionIVA,
            amountDiscountInterest: datCarShop.amountDiscountInterest,
            amountDiscountInterestIVA: datCarShop.amountDiscountInterestIVA,
            maximumInterest: datCarShop.maximumInterest,
            pawnId: datCarShop.pawnId,
            balance: datCarShop.balance,
        },
    };
};

export const scoreStyles: {[key: string]: ScoreStyle} = {
    [INVENTORY_PLEDGE_STATUS.IN_EFFECT]: {
        border: '1px solid #B1E7C9',
        backgroundColor: '#E4F7EC',
        color: '#174A2E',
    },
    [INVENTORY_PLEDGE_STATUS.EXPIRED]: {
        border: '1px solid #FFE299',
        backgroundColor: '#FFF5DB',
        color: '#614500',
    },
    [INVENTORY_PLEDGE_STATUS.DISCHARGED]: {
        border: '1px solid #D4D6D8',
        backgroundColor: '#F4F5F5',
        color: '#54575C',
    },
};

export const defaultStyle: ScoreStyle = {
    border: '0.5px solid black',
    backgroundColor: 'white',
    color: 'black',
};

export const getStyle = (status: string): ScoreStyle => {
    return scoreStyles[status.trim()] || defaultStyle;
};

export const GET_INVENTORY_ERRORS = {
    PRODUCTS_ERROR: 'Hubo un problema al cargar los productos.',
    GENERIC_ERROR: 'Se ha encontrado un error. Por favor, inténtelo de nuevo más tarde.',
};

export const ALLSTATUSESFILTER = 'Todos';
export const defaultBranchOption = {value: 0, label: 'En esta sucursal'};

export const COMMERCIAL_INVENTORY_FILTER_LABELS = {
    AGING: 'ByAging',
    NEWEST: 'ByNewest',
    PROMOTIONS: 'ByPromotions',
    BRANCH: 'ByBranch',
    STORAGE: 'ByStorage',
    STATE: 'ByConservation',
};

export const COMMERCIAL_OPTIONS_DEFAULT = {
    STATE: {value: 0, label: 'Todos los estados'},
    BRANCH: {value: 0, label: 'Todas las Sucursales y Bodegas', type: ''},
};

export const NO_ITEMS_FOUNDED_TEXT = 'No se encuentra ningun articulo';

export const FIXED_PRICES_RANGES = [
    PRICE_RANGES.NO_RANGE,
    PRICE_RANGES.TO_500,
    PRICE_RANGES.FROM_501_TO_1000,
    PRICE_RANGES.MORE_THAN_1000,
];

export const FIXED_PRICES_RANGES_VALUES = {
    [PRICE_RANGES.NO_RANGE]: {minPrice: 0, maxPrice: 0},
    [PRICE_RANGES.TO_500]: {minPrice: 0, maxPrice: 500},
    [PRICE_RANGES.FROM_501_TO_1000]: {minPrice: 501, maxPrice: 1000},
    [PRICE_RANGES.MORE_THAN_1000]: {minPrice: 1001, maxPrice: 1_000_000},
};

export const OPTION_TEXT = {
    FROM: 'Desde',
    TO: 'Hasta',
};
