import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import hoverCheck from '@images/done.svg';
import {
    IContainerFlex,
    IInputColor,
    IPropRowDetailPayCardP,
    IPropsContainerCellGrid,
    IPropsContainerForm,
    IPropsContainerIcon,
    IPropsContainerImg,
    IPropsContainerInfo,
    IPropsFigure,
    IPropsImage,
    IPropsImgAvatar,
    IPropsInput,
    IPropsModal,
    IPropsRadio,
    IPropsSvg,
    IPropsText,
    IPropsTextEllipsis,
    IPropsLink,
    IPropsCheckedRadio,
    IPropsAnchor,
    IPropsContainerGrid,
    IPorpsLine,
    ISpeechBooble,
} from '@components/Shopify/Ecommerce//interfacesStyles';

export const ContainerForm = styled.form<IPropsContainerForm>`
    display: ${(props) => props.Display};
    flex: ${(props) => props.Flex};
    height: ${(props) => props.Height};
    max-height: ${(props) => props.MaxHeight};
    min-height: ${(props) => props.MinHeight};
    width: ${(props) => props.Width};
    flex-direction: ${(props) => props.FlexDir};
    background: ${(props) => props.BackG};
    background-color: ${(props) => props.BColor};
    box-sizing: border-box;
    justify-content: ${(props) => props.Justify};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginT};
    padding: ${(props) => props.Padding};
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
    border-left: ${(props) => props.Bl};
    border-right: ${(props) => props.Br};
    box-shadow: ${(props) => props.Bs};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    align-self: ${(props) => props.Self};
    gap: ${(props) => props.Gap};
    grid-template-columns: ${(props) => props.GridColumns};
    column-gap: ${(props) => props.GapColumn};
    z-index: ${(props) => props.ZIndex};
    overflow: ${(props) => props.OverFlow};
    position: ${(props) => props.Position};
    @media (max-width: 400px) {
        flex: ${(props) => props.smFlex};
        width: ${(props) => props.smWidth};
        flex-direction: ${(props) => props.smFlexDir};
        background: #fff;
        box-sizing: border-box;
        justify-content: ${(props) => props.smJustify};
        padding: ${(props) => props.smPadding};
        align-items: ${(props) => props.smAlign};
        flex-wrap: ${(props) => props.smFlexWrap};
        border-top: ${(props) => props.smBt};
        border-bottom: ${(props) => props.smBb};
        border-left: ${(props) => props.smBl};
        border-right: ${(props) => props.smBr};
    }
`;

ContainerForm.defaultProps = {
    Display: 'flex',
    Width: '100%',
    FlexDir: 'row',
    PaddinsmWidthg: '0',
    Align: 'center',
    Justify: 'center',
    Flex: 1,
    FlexWrap: 'nowrap',
    Bt: 'none',
    Bb: 'none',
    Bl: 'none',
    Br: 'none',
    Bs: ' ',
    smWidth: '100%',
    smFlexDir: 'row',
    smPadding: '0',
    smAlign: 'center',
    smJustify: 'center',
    smFlex: 1,
    smFlexWrap: 'nowrap',
    smBt: 'none',
    smBb: 'none',
    smBl: 'none',
    smBr: 'none',
    BackG: '#fff',
    Height: '100%',
};

export const TooltipText = styled.div`
    width: 30px;
    border-radius: 3px;
    cursor: pointer;
`;

export const ButtonClearFilters = styled.div`
    min-width: 149px;
    height: 40px;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ef4f55;
    border-radius: 5px;
    margin-left: 16px;
    &::hover {
        cursor: pointer;
    }
`;

export const ForSpan = styled.div`
    display: flex;
    flex-direction: row;
    span {
        color: #7ac143;
    }
`;
export const TooltipBox = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    left: -35px;
    visibility: hidden;
    color: transparent;
    background-color: transparent;
    width: 100%;
    padding: 5px 5px;
    border-radius: 4px;

    transition:
        visibility 0.5s,
        color 0.5s,
        background-color 0.5s,
        width 0.5s,
        padding 0.5s ease-in-out;

    &:before {
        content: '';
        width: 0;
        height: 0;
        left: 40px;
        top: -5px;
        position: absolute;

        border: 5px solid transparent;
        transform: rotate(135deg);
        transition: border 0.3s ease-in-out;
    }
`;
export const TooltipCard = styled.div`
    position: relative;
    & ${TooltipText}:hover + ${TooltipBox} {
        display: flex;
        justify-content: center;
        visibility: visible;
        font: normal normal 400 12px Work Sans;
        letter-spacing: 0px;
        color: #ffffff;
        height: 21px;
        background: #a7a9ac 0% 0% no-repeat padding-box;
        border-radius: 5px;
        width: 90px;
        padding: 3px 8px;

        &:before {
            border-color: transparent transparent #a7a9ac #a7a9ac;
        }
    }
`;

export const TextEllipsis = styled.span<IPropsTextEllipsis>`
    font-family: ${(props) => props.FontFamily};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: ${(props) => props.TextAlign};
    width: ${(props) => props.Width};
    align-self: ${(props) => props.Self};
    font-style: ${(props) => props.FontStyle};
    padding: ${(props) => props.Padding};
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    line-height: ${(props) => props.Height};
    color: ${(props) => props.Color};
    margin: ${(props) => props.Margin};
    text-decoration: ${(props) => props.TextDecoration};
    text-transform: ${(props) => props.Transform};
    cursor: ${(props) => props.Cursor};
    max-width: ${(props) => (props.MaxCharacters ? `${props.MaxCharacters}ch` : 'none')};
    letter-spacing: ${(props) => props.LetterSpacing};
    margin-top: ${(props) => props.MarginTop};
    span {
        text-align: start;
    }
    @media (max-width: 400px) {
        align-self: ${(props) => props.smSelf};
        padding: ${(props) => props.smPadding};
        font-weight: ${(props) => props.smFontWeight};
        font-size: ${(props) => props.smFontSize};
        line-height: ${(props) => props.smHeight};
        color: ${(props) => props.smColor};
        margin: ${(props) => props.smMargin};
    }
`;
TextEllipsis.defaultProps = {
    Width: '200px',
    TextDecoration: 'none',
    FontFamily: 'Nunito',
    FontStyle: 'normal',
    FontWeight: '600',
    FontSize: '1rem',
    Self: 'auto',
    Color: '#000',
    Margin: '0',
    smFontWeight: '500',
    smFontSize: '0.75rem',
    smHeight: '1rem',
    smSelf: 'auto',
    smColor: '#000',
    smMargin: '0',
};

export const Text = styled.span<IPropsText>`
    position: ${(props) => props.Position};
    background: ${(props) => props.bGround};
    white-space: ${(props) => (props.wSpace ? props.wSpace : 'normal')};
    text-overflow: ${(props) => props.tOver};
    overflow: ${(props) => props.oFlow};
    display: ${(props) => props.Display};
    font-family: ${(props) => props.FontFamily};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    text-transform: ${(props) => props.Transform};
    align-items: ${(props) => props.Align};
    align-self: ${(props) => props.Self};
    justify-self: ${(props) => props.JustSelf};
    font-style: ${(props) => props.FontStyle};
    padding: ${(props) => props.Padding};
    padding-left: ${(props) => props.PaddingL};
    padding-right: ${(props) => props.PaddingR};
    padding-top: ${(props) => props.PaddingT};
    padding-bottom: ${(props) => props.PaddingB};
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    line-height: ${(props) => props.LHeight};
    color: ${(props) => props.Color};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginTop};
    margin-right: ${(props) => props.MarginRight};
    margin-left: ${(props) => props.MarginLeft};
    margin-bottom: ${(props) => props.MarginBottom};
    text-decoration: ${(props) => props.TextDecoration};
    justify-content: ${(props) => props.Justify};
    flex-wrap: ${(props) => props.FlexWrap};
    flex-direction: ${(props) => props.FlexDirection};
    border: ${(props) => props.Border};
    border-top: ${(props) => props.BorderT};
    border-bottom: ${(props) => props.BorderBt};
    border-radius: ${(props) => props.BorderRadius};
    border-left: ${(props) => props.BorderL};
    border-right: ${(props) => props.BorderR};
    margin-bottom: ${(props) => props.MarginB};
    background-color: ${(props) => props.BGColor};
    cursor: ${(props) => props.Cursor};
    opacity: ${(props) => props.Opacity};
    text-align: ${(props) => props.TextAlign};
    box-shadow: ${(props) => props.BoxS};
    max-width: ${(props) => props.MaxW};
    bottom: ${(props) => props.PositionBottom};
    left: ${(props) => props.PositionLeft};
    right: ${(props) => props.PositionRight};
    z-index: ${(props) => props.ZIndex};
    top: ${(props) => props.PositionTop};
    left: ${(props) => props.PositionLeft};
    right: ${(props) => props.PositionRight};
    visibility: ${(props) => props.Visibility};
    gap: ${(props) => props.Gap};
    min-height: ${(props) => props.MinH};
    box-sizing: border-box;
    user-select: ${(props) => props.UserSelect};
    row-gap: ${(props) => props.RowGap};
    column-gap: ${(props) => props.ColumnGap};
    grid-column-start: ${(props) => props.GridColumnsStart};
    justify-self: ${(props) => props.JustSelf};
    letter-spacing: ${(props) => props.LetterSpacing};
    word-wrap: break-word;
    overflow-wrap: break-word;

    @media (max-width: 400px) {
        align-self: ${(props) => props.smSelf};
        padding: ${(props) => props.smPadding};
        font-weight: ${(props) => props.smFontWeight};
        font-size: ${(props) => props.smFontSize};
        line-height: ${(props) => props.smHeight};
        color: ${(props) => props.smColor};
        margin: ${(props) => props.smMargin};
    }

    &::before {
        content: ${(props) => (props.required ? "'* '" : "''")};
        color: #ff6357;
        margin-right: ${(props) => (props.required ? '0.2rem' : "''")};
    }
    &:hover {
        color: ${(props) => props.HColor};
        border: ${(props) => props.HBorder};
        background-color: ${(props) => props.HBackColor};
        border-left: ${(props) => props.HBorderL};
    }
    .control-label {
        margin-left: 0;
    }
`;
Text.defaultProps = {
    FlexDirection: 'none',
    Position: 'none',
    bGround: 'none',
    tOver: 'ellipsis',
    TextDecoration: 'none',
    FontStyle: 'normal',
    FontWeight: '400',
    Transform: 'none',
    FontSize: 'none',
    Self: 'auto',
    Color: '#4A4A4A',
    BorderBt: '',
    Margin: '0',
    smFontWeight: '500',
    smFontSize: '0.75rem',
    smHeight: '3rem',
    smSelf: 'auto',
    smColor: '#4A4A4A',
    smMargin: '0',
    Justify: 'none',
    FlexWrap: 'wrap',
    Border: '0',
    BorderRadius: '0',
    MarginB: '0',
    Align: 'center',
    Width: 'none',
    Cursor: 'auto',
    Display: 'flex',
    TextAlign: 'none',
    HColor: 'none',
    FontFamily: 'Nunito',
};
export const Containermedia = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    background: #fff;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    @media (max-width: 400px) {
        flex-direction: column;
    }
`;
export const RowDetailPay = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: #fff;
    height: 70px;
`;
export const ImgQrHome = styled.img`
    margin: 2rem 0;
    width: 35%;
`;
export const ModalEcommerce = styled.div`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aab2bd;
    width: 40%;
    height: 55%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`;
export const IconInactive = styled.div`
    background: #cbced1 0% 0% no-repeat padding-box;
    border: 1px solid #cbced1;
    opacity: 1;
    width: 5px;
    height: 5px;
`;
export const RowDetailPayShopi = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: #fff;
    height: 70px;
    margin-top: 25px;
`;
export const RowDetailPayCard = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: #fff;
    height: 100px;
`;

export const RowDetailPayCardP = styled.div<IPropRowDetailPayCardP>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: #fff;
    height: 100px;
    border-bottom: ${(props) => props.Bb};
`;

RowDetailPayCardP.defaultProps = {
    Bb: '0.5px solid #E4E7E9',
};

export const InputColor = styled.input<IInputColor>`
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1px solid #5a5aff;
    border-radius: 50px;
    appearance: none;

    &:checked:after {
        width: ${(props) => props.Width};
        height: ${(props) => props.Height};
        border-radius: 10px;
        top: ${(props) => props.Top};
        left: 0;
        position: relative;
        background-color: ${(props) => props.BackC};
        content: '';
        display: inline-block;
        visibility: visible;
        padding: ${(props) => props.Padding};
        border: 2px solid white;
        outline: 1px solid #5a5aff;
    }
`;
InputColor.defaultProps = {
    Width: '9px',
    Height: '9px',
    Padding: '1px',
    Top: '0',
    BackC: '#5A5AFF',
};

export const LinkAtaskate = styled(Link)<IPropsLink>`
    text-decoration: ${(props) => props.TextDecoration};
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    &:hover {
        color: ${(props) => props.HColor};
    }
`;
LinkAtaskate.defaultProps = {
    Color: '#5A5AFF',
    FontWeight: '500',
    FontSize: '1rem',
    HColor: '#0D166B',
    TextDecoration: 'none',
};

export const ContainerFlex = styled.div<IContainerFlex>`
    position: ${(props) => props.Position};
    top: ${(props) => props.PositionTop};
    bottom: ${(props) => props.PositionBottom};
    left: ${(props) => props.PositionLeft};
    right: ${(props) => props.PositionRight};
    display: ${(props) => props.Display};
    overflow: ${(props) => props.Overflow};
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginTop};
    margin-left: ${(props) => props.MarginL};
    margin-right: ${(props) => props.MarginR};
    margin-bottom: ${(props) => props.MarginBt};
    width: ${(props) => props.Width};
    min-width: ${(props) => props.MinW};
    max-width: ${(props) => props.MaxW};
    flex-direction: ${(props) => props.FlexDir};
    flex: ${(props) => props.Flex};
    min-height: ${(props) => props.MinH};
    max-height: ${(props) => props.MaxH};
    background: ${(props) => props.backG};
    background-image: ${(props) => props.BGImage};
    opacity: ${(props) => props.Opacity};
    box-sizing: border-box;
    justify-content: ${(props) => props.Justify};
    justify-items: ${(props) => props.JustifyItems};
    padding: ${(props) => props.Padding};
    padding-top: ${(props) => props.PaddingT};
    padding-left: ${(props) => props.PaddingL};
    padding-bottom: ${(props) => props.PaddingB};
    padding-right: ${(props) => props.PaddingR};
    align-items: ${(props) => props.Align};
    align-content: ${(props) => props.AlignContent};
    flex-wrap: ${(props) => props.FlexWrap};
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
    border-left: ${(props) => props.Bl};
    border-right: ${(props) => props.Br};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border && props.Border};
    cursor: ${(props) => props.Cursor};
    overflow-y: ${(props) => props.OverFlowY};
    overflow-x: ${(props) => props.OverFlowX};
    overflow: ${(props) => props.OverFlow};
    box-shadow: ${(props) => props.BoxS};
    text-align: ${(props) => props.TextAlign};
    text-overflow: ${(props) => props.TextOver};
    transform: ${(props) => props.Transform};
    word-wrap: ${(props) => props.Wrap};
    z-index: ${(props) => props.ZIndex};
    gap: ${(props) => props.Gap};
    grid-template-columns: ${(props) => props.GridColumns};
    grid-template-rows: ${(props) => props.GridRows};
    filter: ${(props) => props.Filter};
    order: ${(props) => props.Order};
    row-gap: ${(props) => props.RowGap};
    column-gap: ${(props) => props.ColumnGap};
    pointer-events: ${(props) => props.PointerEvents};
    visibility: ${(props) => props.Visibility};
    user-select: ${(props) => props.UserSelect};
    visibility: ${(props) => props.Visibility};
    font-weight: ${(props) => props.FontWeight};
    grid-auto-rows: ${(props) => props.GridAutoRows};
    clip-path: ${(props) => props.ClipPath};
    align-self: ${(props) => props.AlignSelf};
    outline: ${(props) => props.Outline};
    flex-basis: ${(props) => props.FlexBasis};
    backdrop-filter: ${(props) => props.BackdropF};
    grid-column-start: ${(props) => props.gridColumnStart};
    grid-column-end: ${(props) => props.gridColumnEnd};
    grid-row-start: ${(props) => props.gridRowStart};
    grid-row-end: ${(props) => props.gridRowEnd};
    background-size: ${(props) => props.BackgroundSize};
    input {
        box-sizing: ${(props) => props.BoxSizing};
    }
    color: ${(props) => props.Color};
    font-size: ${(props) => props.FontSize};
    border-top-right-radius: ${(props) => props.BorderTRight};
    border-bottom-right-radius: ${(props) => props.BorderBRight};
    border-bottom-left-radius: ${(props) => props.BottomLeftRad};
    border-top-left-radius: ${(props) => props.TopLeftRad};
    align-self: ${(props) => props.Self};

    &::-webkit-scrollbar {
        width: ${(props) => props.ScrollWidth};
        height: ${(props) => props.HeightScroll};
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }

    input {
        width: ${(props) => (props.InputReact ? '60%' : '')};
        border: ${(props) => (props.InputReact ? '1px solid #E4E7E9' : '')};
        height: ${(props) => (props.InputReact ? '35px' : '')};
    }

    input:focus {
        border: ${(props) => (props.InputReact ? '1px solid #35cf44' : '')};
        outline: ${(props) => (props.InputReact ? '1px solid #35cf44' : '')};
    }

    input::placeholder,
    textarea::placeholder {
        color: ${(props) => props.PlaceColor};
        font-weight: ${(props) => props.PlaceFontWeight};
    }

    input:-internal-autofill-selected {
        background-color: -internal-light-dark(#ffffff) !important;
    }

    input:disabled {
        background: ${(props) => props.DBackG};
        border: ${(props) => props.DBorder};
    }

    &:hover {
        border: ${(props) => props.BorderHv};
        background-color: ${(props) => props.HBackground};
        background: ${(props) => props.HbackG};
        border-left: ${(props) => props.HBorderLeft};
    }

    &:hover svg path {
        fill: ${(props) => props.Fill};
    }

    &:focus {
        border: ${(props) => props.BorderFc};
    }

    &:hover span {
        color: ${(props) => props.HColor};
    }

    @media (max-width: 400px) {
        width: ${(props) => props.smWidth};
        flex-direction: ${(props) => props.smFlexDir};
        background: #fff;
        box-sizing: border-box;
        justify-content: ${(props) => props.smJustify};
        padding: ${(props) => props.smPadding};
        align-items: ${(props) => props.smAlign};
        flex-wrap: ${(props) => props.smFlexWrap};
        border-top: ${(props) => props.smBt};
        border-bottom: ${(props) => props.smBb};
        border-left: ${(props) => props.smBl};
        border-right: ${(props) => props.smBr};
        max-height: ${(props) => props.smHeight};
    }

    &:hover {
        box-shadow: ${(props) => props.HBoxShadow};
        background-color: ${(props) => props.BoxBackground};
        border: ${(props) => props.HoverBorder};
        content: ${(props) => props.Content};
        cursor: ${(props) => props.Cursor};
        border-color: ${(props) => props.HBorderColor};
        i,
        p {
            color: ${(props) => (props.BoxBackground === '#E5E7E9' ? '#7ac143' : "''")};
        }
        i,
        div {
            opacity: ${(props) => (props.TOpacity ? '9' : 's')};
            visibility: ${(props) => props.HVisibility};
        }
        span {
            color: ${(props) =>
                props.BoxBackground === 'rgba(122, 193, 67, 0.2)' ? '#7AC143' : "''"};
            color: ${(props) => (props.HBorder ? '#7AC143' : "''")};
        }
    }
    &:hover {
        border: ${(props) => props.BorderHv};
    }

    &:first-child {
        margin: ${(props) => props.FirstChildMargin};
    }
`;
ContainerFlex.defaultProps = {
    Overflow: 'none',
    OverFlowY: 'none',
    OverFlowX: 'none',
    MinH: 'none',
    Cursor: 'auto',
    backG: 'none',
    Opacity: 'none',
    Width: '100%',
    FlexDir: 'row',
    Padding: '0',
    Align: 'center',
    Justify: 'center',
    FlexWrap: 'nowrap',
    Bt: 'none',
    Bb: 'none',
    Bl: 'none',
    Br: 'none',
    BoxShadow: '',
    smWidth: '100%',
    smFlexDir: 'row',
    smPadding: '0',
    smAlign: 'center',
    smJustify: 'center',
    smFlexWrap: 'nowrap',
    smBt: 'none',
    smBb: 'none',
    smBl: 'none',
    smBr: 'none',
    Height: '100%',
    Margin: '0',
    MarginBt: '0',
    Radius: '0',
    BoxS: 'none',
    Position: 'none',
    PositionTop: 0,
    PositionBottom: '',
    PositionLeft: 'none',
    BoxBackground: 'none',
    Content: 'none',
    TextAlign: 'none',
    MaxW: '',
    MinW: '',
    BorderHv: '',
    Display: 'flex',
    ScrollWidth: '0.5rem',
};

export const ContainerCellGrid = styled.div<IPropsContainerCellGrid>`
    display: flex;
    box-sizing: border-box;
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    align-items: ${(props) => props.Align};
    gap: ${(props) => props.Gap};
    cursor: ${(props) => props.Cursor};
    grid-row: ${(props) => props.Row};
    grid-column: ${(props) => props.Column};
    flex-wrap: ${(props) => props.Wrap};
    align-content: ${(props) => props.AlignContent};
    padding: ${(props) => props.Padding};
    border-bottom: ${(props) => props.BorderBt};
    background: ${(props) => props.bGround};
    border-radius: ${(props) => props.BorderR};
    border: ${(props) => props.Border};
    opacity: ${(props) => props.Opacity};
    position: ${(props) => props.Position};
`;
ContainerCellGrid.defaultProps = {
    Height: '100%',
    Width: '100%',
};
export const Img = styled.img`
    height: 313px;
    justify-content: 'flex-start';
`;
export const ImgOrder = styled.img`
    height: 20px;
`;
export const ImgOrd = styled.img`
    width: 100%;
`;
export const Imgqr = styled.img`
    height: 19px;
    margin-right: 8px;
`;
export const PaymentImg = styled.img`
    max-height: 38px;
    max-width: 70px;
`;
export const ButtonAction = styled.img`
    width: 20px;
`;
export const Avatar = styled.img`
    vertical-align: middle;
    margin: auto 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
`;
export const NumberPrincipal = styled.div`
    border-radius: 100px;
    display: flex;
    justify-content: center;
    font: normal normal normal 18px/21px Work Sans;
    letter-spacing: 0px;
    color: #ffffff;
    width: 24px;
    background: #cbced1 0% 0% no-repeat padding-box;
    height: 24px;
`;
export const ContainerInfo = styled.div<IPropsContainerInfo>`
    position: ${(props) => props.Position};
    display: ${(props) => (props.Display ? props.Display : 'flex')};
    color: ${(props) => (props.Color ? props.Color : '')};
    align-items: flex-start;
    box-sizing: border-box;
    padding: ${(props) => props.Padding};
    background: #ffffff 0% 0% no-repeat padding-box;
    border: ${(props) => props.Border};
    border-radius: 5px;
    width: 100%;
    height: ${(props) => props.Margin};
    justify-content: space-between;
    text-indent: ${(props) => props.TextIndent};
    margin: ${(props) => (props.Margin ? props.Margin : '0 auto')};
`;
ContainerInfo.defaultProps = {
    Position: 'none',
    Height: '121px',
    Padding: '16px',
};

export const ContainerInfoFooter = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 18px 4rem 21px 4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    justify-content: space-between;
    width: 100%;
    margin-top: 13px;
    height: 105px;
`;

export const ContainerAsideInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 20%;
    font: normal normal normal 14px/16px Work Sans;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    padding-right: 1rem;
    div {
        cursor: pointer;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 1rem;
        height: 40px;
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.5px solid #e4e7e9;
    }
    div:first-child {
        width: 100%;
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-left: 0.5px solid #e4e7e9;
        border-right: 0.5px solid #e4e7e9;
        border-top: 0.5px solid #e4e7e9;
        border-bottom: none;
        border-radius: 5px 5px 0px 0px;
    }
    div:last-child {
        width: 100%;
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-left: 0.5px solid #e4e7e9;
        border-right: 0.5px solid #e4e7e9;
        border-bottom: 0.5px solid #e4e7e9;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
    }
`;
export const ForWarning = styled.div`
    span {
        color: #f8b800;
    }
`;

export const ContainerAside = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #e4e7e9;
`;
export const ContainerInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 60vw;
    margin-top: 1rem;
    padding-left: 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e4e7e9;
    border-radius: 5px;
`;
export const ContainerPrincipal = styled.div`
    display: flex;
    flex: 4;
    height: 56vh;
    justify-content: space-around;
    background: transparent linear-gradient(180deg, #f4f4f4 0%, #fcfcfc 100%) 0% 0% no-repeat
        padding-box;
    padding: 2rem 11rem;
    > div {
        width: 347px;
        height: 472px;
        padding: 2rem 16px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 15px #0000000d;
        opacity: 1;
        display: flex;
        flex-direction: column;
        h2 {
            text-align: center;
            font: normal normal 600 25px Work Sans;
            letter-spacing: 0px;
            color: #4a4a4a;
        }
        > div {
            display: flex;
            padding-top: 20px;
            justify-content: space-between;
            > div {
                width: 140px;
                display: flex;
                flex-direction: column;
                > div {
                    background: #d1d6f2 0% 0% no-repeat padding-box;
                    border-radius: 20px;
                    opacity: 0.4;
                    width: 140px;
                    height: 140px;
                }
            }
        }
        span {
            text-align: center;
            padding-top: 10px;
            font: normal normal 600 18px/21px Work Sans;
            letter-spacing: 0px;
            color: #6d6e71;
            opacity: 1;
        }
    }
`;

export const Input = styled.input<IPropsInput>`
    background: ${(props) => props.BackGroundColor} 0% 0% no-repeat padding-box;
    border: ${(props) => (props.error ? '1px solid #A82424' : '1px solid #E8E9EA')};
    border-radius: ${(props) => props.Radius};
    text-align: ${(props) => props.TextAlign};
    opacity: 1;
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    margin-left: ${(props) => props.MarginLeft};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    color: ${(props) => props.Color};
    cursor: ${(props) => props.Cursor};
    font-size: ${(props) => props.FontSize};
    font-family: ${(props) => props.FontFamily};
    outline: ${(props) => props.Outline};
    font-weight: ${(props) => props.FontWeight};
    border-top-right-radius: ${(props) => props.RadiusTR};
    border-bottom-right-radius: ${(props) => props.RadiusBR};
    box-sizing: border-box;
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
    border-left: ${(props) => props.Bl};
    border-right: ${(props) => props.Br};
    margin-top: ${(props) => props.MarginTop};
    ::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
    ${(props) =>
        props.disabled
            ? `&:disabled{
            border-color: #E8E9EA;
            background-color: ${props.disabledBackG || '#F4F5F5'};
        }`
            : `&:focus {
                border-color: ${props.error ? '#ef4f55' : '#5A5AFF'};
        }
        &:hover {
            border-color: ${props.error ? '#ef4f55' : '#5A5AFF'};
        }`}
    &::placeholder {
        color: ${(props) => props.HolderColor};
        font-size: ${(props) => props.HolderFontSize};
        line-height: ${(props) => props.HolderHeight};
        font-weight: ${(props) => props.HolderWeight};
    }
`;
Input.defaultProps = {
    Margin: '0',
    Height: '40px',
    Width: '100%',
    Cursor: 'pointer',
    HolderHeight: '',
    HolderWeight: '400',
    FontWeight: '400',
    HolderFontSize: '1rem',
    FontSize: '1rem',
    HolderColor: '#cbced1',
    Radius: '32px',
    Padding: '8px 16px',
    Color: '#050505',
    Outline: 'none',
    BackGroundColor: '#fff',
};

export const TextArea = styled.textarea<IPropsInput>`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #E8E9EA')};
    border-radius: ${(props) => props.Radius};
    text-align: ${(props) => props.TextAlign};
    opacity: 1;
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    color: ${(props) => props.Color};
    cursor: ${(props) => props.Cursor};
    font-size: ${(props) => props.FontSize};
    outline: ${(props) => props.Outline};
    box-sizing: border-box;
    ::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    resize: none;

    &:focus {
        outline: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #5A5AFF')};
    }

    &::placeholder {
        color: ${(props) => props.HolderColor};
        font-size: ${(props) => props.HolderFontSize};
        line-height: ${(props) => props.HolderHeight};
        font-weight: ${(props) => props.HolderWeight};
    }

    &:hover {
        border-color: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
    }
`;
TextArea.defaultProps = {
    Margin: '0',
    Width: '100%',
    Cursor: 'pointer',
    HolderHeight: '',
    HolderWeight: '400',
    HolderFontSize: '1rem',
    HolderColor: '#cbced1',
    Radius: '1rem',
    Padding: '1rem',
};
export const ContainerPages = styled.div`
    cursor: pointer;
    justify-content: space-around;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 19px 11px 19px 11px;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 150px;
    height: 150px;
    h2 {
        text-align: left;
        font: normal normal normal 16px/19px Work Sans;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
    }
    span {
        text-align: center;
        font: normal normal normal 10px/11px Work Sans;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
    }
`;

export const PayCardsContainer = styled.div`
    margin-top: 0;
    width: 100%;
`;
export const ContainerPedidos = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #e4e7e9;
    border-radius: 5px;
    opacity: 1;
    margin-top: 13px;
`;
export const SubContainerPedidos = styled.div`
    display: flex;
    height: 48px;
    box-sizing: border-box;
    border-bottom: 0.5px solid #e4e7e9;
    letter-spacing: 0px;
    color: #4a4a4a;
    opacity: 1;
    padding: 14px 18px;
    align-items: center;
`;

export const ImgFlagOne = styled.img`
    width: 24px;
    margin: 10px;
`;

export const ImgFlagOnes = styled.img`
    width: 24px;
`;
export const ImgProduct = styled.img`
    width: 40px;
    margin-right: 23px;
`;
export const ImgHomsClien = styled.img`
    margin-top: -10px;
    margin-left: -5px;
`;
export const SubContainerFavs = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    .sub-containerfav {
        display: flex;
        align-items: center;
        height: 32px;
        width: 100%;
        background: #f8f8f8 0% 0% no-repeat padding-box;
        border: 1px solid #e4e7e9;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
        padding: 0 1rem;
        justify-content: space-between;
        font: normal normal normal 14px/16px Work Sans;
        letter-spacing: 0px;
        color: #414042;
    }
    .sub-containerfavs {
        min-height: 68px;
        display: flex;
        flex-direction: column;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e4e7e9;
        border-radius: 0px 0px 5px 5px;
        align-items: center;
        padding: 0 2rem;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
`;

export const ImgAvatar = styled.img<IPropsImgAvatar>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    cursor: ${(props) => props.Cursor};
`;

ImgAvatar.defaultProps = {
    Height: '100%',
    Width: '100%',
    Radius: '50%',
};

export const ContainerImg = styled.figure<IPropsContainerImg>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: 0px;
    display: flex;
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.AlignItems};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    padding: ${(props) => props.Padding};
    cursor: ${(props) => props.Cursor};
`;

ContainerImg.defaultProps = {
    AlignItems: 'center',
    Justify: 'center',
};

export const Figure = styled.figure<IPropsFigure>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: rotate 0.2s ease-in-out;
    rotate: ${(props) => props.Rotate};
    position: ${(props) => props.Position};
    background: ${(props) => props.Background};
    border-radius: ${(props) => props.Radius};
`;
export const Image = styled.img<IPropsImage>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    background: ${(props) => props.Background};
    cursor: ${(props) => props.Cursor};
    opacity: ${(props) => props.Opacity};
    margin: ${(props) => props.Margin};
    margin-left: ${(props) => props.MarginLeft};
    object-fit: ${(props) => props.ObjectFit};
    aspect-ratio: ${(props) => props.AspectRatio};
    aspect-ratio: ${(props) => props.AspRatio};
    transform: ${(props) => props.Transform};
    padding: ${(props) => props.Padding};
    transition: ${(props) => props.Transition};
    position: ${(props) => props.Position};
    top: ${(props) => props.PositionTop};
    left: ${(props) => props.PositionLeft};
    right: ${(props) => props.PositionRight};
    bottom: ${(props) => props.PositionBottom};
    filter: ${(props) => props.Filter};
    align-self: ${(props) => props.Self};
    margin-right: ${(props) => props.MarginRight};
    border-top-left-radius: ${(props) => props.BorderTLRadius};
    border-top-right-radius: ${(props) => props.BorderTRRadius};
    &:hover {
        opacity: ${(props) => props.HOpacity};
    }
`;

ImgAvatar.defaultProps = {
    Height: '100%',
    Width: 'auto',
};

export const StyledRadio = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    margin-right: 10px;
    cursor: pointer;
`;
export const Radio = styled.input<IPropsRadio>`
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.BorderColor};
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-right: ${(props) => props.MarginRight};
    cursor: pointer;
    &:checked {
        border: 1px solid ${(props) => props.BorderColorChecked};
        + span {
            transform: scale(1);
        }
    }
    &:hover {
        border-color: ${(props) => props.BorderColorHover};
    }
`;

Radio.defaultProps = {
    BorderColor: '#6d6e71',
    BorderColorChecked: '#35cf44',
    BorderColorHover: '#35cf44',
};

export const CheckedRadio = styled.span<IPropsCheckedRadio>`
    transform: scale(0);
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.BackGround};
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    transition: 120ms transform ease-in-out;
`;

CheckedRadio.defaultProps = {
    BackGround: '#35cf44',
};

export const StyledCheckbox = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 0;
`;
export const InputCheckbox = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    border: 1px solid #5a5aff;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    display: grid;
    z-index: ${(props) => (props.disabled ? 0 : 1)};
    place-content: center;
    &:checked {
        border: none;
        + img {
            animation: checkAnimation 0.3s forwards;
            opacity: 1;
        }
        &:hover {
            background-image: none;
        }
    }
    &:hover {
        border: 1px solid #5a5aff;
        background-image: url(${hoverCheck});
    }
`;

export const CheckedBox = styled.img`
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0;
    @keyframes checkAnimation {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`;

export const Svg = styled.svg<IPropsSvg>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    color: ${(props) => props.color};
    fill: 'none';
    transition: ${(props) => props.transition};
    rotate: ${(props) => props.rotate};
    cursor: ${(props) => props.cursor};
    &:hover path {
        fill: ${(props) => props.hoverF};
    }
`;
export const ContainerIcon = styled.div<IPropsContainerIcon>`
    display: flex;
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    border-radius: ${(props) => props.Radius};
    background: ${(props) => props.BackGround};
    border: ${(props) => props.Border};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    position: ${(props) => props.Position};
    clip-path: ${(props) => props.ClipPath};
    cursor: pointer;
    bottom: ${(props) => props.PositionBottom};
    top: ${(props) => props.PositionTop};
    opacity: ${(props) => props.Opacity};
    visibility: ${(props) => props.Visibility};
    padding: ${(props) => props.Padding};

    &:hover {
        visibility: ${(props) => props.HVisibility};
        opacity: ${(props) => props.HOpacity};
    }
`;

ContainerIcon.defaultProps = {
    Justify: 'center',
    Align: 'center',
};
export const Modal = styled.div<IPropsModal>`
    display: flex;
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    background-color: ${(props) => props.BackC};
    z-index: ${(props) => props.ZIndex};
    align-items: ${(props) => props.Align};
    justify-content: ${(props) => props.Justify};
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
`;

Modal.defaultProps = {
    Height: '100vh',
    Width: '100%',
    BackC: 'rgba(167, 169, 172, 0.25)',
    ZIndex: '3',
    Align: 'center',
    Justify: 'center',
    Position: 'fixed',
    Top: '0',
    Left: '0',
};

export const Anchor = styled.a<IPropsAnchor>`
    cursor: ${(props) => props.cursor};
    color: ${(props) => props.color};
    text-decoration: ${(props) => props.textDecoration};

    & hover {
        color: #2a2c2f;
        text-decoration: none;
    }
    &:visited {
        color: #2a2c2f;
    }
`;

export const ContainerGrid = styled.div<IPropsContainerGrid>`
    display: grid;
    box-sizing: border-box;
    justify-content: ${(props) => props.Justify};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    align-items: ${(props) => props.Align};
    gap: ${(props) => props.Gap};
    cursor: ${(props) => props.Cursor};
    grid-row: ${(props) => props.Row};
    grid-column: ${(props) => props.Column};
    align-content: ${(props) => props.AlignContent};
    padding: ${(props) => props.Padding};
    border-bottom: ${(props) => props.BorderBt};
    background: ${(props) => props.bGround};
    border-radius: ${(props) => props.BorderR};
    border: ${(props) => props.Border};
    border-top: ${(props) => props.BorderTp};
    overflow-y: ${(props) => props.OverFlowY};
    grid-template-columns: ${(props) => props.GridColumns};
    column-gap: ${(props) => props.ColumnGap};
    flex-direction: ${(props) => props.FlexDir};
    grid-column-start: ${(props) => props.gridColumnStart};
    grid-column-end: ${(props) => props.gridColumnEnd};
    grid-row-start: ${(props) => props.gridRowStart};
    grid-row-end: ${(props) => props.gridRowEnd};
    justify-items: ${(props) => props.JustifyItems};
    min-height: ${(props) => props.MinH};
`;

ContainerGrid.defaultProps = {
    Height: '100%',
    Width: '100%',
};

export const ContainerInputImage = styled.div`
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
`;

export const ContainerInput = styled.div``;

export const InputFile = styled.input``;
export const Line = styled.hr<IPorpsLine>`
    border: none;
    border-top: 1px solid ${(props) => (props.Border ? props.Border : '#e8e9ea')};
    margin: 0;
    width: 100%;
`;

export const SpeechBubble = styled.div<ISpeechBooble>`
    position: absolute;
    background: #0d166b;
    border-radius: 20px;
    height: 6rem;
    width: 20rem;
    margin-top: 12.5rem;
    z-index: 1;
    padding: 20px;
    transform: ${(props) => props.transformX};
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: ${(props) => props.afterLeft};
        width: 0;
        height: 0;
        border: 16px solid transparent;
        border-bottom-color: #0d166b;
        border-top: 0;
        margin-left: -16px;
        margin-top: -16px;
        z-index: 2;
    }
`;

SpeechBubble.defaultProps = {
    afterLeft: '50%',
};
