import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, TextEllipsis, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    FOCUSED_COLORS,
    GENERAL_TITLES,
    SCREEN_TABLE_ACCESS,
} from '@components/Users/UserProfile/constants';
import {Table} from '@mui/material';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import {TableItem} from '@/components/DeadLines/styles';
import Save from '@/images/download_focused.svg';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {RecetAccess} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/RecentAccessActions';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {
    LogsFilesUserdownload,
    resetDownloadData,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/LogsFilesUserAction';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import Paginate from '@/components/AdminCashflow/Paginate';
export const ResentAccess = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const {loading} = useSelector((state: RootState) => state.RecentAccessUserData);
    const Access = useSelector((state: RootState) => state.RecentAccessUserData?.userData);
    const downdload = useSelector((state: RootState) => state.LogsFilesUserD.userData || 1);

    const totalPages = useSelector(
        (state: RootState) => state.RecentAccessUserData.userData?.totalPages
    );
    const totalItems = useSelector(
        (state: RootState) => state.RecentAccessUserData.userData?.totalItems
    );
    const accessData = Access && Access.succeeded ? Access.data : [];
    const [currentPage, setCurrentPage] = useState(1);
    const pageNumber = currentPage;
    const pageSize = 10;
    useEffect(() => {
        const fetchData = async () => {
            if (employeeId) {
                await dispatch(RecetAccess(token, employeeId, pageNumber, pageSize));
            }
        };
        fetchData();
    }, [dispatch, employeeId, pageNumber, pageSize, token]);

    const handleDowloadClick = () => {
        dispatch(LogsFilesUserdownload(token, employeeId));
    };
    useEffect(() => {
        if (downdload && downdload.fileContents) {
            const {fileContents, contentType, fileDownloadName} = downdload;
            const blob = new Blob([Uint8Array.from(atob(fileContents), (c) => c.charCodeAt(0))], {
                type: contentType,
            });
            const url = URL.createObjectURL(blob);
            const enlace = document.createElement('a');
            enlace.href = url;
            enlace.download = fileDownloadName;
            document.body.appendChild(enlace);
            enlace.click();
            document.body.removeChild(enlace);
            URL.revokeObjectURL(url);
            dispatch(resetDownloadData());
        }
    }, [downdload]);
    return (
        <ContainerFlex
            Radius="24px"
            Justify="start"
            Padding="16PX"
            Align="start"
            Gap="16px"
            FlexDir="column"
            Border={FOCUSED_COLORS.BORDER_LEFT}
        >
            {loading ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex Justify="space-between" Height="40px">
                        <Text FontSize="1.25rem" FontWeight="700">
                            {GENERAL_TITLES.TITLE_RECENT}
                        </Text>
                        <ContainerFlex
                            Cursor="pointer"
                            Gap="8px"
                            Radius="32px"
                            Padding="8px 16px"
                            Border={FOCUSED_COLORS.FOCUSED}
                            Width="143px"
                            onClick={handleDowloadClick}
                        >
                            <Text
                                onClick={handleDowloadClick}
                                Cursor="pointer"
                                FontSize="1rem"
                                FontWeight="700"
                                Color={FOCUSED_COLORS.FOCUSED_TEXT}
                            >
                                {GENERAL_TITLES.SAVE}
                            </Text>
                            <Image
                                onClick={handleDowloadClick}
                                Cursor="pointer"
                                alt="save"
                                src={Save}
                            />
                        </ContainerFlex>
                    </ContainerFlex>

                    <Table>
                        <TableRowHead
                            Height="40px"
                            GridColumn="1fr 2fr 2fr 1fr 1fr"
                            BackG="none"
                            Border="none"
                            Gap="16px"
                        >
                            <Text FontWeight="700" Padding="4px 0px">
                                {SCREEN_TABLE_ACCESS.DATE}
                            </Text>
                            <Text FontWeight="700" Padding="4px 0px">
                                {SCREEN_TABLE_ACCESS.IP_ADDRESS}
                            </Text>
                            <Text FontWeight="700" Padding="4px 0px">
                                {SCREEN_TABLE_ACCESS.ISP}
                            </Text>
                            <Text FontWeight="700" Padding="4px 0px">
                                {SCREEN_TABLE_ACCESS.UBICATION}
                            </Text>
                        </TableRowHead>
                        <TableItemsContainer>
                            {Array.isArray(accessData) && accessData.length > 0 ? (
                                accessData.map((access, index) => (
                                    <TableRowItems
                                        key={index}
                                        Height="40px"
                                        GridColumn="1fr 2fr 2fr 1fr 1fr"
                                        BackGround="none"
                                        BorderBottom="1px solid #E8E9EA"
                                        Border="none"
                                        Gap="16px"
                                        BorderRadius="0px"
                                    >
                                        <TableItem Justify="start" Height="40px">
                                            <Text
                                                FontSize="0.875rem"
                                                FontWeight="400"
                                                Padding="4px 0px"
                                            >
                                                {new Date(
                                                    access.accessDateTime
                                                ).toLocaleDateString()}
                                            </Text>
                                        </TableItem>
                                        <TableItem Justify="start" Height="40px">
                                            <TextEllipsis
                                                FontSize="0.875rem"
                                                FontWeight="400"
                                                Padding="4px 0px"
                                            >
                                                {access.accessLocation}
                                            </TextEllipsis>
                                        </TableItem>
                                        <TableItem Justify="start" Height="40px">
                                            <Text
                                                FontSize="0.875rem"
                                                FontWeight="700"
                                                Padding="4px 0px"
                                            >
                                                {access.ISP}
                                            </Text>
                                        </TableItem>
                                        <TableItem Justify="start" Height="40px">
                                            <Text
                                                FontSize="0.875rem"
                                                FontWeight="700"
                                                Padding="4px 0px"
                                            >
                                                {access.accessLocation}
                                            </Text>
                                        </TableItem>
                                    </TableRowItems>
                                ))
                            ) : (
                                <TableRowItems BorderBottom="1px solid #E8E9EA" Border="none">
                                    <TableItem>
                                        <Text
                                            FontSize="0.875rem"
                                            FontWeight="400"
                                            Padding="4px 0px"
                                        >
                                            {BRANCH_ASSIGNMENT.NOT_DATA}
                                        </Text>
                                    </TableItem>
                                </TableRowItems>
                            )}
                        </TableItemsContainer>
                    </Table>
                    <ContainerFlex Height="24px" Gap="8px">
                        {totalPages > 0 && (
                            <Paginate
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                totalItems={totalItems}
                            />
                        )}
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};
