import React from 'react';

import {
    checkExpiredElapsed,
    PRODUCT_OPTIONS,
    INVENTORY_PLEDGE_STATUS,
    PRODUCT_CARD,
} from '@components/InventoryManagement/constants';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {useInventoryActions} from '@components/InventoryManagement/hooks/useInventoryActions';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {cardButtonStyle, cardButtonTextStyle} from '@components/InventoryManagement/styles';
import {ActionMenuOption} from '@components/ActionMenu/ActionMenuOption';
import {InventoryProductItem} from '@components/InventoryManagement/Redux/interfaces';
import {ProductCardProps} from '@components/InventoryManagement/interfaces';
import ProductContractInfo from '@components/InventoryManagement/ProductContractInfo';

const ProductCard: React.FC<ProductCardProps> = ({
    product,
    inventoryProducts,
    openOption,
    handleOptionClick,
    goToDetail,
}) => {
    const {handleEndorsementDischarge, handleError} = useInventoryActions();
    const tempNullFunction = () => {
        return;
    };
    const getButtonRender = (item: InventoryProductItem) => {
        const actions = {
            [INVENTORY_PLEDGE_STATUS.IN_EFFECT]: {
                text: PRODUCT_CARD.RENEW,
                onClick: () =>
                    handleEndorsementDischarge(
                        TYPE_MOVEMENT_ADD_CART.RENEWAL,
                        item,
                        {},
                        handleError
                    ),
            },
            [INVENTORY_PLEDGE_STATUS.EXPIRED]:
                checkExpiredElapsed(item.expires) <= 7
                    ? {
                          text: PRODUCT_CARD.REACTIVATE,
                          onClick: () => tempNullFunction(),
                      }
                    : null,
        };
        const defaultAction = {
            text: PRODUCT_CARD.DETAILS,
            onClick: () => tempNullFunction(),
        };
        return actions[item.itemStatus] || defaultAction;
    };
    const getItemMenuOptions = (item: InventoryProductItem) => [
        {
            id: 'extension',
            name: PRODUCT_OPTIONS.EXTEND,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    item,
                    {extend: true},
                    handleError
                ),
        },
        {
            id: 'passToCapital',
            name: PRODUCT_OPTIONS.PASS_TO_CAPITAL,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    item,
                    {addPay: true},
                    handleError
                ),
        },
        {
            id: 'discharge',
            name: PRODUCT_OPTIONS.DISCHARGE,
            action: () =>
                handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.DISCHARGE, item, {}, handleError),
        },
    ];

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    const buttonData = getButtonRender(product);

    return (
        <ContainerFlex
            FlexDir="Column"
            MaxW="16.875rem"
            backG="#FFFFFF"
            Radius="1.25rem"
            Justify="space-between"
            Height="28.313rem"
            onClick={() => goToDetail(product.itemId)}
        >
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="0rem"
                Position="relative"
                PositionTop="1rem"
                PositionLeft="1rem"
            >
                <span onClick={stopPropagation}>
                    <Checkbox />
                </span>
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="center" Height="11.25rem">
                <Image
                    src={product.itemImage}
                    alt="product-image"
                    Height="11.25rem"
                    Width="11.25rem"
                    BorderTLRadius="1.25rem"
                    BorderTRRadius="1.25rem"
                />
            </ContainerFlex>
            <ProductContractInfo pledge={inventoryProducts} item={product} />
            <ContainerFlex
                Align="center"
                Justify="center"
                Padding="0rem 1rem 1.5rem 1rem"
                Height="4rem"
                Gap="1rem"
            >
                <ButtonGeneral
                    {...cardButtonStyle}
                    clic={buttonData.onClick}
                    text={<Text {...cardButtonTextStyle}>{buttonData.text}</Text>}
                />
                {product.itemStatus === INVENTORY_PLEDGE_STATUS.IN_EFFECT && (
                    <ContainerFlex
                        Cursor="pointer"
                        Height="2rem"
                        Width="2rem"
                        onClick={stopPropagation}
                    >
                        <ActionMenuOption
                            id={product.itemId}
                            menuOptions={getItemMenuOptions(product)}
                            isOpen={openOption === product}
                            onOptionClick={() => handleOptionClick(product)}
                        />
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ProductCard;
