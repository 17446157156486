import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    setSelectedStorageListItem,
    setSelectedStorageItemType,
} from '@components/InventoryManagement/Redux/Actions/InventoryLocationStorage';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@components/General/Atoms/Button';
import {InventoryLocationModalProps} from '@components/InventoryManagement/interfaces';
import InventoryLocationContainer from '@components/InventoryManagement/InventoryLocationContainer';
import {INVENTORY_LOCATION} from '@components/InventoryManagement/constants';
import {RootState} from '@/config/store';

const InventoryLocationModal: React.FC<InventoryLocationModalProps> = ({
    showModal,
    setShowModal,
}) => {
    const dispatch = useDispatch();
    const [isTouched, setIsTouched] = useState(false);
    const {selectedStorageItemType} = useSelector((state: RootState) => {
        return state.InventoryLocationStorage;
    });

    const closeModal = () => {
        setShowModal(false);
        setIsTouched(false);
        dispatch(setSelectedStorageListItem(null));
        dispatch(setSelectedStorageItemType(null));
    };

    const onSave = () => {
        setIsTouched(true);
        if (selectedStorageItemType) {
            closeModal();
        }
    };
    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => setShowModal(true)}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            applyAnimation={true}
            Width="90%"
        >
            <ContainerFlex
                FlexDir="row"
                FlexWrap="wrap"
                Padding="1rem 1.5rem 1rem 1.5rem"
                Height="90vh"
                Gap="0.5rem"
            >
                <ContainerFlex Height="5vh" Justify="start" Align="start">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700">
                        {INVENTORY_LOCATION.TITLE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Width="100%"
                    Height="65vh"
                    Align="start"
                    Justify="start"
                    Gap="1rem"
                    OverFlowY="auto"
                >
                    {INVENTORY_LOCATION.STORAGES.map((storage, index) => (
                        <InventoryLocationContainer key={index} storage={storage} />
                    ))}
                </ContainerFlex>

                {!selectedStorageItemType && isTouched && (
                    <Text FontWeight="400" FontSize="1rem" Color="#FF6357" MarginB="1rem">
                        {INVENTORY_LOCATION.NOT_ITEM_SELECTED}
                    </Text>
                )}
                <ContainerFlex Gap="1.5rem" Height="5vh" Align="center" Justify="center">
                    <ButtonGeneral
                        width="6.25rem"
                        text={INVENTORY_LOCATION.CANCEL}
                        clic={() => {
                            closeModal();
                        }}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        cursor="pointer"
                        border="1px solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        clic={() => onSave()}
                        width="6.25rem"
                        text={INVENTORY_LOCATION.SAVE}
                        type="submit"
                        cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
export default InventoryLocationModal;
