import React, {useCallback, useEffect, useState} from 'react';
import Paginate from '@/components/AdminCashflow/Paginate';
import {ImageLogo} from '@/components/LoginClient/styles';
import {SearchFilterOfUser} from '@/components/MyCompanyUser/filtersOfUser';
import {
    EMPTY_VALUE,
    TEXT_USER,
    USER_STATES,
    UserListPageSize,
    header,
} from '@/components/MyCompanyUser/Operational/Constants';
import {User, valuesSearch} from '@/components/MyCompanyUser/Operational/interfaceType';
import {IconUser} from '@/components/MyCompanyUser/Operational/style';
import {TableAllUserData} from '@/components/MyCompanyUser/Redux/Actions/DataTableUserActions';
import {RootState} from '@/config/store';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {DoneSwitch} from '@components/CreateUsers/Form/styles';
import {updateStatusAllUsers} from '@components/Users/Redux/Actions/updateUsersStatusAction';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import points from '@images/iconeThreePoin.svg';
import {ContainerCellGrid, ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SideMenu from '@components/MyCompanyUser/SideMenu';
import {ClickAwayListener} from '@mui/material';
import DisableUserModal from '@components/MyCompanyUser/ConfirmDisable';
import {setTimerAction} from '@components/UserProfileResetPassword/Redux/Actions/ResetTimerUserAction';

export const TableAllUsers: React.FC = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState<valuesSearch>({
        roleId: 0,
        Rol: '',
        RolLabel: '',
        Access: '',
        State: '',
    });
    const navigate = useNavigate();
    const [disableMenu, setDisableMenu] = useState(false);
    const [userToDisable, setuserToDisable] = useState(0);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [activeSideMenuId, setActiveSideMenuId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasAppliedFilter, setHasAppliedFilter] = useState(false);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const companyId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.companyId
    );
    const dataTable = useSelector((state: RootState) => state.TableAllUserAdmin.userData);
    const loading = useSelector((state: RootState) => state.TableAllUserAdmin.loading);
    const totalPages = useSelector(
        (state: RootState) => state.TableAllUserAdmin.userData?.totalPages
    );

    const totalItems = useSelector(
        (state: RootState) => state.TableAllUserAdmin.userData?.totalItems
    );
    const TermName = (role: {roleName: string}[]) => {
        const Message = role.map((data, index) => {
            return ' ' + data.roleName;
        });
        return String(Message);
    };

    // TODO: Fix multiple rendering on this component
    const fetchUsers = () => {
        if (token && companyId) {
            const requestData = {
                companyId,
                pageSize: UserListPageSize,
                pageNumber: currentPage,
                employeeName: searchTerm,
                roleId: filters.Rol,
                accessId: filters.Access,
                isActive:
                    filters.State === USER_STATES.EMPTY || filters.State === USER_STATES.ALL
                        ? null
                        : filters.State === USER_STATES.TRUE,
            };
            dispatch(TableAllUserData(token, requestData));
        }
    };

    useEffect(() => {
        if (dataTable && dataTable.data) {
            setFilteredUsers(dataTable.data);
        }
    }, [dataTable]);

    const handleSearch = useCallback((term: string) => {
        setSearchTerm(term);
        setCurrentPage(1);
        setHasAppliedFilter(true);
    }, []);

    const handleFilter = (newFilters: valuesSearch) => {
        setFilters(newFilters);
        setCurrentPage(1);
        setHasAppliedFilter(true);
        fetchUsers();
    };

    const handleClearFilters = useCallback(() => {
        setSearchTerm('');
        setFilters({
            roleId: 0,
            Rol: '',
            RolLabel: '',
            Access: '',
            State: '',
        });
        setCurrentPage(1);
        setHasAppliedFilter(false);
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [searchTerm, filters, currentPage]);

    const handleClick = async (employId: number) => {
        if (token) {
            await dispatch(setTimerAction(employId));
            navigate('/user/userProfile');
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchUsers();
    };

    const ChangeStatus = (id: number, status: boolean) => {
        const data = {userId: id};
        if (!status) {
            if (token) {
                dispatch(updateStatusAllUsers(token, data));
            }
        } else {
            setuserToDisable(id);
            setDisableMenu(true);
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            );
        }

        if (
            hasAppliedFilter &&
            (!dataTable || !dataTable.data || dataTable.data.length === EMPTY_VALUE)
        ) {
            return (
                <ContainerFlex Justify="center" Align="center" Height="200px">
                    <Text FontSize="1rem" Color="#54575C">
                        {TEXT_USER.MESSAGE_SEARCH}
                    </Text>
                </ContainerFlex>
            );
        }

        const handleSideMenu = (id: number) => {
            setActiveSideMenuId((prevId) => (prevId === id ? null : id));
        };

        return (
            <>
                {disableMenu && (
                    <DisableUserModal
                        id={userToDisable}
                        setDisableMenu={setDisableMenu}
                        fetchUsers={fetchUsers}
                    />
                )}
                <Table>
                    <TableRowHead
                        BackG="none"
                        GridColumn="1fr 2fr 1fr .5fr"
                        Border="0 0 1px 0 solid red"
                        Height="40px"
                        BorderB="1px solid #F4F5F5"
                        Gap="8px"
                    >
                        {header.map((headerItem, index) => (
                            <TableItem Justify="start" Gap="8px" Width="100%" key={index}>
                                <Text
                                    FontSize="1rem"
                                    Color="#2A2C2F"
                                    FontWeight="700"
                                    MarginLeft={headerItem === 'Activado' ? '5rem' : '0'}
                                >
                                    {headerItem}
                                </Text>
                            </TableItem>
                        ))}
                    </TableRowHead>
                    <TableItemsContainer>
                        {filteredUsers.map((user: User, rowIndex) => (
                            <TableRowItems
                                Height="56px"
                                GridColumn="1fr 2fr 1fr .5fr"
                                BorderB="0.5px solid #F4F5F5"
                                Gap="8px"
                                Border=""
                                key={rowIndex}
                            >
                                <TableItem Gap="15px" Justify="start" Padding="4px">
                                    <IconUser src={user.image} alt="user-photo" />
                                    <Text
                                        FontSize="0.88rem"
                                        wSpace=""
                                        onClick={() => {
                                            handleClick(user.employId);
                                        }}
                                    >
                                        {user.names}
                                    </Text>
                                </TableItem>

                                <TextEllipsis
                                    FontWeight=""
                                    title={TermName(user.role)}
                                    FontSize="0.88rem"
                                    Width="95%"
                                    MarginTop="17px"
                                >
                                    {TermName(user.role).length === EMPTY_VALUE
                                        ? TEXT_USER.EMPTY
                                        : TermName(user.role)}
                                </TextEllipsis>

                                <TableItem Justify="space-between">
                                    <Text FontWeight="500" FontSize="0.88rem">
                                        {user.lastSession}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="space-between" MarginLeft="5rem">
                                    <DoneSwitch
                                        checked={user.isActive}
                                        onChange={() => {
                                            ChangeStatus(user.employId, user.isActive);
                                        }}
                                    />
                                    <ContainerFlex Position="relative">
                                        <ImageLogo
                                            Cursor="pointer"
                                            Width="24px"
                                            Height="24px"
                                            src={points}
                                            alt="point-icon"
                                            onClick={() => handleSideMenu(user.employId)}
                                        />
                                        {activeSideMenuId === user.employId && (
                                            <ClickAwayListener
                                                onClickAway={() => setActiveSideMenuId(null)}
                                            >
                                                <SideMenu
                                                    token={token}
                                                    userId={user.employId}
                                                    fetchUsers={fetchUsers}
                                                />
                                            </ClickAwayListener>
                                        )}
                                    </ContainerFlex>
                                </TableItem>
                            </TableRowItems>
                        ))}
                    </TableItemsContainer>
                </Table>
                {filteredUsers.length > EMPTY_VALUE && (
                    <Paginate
                        setCurrentPage={handlePageChange}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                    />
                )}
            </>
        );
    };

    return (
        <ContainerFlex FlexDir="column" Height="max-content">
            <ContainerCellGrid FlexDir="column" Gap="16px" Height="max-content">
                <SearchFilterOfUser
                    onSearch={handleSearch}
                    onFilter={handleFilter}
                    onClearFilters={handleClearFilters}
                    activeFilters={filters}
                />
                {renderContent()}
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
