import {CommercialInventoryState} from '@components/InventoryManagement/interfaces';
import {
    CHANGE_COMMERCIAL_INVENTORY_PAGE,
    GET_COMMERCIAL_INVENTORY,
    GET_COMMERCIAL_INVENTORY_ERROR,
    GET_COMMERCIAL_INVENTORY_SUCCESS,
} from '@components/InventoryManagement/Redux/types';
import {AxiosResponse} from 'axios';

const initialState: CommercialInventoryState = {
    data: [],
    error: false,
    loading: false,
    pageNumber: 1,
    pageSize: 20,
    totalItems: 0,
    totalPages: 0,
};

export const getCommercialInventory = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; pageNumber: number}
) => {
    switch (action.type) {
        case GET_COMMERCIAL_INVENTORY:
            return {...state, loading: true, error: false};
        case GET_COMMERCIAL_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload.data,
            };
        case GET_COMMERCIAL_INVENTORY_ERROR:
            return {...state, loading: false, error: true};
        case CHANGE_COMMERCIAL_INVENTORY_PAGE:
            return {...state, pageNumber: action.pageNumber};
        default:
            return state;
    }
};

export default getCommercialInventory;
