import React from 'react';
import {TableRow, TableCell} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image, Text, TextEllipsis} from '@EcommerceShopify/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import useFormatDate from '@/hooks/useFormatDate';
import {CommercialListRowProps} from '@components/InventoryManagement/interfaces';
import {CommercialCategoryItem} from '@components/InventoryManagement/CommercialCategoryItem';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

const CommercialInventoryListRow = ({inventory, handleItemSelect}: CommercialListRowProps) => (
    <TableRow
        borderBottom="1px solid #E8E9EA"
        gridColumns="1.125rem minmax(18.625rem,1fr) repeat(4, minmax(5rem, 1fr))"
        gap="1rem"
    >
        <TableCell>
            <Checkbox
                checked={inventory.selected}
                onChange={() => handleItemSelect(inventory.id)}
            />
        </TableCell>
        <TableCell>
            <ContainerFlex Gap="0.5rem">
                <Image
                    src={inventory.image}
                    Height="3rem"
                    Width="3rem"
                    Radius="0.25rem"
                    Border="1px solid #E8E9EA"
                    alt="article"
                />
                <ContainerFlex FlexDir="column" Align="start" MaxW="15rem">
                    <TextEllipsis Color="#5A5AFF" FontSize="0.875rem" FontWeight="700">
                        {inventory.pledge}
                    </TextEllipsis>
                    {inventory.category && (
                        <ContainerFlex Justify="start">
                            <CommercialCategoryItem
                                key={inventory.category.family}
                                label={inventory.category.family}
                            />
                            <CommercialCategoryItem
                                key={inventory.category.subFamily}
                                label={inventory.category.subFamily}
                            />
                            <CommercialCategoryItem
                                key={inventory.category.brand}
                                label={inventory.category.brand}
                                isLastItem={true}
                            />
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </TableCell>
        <TableCell padding="0.25rem 0">
            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                $ {numberToCurrency(inventory.price)}
            </Text>
        </TableCell>
        <TableCell padding="0.25rem 0">
            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                {inventory.conservation}
            </Text>
        </TableCell>
        <TableCell padding="0.25rem 0">
            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                {inventory.sku}
            </Text>
        </TableCell>
        <TableCell padding="0.25rem 0">
            {inventory.aggregate && (
                <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                    {useFormatDate(inventory.aggregate)}
                </Text>
            )}
        </TableCell>
    </TableRow>
);

export default CommercialInventoryListRow;
