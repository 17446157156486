import React from 'react';

import {TableRow, TableCell} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image, Text, TextEllipsis} from '@EcommerceShopify/styles';
import {useInventoryActions} from '@components/InventoryManagement/hooks/useInventoryActions';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import useFormatDate from '@/hooks/useFormatDate';
import useToast from '@/hooks/useToast';
import {ProductsListRowProps} from '@components/InventoryManagement/interfaces';
import {ActionMenuOption} from '@components/ActionMenu/ActionMenuOption';
import {InventoryProductItem} from '@components/InventoryManagement/Redux/interfaces';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {getStyle} from '@components/InventoryManagement/constants';
import {StatusPill} from '@components/InventoryManagement/styles';
import {PRODUCT_OPTIONS, INVENTORY_PLEDGE_STATUS} from '@components/InventoryManagement/constants';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

const InventoryProductListRow = ({
    inventory,
    handleItemSelect,
    handleItemMenuClick,
    isMenuOpen,
}: ProductsListRowProps) => {
    const {handleEndorsementDischarge} = useInventoryActions();
    const {showToast, ToastContainer} = useToast();

    const handleError = (message: string) => {
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const getItemMenuOptions = (item: InventoryProductItem) => [
        {
            id: 'extension',
            name: PRODUCT_OPTIONS.EXTEND,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    item,
                    {extend: true},
                    handleError
                ),
        },
        {
            id: 'passToCapital',
            name: PRODUCT_OPTIONS.PASS_TO_CAPITAL,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    item,
                    {addPay: true},
                    handleError
                ),
        },
        {
            id: 'discharge',
            name: PRODUCT_OPTIONS.DISCHARGE,
            action: () =>
                handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.DISCHARGE, item, {}, handleError),
        },
    ];
    const scoreStyle = getStyle(inventory.itemStatus);
    return (
        <>
            <TableRow
                borderBottom="1px solid #E8E9EA"
                gridColumns="1.125rem 15.625rem repeat(4, minmax(5rem, 1fr)) 1.5rem"
                gap="1rem"
            >
                <TableCell>
                    <Checkbox
                        checked={inventory.selected}
                        onChange={() => handleItemSelect(inventory.itemId)}
                    />
                </TableCell>
                <TableCell>
                    <ContainerFlex Gap="0.5rem">
                        <Image
                            src={inventory.itemImage}
                            Height="3rem"
                            Width="3rem"
                            Radius="0.25rem"
                            Border="1px solid #E8E9EA"
                            alt="article"
                        />
                        <ContainerFlex FlexDir="column" Gap="0.5rem" Align="flex-start">
                            <TextEllipsis Color="#5A5AFF" FontSize="0.875rem" FontWeight="700">
                                {inventory.itemName}
                            </TextEllipsis>
                            <TextEllipsis Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                {inventory.itemLocation}
                            </TextEllipsis>
                        </ContainerFlex>
                    </ContainerFlex>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <TextEllipsis Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {numberToCurrency(inventory.loan)}
                    </TextEllipsis>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700">
                        {inventory.contractNumber}
                    </Text>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {useFormatDate(inventory.expires)}
                    </Text>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <StatusPill
                        border={scoreStyle.border}
                        backgroundColor={scoreStyle.backgroundColor}
                    >
                        <Text FontWeight="500" FontSize="0.75rem" Color={scoreStyle.color}>
                            {inventory.itemStatus}
                        </Text>
                    </StatusPill>
                </TableCell>
                <TableCell padding="0" overFlow="visible">
                    {inventory.itemStatus === INVENTORY_PLEDGE_STATUS.IN_EFFECT && (
                        <ContainerFlex Cursor="pointer" Height="2rem" Width="2rem">
                            <ActionMenuOption
                                id={inventory.itemId}
                                menuOptions={getItemMenuOptions(inventory)}
                                onOptionClick={() => handleItemMenuClick(inventory)}
                                isOpen={isMenuOpen}
                            />
                        </ContainerFlex>
                    )}
                </TableCell>
            </TableRow>
            <ToastContainer />
        </>
    );
};

export default InventoryProductListRow;
