import React from 'react';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerImg,
    Text,
    Image,
    Input,
} from '@components/Shopify/Ecommerce/styles';
import {TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {SearchInput} from '@/components/Customer/Styles';
import {Controller} from 'react-hook-form';
import automatiCarge from '@/images/autoIcone.svg';
import {DataPersonalUsers} from '@/components/NewUserEmployee/Operational/interface';
import {ErrorPassword} from '@/components/NewUserEmployee/MessagePassword';
import {ErrorPasswordRepeat} from '@/components/NewUserEmployee/MesageRepeat';
import closed from '@/images/closeRed.svg';
import {repeatPassword, searchStyles} from '@/components/NewUserEmployee/Operational/styles';
import {InputCustomContainer} from '@components/PersonalLoans/NewLoanProduct/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {INewPassWord} from '@components/NewUserEmployee/interface';

export const PassWordInputs: React.FC<INewPassWord> = ({
    control,
    errors,
    setValue,
    register,
    periodOptions,
    setPeriodOptions,
    getFieldValue,
    handleGeneratePassword,
    viewPassword,
    setViewPassword,
}) => {
    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <ContainerCellGrid Gap="16px">
                <ContainerFlex Align="start" FlexDir="column" Gap="4px">
                    <Text FontWeight="700">{TEXT_NEW_USER.ACCESS_C}</Text>
                    <Controller<DataPersonalUsers>
                        name="password"
                        control={control}
                        render={({field}) => (
                            <>
                                <SearchInput {...searchStyles} error={!!errors.password}>
                                    <input
                                        type={viewPassword ? 'text' : 'password'}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        onBlur={field.onBlur}
                                        value={getFieldValue(field.value)}
                                        name={field.name}
                                        maxLength={50}
                                    />
                                    <ContainerImg
                                        onClick={() => setViewPassword(!viewPassword)}
                                        Cursor="pointer"
                                    >
                                        <Image
                                            src={viewPassword ? VisibilityOn : VisibilityOff}
                                            alt="icon-visibility"
                                            Cursor="pointer"
                                        />
                                    </ContainerImg>
                                </SearchInput>
                                <Controller
                                    name="repeatPassword"
                                    control={control}
                                    render={({field: repeatField}) => (
                                        <>
                                            {field.value && (
                                                <ErrorPassword
                                                    error={!!errors.password}
                                                    password={getFieldValue(field.value)}
                                                    repeatPassword={getFieldValue(
                                                        repeatField.value
                                                    )}
                                                    errorMessage={''}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                {errors.password && (
                                    <ContainerFlex Justify="start" Gap="4px">
                                        <Image src={closed} alt="closed-icon" />
                                        <Text Color="#ef4f55" FontSize="0.75rem">
                                            {errors.password.message}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </>
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex {...repeatPassword}>
                    <Text FontWeight="700">{TEXT_NEW_USER.ACCESS_R}</Text>
                    <Controller<DataPersonalUsers>
                        name="repeatPassword"
                        control={control}
                        render={({field}) => (
                            <>
                                <SearchInput {...searchStyles} error={!!errors.repeatPassword}>
                                    <input
                                        type={viewPassword ? 'text' : 'password'}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        onBlur={field.onBlur}
                                        value={getFieldValue(field.value)}
                                        name={field.name}
                                        maxLength={50}
                                    />
                                    <ContainerImg
                                        onClick={() => setViewPassword(!viewPassword)}
                                        Cursor="pointer"
                                    >
                                        <Image
                                            src={viewPassword ? VisibilityOn : VisibilityOff}
                                            alt="icon-visibility"
                                            Cursor="pointer"
                                        />
                                    </ContainerImg>
                                </SearchInput>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field: passwordField}) => (
                                        <>
                                            {field.value && (
                                                <ErrorPasswordRepeat
                                                    error={!!errors.repeatPassword}
                                                    password={getFieldValue(passwordField.value)}
                                                    repeatPassword={getFieldValue(field.value)}
                                                    errorMessage={''}
                                                />
                                            )}
                                        </>
                                    )}
                                />
                                {errors.repeatPassword && (
                                    <ContainerFlex Justify="start" Gap="4px">
                                        <Image src={closed} alt="closed-icon" />
                                        <Text Color="#ef4f55" FontSize="0.75rem">
                                            {errors.repeatPassword.message}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </>
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="center" Height="70px" Padding="24px 0 0 0">
                    <ContainerFlex
                        Justify="center"
                        Align="center"
                        Width="max-content"
                        Cursor="pointer"
                        onClick={() => handleGeneratePassword()}
                    >
                        <Image Width="24px" height="24px" src={automatiCarge} alt="auto-icone" />
                        <Text FontWeight="700" Color="#5A5AFF" Cursor="pointer">
                            {TEXT_NEW_USER.AUTO_ACCESS}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerCellGrid>

            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.25rem">
                <Text FontWeight="700">{TEXT_NEW_USER.AUTO_NEW_ACCESS}</Text>
                <ContainerFlex Gap="16px" Width="33%">
                    <InputCustomContainer
                        Gap="0.25rem"
                        Width="100%"
                        Display="grid"
                        GridColumns="1fr 216.236px"
                        InputPadding="8px 16px"
                        error={!!errors.numberPeriods || !!errors.catPasswordUpdateId}
                    >
                        <Input maxLength={2} {...register('numberPeriods')} />
                        <Controller
                            name="catPasswordUpdateId"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    options={periodOptions}
                                    isSearchable={false}
                                    placeholder="Mes"
                                    radius="0px 32px 32px 0px"
                                    border="none"
                                    BorderLeft="1px solid #E8E9EA"
                                    hborder=""
                                    controlTop=""
                                    width="100%"
                                    PaddingSelection="0px"
                                />
                            )}
                        />
                    </InputCustomContainer>
                </ContainerFlex>
                {errors.numberPeriods && (
                    <Text FontSize="0.75rem" Color="#ef4f55">
                        {errors.numberPeriods.message}
                    </Text>
                )}
                {errors.catPasswordUpdateId && (
                    <Text FontSize="0.75rem" Color="#ef4f55">
                        {errors.catPasswordUpdateId.label?.message}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
