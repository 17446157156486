import React, {useEffect} from 'react';
import {ContainerFlex, Image} from '@EcommerceShopify/styles';
import qrIcon from '@images/qrcodeNav.svg';
import searchIcon from '@components/Branches/Images/AppMagnifierIcon.svg';
import {SearchInput} from '@Customer/Styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {
    COMMERCIAL_INVENTORY_FILTER_LABELS,
    COMMERCIAL_OPTIONS_DEFAULT,
    INVENTORY_PAGE,
} from '@components/InventoryManagement/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {SingleValue} from 'react-select';
import {BranchStorageOption, OptionValue} from '@/components/InventoryManagement/interfaces';
import {
    setCommercialFilterByBranch,
    setCommercialFilterByState,
    setCommercialFilterByStorage,
} from '@/components/InventoryManagement/Redux/Actions/commercialInventoryFilters';
import {changeCommercialInventoryPage} from '@/components/InventoryManagement/Redux/Actions/getCommercialInventory';
import {fetchConservationStates} from '@/components/InventoryManagement/Redux/Actions/getConservationStates';
import CommercialPriceInputDropdown from '@/components/InventoryManagement/CommercialPriceInputDropdown';
import {fetchBranchStoragesByCompany} from '@/components/InventoryManagement/Redux/Actions/getBranchStorages';

const CommercialInventoryFilters = () => {
    const dispatch = useDispatch();

    const userData = useSelector((state: RootState) => state.getUsersValidation.userData);
    const branchStorages = useSelector((state: RootState) => state.getBranchStorages.storages);
    const conservationStates = useSelector(
        (state: RootState) => state.getConservationStates.states
    );

    const stateOptions: OptionValue[] = [
        COMMERCIAL_OPTIONS_DEFAULT.STATE,
        ...conservationStates.map((state) => {
            return {
                label: state.stateConservationName,
                value: state.id,
            };
        }),
    ];

    const branchOptions: BranchStorageOption[] = [
        COMMERCIAL_OPTIONS_DEFAULT.BRANCH,
        ...(branchStorages.map((storage) => {
            return {value: storage.id, label: storage.name, type: storage.type};
        }) ?? []),
    ];

    const handleChangeBranch = (option: SingleValue<BranchStorageOption>) => {
        dispatch(changeCommercialInventoryPage(1));
        if (option?.type === COMMERCIAL_INVENTORY_FILTER_LABELS.BRANCH) {
            dispatch(setCommercialFilterByBranch(option.value));
            return;
        }
        if (option) dispatch(setCommercialFilterByStorage(option.value));
    };

    const handleChangeState = (option: SingleValue<OptionValue>) => {
        dispatch(changeCommercialInventoryPage(1));
        if (option) dispatch(setCommercialFilterByState(option.value));
    };

    useEffect(() => {
        if (!userData?.enterpriceDetails) return;
        dispatch(fetchConservationStates(userData?.token));
        dispatch(
            fetchBranchStoragesByCompany(
                userData.enterpriceDetails[0].enterpriceId,
                userData?.token
            )
        );
    }, []);

    return (
        <>
            <ContainerFlex Align="baseline" Padding="0" Height="2.5rem" Gap="1rem">
                <SearchInput
                    Height="2.7rem"
                    Position="none"
                    FontSize="1rem"
                    PlaceholderColor="#A1A5AA"
                    Padding="0 0 0 1rem"
                    WBoxShadow=""
                >
                    <input type="text" autoComplete="off" placeholder={INVENTORY_PAGE.SEARCH} />
                    <ContainerFlex
                        Justify="flex-end"
                        Align="center"
                        Gap="0.5rem"
                        Padding="0.5rem 1rem"
                    >
                        <Image src={qrIcon} alt="icon-qr" Width="1.5rem" Height="1.5rem" />
                        <Image
                            src={searchIcon}
                            alt="icon-magnifier"
                            Cursor="pointer"
                            Width="1.5rem"
                            Height="1.5rem"
                        />
                    </ContainerFlex>
                </SearchInput>
                <SelectGeneral
                    width="13.938rem"
                    isSearchable={false}
                    options={branchOptions}
                    defaultValue={COMMERCIAL_OPTIONS_DEFAULT.BRANCH}
                    onChange={handleChangeBranch}
                />
            </ContainerFlex>
            <ContainerFlex Gap="1rem">
                <CommercialPriceInputDropdown />
                <SelectGeneral
                    placeholder={INVENTORY_PAGE.STATUS}
                    isSearchable={false}
                    options={stateOptions}
                    defaultValue={COMMERCIAL_OPTIONS_DEFAULT.STATE}
                    onChange={handleChangeState}
                />
            </ContainerFlex>
        </>
    );
};

export default CommercialInventoryFilters;
