import React from 'react';
import InventoryLocationStorageList from '@components/InventoryManagement/InventoryStorageList';
import {InventoryLocationStorageProps} from '@components/InventoryManagement/interfaces';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import InventoryLocationStorageListLevels from '@components/InventoryManagement/InventorLocationStorageLevels';

const InventoryLocationStorage: React.FC<InventoryLocationStorageProps> = ({storage}) => {
    const {selectedStorageListItem} = useSelector((state: RootState) => {
        return state.InventoryLocationStorage;
    });

    return (
        <>
            {!selectedStorageListItem || selectedStorageListItem.parentId !== storage.id ? (
                <InventoryLocationStorageList storage={storage} />
            ) : (
                <InventoryLocationStorageListLevels />
            )}
        </>
    );
};
export default InventoryLocationStorage;
