import * as yup from 'yup';
import {
    MAX_ACCOUNT_LENGTH,
    MAX_RFC_LENGTH,
    MIN_RFC_LENGTH,
    validationYup,
} from '@/components/CashFlow/CreateProvider/constants';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const schema = yup.object().shape({
    type: yup.object().shape({
        value: yup.string().required(validationYup.required).min(1),
        label: yup.string().required(validationYup.providerTypeRequired),
    }),
    providerName: yup
        .string()
        .required(validationYup.providerRequired)
        .max(50, validationYup.max50),
    activity: yup
        .object()
        .shape({
            value: yup.string().required(validationYup.required).min(1),
            label: yup.string().required(validationYup.required),
        })
        .required(validationYup.providerTypeRequired),
    rfc: yup
        .string()
        .required(validationYup.RFCRequired)
        .min(MIN_RFC_LENGTH, validationYup.RFCLength)
        .max(MAX_RFC_LENGTH, validationYup.RFCLength),
    accountNumber: yup
        .number()
        .test(
            'maxDigits',
            validationYup.max20,
            (number) => String(number).length <= MAX_ACCOUNT_LENGTH
        )
        .integer(validationYup.validNumber)
        .typeError(validationYup.validNumber),
    phone: yup
        .string()
        .required(validationYup.phoneRequired)
        .min(10, validationYup.max10)
        .max(10, validationYup.max10),
    email: yup
        .string()
        .required(validationYup.emailRequired)
        .matches(EMAIL_REGEX, validationYup.emailValid),
});
