import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import CompanyInfo from '@components/GlobalQuoter/CompanyInfo';
import LoanListCardButtons from '@/components/GlobalQuoter/LoanListCardButtons';
import {DivisorLine} from '@components/MyAccount/styles';
import {LOAN_INFO} from '@/components/GlobalQuoter/TempConstants';
import {PERSONAL_LOAN_CONFIRMATION} from '@/components/PersonalLoans/constants';
import {IAdditionalOffer} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const AdditionalLoansOptionsCard = ({
    companyName,
    logoCompany,
    frecuencyName,
    anualRate,
    amountMax,
    countPayments,
    companyId,
    frequencyTermCreditId,
}: IAdditionalOffer) => {
    const getRandomeColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    return (
        <>
            <DivisorLine width="100%" height="1px" />
            <ContainerFlex Height="129px" Justify="flex-start" Gap="16px">
                <CompanyInfo
                    needTag={false}
                    companyName={companyName}
                    rating={0}
                    logo={logoCompany}
                    companyAvatarColor={getRandomeColor()}
                    labelPersonCounter={0}
                    isConfirmationPage={true}
                />
                <ContainerFlex Justify="center" Align="flex-start" Height="129px">
                    <ContainerFlex
                        FlexDir="column"
                        Justify="center"
                        Align="flex-start"
                        MaxW="140px"
                    >
                        <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                            {LOAN_INFO.LABELS.MAX_AMOUNT}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            FontSize="1.5rem"
                            FontFamily="Nunito"
                            FontWeight="700"
                        >
                            {numberToCurrencyWithoutCents(amountMax)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Gap="16px"
                        backG="#FAFAFA"
                        Radius="16px"
                        FlexDir="column"
                        Padding="16px"
                    >
                        <ContainerFlex Justify="flex-start" Align="flex-start" Gap="4px">
                            <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                                {LOAN_INFO.LABELS.PAYMENT_NUM}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                FontSize="0.875rem"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {countPayments}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="flex-start" Align="flex-start" Gap="4px">
                            <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                                {LOAN_INFO.LABELS.LOAN_FREQ}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                FontSize="0.875rem"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {frecuencyName}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="flex-start" Align="flex-start" Gap="4px">
                            <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                                {PERSONAL_LOAN_CONFIRMATION.INTERES_FROM}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                FontSize="0.875rem"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {anualRate}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <LoanListCardButtons
                    companyId={companyId}
                    frequencyTermCreditId={frequencyTermCreditId}
                    maxWidth="205px"
                />
            </ContainerFlex>
        </>
    );
};

export default AdditionalLoansOptionsCard;
