import React from 'react';
import {TableCell} from '@General/Atoms/TableList/styles';
import {Text} from '@EcommerceShopify/styles';
import {ListColumnHeaderProps} from '@components/InventoryManagement/interfaces';

const ListColumnHeader = ({label, renderSortIcon}: ListColumnHeaderProps) => (
    <TableCell padding="0.25rem 0" gap="0.25rem">
        <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="700" oFlow="none">
            {label}
        </Text>
        {renderSortIcon && renderSortIcon()}
    </TableCell>
);

export default ListColumnHeader;
