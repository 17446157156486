import React from 'react';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import {DUMMY_THUMBNAIL} from '@components/InventoryManagement/constants';

const ProductImages: React.FC = () => {
    return (
        <ContainerFlex Align="start" Justify="start" Gap="1rem" Padding="1.5rem 1rem 0rem 1.5rem">
            <ContainerFlex
                FlexDir="Column"
                Align="center"
                Justify="start"
                Gap="0.625rem"
                Width="4rem"
                MarginTop="8.594rem"
            >
                {Array.from({length: DUMMY_THUMBNAIL.thumbnailCount}).map((_, index) => (
                    <Image
                        key={index}
                        src={DUMMY_THUMBNAIL.image}
                        Height="3.25rem"
                        Width="100%"
                        Radius="0.5rem"
                        HOpacity="0.5"
                    />
                ))}
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start" Gap="1rem">
                <Image
                    src={DUMMY_THUMBNAIL.image}
                    Height="34.063rem"
                    Width="100%"
                    Radius="0.5rem"
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ProductImages;
