import {useEffect, useState} from 'react';

import {FeatureUnified, FeatureHereditaryData} from '@Articles/interface';
import {IDynamicFeatures} from '@components/Catalogue/interfaces';
import {ICharacteristics} from '@components/Articles/Redux/interfaces';

export const useNormalizedFeatures = (
    hereditaryFeatures: FeatureHereditaryData[],
    DinamicFeature: IDynamicFeatures[],
    DataUpdate: ICharacteristics[] | undefined
) => {
    const [normalizedFeatures, setNormalizedFeatures] = useState<FeatureUnified[]>([]);

    function getFirstItemBeforeComma(valueString: string) {
        if (!valueString) return '';
        const firstPart = valueString.split(',')[0];

        if (!firstPart.includes('|')) {
            return firstPart.trim();
        }

        return firstPart.split('|')[0].trim() || '';
    }

    useEffect(() => {
        const normalizeFeatures = (): FeatureUnified[] => {
            const hereditary =
                hereditaryFeatures?.map((f) => ({
                    id: f.itemControlId,
                    name: f.itemControlName,
                    isRequired: f.itemControlMandatory,
                    includeLabel: f.itemControlIncludeTag,
                    isInherited: f.isInherited,
                    selected: false,
                    valuename: getFirstItemBeforeComma(f.values),
                })) || [];

            const dynamic =
                DinamicFeature?.map((f) => ({
                    id: f.idTypeCharacteristics,
                    name: f.nameCharacteristics,
                    isRequired: !!f.isRequerid,
                    includeLabel: f.includeLabelPrinter,
                    isInherited: f.isInherited,
                    selected: false,
                    valuename:
                        f.details && f.details.length > 0 ? f.details[0].valueDescription : '',
                })) || [];

            const updateData =
                DataUpdate?.map((f) => ({
                    id: f.articleDetailId,
                    name: f.propertyName,
                    isRequired: !!f.isRequerid,
                    includeLabel: f.includeLabel,
                    isInherited: false,
                    selected: false,
                    valuename:
                        f.articlesPropertyLists.length > 0
                            ? f.articlesPropertyLists[0].descriptionItem
                            : getFirstItemBeforeComma(f.values),
                })) || [];

            return [...hereditary, ...dynamic, ...updateData];
        };

        setNormalizedFeatures(normalizeFeatures());
    }, [hereditaryFeatures, DinamicFeature, DataUpdate]);

    return normalizedFeatures;
};
