import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {clearDocumentsError} from '@Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import documentIcon from '@images/documentIconDark.svg';
import checkMark from '@images/checkMark.svg';
import refreshIcon from '@images/icon-refresh.svg';
import {DOCUMENTS} from '@Shopify/ClientProfile/constants';
import ClientProofDocsModal from '@Shopify/ClientProfile/ClientProofDocsModal';
import DomiciliaryProofInputBox from '@/components/Shopify/ClientProfile/Information/DomiciliaryProofInputBox';

const Receipts: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const dispatch = useDispatch();
    const [showAddressProofModal, setShowAddressProofModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const clientDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.documents
    );

    const incomeDocs = clientDocs?.filter((doc) => doc.document === DOCUMENTS.INCOME_DOCUMENT_TYPE);

    const handleOpenSelection = () => {
        dispatch(clearDocumentsError());
        setShowAddressProofModal(true);
    };

    const handleAddressProofSuccess = () => {
        setErrorMessage('');
        setShowAddressProofModal(false);
    };

    const handleAddressProofError = () => {
        setErrorMessage(DOCUMENTS.ADDRESS_PROOF_ERROR);
        setShowAddressProofModal(false);
    };

    useEffect(() => {
        dispatch(clearDocumentsError());
    }, []);

    return (
        <>
            <ContainerFlex
                Padding="1rem 1.5rem"
                FlexDir="column"
                Align="start"
                Gap="1rem"
                Radius="1.5rem"
                Border="1px solid #E8E9EA"
                Self="stretch"
            >
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                    {DOCUMENTS.TITLE}
                </Text>
                <ContainerFlex
                    Align="flex-start"
                    Justify="start"
                    FlexDir="column"
                    AlignContent="flex-start"
                    Gap="1rem"
                    FlexWrap="nowrap"
                >
                    <DomiciliaryProofInputBox
                        onOpenSelection={handleOpenSelection}
                        error={errorMessage}
                    />
                    <ContainerFlex
                        FlexDir="column"
                        Gap="0.5rem"
                        Align="flex-start"
                        Justify="flex-start"
                        Width="36.5rem"
                    >
                        <Text
                            Padding="0.125rem 0"
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {DOCUMENTS.ADD_PROOF_OF_INCOME}
                        </Text>
                        <ContainerFlex
                            Gap="1rem"
                            Height="3.5rem"
                            Border="1px solid #E8E9EA"
                            Radius="1rem"
                            PaddingR="1rem"
                        >
                            <FlexPanel
                                Padding="1rem"
                                Justify="flex-start"
                                Align="center"
                                Background="#FAFAFA"
                                BottomLeftRad="1rem"
                                TopLeftRad="1rem"
                            >
                                <Image
                                    src={documentIcon}
                                    Width="1.5rem"
                                    Height="1.5rem"
                                    ObjectFit="cover"
                                />
                            </FlexPanel>
                            <ContainerFlex
                                Padding="0.5rem 0 0.25rem 0"
                                FlexDir="column"
                                Align="flex-start"
                                Gap="0.25rem"
                                Flex="1 0 0"
                                Self="stretch"
                                OverFlowX="scroll"
                                OverFlowY="hidden"
                                HeightScroll="0.3rem"
                            >
                                <Text
                                    FontWeight="500"
                                    FontSize="0.875rem"
                                    Color="#2A2C2F"
                                    oFlow="visible"
                                >
                                    {incomeDocs ? incomeDocs[0]?.nameInDocuments : ''}
                                </Text>
                                <ContainerFlex Gap="0.25rem" Justify="flex-start">
                                    <Image src={checkMark} Width="1rem" Height="1rem" />
                                    <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                                        {incomeDocs ? incomeDocs[0]?.size : ''}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <FlexPanel
                                Align="center"
                                Justify="center"
                                Gap="0.25rem"
                                Cursor="pointer"
                            >
                                <Image src={refreshIcon} Width="1.5rem" Height="1.5rem" />
                                <Text
                                    FontWeight="700"
                                    FontSize="0.875rem"
                                    Color="#5A5AFF"
                                    LHeight="120%"
                                    TextAlign="center"
                                    Cursor="inherit"
                                >
                                    {DOCUMENTS.REPLACE}
                                </Text>
                            </FlexPanel>
                        </ContainerFlex>
                        <Text FontSize="0.875rem" FontWeight="400" LHeight="120%">
                            {DOCUMENTS.MAX_SIZE_17}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {showAddressProofModal && (
                <ClientProofDocsModal
                    clientId={clientProfile.clientId}
                    showModal={showAddressProofModal}
                    setShowModal={setShowAddressProofModal}
                    onSuccess={handleAddressProofSuccess}
                    onError={handleAddressProofError}
                />
            )}
        </>
    );
};
export default Receipts;
