import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {MESSAGE_NO_RESULTS} from '@/components/GlobalQuoter/TempConstants';

const MessageNoResults = () => {
    return (
        <ContainerFlex
            Padding="4px 8px"
            Width="100%"
            Height="60px"
            backG="#DBF4FFA3"
            Radius="8px"
            FlexDir="column"
        >
            <Text FontFamily="Nunito" FontSize="0.88rem" FontWeight="400" Color="#54575C">
                {MESSAGE_NO_RESULTS.FIRST_PARAGRAPH}
            </Text>
            <Text FontFamily="Nunito" FontSize="0.88rem" FontWeight="400" Color="#54575C">
                {MESSAGE_NO_RESULTS.SECOND_PARAGRAPH}
            </Text>
            <Text FontFamily="Nunito" FontSize="0.88rem" FontWeight="700" Color="#54575C">
                {MESSAGE_NO_RESULTS.FINAL_PARAGRAPH}
            </Text>
        </ContainerFlex>
    );
};

export default MessageNoResults;
