import {
    GET_CAT_SUBFAMILY,
    GET_CAT_SUBFAMILY_ERROR,
    GET_CAT_SUBFAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {updateSubFamilyChildren} from '@components/RequestNewCatalogLevel/Redux/Actions/GetCatGroupAction';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {getAuctionBrandAction} from '@/components/AuctionPass/Redux/Actions/GetBrandAction';
import {getAllChildren} from '@/components/AuctionPass/Redux/Actions/GetFamilyAction';
export const getSubFamily = () => {
    return {
        type: GET_CAT_SUBFAMILY,
    };
};

export const getSubFamilySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_SUBFAMILY_SUCCESS,
        payload: result,
    };
};

export const getSubFamilyError = (error: AxiosError) => {
    return {
        type: GET_CAT_SUBFAMILY_ERROR,
        payload: error,
    };
};

export const getAuctionSubFamilyAction = (familyId: number, companyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getSubFamily());
        const params = {
            familyId: familyId?.toString(),
            companyId: companyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAuctionSubFamilies}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getSubFamilySuccess(response));
            dispatch(updateSubFamilyChildren(familyId, response.data.data));
            dispatch(getAllChildren(response.data.data, getAuctionBrandAction, companyId, token));
        } catch (error) {
            dispatch(getSubFamilyError(error as AxiosError));
        }
    };
};
