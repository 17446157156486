import {
    CREATE_USER_MARKET,
    CREATE_USER_MARKET_ERROR,
    CREATE_USER_MARKET_SUCCESS,
    RESET_USER_MARKET_DATA,
} from '@components/Login/MarketPlaceLogin/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDataLogin} from '@components/Login/interfaces';

const initialState: IDataLogin = {
    userData: null,
    preData: null,
    loading: false,
    error: false,
    errorData: null,
    userType: '',
    userExists: true,
    token: '',
};

const creaateUserData = (
    state: IDataLogin = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IDataLogin => {
    switch (action.type) {
        case CREATE_USER_MARKET:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CREATE_USER_MARKET_SUCCESS:
            return {
                ...state,
                userData: action.payload.data.data,
                token: action.payload.data.token,
                loading: false,
            };
        case CREATE_USER_MARKET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                userExists: false,
                errorData: action.error,
            };
        case RESET_USER_MARKET_DATA:
            return {
                ...state,
                userExists: true,
                userData: null,
            };
        default:
            return state;
    }
};

export default creaateUserData;
