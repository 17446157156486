import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IQuoterHeaderProps} from '@/components/GlobalQuoter/interface';

const QuoterHeader: React.FC<IQuoterHeaderProps> = ({title, subTitle, isExpressQuoter}) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Gap="8px"
            Justify="flex-start"
            Align="flex-start"
            Height={isExpressQuoter ? '70px' : '80px'}
        >
            <Text
                FontSize={isExpressQuoter ? '2.0rem' : '2.5rem'}
                FontFamily="Nunito"
                FontWeight="500"
                Color="#1D1E20"
            >
                {title}
            </Text>
            <Text FontSize="1.25rem" FontFamily="Nunito" FontWeight="600" Color="#1D1E20">
                {subTitle}
            </Text>
        </ContainerFlex>
    );
};

export default QuoterHeader;
