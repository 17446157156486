import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    PRE_APPROVAL_TEXTS,
    TABLE_PRE_APPROVAL_FILTER_STATUS,
} from '@/components/PreApproval/constants';
import ProductImage from '@images/ProductsAtaskate.svg';
import Add from '@images/add - plus.svg';
import {useNavigate} from 'react-router-dom';
import {IEmptyPreApprovalProps} from '@/components/PreApproval/interfaces';

const EmptyPreApproval = ({status}: IEmptyPreApprovalProps) => {
    const navigate = useNavigate();
    return (
        <ContainerFlex
            Padding="8px 0"
            FlexDir="column"
            Justify="center"
            Align="center"
            Gap="8px"
            Bt="1px solid #E8E9EA"
        >
            <Image src={ProductImage} alt="product" Padding="10.5px 27.061px 10.5px 26.88px" />
            {status === TABLE_PRE_APPROVAL_FILTER_STATUS[0].value ? (
                <ButtonGenerals
                    BackGC="transparent"
                    HBackG="transparent"
                    onClick={() => navigate('/NewLoanCondition')}
                >
                    {/* TODO: Cambiar a archivo router cuando se suba la historia 209 */}
                    <Image src={Add} alt="add" Cursor="pointer" Width="20px" Height="20px" />
                    <Text FontSize="0.875rem" FontWeight="700" Color="#5A5AFF" Cursor="pointer">
                        {PRE_APPROVAL_TEXTS.CREATE_FIRST_CONDITION}
                    </Text>
                </ButtonGenerals>
            ) : (
                <Text FontSize="0.875rem" Color="#2A2C2F">
                    {PRE_APPROVAL_TEXTS.NO_ARCHIVED}
                </Text>
            )}
        </ContainerFlex>
    );
};

export default EmptyPreApproval;
