import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {routers} from '@/appRoute';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RESUMECREDITS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {showWarningMessageAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';

export const ResumeCredits = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {subTotal, loansCarCreditsSelected, showWarningMessage} = useSelector(
        (state: RootState) => state.shoppingCarClient
    );

    const handleOnClick = () => {
        if (showWarningMessage) return;
        if (!loansCarCreditsSelected.length) {
            dispatch(showWarningMessageAction());
            return;
        }
        navigate(routers.CreditsPayment);
    };

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Width="23.438rem"
            Height="15.63rem"
            FlexDir="column"
            backG="#FFFFFF"
            Padding="1.5rem"
            Border="solid 1px #0D166B"
            Radius="1.5rem"
            Gap="1.5rem"
        >
            <Text wSpace="normal" FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                {RESUMECREDITS.TITLE}
            </Text>
            <ContainerFlex Justify="space-between" Align="start" Height="1.5rem">
                <Text wSpace="normal" FontSize="1rem" Color="#2A2C2F">
                    {RESUMECREDITS.PAY} ({loansCarCreditsSelected.length})
                </Text>
                <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                    {numberToCurrencyWithoutCents(subTotal)}
                </Text>
            </ContainerFlex>
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <ContainerFlex Justify="space-between" Align="start" Height="auto">
                <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                    {RESUMECREDITS.TOTAL}
                </Text>
                <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                    {numberToCurrencyWithoutCents(subTotal)}
                </Text>
            </ContainerFlex>
            <ButtonGenerals
                Width="100%"
                Height="40px"
                Padding="8px 16px"
                FontWeight="500"
                onClick={handleOnClick}
                Cursor="pointer"
            >
                {RESUMECREDITS.BUTTON}
            </ButtonGenerals>
        </ContainerFlex>
    );
};
