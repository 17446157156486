import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, ContainerCellGrid, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {STEPFOURPAYMENT} from '@components/PersonalLoans/constants';
import {TransferPaymentDetailsProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

const TransferPaymentDetails = ({control, errors, options}: TransferPaymentDetailsProps) => {
    return (
        <ContainerFlex Justify="start" Gap="1rem" MarginBt="0.5rem">
            <ContainerCellGrid FlexDir="column" Align="start" Width="21.875rem" Gap="0.25rem">
                <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                    {STEPFOURPAYMENT.BANK}
                </Text>
                <Controller
                    control={control}
                    name="catBankId"
                    render={({field}) => (
                        <SelectGeneral
                            {...field}
                            options={options}
                            onChange={(selectedOption) => {
                                field.onChange(selectedOption ? selectedOption.value : '');
                            }}
                            value={options.find((option) => option.value === field.value) || null}
                            placeholder="Selecciona un banco"
                            loadingMessage={() => 'Cargando...'}
                            noOptionsMessage={() => 'Sin opciones'}
                            hasError={!!errors.catBankId}
                            maxH="40px"
                        />
                    )}
                />
                {(errors?.catBankId || errors?.accountClabe) && (
                    <ContainerFlex Justify="start" Gap="0.25rem" Height="16px">
                        <Text
                            Color="#A82424"
                            FontSize="0.75rem"
                            Align="start"
                            wSpace="normal"
                            FontWeight="400"
                        >
                            {errors?.catBankId?.message || ''}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid FlexDir="column" Align="start" Width="21.875rem" Gap="0.25rem">
                <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                    {STEPFOURPAYMENT.CLABE}
                </Text>
                <Controller
                    control={control}
                    name="accountClabe"
                    render={({field}) => (
                        <Input {...field} maxLength={19} error={!!errors?.accountClabe} />
                    )}
                />
                {(errors?.accountClabe || errors?.catBankId) && (
                    <ContainerFlex Justify="start" Gap="0.25rem" Height="16px">
                        <Text
                            Color="#A82424"
                            FontSize="0.75rem"
                            Align="start"
                            wSpace="normal"
                            FontWeight="400"
                        >
                            {errors?.accountClabe?.message}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerCellGrid>
        </ContainerFlex>
    );
};

export default TransferPaymentDetails;
