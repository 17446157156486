import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {RootState} from '@/config/store';
import {InfoUserdata} from '@/components/Users/Redux/Actions/GetInfoUserActions';
import {AddUpdateInfoUser} from '@/components/Users/Redux/Actions/UpdateInfoUserAction';
import {GetAdressUserInfo} from '@/components/Users/Redux/Actions/adressUserActions';
import {ContainerForm, ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {GENERAL_TITLES} from '@components/Users/UserProfile/constants';
import {
    contactSchema,
    DEFAULT_VALUES_CONTACT,
    IContactValues,
} from '@components/Users/UserProfile/yupSchema';
import {userProfile} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import Edit from '@images/editIcon.svg';
import AddressForm from '@components/Users/UserProfile/Addressform';
import {profileUser} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';
import {useNavigate} from 'react-router-dom';
export const AddressInfo: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const dataPersonal = useSelector((state: RootState) => state.getInfoUserData.userData);
    const address = useSelector((state: RootState) => state.getAdressUser.userData);
    const Credentials = useSelector(
        (state: RootState) => state.getInfoUserData.userData?.accessCredentials
    );
    const addressUser = useSelector(
        (state: RootState) => state.getInfoUserData.userData?.addressUser?.[0]
    );
    const loading = useSelector((state: RootState) => state.getInfoUserData.loading);
    const [isEdit, setIsEdit] = useState(false);

    const {
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm<IContactValues>({
        defaultValues: DEFAULT_VALUES_CONTACT,
        resolver: yupResolver(contactSchema),
    });

    useEffect(() => {
        if (addressUser && Object.keys(addressUser).length > 0) {
            reset({
                postal: addressUser.postalCode,
                street: addressUser.street,
                outsideNumber: addressUser.outdoorNumber,
                insideNumber: addressUser.interiorNumber,
                colony: addressUser.suburbName,
                town: addressUser.municipalityName,
                state: addressUser.stateName,
            });
        }
    }, [addressUser, reset]);

    const onSubmit = async (data: IContactValues) => {
        if (!token || !employeeId) return;
        const dataToSend = {
            userId: employeeId,
            firstname: dataPersonal.name || '',
            paternalLastname: dataPersonal.lastName || '',
            maternalLastname: dataPersonal.motherLastName || '',
            gender: dataPersonal.gender || '',
            mainPhoneNumber: dataPersonal.mainPhoneNumber,
            secondaryPhoneNumber: dataPersonal.secondaryPhoneNumber || '',
            birthDate: dataPersonal.birthdate || '',
            userCredentials: {
                email: Credentials.email || '',
                alternateEmail: Credentials.alternateEmail || '',
            },
            address: [
                {
                    codigoPostal: data.postal,
                    address: data.street,
                    externalNumber: data.outsideNumber || '',
                    internalNumber: data.insideNumber || '',
                    catColoniaId: data.catColony,
                },
            ],
        };
        try {
            await dispatch(AddUpdateInfoUser(dataToSend, token));

            const infoUserData = {
                userId: employeeId,
                userProfile,
            };
            await dispatch(InfoUserdata(token, infoUserData));
            await dispatch(profileUser(token, employeeId, navigate, false));
            setIsEdit(false);
        } catch (error) {
            return error;
        }
    };

    const handlePostalCodeChange = async (postalCode: string) => {
        await dispatch(GetAdressUserInfo(token, postalCode));
    };

    return (
        <ContainerForm FlexDir="column" Gap="16px" onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex Justify="space-between" Height="40px" Padding="0 0 16px 0">
                        <ContainerFlex Justify="start">
                            <Text FontSize="1.25rem" FontWeight="700">
                                {GENERAL_TITLES.TITLE_CONTACT_HEADER}
                            </Text>
                        </ContainerFlex>

                        {!isEdit && (
                            <ContainerFlex ColumnGap="8px" Justify="end">
                                <Image alt="save" src={Edit} />
                                <Text
                                    FontWeight="700"
                                    Color="#5A5AFF"
                                    Cursor="pointer"
                                    onClick={() => setIsEdit(true)}
                                >
                                    {GENERAL_TITLES.EDIT}
                                </Text>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>

                    <AddressForm
                        isEdit={isEdit}
                        addressUser={addressUser}
                        control={control}
                        errors={errors}
                        onPostalCodeChange={handlePostalCodeChange}
                        addressData={address}
                    />

                    {isEdit && (
                        <ContainerFlex Justify="end" Gap="16px" Height="max-content">
                            <ButtonGenerals
                                SecondaryButton="none"
                                Height="40px"
                                onClick={() => {
                                    setIsEdit(false);
                                    reset();
                                }}
                            >
                                {GENERAL_TITLES.CANCEL}
                            </ButtonGenerals>
                            <ButtonGenerals type="submit" Height="40px">
                                {GENERAL_TITLES.SAVE_CHANGES}
                            </ButtonGenerals>
                        </ContainerFlex>
                    )}
                </>
            )}
        </ContainerForm>
    );
};
