import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const useInventorySelectors = () => {
    return useSelector((state: RootState) => ({
        inventoryData: state.GetProductsForInventory.data,
        token: String(state.getUsersValidation.userData?.token),
        branchId: Number(state.getUsersValidation.userData?.branchDetails?.[0]?.branchId),
        cartShoppingId: Number(state.getUsersValidation.userData?.carShoopingId),
    }));
};
