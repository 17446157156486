import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {CommercialInventoryItem} from '@components/InventoryManagement/interfaces';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    INVENTORY_PRODUCT,
    NO_ITEMS_FOUNDED_TEXT,
    PRODUCT_CARD_ATTRIBUTES,
} from '@components/InventoryManagement/constants';
import CommercialCardFeature from '@components/InventoryManagement/CommercialCardFeature';
import {CheckboxWrapper} from '@/components/InventoryManagement/styles';

const CommercialProductCard = () => {
    function temporaryNullFunction() {
        return; //TODO: Replace with "add to cart" functionality when defined
    }

    function temporaryHandleChange() {
        return; //TODO: Implement when filters defined
    }
    const inventoryItems: CommercialInventoryItem[] | [] = useSelector(
        (state: RootState) => state.getCommercialInventory.data
    );

    const addToCartButton = {
        text: PRODUCT_CARD_ATTRIBUTES.ADD_BUTTON,
        onClick: temporaryNullFunction,
    };
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Gap="1rem"
            backG="#FAFAFA"
            Radius="1rem"
            FlexWrap="wrap"
        >
            {inventoryItems.length > 0 ? (
                inventoryItems.map((item, index) => (
                    <ContainerFlex
                        key={index}
                        FlexDir="Column"
                        MaxW="16.875rem"
                        backG="#FFFFFF"
                        Radius="1.25rem"
                        Justify="space-between"
                        Height="28.313rem"
                        BoxS="0 0.688rem 1.663rem 0 rgba(0, 0, 0, 0.06)"
                    >
                        <ContainerFlex
                            Align="start"
                            Justify="start"
                            Height="0rem"
                            Position="relative"
                            PositionTop="1rem"
                            PositionLeft="1rem"
                        >
                            <CheckboxWrapper>
                                <Checkbox onChange={temporaryHandleChange} />
                            </CheckboxWrapper>
                        </ContainerFlex>
                        <ContainerFlex
                            Align="center"
                            Justify="center"
                            Height="11.25rem"
                            Padding="0.047rem 0.047rem 0 0.047rem"
                        >
                            <Image
                                alt="product-image"
                                src={item.image}
                                Height="11.25rem"
                                width="100%"
                                BorderTLRadius="1.25rem"
                                BorderTRRadius="1.25rem"
                            />
                        </ContainerFlex>
                        <ContainerFlex
                            Align="start"
                            Justify="start"
                            Padding="1.5rem 1rem 1rem 1rem"
                            Height="3.5rem"
                        >
                            <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                                {item.pledge}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="Column"
                            Align="start"
                            Justify="start"
                            PaddingL="1rem"
                            Height="2.813rem"
                        >
                            <Text Color="#1D1E20" FontWeight="600" FontSize="1.25rem">
                                {numberToCurrency(item.price)}
                                {item.discount && (
                                    <Text
                                        Border="solid 1px #B1E7C9"
                                        BGColor="#E4F7EC"
                                        Color="#174A2E"
                                        FontWeight="700"
                                        FontSize="0.875rem"
                                        Margin="0.125rem 0.5rem 0.125rem 0.5rem"
                                        BorderRadius="2rem"
                                        Padding="0.125rem 0.5rem"
                                    >
                                        {item.discount}
                                        {INVENTORY_PRODUCT.PERCENT_OFF}
                                    </Text>
                                )}
                            </Text>

                            <Text
                                Color="#54575C"
                                FontWeight="400"
                                FontSize="0.875rem"
                                PaddingT="0.25rem"
                            >
                                {PRODUCT_CARD_ATTRIBUTES.SET_ASIDE}
                                {item.setAside}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="Column"
                            Align="start"
                            Justify="start"
                            Padding="1rem"
                            PaddingL="1rem"
                            Gap="0.5rem"
                        >
                            <CommercialCardFeature
                                alt={PRODUCT_CARD_ATTRIBUTES.CONSERVATION_ALT}
                                icon={PRODUCT_CARD_ATTRIBUTES.CONSERVATION_ICON}
                                title={item.conservation}
                            />
                            <CommercialCardFeature
                                alt={PRODUCT_CARD_ATTRIBUTES.BRANCH_ALT}
                                icon={PRODUCT_CARD_ATTRIBUTES.BRANCH_ICON}
                                title={item.branch}
                            />
                            <CommercialCardFeature
                                alt={PRODUCT_CARD_ATTRIBUTES.STATUS_ALT}
                                icon={PRODUCT_CARD_ATTRIBUTES.STATUS_ICON}
                                title={item.pledgeStatus}
                            />
                        </ContainerFlex>
                        <ContainerFlex
                            Align="center"
                            Justify="center"
                            Padding="0rem 1rem 1.5rem 1rem"
                            Height="4rem"
                        >
                            <ButtonGeneral
                                color="#5A5AFF"
                                backGround="#FFFFFF"
                                hText="#5A5AFF"
                                cursor="pointer"
                                border="1px solid #5A5AFF"
                                hBackG="#E5E5FF"
                                clic={addToCartButton.onClick}
                                width="100%"
                                height="2rem"
                                text={
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="700"
                                        Cursor="pointer"
                                        FontSize="1rem"
                                    >
                                        {addToCartButton.text}
                                    </Text>
                                }
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                ))
            ) : (
                <ContainerFlex Justify="center" Align="center">
                    <Text Color="#1D1E20" FontWeight="400" Cursor="pointer" FontSize="1rem">
                        {NO_ITEMS_FOUNDED_TEXT}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
export default CommercialProductCard;
