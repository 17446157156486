import styled from '@emotion/styled';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {IPropsCalendarField} from '@/components/CreateUsers/DatePiker/intefacesStyle';

const getCalendarFieldStyles = () => {
    return `
        border: 1px solid #E8E9EA;
        &:hover{
            border: 1px solid #5A5AFF;
        }
        &:focus-within {
            border: 1px solid #5A5AFF;
        }
        `;
};
const getCalendarFieldError = () => {
    return `
        border: 1px solid #A82424;
        &:hover{
            border: 1px solid #A82424;
        }
        `;
};

export const DatePickerCustom = styled(DesktopDatePicker)`
    .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
        z-index: 1;
    }
    .css-169iwlq-MuiCalendarPicker-root {
        height: 200px !important;
    }
`;

export const CalendarFieldComponent = styled.div<IPropsCalendarField>`
    display: flex;
    flex-direction: row;
    width: ${(props) => props.Width};
    height: 40px;
    border-radius: 32px;
    justify-content: space-between;
    padding: 0 1rem 0 0;
    font-size: 1rem;
    background: #ffffff;
    margin: ${(props) => props.Margin};
    & svg path {
        fill: ${({error}) => (error ? '#FF8E85' : `#ACACFF`)};
        content: '+';
    }
    ${({error}) => (!error ? getCalendarFieldStyles() : getCalendarFieldError())}
`;
export const InputCalendarField = styled.input<IPropsCalendarField>`
    display: flex;
    border-radius: 32px;
    padding-left: 16px;
    border: none;
    color: ${({error}) => (error ? '#FF6357' : `#2A2C2F`)};
    width: ${(props) => props.Width};
    font-size: ${(props) => props.FontSize};

    &:focus {
        outline: none;
    }
    &::placeholder {
        color: ${(props) => props.ColorPlace};
    }
    box-sizing: border-box;
`;

InputCalendarField.defaultProps = {
    ColorPlace: '#D4D6D8',
};

export const CalendarIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
`;
export const AddInfoContainer = styled.div<IPropsCalendarField>`
    display: flex;
    column-gap: 4px;
    align-items: center;
    & span {
        color: #71767d;
        margin-top: 4px;
    }
`;
export const CalendarFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    height: auto;
    width: 100%;
    user-select: none;
`;
