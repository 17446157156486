import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    GET_STORAGES,
    GET_STORAGES_ERROR,
    GET_STORAGES_SUCCESS,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const GetStorages = () => {
    return {
        type: GET_STORAGES,
    };
};

export const GetStoragesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_STORAGES_SUCCESS,
        payload: result,
    };
};

export const GetStoragesError = (error: AxiosError) => {
    return {
        type: GET_STORAGES_ERROR,
        payload: error,
    };
};

export const GetStoragesAction = (employeeId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        const params = {
            employeeId: employeeId.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        dispatch(GetStorages());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUserStorages}?${queryString}`;

        try {
            const response = await axios.get(url, {headers});
            dispatch(GetStoragesSuccess(response));
        } catch (error) {
            dispatch(GetStoragesError(error as AxiosError));
        }
    };
};

export default GetStoragesAction;
