import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {itemsModulesEmployee} from '@/components/SideBarMenu/Redux/modulesAdministratorActions';
import {RootState} from '@/config/store';
import {IModulesAdministrator, ISideBarMenuProps} from '@components/SideBarMenu/interfaces';
import {MenuAdminSettings} from '@/components/SideBarMenu/MenuAdminSettings';
import AccordionSideBarMenu from '@/components/SideBarMenu/AccordionSideBarMenu';
import MenuItem from '@/components/SideBarMenu/MenuItem';
import directoryIcon from '@/components/SideBarMenu/Icons/directoryIcon.svg';
import {OPERATOR_MENU} from '@/components/SideBarMenu/constantsText';
import homeIcon from '@/components/SideBarMenu/Icons/homeIcon.svg';
import clientsIcon from '@/components/SideBarMenu/Icons/groupsIcon.svg';
import closeIcon from '@/images/closeBlueIcone.svg';
import ataskateIcon from '@/images/AtaskatePos.svg';
import {ClickAwayListener} from '@mui/material';

const SideBarMainMenu: React.FC<ISideBarMenuProps> = ({openMenuPos, setOpenMenuPos}) => {
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const modulesSideBarMenu: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );
    const dispatch = useDispatch();

    const [state] = useState({
        expandedSubItems: [] as string[],
        clickedItems: null as number | null,
        clickedSubItem: null as string | null,
        menuSettings: false,
        settingsOpen: false,
    });

    useEffect(() => {
        if (token) dispatch(itemsModulesEmployee(token));
    }, []);

    const handleCloseSideBarMenu = () => {
        setOpenMenuPos(!openMenuPos);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpenMenuPos(false)}>
            <ContainerFlex
                Align="start"
                Justify="start"
                backG="#FAFAFF"
                Width="264px"
                Height="calc(100vh - 93px)"
                OverFlow="auto"
                Position="absolute"
                PositionTop="56px"
                ZIndex="5"
                BoxS="0px 0px 32px 0px rgba(0, 0, 0, 0.20)"
            >
                <ContainerFlex
                    Width={state.settingsOpen ? '56px' : '264px'}
                    Height="100%"
                    backG="#FAFAFF"
                    Padding={state.settingsOpen ? '1rem 0.5rem' : '1rem 0rem 1rem 1rem'}
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="8px"
                >
                    <ContainerFlex
                        Width="100%"
                        Height="40px"
                        Gap="8px"
                        Justify="start"
                        Align="center"
                        Padding="8px"
                    >
                        <Image
                            Height="30px"
                            Width="30px"
                            alt="close-blue-icon"
                            Cursor="pointer"
                            src={closeIcon}
                            onClick={handleCloseSideBarMenu}
                        />
                        <Image
                            Height="30px"
                            Width="130px"
                            alt="company-logo"
                            Cursor="pointer"
                            src={ataskateIcon}
                        />
                    </ContainerFlex>
                    <ContainerFlex
                        Width="100%"
                        Height="40px"
                        Gap="8px"
                        Justify="start"
                        Align="center"
                        Padding="8px"
                    >
                        <Image Height="20px" Width="20px" alt="home-icon" src={homeIcon} />
                        <Text
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            FontWeight="700"
                            Color="#2A2C2F"
                            Cursor="pointer"
                        >
                            {OPERATOR_MENU.HOME}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Width="100%"
                        Height="40px"
                        Gap="8px"
                        Justify="start"
                        Align="center"
                        Padding="8px"
                    >
                        <Image
                            Height="20px"
                            Width="20px"
                            alt="group-people-icon"
                            Cursor="pointer"
                            src={clientsIcon}
                        />
                        <Text
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            FontWeight="700"
                            Color="#2A2C2F"
                            Cursor="pointer"
                        >
                            {OPERATOR_MENU.CLIENTS}
                        </Text>
                    </ContainerFlex>
                    {modulesSideBarMenu
                        ?.filter((category) => category.item.length > 0)
                        ?.map((category) => (
                            <>
                                <Text
                                    FontFamily="Nunito"
                                    FontSize="0.88rem"
                                    FontWeight="700"
                                    Color="#2A2C2F"
                                    Padding="0px 8px 8px 8px"
                                >
                                    {category.title}
                                </Text>
                                {category?.item?.map((subCategory, index) => (
                                    <AccordionSideBarMenu
                                        key={index}
                                        summaryIcon={subCategory?.cssIcon || directoryIcon}
                                        summaryTitle={subCategory.title}
                                        hasItems={subCategory.item.length > 0}
                                        route={subCategory.route}
                                    >
                                        {subCategory?.item.map((item, index) => (
                                            <MenuItem key={index} {...item} />
                                        ))}
                                    </AccordionSideBarMenu>
                                ))}
                            </>
                        ))}
                </ContainerFlex>
                {state.menuSettings && <MenuAdminSettings />}
            </ContainerFlex>
        </ClickAwayListener>
    );
};

export default SideBarMainMenu;
