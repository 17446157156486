import {
    GET_CONDITIONS_START,
    GET_CONDITIONS_SUCCESS,
    GET_CONDITIONS_ERROR,
    GET_TOTAL_CONDITIONS_START,
    GET_TOTAL_CONDITIONS_SUCCESS,
    GET_TOTAL_CONDITIONS_ERROR,
} from '@/components/PreApproval/Redux/Types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';

export const getConditionsStart = () => {
    return {
        type: GET_CONDITIONS_START,
    };
};

export const getConditionsSuccess = (data: AxiosResponse) => {
    return {
        type: GET_CONDITIONS_SUCCESS,
        payload: data,
    };
};

export const getConditionsError = () => {
    return {
        type: GET_CONDITIONS_ERROR,
    };
};

export const getConditions = (
    token: string,
    statusConditionId: number,
    pagenNumber: number,
    pageSize: number
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getConditionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPreApprovalConditionsLoan}`,
                {headers, params: {statusConditionId, pagenNumber, pageSize}}
            );
            dispatch(getConditionsSuccess(response));
        } catch (e) {
            dispatch(getConditionsError());
        }
    };
};

export const getTotalConditionsStart = () => {
    return {
        type: GET_TOTAL_CONDITIONS_START,
    };
};

export const getTotalConditionsSuccess = (data: AxiosResponse) => {
    return {
        type: GET_TOTAL_CONDITIONS_SUCCESS,
        payload: data,
    };
};

export const getTotalConditionsError = () => {
    return {
        type: GET_TOTAL_CONDITIONS_ERROR,
    };
};

export const getTotalConditions = (token: string, companyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTotalConditionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSummaryCondition}`,
                {headers, params: {companyId}}
            );
            dispatch(getTotalConditionsSuccess(response));
        } catch (e) {
            dispatch(getTotalConditionsError());
        }
    };
};
