import React, {useState, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@EcommerceShopify/styles';
import {INVENTORY_PAWNBROKER, defaultBranchOption} from '@components/InventoryManagement/constants';
import {PriceRange} from '@components/InventoryManagement/interfaces';
import mobileIcon from '@images/mobileIcon.svg';
import contractIcon from '@images/contractIconDark.svg';
import InventoryFilterButton from '@components/InventoryManagement/InventoryFilterButton';
import InventoryFilters from '@components/InventoryManagement/InventoryFilters';
import ProductsForInventory from '@/components/InventoryManagement/ProductsForInventory';
import ContractsForInventory from '@/components/InventoryManagement/ContractsForInventory';
import {FetchBranchStorageByCompanyId} from '@/components/InventoryManagement/Redux/Actions/GetBranchStorageByCompanyId';

const Pawnbroker = () => {
    const dispatch = useDispatch();
    const [selectedFilter, setSelectedFilter] = useState<string>(INVENTORY_PAWNBROKER.PRODUCTS);
    const [productListView, setProductListView] = useState<boolean>(false);
    const [contractsListView, setContractsListView] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [priceRange, setPriceRange] = useState<PriceRange | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<{value: number; label: string} | null>(
        null
    );
    const [selectedBranch, setSelectedBranch] = useState<{value: number; label: string} | null>(
        defaultBranchOption
    );

    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const companyId = useSelector((state: RootState) =>
        Number(state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId)
    );
    const contractStatuses = useSelector((state: RootState) => {
        return state.GetContractStatuses;
    });
    const statuses =
        (contractStatuses &&
            contractStatuses.statuses &&
            contractStatuses.statuses.map((status) => ({
                value: status.id,
                label: status.name,
            }))) ||
        [];
    const clientBranches = useSelector((state: RootState) => {
        return state.GetBranchStorageByCompanyId;
    });
    const branchesOptions = [
        defaultBranchOption,
        ...(clientBranches?.locations?.map((branch) => ({
            value: branch.id,
            label: branch.name,
        })) || []),
    ];
    const handleViewChange = (listView: boolean) => {
        setProductListView(listView);
    };
    const handleContractsViewChange = (listView: boolean) => {
        setContractsListView(listView);
    };
    const handleInventorySearch = (term: string) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        const fetchSelectOptions = async () => {
            dispatch(FetchBranchStorageByCompanyId(token, companyId, true));
        };
        fetchSelectOptions();
    }, [companyId]);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Gap="1rem">
            <ContainerFlex
                Align="flex-start"
                FlexDir="column"
                Padding="1.5rem"
                Justify="start"
                Gap="1rem"
                Border="1px solid #E8E9EA"
                backG="#FFF"
                Radius="1.5rem"
                Height="13rem"
            >
                <ContainerFlex Align="flex-start" Radius="0.5rem" Height="2.5rem">
                    <InventoryFilterButton
                        imageSrc={mobileIcon}
                        text={INVENTORY_PAWNBROKER.PRODUCTS}
                        radius="0.5rem 0 0 0.5rem"
                        selected={selectedFilter}
                        onSelect={setSelectedFilter}
                    />
                    <InventoryFilterButton
                        imageSrc={contractIcon}
                        text={INVENTORY_PAWNBROKER.CONTRACTS}
                        radius="0 0.5rem 0.5rem 0"
                        selected={selectedFilter}
                        onSelect={setSelectedFilter}
                    />
                </ContainerFlex>
                <InventoryFilters
                    onSearch={handleInventorySearch}
                    onPriceRangeSelect={setPriceRange}
                    contractStatuses={statuses}
                    setSelectedStatus={setSelectedStatus}
                    selectedStatus={selectedStatus}
                    branchesOptions={branchesOptions}
                    setSelectedBranch={setSelectedBranch}
                    selectedBranch={selectedBranch}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="flex-start" Gap="1rem">
                {selectedFilter === INVENTORY_PAWNBROKER.PRODUCTS ? (
                    <ProductsForInventory
                        listView={productListView}
                        onViewChange={handleViewChange}
                        inventorySearch={searchTerm}
                        priceRange={priceRange}
                        status={selectedStatus}
                        selectedLocationId={
                            selectedBranch && selectedBranch?.value > 0 ? selectedBranch?.value : 0
                        }
                    />
                ) : (
                    <ContractsForInventory
                        listView={contractsListView}
                        onViewChange={handleContractsViewChange}
                        inventorySearch={searchTerm}
                        priceRange={priceRange}
                        status={selectedStatus}
                        selectedLocationId={
                            selectedBranch && selectedBranch?.value > 0 ? selectedBranch?.value : 0
                        }
                    />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Pawnbroker;
