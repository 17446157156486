import React from 'react';
import {FieldValues, useForm, Controller} from 'react-hook-form';

import {yupResolver} from '@hookform/resolvers/yup';
import ButtonGeneral from '@General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {PRODUCT_PRICE} from '@components/InventoryManagement/constants';
import {ProductConditionProps} from '@components/InventoryManagement/interfaces';
import {productPriceSchema} from '@/components/InventoryManagement/yupProduct';
import {StyleNumericFormat} from '@components/Loan/styles';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

const ProductPriceModal: React.FC<ProductConditionProps> = ({showModal, setShowModal}) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            price: '',
        },
        resolver: yupResolver(productPriceSchema),
    });

    const closeModal = () => {
        setShowModal(false);
        reset();
    };

    const onSubmit = async (values: FieldValues) => {
        setShowModal(false);
        reset();
    };

    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => setShowModal(true)}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerFlex
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                    {PRODUCT_PRICE.TITLE}
                </Text>

                <ContainerFlex
                    FlexDir="column"
                    Gap="0.5rem"
                    Align="start"
                    Justify="start"
                    Height={errors?.price?.message ? '5rem' : '4rem'}
                >
                    <ContainerFlex Align="start" Justify="start">
                        <Controller
                            name="price"
                            control={control}
                            render={({field}) => (
                                <StyleNumericFormat
                                    {...field}
                                    type="text"
                                    autoComplete="off"
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    hasError={!!errors.price?.message}
                                    allowNegative={false}
                                    prefix="$ "
                                    width="100%"
                                />
                            )}
                        />
                    </ContainerFlex>
                    {errors?.price?.message && (
                        <ContainerFlex Align="start" Justify="start" Height="2rem">
                            <Text Color="#ef4f55" FontWeight="400" FontSize="12px">
                                {errors.price.message}
                            </Text>
                        </ContainerFlex>
                    )}
                    <ContainerFlex Align="start" Justify="start">
                        <Text
                            Color="#54575C"
                            FontWeight="400"
                            FontSize="1rem"
                            TextDecoration="line-through"
                        >
                            {numberToCurrency(Number(PRODUCT_PRICE.WAS_PRICE_VALUE))}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex
                    FlexDir="column"
                    Gap="0.5rem"
                    Align="start"
                    Justify="start"
                    Height="4rem"
                >
                    <ContainerFlex Align="start" Justify="start">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                            {PRODUCT_PRICE.VALUATION}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" Justify="start">
                        <Text Color="#2A2C2F" FontWeight="400" FontSize="1rem">
                            {numberToCurrency(Number(PRODUCT_PRICE.VALUATION_VALUE))}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Gap="0.5rem"
                    Align="start"
                    Justify="start"
                    Height="4rem"
                >
                    <ContainerFlex Align="start" Justify="start">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                            {PRODUCT_PRICE.PAID_RENEWAL}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" Justify="start">
                        <Text Color="#2A2C2F" FontWeight="400" FontSize="1rem">
                            {PRODUCT_PRICE.PAID_RENEWAL_VALUE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Align="end" Gap="1.5rem">
                    <ButtonGeneral
                        width="100%"
                        text={PRODUCT_PRICE.CANCEL_BUTTON}
                        clic={() => closeModal()}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="2.5rem"
                        cursor="pointer"
                        border="1px solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        width="100%"
                        text={PRODUCT_PRICE.SAVE_BUTTON}
                        clic={handleSubmit(onSubmit)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
export default ProductPriceModal;
