import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import iconError from '@/images/iconError.svg';
import {ImageLogo as LoginImageLogo} from '@LoginClient/styles';
import {IMessage} from '@/components/SignUp/interfaces';

export const ErrorMessagesPass = ({message}: IMessage) => {
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <LoginImageLogo Width="auto" Height="auto" src={iconError} alt="icone-correct" />
            <Text
                Color="#FF6357"
                FontSize="0.7rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
            >
                {message}
            </Text>
        </ContainerFlex>
    );
};
