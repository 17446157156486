import React, {useEffect, useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';

import {SearchInput} from '@Customer/Styles';
import {IGeneralQuoterData} from '@Quoter/inferfaces';
import {ListQuoter} from '@Quoter/Search/listSearch';
import {schemaSearch} from '@Quoter/Redux/yupValidations';
import {MESSAGESEARCH, SEARCHVALUES} from '@Quoter/constants';
import {ContainerFlex, ContainerForm, Text, Image} from '@Shopify/Ecommerce/styles';
import {
    SearchQuoterProduct,
    resetSearch,
    searchQuoterLoadReset,
} from '@Quoter/Redux/Actions/QuoterActions';
import Switch from '@mui/material/Switch';
import searchIcon from '@images/search.svg';
import clearIcon from '@images/close.svg';
import qrIcon from '@images/qrcodeNav.svg';
import useDebounce from '@/hooks/useDebounce';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';

export const SearchQuoter = (props: IGeneralQuoterData) => {
    const {branchId, token, setView, onSubmitQuoter} = props;
    const dispatch: AppDispatch = useDispatch();
    const [viewList, setViewList] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const {success} = useSelector((state: RootState) => state.addCatalogRequest);
    const loading = useSelector((state: RootState) => state.SearchQuoter.loading);
    const {productSearch} = useSelector((state: RootState) => state.saveEndeavor);
    const {register, watch, reset} = useForm({
        defaultValues: SEARCHVALUES,
        resolver: yupResolver(schemaSearch),
    });
    const searchTerm = watch('product');
    const validateArticle = searchTerm.length > 2;
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const abortControllerRef = useRef<AbortController | null>(null);
    const getInputIcon = () => (searchTerm.length > 0 ? clearIcon : searchIcon);

    useEffect(() => {
        if (
            !isSearching ||
            !validateArticle ||
            productSearch.articleName === debouncedSearchTerm ||
            debouncedSearchTerm.length === 0
        )
            return;

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            dispatch(searchQuoterLoadReset());
        }

        if (validateArticle && debouncedSearchTerm.length > 0) {
            const controller = new AbortController();
            abortControllerRef.current = controller;

            dispatch(
                SearchQuoterProduct(
                    token,
                    {
                        branchId,
                        articleName: debouncedSearchTerm,
                    },
                    controller.signal
                )
            );
        }

        return () => {
            abortControllerRef.current?.abort();
            dispatch(searchQuoterLoadReset());
            abortControllerRef.current = null;
        };
    }, [debouncedSearchTerm, validateArticle, isSearching, token, branchId]);

    useEffect(() => {
        if (success) {
            reset(SEARCHVALUES);
            closeProducts();
        }
    }, [success]);
    useEffect(() => {
        reset(SEARCHVALUES);
        closeProducts();
    }, [onSubmitQuoter]);

    const closeProducts = () => {
        setViewList(false);
        dispatch(resetSearch());
    };

    const handleInputFocus = () => {
        setViewList(true);
        setIsSearching(true);
    };

    return (
        <ContainerForm
            FlexDir="column"
            Gap="1rem"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
        >
            <Text FontWeight="500" FontSize="1.5rem" Color="#54575C">
                {MESSAGESEARCH.SEARCH}
            </Text>
            <ContainerFlex Justify="space-between" FlexDir="column">
                <ContainerFlex Position="relative" Align="end" Padding="0 0 24px 0">
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="90% 5% 5%"
                        FontSize="1rem"
                        PlaceholderColor="#A1A5AA"
                        WBoxShadow=""
                    >
                        <input
                            type="text"
                            {...register('product')}
                            autoComplete="off"
                            placeholder={MESSAGESEARCH.SEARCH}
                            onKeyDown={handleInputFocus}
                            onFocus={handleInputFocus}
                        />
                        <Image src={qrIcon} alt="icon-qr" Width="1.5rem" Height="1.5rem" />
                        {loading ? (
                            <LoadingAtaskate width="1rem" />
                        ) : (
                            <Image
                                src={getInputIcon()}
                                alt="icon-search"
                                Cursor="pointer"
                                Width="1.5rem"
                                Height="1.5rem"
                                onClick={() => {
                                    reset(SEARCHVALUES);
                                    closeProducts();
                                }}
                            />
                        )}
                    </SearchInput>
                    <ListQuoter
                        reset={reset}
                        viewList={viewList}
                        setViewList={setViewList}
                        validateArticle={validateArticle}
                        branchId={branchId}
                        setView={setView}
                    />
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" />
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Padding="24px 0 0 0"
                    Gap="16px"
                >
                    <Text FontSize="1rem" Color="#54575C">
                        {MESSAGESEARCH.EXTRACTION}
                    </Text>
                    <Switch
                        sx={{
                            width: 48,
                            height: 24,
                            padding: 0,
                            borderRadius: 16,
                            '& .MuiSwitch-switchBase': {
                                color: '#FFF',
                                padding: 0,
                                margin: 0.25,
                                boxShadow: '1px 1px 4px rgba(212, 214, 216, 0.88)',
                                '&.Mui-checked': {
                                    color: '#5A5AFF',
                                    transform: 'translateX(24px)',
                                    boxShadow: '1px 1px 4px rgba(172, 172, 255, 0.88)',
                                },
                                '&.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#E5E5FF',
                                    opacity: 1,
                                },
                            },
                            '& .MuiSwitch-track': {
                                backgroundColor: '#F4F5F5',
                                borderRadius: 16,
                                opacity: 1,
                                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset',
                            },
                            '& .MuiSwitch-thumb': {
                                boxShadow: 'none',
                            },
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
