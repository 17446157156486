import {AppDispatch} from '@/config/store';
import {
    PUT_RESET_ADMIN_PASSWORD,
    PUT_RESET_ADMIN_PASSWORD_SUCCESS,
    PUT_RESET_ADMIN_PASSWORD_ERROR,
} from '@components/Login/Redux/Types/Types';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {IPutResetPassWordAdminProps} from '@components/Login/Operational/interfaces';

export const putResetAdminPassword = () => {
    return {
        type: PUT_RESET_ADMIN_PASSWORD,
    };
};
export const putResetAdminPasswordSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_RESET_ADMIN_PASSWORD_SUCCESS,
        payload: result,
    };
};
export const putResetAdminPasswordError = (error: AxiosError) => {
    return {
        type: PUT_RESET_ADMIN_PASSWORD_ERROR,
        error: error,
    };
};

export function ResetAdminPassword({data, token, navigate}: IPutResetPassWordAdminProps) {
    return async (dispatch: AppDispatch) => {
        const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        };
        dispatch(putResetAdminPassword());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.RecoveryAdminPasswordUpdate}`,
                {employeeId: data.employeeId, password: data.password},
                {
                    headers,
                }
            );
            await dispatch(putResetAdminPasswordSuccess(response));
            navigate('/');
        } catch (error) {
            dispatch(putResetAdminPasswordError(error as AxiosError));
        }
    };
}
