import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {ErrorMessage} from '@hookform/error-message';
import {HeadTableDelivery} from '@/components/Foundry/Delivery/HeadTableDelivery';
import {useForm, Controller} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {DateTime} from 'luxon';

import {getFoundry} from '@ActionsAuditAssignment/FoundryAction';
import {
    getCollector,
    getSecurityBagBranch,
    fetchPostSecurityImage,
    fetchPostSecurityImageSuccess,
    putSecurityImage,
    getCollectorInternal,
} from '@ActionsDelivery/DeliveryActions';
import {RowDelivery} from '@Delivery/RowDelivery';
import Swal from 'sweetalert2';
import {ArrowAuction} from '@Auction/styles';
import {
    ContainerDelivery,
    DivFlex,
    DivInputsDelivery,
    DivLink,
    FormDelivery,
} from '@Delivery/styles';
import {Table} from '@DeadLines/styles';

export const Delivery = () => {
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        watch,
        reset,
        setValue,
        getValues,
        control,

        formState: {errors},
    } = useForm({
        defaultValues: {
            noSeguriBolsa: '',
            idCollector: {value: '', label: ''},
            IdRecolectorInterno: null,
            fundidora: '',
        },
    });
    const watchAllFields = watch();

    const [images, setImages] = React.useState([]);
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const [pictureprincipal, setPicturesPrincipal] = useState('');

    const guardarImages = async (value) => {
        if (images.length > 0) {
            let database = images.filter((itm) => itm.dataURL === pictureprincipal);
            const formData = new FormData();
            formData.append('noSecurityBag', value.noSeguriBolsa);
            formData.append('idCollector', value.idCollector.value);
            formData.append('now', DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss"));
            formData.append('imageFile', database[0].file);

            if (value.IdRecolectorInterno !== undefined) {
                formData.append('IdRecolectorInterno', value.IdRecolectorInterno.value);
            }

            dispatch(putSecurityImage(formData, token))
                .then((result) => {
                    dispatch(fetchPostSecurityImage());
                    dispatch(fetchPostSecurityImageSuccess(result));
                    dispatch(getSecurityBagBranch(token));
                    setPicturesPrincipal('');
                    setImages([]);

                    reset({
                        noSeguriBolsa: '',
                        idCollector: {},
                        IdRecolectorInterno: null,
                        fundidora: '',
                    });
                    Swal.fire({
                        icon: 'success',
                        title: 'Entrega Seguribolsa',
                        text: '¡La seguribolsa ha sido entregada con éxito!',
                    });
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Cancelar asignación',
                        text: err.response.data,
                    });
                });
        }
    };

    useEffect(() => {
        if (token) {
            const getCollectorSelect = () => dispatch(getCollector(token));
            getCollectorSelect();
            const getSecurityBagBranchTable = () => dispatch(getSecurityBagBranch(token));
            getSecurityBagBranchTable();
            const getFoundrySelect = () => dispatch(getFoundry(token));
            getFoundrySelect();
            const getCollectorSelectInternal = () => dispatch(getCollectorInternal(token));
            getCollectorSelectInternal();
        }
    }, [dispatch, token]);

    const collector = useSelector((state) => {
        return state.collector.collector;
    });
    const securitybranch = useSelector((state) => {
        return state.collector.securitybag;
    });
    const securitybranchvalue = useSelector((state) => {
        return state.collector.securitybagvalue;
    });
    const collectorinternal = useSelector((state) => {
        return state.collector.collectorinternal;
    });

    const onSubmit = async (values) => {};

    useEffect(() => {
        if (securitybranchvalue) {
            reset({
                noSeguriBolsa: securitybranchvalue.noSeguribolsa,
                idCollector: {value: '', label: ''},
            });
        } else {
            return;
        }
    }, [reset, securitybranchvalue]);

    return (
        <ContainerDelivery>
            <ArrowAuction>
                <span>Fundiciones</span>
                <i className="material-icons">keyboard_arrow_right</i>
                <span>Entrega</span>
            </ArrowAuction>
            <Link to={`/Fundiciones/Entrega/Seguribolsa`}>
                <DivLink>
                    <span>Selecciona la seguribolsa a entregar.</span>
                    <button>crear seguribolsa</button>
                </DivLink>
            </Link>
            <Table>
                <HeadTableDelivery />
                <tbody>
                    {securitybranch &&
                        securitybranch.map((securitybranchrow) => (
                            <RowDelivery
                                key={securitybranchrow.noSeguribolsa}
                                {...securitybranchrow}
                            />
                        ))}
                </tbody>
            </Table>
            <FormDelivery onSubmit={handleSubmit(onSubmit)}>
                <span>
                    Recuerde que la captura de papeleta debe contener las firmas por parte de la
                    recolectora.
                </span>
                <DivFlex>
                    <div>
                        <label className="required">No. De Seguribolsa</label>
                        <DivInputsDelivery>
                            <input {...register('noSeguriBolsa', {})} />
                            <ErrorMessage
                                errors={errors}
                                name="noSeguriBolsa"
                                message="This is required"
                            />
                        </DivInputsDelivery>
                        <label className="required">Recolectora</label>
                        <DivInputsDelivery>
                            <Controller
                                name="idCollector"
                                render={({field}) => (
                                    <Select
                                        options={collector}
                                        placeholder="Seleccione recolectora"
                                        onChange={(e) => setValue('idCollector', e)}
                                    />
                                )}
                                defaultValue=""
                                control={control}
                                rules={{required: true}}
                            />
                        </DivInputsDelivery>
                        {watchAllFields.idCollector.label === 'Interna' ? (
                            <>
                                <label className="required">Recolector Interno</label>
                                <DivInputsDelivery div className="width-100 mb-4">
                                    <Controller
                                        as={Select}
                                        className=""
                                        options={collectorinternal}
                                        name="IdRecolectorInterno"
                                        isClearable
                                        onChange={(e) => setValue('IdRecolectorInterno', 'value')}
                                        control={control}
                                        placeholder="Seleccione recolectora"
                                        rules={{required: true}}
                                    />
                                </DivInputsDelivery>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="flex w-100 pl-3 flex-row">
                        <div className="img-select">
                            <img src={pictureprincipal} alt="" />
                        </div>
                    </div>
                </DivFlex>

                <div className="flex width-100 mt-4 flex-end-button pr-2">
                    <button
                        className="btn-submit"
                        disabled={pictureprincipal !== '' ? false : true}
                        type="submit"
                        onClick={() =>
                            guardarImages(
                                getValues(['noSeguriBolsa', 'idCollector', 'IdRecolectorInterno'])
                            )
                        }
                    >
                        {' '}
                        Guardar
                    </button>
                </div>
            </FormDelivery>
        </ContainerDelivery>
    );
};
