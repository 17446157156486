import React from 'react';
import {ContainerFlex, Image, Text, ContainerGrid} from '@/components/Shopify/Ecommerce/styles';
import Spark from '@images/sparkles.svg';
import {
    PRE_APPROVAL_TABLE_HEADERS,
    PRE_APPROVAL_TABLE,
    PRE_APPROVAL_TABLE_DATA,
} from '@/components/PreApproval/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';

const SuggestionPreApproval = () => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="16px 24px"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="24px"
            Gap="24px"
            Height="fit-content"
        >
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="8px" Height="61px">
                <ContainerFlex Justify="start" Gap="8px">
                    <Image src={Spark} alt="spark" />
                    <Text oFlow="normal" Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE.TITLE}
                    </Text>
                </ContainerFlex>
                <Text>{PRE_APPROVAL_TABLE.SUB}</Text>
            </ContainerFlex>
            <ContainerFlex Justify="flex-start" Align="flex-start" FlexDir="column">
                <ContainerGrid
                    GridColumns="0.93fr 1fr 1fr 0.46fr 0.34fr"
                    Height="44px"
                    Padding="8px 16px"
                    Gap="16px"
                >
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.NAME}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.DEMOGRAPHIC_CONDITIONS}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.BEHAVIOR_CONDITIONS}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.APPLIED_TO}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.ACTIONS}
                    </Text>
                </ContainerGrid>
                {PRE_APPROVAL_TABLE_DATA.map((data, index) => (
                    <ContainerGrid
                        GridColumns="0.93fr 1fr 1fr 0.46fr 0.34fr"
                        Height="fit-content"
                        Padding="24px 16px"
                        Gap="16px"
                        BorderTp="1px solid #E8E9EA"
                        key={index}
                    >
                        <Text Self="flex-start" FontSize="0.875rem">
                            {data.name}
                        </Text>
                        <ContainerFlex Justify="start" Align="start" Gap="4px" FlexWrap="wrap">
                            {data.demographicConditions.map((condition, index) => (
                                <Text
                                    FontSize="0.875rem"
                                    BGColor="#DBF4FF"
                                    Color="#004461"
                                    Padding="2px 8px"
                                    BorderRadius="4px"
                                    FontWeight="700"
                                    wSpace="nowrap"
                                    key={index}
                                >
                                    {condition}
                                </Text>
                            ))}
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" Gap="4px" FlexWrap="wrap">
                            {data.behaviorConditions.map((condition, index) => (
                                <Text
                                    FontSize="0.875rem"
                                    BGColor="#FFF5DB"
                                    Color="#614500"
                                    Padding="2px 8px"
                                    BorderRadius="4px"
                                    FontWeight="700"
                                    wSpace="nowrap"
                                    key={index}
                                >
                                    {condition}
                                </Text>
                            ))}
                        </ContainerFlex>
                        <Text Self="flex-start" FontSize="0.875rem">
                            {data.appliedTo}
                        </Text>
                        <ButtonGenerals
                            Height="17px"
                            BackGC="transparent"
                            Color="#5A5AFF"
                            FontSize="0.875rem"
                            Padding="4px 0"
                            HBackG="transparent"
                        >
                            {data.selected}
                        </ButtonGenerals>
                    </ContainerGrid>
                ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SuggestionPreApproval;
