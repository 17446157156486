import React from 'react';
import {ContainerFlex, ContainerGrid, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import RatingStars from '@/components/CreditQuoter/RatingStars';
import {COMPANY_LOANS_QUOTER} from '@/components/CreditQuoter/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {QuoterOtherLoansProps} from '@/components/CreditQuoter/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const LoanQuoter = ({
    urlLogo,
    rating,
    companyName,
    maxAmount,
    minAmount,
    paymentNumbers,
    paymentFrecuencies,
    interestRate,
    SelectedId,
}: QuoterOtherLoansProps) => {
    return (
        <ContainerGrid
            Border="1px solid #E8E9EA"
            BorderR="16px"
            bGround="#fff"
            Width="100%"
            Height="130px"
            GridColumns="212px 2fr 231px"
            FlexDir="row"
            Justify="space-between"
        >
            <ContainerFlex Padding="16px" Justify="center" Align="center" Gap="16px">
                <Image
                    src={urlLogo}
                    Width="40px"
                    Height="40px"
                    alt="brand-logo"
                    Radius="50%"
                    Padding="8px"
                />
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="center"
                    Gap="8px"
                    Height="50px"
                >
                    <Text
                        FontWeight="400"
                        Color="#2A2C2F"
                        FontFamily="Nunito"
                        FontSize="1.0rem"
                        Width="100%"
                        TextDecoration="underline"
                    >
                        {companyName}
                    </Text>
                    <RatingStars rating={rating} justify="start" />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Padding="16px" Gap="32px" Justify="center" Align="center">
                <ContainerFlex Gap="4px" FlexDir="column" Justify="center" Align="center">
                    <Text FontWeight="700" Color="#1D1E20" FontFamily="Nunito" FontSize="1.5rem">
                        {numberToCurrencyWithoutCents(minAmount) +
                            COMPANY_LOANS_QUOTER.LOANS_QUOTER.PAYMENT_EVELYN +
                            numberToCurrencyWithoutCents(maxAmount)}
                    </Text>
                    <Text FontWeight="400" Color="#54575C" FontFamily="Nunito" FontSize="0.88rem">
                        {COMPANY_LOANS_QUOTER.LOANS_QUOTER.AMOUNT}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Padding="16px" Gap="16px" backG="#FAFAFA" Radius="16px">
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                        >
                            {COMPANY_LOANS_QUOTER.LOANS_QUOTER.PAYMENTS}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                            wSpace="break-spaces"
                        >
                            {paymentNumbers}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                        >
                            {COMPANY_LOANS_QUOTER.LOANS_QUOTER.FREQUENCY}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                            wSpace="break-spaces"
                        >
                            {paymentFrecuencies}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Gap="4px">
                        <Text
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                        >
                            {COMPANY_LOANS_QUOTER.LOANS_QUOTER.RATE}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            Width="100%"
                            wSpace="break-spaces"
                        >
                            {interestRate + COMPANY_LOANS_QUOTER.LOANS_QUOTER.ANUAL}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Padding="16px 16px 16px 0px">
                <ButtonGenerals
                    Width="320px"
                    FontSize="1rem"
                    FontWeight="700"
                    Cursor="pointer"
                    Padding="8px 16px"
                    MHeight="40px"
                    BackGC="#F0F0FF"
                    Color="#5A5AFF"
                    HBackG="#F0F0FF"
                    onClick={SelectedId}
                >
                    {COMPANY_LOANS_QUOTER.LOANS_QUOTER.CTA}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerGrid>
    );
};

export default LoanQuoter;
