import axios, {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CLIEND_PERSONAL_DATA_START,
    GET_CLIEND_PERSONAL_DATA_SUCCESS,
    GET_CLIEND_PERSONAL_DATA_ERROR,
} from '@/components/PersonalLoans/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@/config/constants';

export const getPersonalDataStart = () => {
    return {
        type: GET_CLIEND_PERSONAL_DATA_START,
    };
};

export const getPersonalDataSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CLIEND_PERSONAL_DATA_SUCCESS,
        payload,
    };
};

export const getPersonalDataError = (error: AxiosError) => {
    return {
        type: GET_CLIEND_PERSONAL_DATA_ERROR,
        error,
    };
};

export const getPreLoadClientData = (token: string, personalInformationId?: number) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const url = personalInformationId
        ? URL.urlWeb.GetPersonalLoanIdQuery
        : URL.urlWeb.LoadClientDataHistory;
    return async (dispatch: AppDispatch) => {
        dispatch(getPersonalDataStart());
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVICE_WEB}${url}`, {
                headers,
                params: {personalInformationId},
            });
            dispatch(getPersonalDataSuccess(response));
            return response;
        } catch (error) {
            dispatch(getPersonalDataError(error as AxiosError));
        }
    };
};
