import React, {useState} from 'react';
import {TABLE_PRE_APPROVAL_FILTER_STATUS} from '@/components/PreApproval/constants';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import IconSearch from '@images/search.svg';
import IconClose from '@images/closeBlueIcone.svg';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {IFiltersSectionProps} from '@/components/PreApproval/interfaces';
// import IconAlert from '@images/Alert.svg';

const FiltersSection = ({activeStatus, setActiveStatus}: IFiltersSectionProps) => {
    const {status} = useSelector((state: RootState) => state.ConditionStatusReducer);
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
    };
    const handleCancelSearch = () => {
        setInputValue('');
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setInputValue('');
        }
    };
    const getRadius = (index: number, optionStatusId: number): string => {
        if (activeStatus === optionStatusId && index === 0) return '8px 0px 0px 8px';
        if (activeStatus === optionStatusId && index === status.length - 1)
            return '0px 8px 8px 0px';
        return '';
    };

    const changeStatusHandler = (activeStatus: number) => {
        setActiveStatus(activeStatus);
    };

    return (
        <ContainerFlex Justify="space-between" Align="center">
            <ContainerFlex Radius="0.5rem" Border="1px solid #E8E9EA" Width="307px" Height="48px">
                {status
                    ? status.map((data, index) => {
                          const radius = getRadius(index, data.idStatus);
                          const Br =
                              index !== TABLE_PRE_APPROVAL_FILTER_STATUS.length - 1
                                  ? '1px solid #E8E9EA'
                                  : '';
                          const Border = activeStatus === data.idStatus ? '1px solid #5A5AFF' : '';
                          const backG = activeStatus === data.idStatus ? '#F0F0FF' : '#fff';
                          const Color = activeStatus === data.idStatus ? '#5A5AFF' : '#1D1E20';
                          const FontWeight = activeStatus === data.idStatus ? '700' : '400';
                          return (
                              <ContainerFlex
                                  key={index}
                                  Flex="1 0 0"
                                  Justify="center"
                                  Padding="16px"
                                  Br={Br}
                                  Border={Border}
                                  backG={backG}
                                  Radius={radius}
                                  Cursor="pointer"
                                  onClick={() => changeStatusHandler(data.idStatus)}
                              >
                                  <Text
                                      FontFamily="Nunito"
                                      Color={Color}
                                      FontWeight={FontWeight}
                                      Cursor="pointer"
                                  >
                                      {data.statusDescription}
                                  </Text>
                              </ContainerFlex>
                          );
                      })
                    : null}
            </ContainerFlex>
            <SearchInput
                Height="40px"
                Width="270px"
                Position="none"
                GridColumn="90% 10%"
                widthInput="200px"
            >
                <input
                    type="text"
                    placeholder={'Buscar'}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                {inputValue.length > 0 ? (
                    <Image
                        alt="icon-close"
                        src={IconClose}
                        onClick={handleCancelSearch}
                        Cursor="pointer"
                    />
                ) : (
                    <Image alt="icon-search" src={IconSearch} Cursor="pointer" />
                )}
            </SearchInput>
        </ContainerFlex>
    );
};

export default FiltersSection;
