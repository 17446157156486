import {
    VALIDATE_USER_ADMINISTRATOR_USER,
    VALIDATE_USER_ADMINISTRATOR_USER_ERROR,
    VALIDATE_USER_ADMINISTRATOR_USER_SUCCESS,
} from '@components/SignUp/Redux/types';
import {AxiosResponse} from 'axios';

const initialState = {
    error: false,
    loading: false,
    data: null,
};

const validateNewEmailAdministrator = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case VALIDATE_USER_ADMINISTRATOR_USER:
            return {...state, loading: true};
        case VALIDATE_USER_ADMINISTRATOR_USER_ERROR:
            return {...state, loading: false, error: true};
        case VALIDATE_USER_ADMINISTRATOR_USER_SUCCESS:
            return {...state, loading: false, data: action.payload.data.data};
        default:
            return state;
    }
};
export default validateNewEmailAdministrator;
