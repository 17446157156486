import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import add from '@images/iconeAddWhithe.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {TableAllUsers} from '@/components/MyCompanyUser/TableAllUser';
import {TEXT_USER} from '@/components/MyCompanyUser/Operational/Constants';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {useNavigate} from 'react-router-dom';

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Mi empresa'},
    {to: '#/', label: 'Usuarios'},
];

export const AllUsers = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/newUser');
    };

    return (
        <ContainerFlex
            Padding="16px 24px"
            FlexDir="column"
            Gap="16px"
            Justify="start"
            backG="#F4F5F5"
            Align="start"
            Height=""
        >
            <Breadcrumb items={itemsBreadcrumb} />
            <ContainerFlex Height="">
                <ContainerFlex Justify="start">
                    <Text FontSize="24px" FontWeight="700">
                        {TEXT_USER.TITLE}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Gap="16px" Justify="end">
                    <ButtonGenerals type="submit" Width="174px" onClick={handleNavigate}>
                        <ImageLogo src={add} alt="icone-add" />
                        {TEXT_USER.BTN_SECUNDARY}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Radius="16px"
                Border="1px solid #E8E9EA"
                backG="#FFFFFF"
                FlexDir="column"
                Padding="24px"
                Align="start"
                Justify="start"
                Gap="16PX"
            >
                <Text FontSize="1.25rem" FontWeight="600">
                    {TEXT_USER.LIST}
                </Text>
                <TableAllUsers />
            </ContainerFlex>
        </ContainerFlex>
    );
};
