import React from 'react';

import {Modal, ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import QuoterForm from '@/components/GlobalQuoter/QuoterForm';
import QuoterData from '@/components/GlobalQuoter/QuoterData';
import QuoterHeader from '@/components/GlobalQuoter/QuoterHeader';
import {EXPRESS_QUOTER} from '@/components/ExpressQuoter/constants';
import closeModalIcon from '@/images/closeDark.svg';
import {IModalProps} from '@/components/ExpressQuoter/interface';
const ExpressQuoter: React.FC<IModalProps> = ({closeShowModal}) => {
    return (
        <Modal Top="0" BackC="rgba(0, 0, 0, 0.40)">
            <ContainerFlex
                Radius="1.5rem"
                backG="#FFF"
                BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 40)"
                Padding="32px 32px 16px 32px"
                Width="798px"
                Height="460px"
                Align="start"
                Gap="24px"
                FlexDir="column"
            >
                <ContainerFlex Justify="space-between" Align="start" Height="70px">
                    <QuoterHeader
                        isExpressQuoter={true}
                        title={EXPRESS_QUOTER.TITLE}
                        subTitle={EXPRESS_QUOTER.SUBTITLE}
                        isPublicQuoter={false}
                    />
                    <Image
                        Width="24px"
                        Height="24px"
                        alt="close-x-icon"
                        src={closeModalIcon}
                        Cursor="pointer"
                        onClick={() => {
                            closeShowModal(false);
                        }}
                    />
                </ContainerFlex>
                <ContainerFlex Gap="48px">
                    <QuoterForm isExpressQuoter={true} isPublicQuoter={false} />
                    <QuoterData isExpressQuoter={true} isPublicQuoter={false} />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default ExpressQuoter;
