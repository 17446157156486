import styled from '@emotion/styled';
export const MenuWrapper = styled.div<{
    Width?: string;
    Margin?: string;
    ScrollBarWidth?: string;
    HeightBarScroll?: string;
    Top?: string;
    Left?: string;
}>`
    background-color: #ffff;
    position: fixed;
    display: flex;
    width: ${(props) => props.Width || '10.313rem'};
    top: ${(props) => props.Top || '0'};
    left: ${(props) => props.Left || '0'};
    border-radius: 0.5rem;
    border: 1px solid #f3f3f3;
    box-shadow: 0 0.688rem 1.663rem 0 rgba(0, 0, 0, 0.06);
    flex-direction: column;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;
    margin: ${(props) => props.Margin || '1.9rem 0 -10rem -10.3rem'};
    z-index: 3;
    &::-webkit-scrollbar {
        width: ${(props) => props.ScrollBarWidth};
        height: ${(props) => props.HeightBarScroll};
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;
