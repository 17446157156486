import React from 'react';
import {ContainerFlex, Modal, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import confuseImage from '@images/confuse_image.svg';
import {CONFIRMATION_MODAL_MESSAGE} from '@/components/PersonalLoans/constants';

interface ICancelLoanRequestModal {
    onClose: () => void;
    onConfirm: () => void;
}

const CancelLoanRequestModal = ({onClose, onConfirm}: ICancelLoanRequestModal) => {
    return (
        <Modal BackC="rgba(0, 0, 0, 0.40)">
            <ContainerFlex
                FlexDir="column"
                Radius="24px"
                Padding="24px"
                backG="#fff"
                Width="600px"
                Height="532px"
                Justify="flex-start"
                Align="flex-start"
            >
                <Text FontSize="24px" Color="#2A2C2F" FontFamily="Nunito" FontWeight="700">
                    {CONFIRMATION_MODAL_MESSAGE.TITLE}
                </Text>
                <ContainerFlex>
                    <Image src={confuseImage} />
                </ContainerFlex>
                <Text
                    Color="#000"
                    FontFamily="Nunito"
                    Padding="12px 0 24px 0"
                    LetterSpacing="0.3px"
                >
                    {CONFIRMATION_MODAL_MESSAGE.DESCRIPTION}
                </Text>
                <ContainerFlex Justify="center" Height="76px" PaddingT="12px" Gap="16px">
                    <ButtonGenerals
                        Width="170px"
                        Padding="0 16px"
                        onClick={onClose}
                        BackGC="#FFFFFF"
                        Color="#5A5AFF"
                        Border="solid 1px #5A5AFF"
                        HBackG="#FFFFFF"
                    >
                        {CONFIRMATION_MODAL_MESSAGE.CLOSE}
                    </ButtonGenerals>
                    <ButtonGenerals Width="170px" Padding="0 16px" onClick={onConfirm}>
                        {CONFIRMATION_MODAL_MESSAGE.CANCEL_REQUEST}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default CancelLoanRequestModal;
