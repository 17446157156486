import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PersonalLoansStepOne} from '@components/PersonalLoans/PersonalLoansStepOne';
import {PersonalLoansStepTwo} from '@components/PersonalLoans/PersonalLoansStepTwo';
import {PersonalLoansStepThree} from '@components/PersonalLoans/PersonalLoansStepThree';
import {PersonalLoansStepFour} from '@components/PersonalLoans/PersonalLoansStepFour';
import {ProgressBarStep} from '@components/PersonalLoans/ProgressBarStep';
import {PERSONALLOANS, BANNER_MESSAGE} from '@components/PersonalLoans/constants';
import {ResumeLoans} from '@components/PersonalLoans/ResumeLoans';
import {PersonalLoansStepFourPayment} from '@components/PersonalLoans/PersonalLoansStepFourPayment';
import {getAdditionalDocuments} from '@components/PersonalLoans/Redux/Actions/GetAdditionalCreditDocumentsActions';
import PersonalLoansStepConfirmation from '@components/PersonalLoans/PersonalLoansStepConfirmation';
import ConfirmationLoanAdditonalInfo from '@components/PersonalLoans/ConfirmationLoanAdditonalInfo';
import InfoBanner from '@/components/PersonalLoans/InfoBanner';
import {IPersonalLoanRequestSummaryReducer} from '@components/PersonalLoans/interfaces';
import CompanyAvatarDefault from '@components/CreditQuoter/CompanyAvatarDefault';
import {COMPANY_AVATAR_DEFAULT_COLORS} from '@/components/CreditQuoter/constants';

export const PersonalLoans = () => {
    const {NameCompany, UrlCompany} = useSelector(
        (state: RootState) =>
            state.personalLoanRquestSummaryReducer as IPersonalLoanRequestSummaryReducer
    );
    const randomColor =
        COMPANY_AVATAR_DEFAULT_COLORS[
            Math.floor(Math.random() * COMPANY_AVATAR_DEFAULT_COLORS.length)
        ];
    const dispatch = useDispatch();
    const DataStepLoans = useSelector((state: RootState) => {
        return state.DataStepLoans.dataStep;
    });
    const IPersonalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId;
    });

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {hasApplicationHistory, personalInformationId: historyPersonalInformationId} =
        useSelector((state: RootState) => state.personalLoanRquestSummaryReducer);

    useEffect(() => {
        if ((IPersonalInformationId || historyPersonalInformationId) && token) {
            const personalInformationId = IPersonalInformationId || historyPersonalInformationId;
            dispatch(getAdditionalDocuments(token, personalInformationId));
        }
    }, [IPersonalInformationId, historyPersonalInformationId, token]);

    const infoMessage = hasApplicationHistory ? BANNER_MESSAGE.STEP_THREE : BANNER_MESSAGE.STEP_ONE;

    const renderStep = (
        <ContainerFlex
            Align={
                DataStepLoans === PERSONALLOANS.FIVE || DataStepLoans === PERSONALLOANS.SIX
                    ? 'center'
                    : 'start'
            }
            Justify="center"
            backG="#E5E5FF"
            Padding="1.5rem 3.75rem"
            Gap="1rem"
            Height={DataStepLoans === PERSONALLOANS.SIX ? '100vh' : 'calc(100vh - 56px)'}
            Overflow={DataStepLoans === PERSONALLOANS.SIX ? 'visible' : 'auto'}
            FlexDir={DataStepLoans === PERSONALLOANS.SIX ? 'column' : 'row'}
        >
            <ContainerFlex
                Justify="start"
                FlexDir="column"
                Border="solid 1px #0D166B"
                Radius="1rem"
                Padding="1.5rem"
                Gap="1rem"
                backG="#FFFFFF"
                // MaxW="1162px"
                Height="max-content"
            >
                <ContainerFlex Justify="space-between" Height="40px">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {PERSONALLOANS.TITLE}
                    </Text>
                    {UrlCompany ? (
                        <Image src={UrlCompany} Width="40px" Height="40px" Radius="40px" />
                    ) : NameCompany ? (
                        <CompanyAvatarDefault
                            companyName={NameCompany}
                            Width="40px"
                            Height="40px"
                            color={randomColor}
                        />
                    ) : null}
                </ContainerFlex>
                <ContainerFlex Bb="solid 1px #E8E9EA" Height="1px" />
                <ContainerFlex Align="start" FlexDir="column" Justify="start" Gap="1.5rem">
                    {DataStepLoans !== PERSONALLOANS.SIX && !hasApplicationHistory && (
                        <ProgressBarStep dataStep={DataStepLoans} />
                    )}
                    {(DataStepLoans === PERSONALLOANS.ONE ||
                        DataStepLoans === PERSONALLOANS.TWO ||
                        (DataStepLoans === PERSONALLOANS.THREE && hasApplicationHistory)) && (
                        <InfoBanner message={infoMessage} />
                    )}
                    {DataStepLoans === PERSONALLOANS.ONE && <PersonalLoansStepOne />}
                    {DataStepLoans === PERSONALLOANS.TWO && <PersonalLoansStepTwo />}
                    {DataStepLoans === PERSONALLOANS.THREE && <PersonalLoansStepThree />}
                    {DataStepLoans === PERSONALLOANS.FOUR && <PersonalLoansStepFourPayment />}
                    {DataStepLoans === PERSONALLOANS.FIVE && <PersonalLoansStepFour />}
                    {DataStepLoans === PERSONALLOANS.SIX && <PersonalLoansStepConfirmation />}
                </ContainerFlex>
            </ContainerFlex>
            {DataStepLoans !== PERSONALLOANS.FIVE && DataStepLoans !== PERSONALLOANS.SIX && (
                <ResumeLoans />
            )}
        </ContainerFlex>
    );

    return DataStepLoans === PERSONALLOANS.SIX ? (
        <ContainerFlex
            FlexDir="column"
            Justify="flex-start"
            Align="center"
            backG="#E5E5FF"
            Height="calc(100vh - 56px)"
            Overflow="auto"
            PaddingB="24px"
        >
            {renderStep}
            {DataStepLoans === PERSONALLOANS.SIX && <ConfirmationLoanAdditonalInfo />}
        </ContainerFlex>
    ) : (
        renderStep
    );
};
