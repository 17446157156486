export const PERSONALLOANS = {
    TITLE: 'Solicitud de préstamo',
    AVOUT: 'Acerca de ti',
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    STEP: 'Paso',
    FORSTEP: 'de 4',
};

export const PERSONALDATA = {
    ABOUT: 'Acerca de ti',
    NAME: 'Nombres',
    LASTNAME: 'Apellido Paterno',
    SECONDLASTNAME: 'Apellido Materno',
    CURP: 'CURP',
    BIRTHDAY: 'Fecha de nacimiento',
    PHONE: 'Teléfono',
    GENDER: 'Género',
};

export const ADRESSDATA = {
    LIVE: '¿En dónde vives?',
    STREET: 'Calle',
    NOEXT: 'No. exterior',
    NOINT: 'No. interior',
    OPTIONAL: '(Opcional)',
    CP: 'Código postal',
    COLONY: 'Colonia',
    CITYHALL: 'Alcaldía o municipio',
    STATE: 'Estado',
};

export const JOBDATA = {
    ABOUT: 'Acerca de tu trabajo',
    STATE: 'Situación laboral',
    NAME: 'Nombre de la empresa',
    ADDRESS: 'Dirección  de la empresa',
    PHONE: 'Teléfono de la empresa',
    POSITION: 'Puesto',
    OPTIONAL: '(Opcional)',
    SENIORITY: 'Antigüedad',
    INCOME: 'Ingreso mensual',
};
export const UPLOADDATA = {
    VERIFY: 'Comprobaremos tu identidad',
    SELECT: 'Selecciona tu identificación:',
    TITLE: 'Sube tus documentos aquí',
    SIGNATURE: 'Firma de solicitud de crédito',
    AUTHORIZED: 'Acepto consulta de buró de crédito',
    VALIDATE:
        'Al hacer click en “Validar identidad” validaremos internamente tu identidad y te mostraremos tu contrato',
    ACCEPT: 'Aceptar préstamo automáticamente una vez aprobado por la empresa.',
};
export const UPLOADFRONTDATA = {
    TITLE: 'Parte frontal de la identificación',
    SUBTITLE: 'La parte frontal es el lado de tu identificación donde aparece tu foto.',
};
export const UPLOADBACKDATA = {
    TITLE: 'Parte de atrás de la identificación',
    SUBTITLE: 'La parte trasera es el lado de tu identificación donde no hay foto.',
};
export const UPLOADINCOMEDATA = {
    TITLE: 'Comprobante de ingresos',
};
export const UPLOADDROPDATA = {
    DROP: 'Arrastra aquí tu documento',
    TYPE: 'JPEG, JPG, PNG, PDF | Peso máximo 4MB',
    SELECT: 'Elegir archivo',
    REMPLACE: 'Remplazar',
    ERROR: 'El archivo excede el tamaño máximo permitido de 4 MB.',
    FORMAT_ERROR: 'Solo está permitido cargar archivos en formato JPEG, JPG, PNG o PDF.',
    PDF_FORMAT: 'application/pdf',
};
export const formJobLoans = {
    personalInformationId: null,
    catEmploymentSituationId: {},
    nameCompany: '',
    addresCompany: '',
    catLadaId: '',
    phoneCompany: '',
    job: '',
    catLaborOldId: {},
    monthlyIncome: null,
};

export const formUsers = {
    name: '',
    lastName: '',
    maternalSurname: '',
    catGenderId: 0,
    curp: '',
    birthdate: '',
    catLadaId: 0,
    phone: '',
    addressPersonalLoans: {
        street: '',
        interiorNumber: '',
        externalNumber: '',
        postalCode: '',
        catColonyId: {},
        catMunicipaliyId: {},
        catStateId: {},
    },
};

export const DATAPASSED = {
    ONE: 1,
    TWO: 2,
};
export const STEPFOUR = {
    SUBTITLE: 'Estamos validando tu solicitud',
    VALIDATION: {
        INFORMATION: 'Estamos validando tu información',
        IDENTITY: 'Estamos validando tu identidad',
        CREDIT_HISTORY: 'Estamos validando tu historial crediticio',
    },
    MESSAGE:
        'Esto puede tardar algunos minutos si se cierra por error puedes regresar a validar el estatus de tu solicitud con tu usuario y contraseña',
};

export const PERSONAL_LOAN_APPROVED = {
    HEADER: {
        TITLE: 'Solicitud de préstamo',
    },
    BODY: {
        DEPOSIT_INFORMATION: {
            BANNER_MESSAGE: 'tu solicitud ha sido aprobada!',
            BANNER_MESSAGE_SA: 'Nosotros te ayudamos a encontrar el dinero que necesitas',
            BANNER_MESSAGE_PARAGRAPH_SA:
                'Puedes aceptar este préstamo y nosotros nos encargamos de buscar el resto con otro negocio.',
            BANNER_MESSAGE_NAME_SA: 'tenemos noticias para ti!',
            BANNER_MESSAGE_HAS_APPROVED_SA: 'Han sido aprobados',
            BANNER_MESSAGE_HAS_APPROVED_OF_SA: 'de los',
            BANNER_MESSAGE_CREDIT_REQUESTED_SA: 'solicitados',
            SUBTITLE: '¿Dónde quieres recibir tu dinero?',
            SUBTITLE_PARAGRAPH:
                'Selecciona el lugar donde quieres que te depositemos el dinero del préstamo:',
            ACC_BANK_TRANSFER_TITLE: 'Transferencia bancaria',
            ACC_BANK_TRANSFER_PARAGRAPH: 'El depósito puede tardar hasta 48h.',
            ACC_BANK_TRANSFER_PANEL: 'BANK_TRANSFER_PANEL',
            BANK_TRANSFER_PASSWORD_LB: 'Cuenta CLABE',
            BANK_TRANSFER_PASSWORD_PLACEHOLDER: 'Ingresa tu cuenta clave',
            BANK_TRANSFER_TITLE_LB: 'Banco',
            BANK_TRANSFER_SELECT_PLACEHOLDER: 'Selecciona una opción',
            ACC_ATASKATE_WALLET_TITLE: 'Billetera Digital de Ataskate',
            ACC_ATASKATE_WALLET_PARAGRAPH: 'Tiempo promedio para el deposito de 1 a 2 horas.',
            ACC_ATASKATE_WALLET_PANEL: 'ATASKATE_WALLET_PANEL',
        },
        SUMMARY: {
            SUBTITLE: 'Resumen del préstamo',
            LOAN_REQUESTED: 'Préstamo Solicitado',
            LOAN_GRANTED: 'Préstamo Aprobado',
            FEES: 'Cuotas',
            ANUAL_INTEREST_RATE: 'Tasa de interes anual',
            MONTHLY_PAYMENTS: 'pagos mensuales de: ',
            TOTAL_AMOUNT: 'Monto total a pagar: ',
            CTA_AMORTIZATION_TABLE: 'Ver tabla de amotización',
            TERMS_AND_CONDITIONS_ACCEPT: 'Acepto',
            TERMS_AND_CONDITIONS: 'términos y condiciones del contrato',
            CTA_ACCEPT_LOAN: 'Aceptar préstamo',
            REJECT_LOAN: 'Rechazar préstamo',
        },
    },
};

export const FORM_DATA_NAMES = {
    PASSWORD_FIELD: 'pasword',
    TRANSFER_BANK: 'bank',
    TERMS_CONDITIONS_ACCEPTED: 'terms_conditions',
};

export const VALIDATION_MESSAGE = {
    TERMS_CONDITIONS: 'Debes aceptar los términos y condiciones.',
    ACCOUNT_NUMBER: {
        REQUIRED: 'El número de cuenta clave es obligatorio.',
        LENGTH: 'El número de cuenta clave debe tener entre 8 y 16 caracteres',
        INVALID_CHARS: 'El número de cuenta clave debe contener solo dígitos.',
    },
    BANK: 'Debes seleccionar un banco.',
    MANDATORY_FIELD: 'Campo obligatorio',
    CLABE_TRANSACTIONS_LENGTH: 'La cuenta CLABE debe tener exactamente 18 dígitos',
};

export const AMORTIZATION_TABLE_LOANS = {
    TITLE: 'Tabla de amortización',
    HEADERS: {
        TERM: 'No.cuota',
        CAPITAL_PAYMENT: 'Pago a capital',
        INTEREST: 'Interés',
        MONTH_PAYMENT: 'Pago de cuota',
        BALANCE: 'Saldo',
    },
    ERROR_MSG: 'No existe un plazo disponible para este monto',
};
export const LadaDefault = {
    countryId: 40,
    name: 'Cánada',
    urlFlag:
        '"https://storage.googleapis.com/goldenstarweb/media/Assests/Banderas/America/Canada.svg"',
    lada: '+1',
};

export const REJECT_LOAN_MODAL = {
    TITLE: '¿Está seguro de rechazar este préstamo?',
    DESCRIPTION: 'Si rechaza la oferta, no podrá acceder a estas condiciones nuevamente.',
    CANCEL_BTN: 'Cancelar',
    REJECT_BTN: 'Rechazar préstamo',
};

export const RESUMELOANS = {
    TITLE: 'Resumen de la solicitud',
    EDIT: 'Editar',
    MOUNT: 'Monto solicitado',
    VALUE: '$20.000',
    FRECUENCY: 'Frecuencia',
    NUMBERPAY: 'Número de pagos',
    MONTHLY: 'Mensual',
    SIX: '6',
    REQUEST_NUMBER: 'No. Solicitud',
};

export const STEP_FORM_BTN = {
    CONTINUE: 'Continuar',
    CANCEL: 'Cancelar',
    BACK: 'Paso anterior',
};

export const IMAGE_ALT = {
    PURPLE_ARROW: 'Flecha morada',
    WHITE_ARROW: 'Flecha blanca',
};
export const UPLOADIMAGESLOANS = {
    NAME: 'Nombre',
    VALID: 'Vigencia',
    REQUIRED: 'Campo obligatorio',
};
export const STEPFOURPAYMENT = {
    TITLE: 'Selecciona donde quieres recibir tu dinero',
    WALLET: 'Billetera Digital Ataskate',
    WALLETDES: 'Tiempo promedio para el deposito: Inmediato.',
    TRANSFER: 'Transferencia bancaria',
    TRANSFERDES: 'El depósito puede tardar hasta 48h.',
    BANK: 'Banco',
    CLABE: 'Cuenta CLABE',
    ADVISE: 'En el caso de que el monto aprobado sea inferior al monto solicitado, marca esta casilla si desea aceptar automáticamente el préstamo.',
    ADVISEDES:
        'Nosotros te notificaremos mediante un correo electrónico cuando se haya aprobado el préstamo y procederemos a efectuar el desembolso del mismo.',
    BEFORE: 'Paso anterior',
    VALIDATE: 'Solicitar Préstamo',
    TWO: 2,
};
export const DefaultValuesLoans = {
    accountClabe: '',
    catBankId: 0,
    personalCreditApplicationId: 0,
    acceptLoan: false,
};

export const LOANSMESSAGEYUP = {
    AVIABLEBANK: 'Campo obligatorio',
    BANKREQUIRED: 'Campo obligatorio.',
    CLABEREQUIRED: 'Campo obligatorio',
    CLABEFOR: 'Este campo deberá ser de 18 dígitos',
    CLICK: 'Por favor acepte la solicitud',
    REQUIRED: 'Campo obligatorio',
    FOUR: 'Deben ser 4 dígitos',
    DATE: 'Ingrese una fecha valida',
    BEMONTH: 'El ingreso mensual no puede ser cero',
    BENUMBER: 'Debe ser un número',
    BEDIGITAL: 'Debe contener solo dígitos y tener entre 10 y 15 dígitos.',
    BECURP: 'El CURP debe tener exactamente 18 caracteres',
    BECURP_REPEATED: 'Este CURD ya existe, por favor, cámbialo.',
    BETEN: 'Este campo deberá ser de 10 dígitos',
    BECP: 'El CP debe tener 5 caracteres',
};

export const fileErrorsInitialState = {
    imageSize: false,
    validImageFormat: false,
};

export const IDENTIFICATION_DOCUMENT = 'Documento de identificación';

export const PERSONAL_LOAN_CONFIRMATION = {
    GREET: ', estamos validando tu información',
    EMAIL_CONST: 'email',
    LET_YOU_KNOW:
        'Te avisaremos mandando un correo a email cuando terminemos de validar tu solicitud',
    SUMMARY_TITLE: 'Préstamo solicitado:',
    REQUESTED_LOAN: 'Préstamo Solicitado',
    REQUEST_NUMBER: 'No. solicitud',
    FEES: 'Cuotas',
    ANUAL_INTEREST_RATE: 'Tasa de interés anual',
    REQUESTED_LOAN_VALUE: '$20.000',
    REQUEST_NUMBER_VALUE: '100CP001',
    FEES_VALUE: '24',
    ANUAL_INTEREST_RATE_VALUE: '24%',
    INTERES_FROM: 'Intereses desde',
    TO_MY_CREDIT: 'Ir a Mis Créditos',
};

export const ADDITIONAL_LOAN_OPTIONS = {
    NEED_QUICK_MONEY: '¿Necesitas dinero rápido?',
    REQUEST_WITH_OTHER_BUSINESSES: 'Solicita con estos otros negocios',
    PAWN_AND_GET_THE_MONEY_YOU_NEED: 'Empeña y consigue el dinero que necesitas',
    QUOTE_YOUR_PAWN_WITH_US: 'Cotiza tu empeño con nosotros',
    NEED_MORE_MONEY: '¿Necesitas dinero?',
    REQUEST_WITH_OTHER_BUSINESSES_TWO: 'Solicita un préstamo, empeño o ambos',
    QUOTE_YOUR_LOAN_WITH_US: 'Cotiza un crédito',
    QUOTE_YOUR_PAWN_WITH_US_TWO: 'Cotiza un empeño',
};

export const BANNER_MESSAGE = {
    STEP_ONE:
        'Revisa y/o agrega la información que falta para continuar con la solicitud del préstamo',
    STEP_THREE: 'Necesitamos algunos documentos para proceder con tu solicitud.',
};

export const CONFIRMATION_MODAL_MESSAGE = {
    TITLE: '¿Quieres salir de la solicitud?',
    DESCRIPTION:
        'Haciendo click en “Cancelar solicitud”, se cerrara el formulario de solicitud de un préstamo y se te direccionará a la página de cotizador de Ataskate.',
    CLOSE: 'Cerrar',
    CANCEL_REQUEST: 'Cancelar Solicitud',
};

export const GLOBAL_QUOTER = '/GlobalQuoter';

export const CURP_REPEATED_ERROR = {
    VALUE: 'El CURP ya se encuentra en otro credito en proceso de ser aprobado',
    LABEL: 'Este CURD ya existe, por favor, cámbialo.',
};
