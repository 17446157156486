export const URL = Object.freeze({
    urlFoundry: {
        Sucursal: 'Store/CostOfGoldItemsByStoreAsync',
        AssignmentTypes: 'Assignment/Types',
        Auditors: 'Auditor',
        Foundry: 'Foundry',
        AssignmentByStore: 'Store/{id}/Assignments',
        Assignment: 'Assignment/Filter',
        AssignmentPost: 'Assignment',
        AssignmentCancel: 'Assignment/Cancel',
        AssignmentById: 'Assignment/Edit/{id}',
        EditAssignmentById: 'Assignment/Edit',
        AuditorByAssignment: 'Assignment/AuditorsWithAssignments',
        SucursalByAssignment: 'Assignment/StoresWithAssignments',
        RegionByAssignment: 'Assignment/RegionsOfStoresWithAssignments',
        DateRangeByAssignment: 'Assignment/DateRange',
        StatusByAssignment: 'Assignment/Status',
        AssignmentValidation: 'Store/AssignmentValidationByAuditor',
        HeaderRecolectionGold: 'Store/HeaderGoldItemsForSaleByStore/{id}',
        HeaderAuditGold: 'Store/HeaderAuditedGoldItemsAsync/{id}',
        AssignmentRecolectionGold: 'Store/GoldItemsForSaleByStore/{id}',
        AssignmentAuditGold: 'Store/AuditedGoldItemsAsync/{id}',
        Auctions: 'Audit/Item/{sku}',
        ImagesAuctions: 'Audit/Item/{sku}/Images',
        AuditAuctions: 'Audit/Item/Validation',
        EmployeeIncident: 'Employee/Incident/{sku}',
        EmployeeResponsible: 'Employee/Responsible/{id}',
        IncidentAudit: 'Audit/Incidence',
        DeleteAudit: 'Audit',
        PostAudit: 'Audit',
        Collector: 'Collector',
        SecurityBranch: 'SecurityBag/branch',
        SecurityImage: 'SecurityBag/delivery',
        CollectorInternal: 'Collector/internal',
        SecuritybagAdd: 'SecurityBag/add',
        DocumentHeader: 'Document/Header/{id}',
        DocumentBody: 'Document/Body/{id}',
        AssignmentFinish: 'Assignment/Finish',
        ImagesPapeleta: 'SecurityBag',
        AssignmentProcess: 'Assignment/Process',
        EmployeeManagers: 'Employee/managers',
        EmployeeWitnesses: 'Employee/witnesses',
        EmployeeByID: 'Employee/job/{id}',
        Smelting: 'Smelting',
        DocumentDownload: 'Document/{id}',
        SecurityBagTransit: 'SecurityBag/transit',
        SecurityBagWeight: 'Smelting/securitybag/{nosecuritybag}/review',
        IdentifyFoundry: 'Smelting/{fecha}',
        SecurityBagWightReview: 'Smelting/{id}/securitybags',
        SecurityBagWeightDelete: 'Smelting/securitybag/review',
        postSecurityBagWeight: 'Smelting/securitybag/review',
        SecurityBagWeightBuckets: 'Smelting/{id}/buckets',
        postSecurityBagWeightBuckets: 'Smelting/buckets',
        SecurityBagWeightIngots: 'Smelting/{id}/ingots',
        postSecurityBagWeightIngots: 'Smelting/ingots',
        SecurityBagWeightGold: 'Smelting/{id}/prices',
        postSecurityBagWeightGold: 'Smelting/prices',
        getPurity: 'Smelting',
        getPurityLab: 'Smelting/{id}/ingots/record',
        getPurityFile: 'Smelting/{id}/ingot/{idingot}',
        postPurityFile: 'Smelting/purify',
        postPurityPay: 'Smelting/pay',
    },
    urlShopify: {
        getProductsShopify: 'Products',
        getProductsShopifyDetail: 'Products/Detail?sku={sku}',
    },
    urlStore: {
        ContractReactivation: 'Reactivacion?IdSucursal={idSucursal}&Contrato={Contract}',
        EmailReactivation: 'EmailReactivacion',
        Banner: 'Banner',
        BannerDetail: 'Banner/detail',
        Family: 'catalog/Family',
        FamilybyId: 'catalog/Family/{familyId}/subfamilies',
        SubFamily: 'catalog/SubFamily',
        SubFamilybyId: 'catalog/SubFamily/{subFamilyId}/brands',
        Group: 'catalog/Group',
        GroupbyId: 'catalog/Group/{groupId}/families',
        Brand: 'catalog/Brand',
        BrandbyId: 'catalog/Brand/{brandId}/items',
        Item: 'catalog/Item',
        Sucursal: 'catalog/Store',
        Clients: 'clients/ClientsHome',
        HomeMovements: 'clients/ClientsHome/Movements',
        HomeOrders: 'clients/ClientsHome/Orders',
        HomeFilter: 'clients/ClientsHome/Filter',
        HomeStars: 'clients/ClientsHome/Stars',
        HomeFavs: 'clients/ClientsHome/favoritesClients',
        AutomaticEndorsement:
            '/clients/AutomaticEndorsement?branchId={branchId}&contract={contract}',
        // Movimientos: "movements/Movements",
        DocumentClients: 'clients/Clients/Documentos/{idRow}',
        Contract: 'prendas/Prendas/ContratoDesempeno/{idRow}',
        DeliveryContract: 'prendas/Prendas/EntregaDesempeno/{idRow}',
        Transaction: 'mastertransaction/MasterTransaction',
        AdminLocations: 'Locations/AdminLocations',
        PaymentCards: 'clientes/Datacard',
    },
    urlStoreEndeavor: {
        AutomaticEndorsement:
            'clients/AutomaticEndorsement?branchId={branchId}&contract={contract}',
    },
    urlPawn: {
        Reactivations: 'Reactivations?idSucursal={sucursalId}&Contrato={contract}',
        ReactivationsPut: 'Reactivations',
    },
    urlDeadline: {
        Lista: 'Terms/List/{companyId}',
        Description: 'Terms/Description/{companyId}',
        FilterforBranch: 'Branch/Company/{companyId}/Branches',
        SinglePage: 'Terms/Single/{companyId}/{sendProduct}',
        PeriodType: 'PeriodType',
        ProductsExchange:
            'Terms/ProductsExchange/{companyId}?groupId={groupId}&familyId={familyId}&subfamilyId={subfamilyId}&brandId={brandId}&itemId={itemId}',
        SinglePost: 'Terms',
        ExistentInitialDeadline: 'Terms/InitialInfo/{companyId}/{branchId}/{termId}',
        ExistentInterestDeadline: 'Terms/Interest/{companyId}/{branchId}/{termId}',
        ExistentAmountsTimesDeadline: 'Terms/AmountsAndTimes/{companyId}/{branchId}/{termId}',
        ExistentTermsDeadline: 'Terms/GracePhases/{companyId}/{branchId}/{termId}',
        ExistentDiscountsDeadline: 'Terms/Discounts/{companyId}/{branchId}/{termId}',
        ExistentExtensions: 'Terms/Extensions/{companyId}/{branchId}/{termId}',
        ExistentStars: 'Terms/Stars/{companyId}/{branchId}/{termId}',
        Status: 'Terms/Status',
        SingleTerms: 'Terms/Single',
        Example: 'Terms/Example',
    },
    urlSupport: {
        Group: 'Group?IDBranch={BRANCH}', //Quitar
        Family: 'Group/groupId/families?IDGroup={ID}&IDBranch={BRANCH}', //Quitar
        Subfamily: 'catalog/Family/familyId/subfamilies?familyId={ID}&IDBranch={BRANCH}', //Quitar
        Brand: 'catalog/Brand/SubfamiliaId/brands?IDSubfamily={ID}&IDBranch={BRANCH}', //Quitar
        Article: 'catalog/Item/brandId/Item?IDBrand={ID}&IDBranch={BRANCH}', //Quitar
        BlockOrActive: 'catalog/Catalog?level={LEVEL}&idCategory={ID}', //Quitar
        Add: 'Support', //Quitar
        FieldDinamic: 'ControlArticulos',
        Search: '',
        SearchById: '',
        StatusControl: '',
        GetArticulos: 'Support/GetDinamicLevels?Level={LEVEL}&ID={IDS}',
        PutArticles: 'ControlArticulos',
        PutRequiredArticles: 'ControlArticulos/PutRequired?RowID={ROWID}&Status={STATUS}',
    },
    urlFlow: {
        Catalog: 'catalog/Inicio?idSucursal={idSucursal}',
        AddFlow: 'Flujo/AddFlow', //Quitar
        GetAll: 'Flujo/GETAll',
        GetRequestById: 'Flujo/GETID?requestId={requestId}&branchId={branchId}',
        ApproveRequest: '/Flujo/SetApprobation',
        GETBranchUser: 'UsuarioCuentas/GETBranchUser?branchId={branchId}',
        GetBalanceByStore: 'Saldo/GetBranchBalance?branchId={branchId}',
        PutDeposited: 'Flujo/SetDeposited',
        PutReceived: 'Flujo/SetReceived',
        GETBranchsByCompany: 'Flujo/GETBranchsByCompany?CompanyId={CompanyId}',
        GetStatusFlow: 'Flujo/GETStatusFlow',
        GetMonths: 'Flujo/GETMonths',
        GETAccount: 'Flujo/GETAccount?RequestID={requestId}&Branchid={branchId}',
        GetNearByBranches: 'UbicacionSucursal/GetNearbyBranches?IDBranch={branchId}', // Quitar
        PostCloseCheckout: 'CierresEfectivo/ExecuteCloseCheckout',
        GetVaultsPerBranch: 'CierresEfectivo/GetVaultPerBranch?IdBranch={branchId}',
        GetBalance:
            '/Saldo/GetBranchBalanceByCompany?CompaniId={CompaniId}&pageindex={pageindex}&numberregisters={numberregisters}',
        GetCatalogCountries: 'Countries/GETCountryCorrency',
    },
    urlQuote: {
        LocationsConsult: 'Locations/GetAllSucursales',
        LocationsAdmin: 'Locations/ManageLocation',
        LocationValidation: 'Locations/ContainerValidation',
        LocationContainer: 'Locations/QueryContainer',
        LocationInsert: '/Locations/InsertLocation',
        LocationInsertMult: '/Locations/InsertLocationMult',
        LocationWareHouse: 'Locations/InsertWareHouse',
        LocationOrganization: 'Locations/Organization?idBranch={idBranch}',
        LocationCities: 'Locations/Cities',
        LocationCount: 'Locations/CountContainer?IdBranch={idBranch}&IdContainer={idContainer}',
        QueryLocation: 'Locations/QueryLocation',
        ArticleLocation: 'Locations/InsertLocationToArticle',
        CustomerProfitability: 'Customer/Profitability?NUC={NUC}&IDBranch={IDBranch}',
        GetSaleDay: 'Customer/GetSaleDay?NUC={NUC}&IDBranch={IDBranch}',
        ProfitabilityCountersign:
            '/Customer/ProfitabilityCountersign?NUC={NUC}&IDBranch={IDBranch}',
        ProfitabilityDischarge: '/Customer/ProfitabilityDischarge?NUC={NUC}&IDBranch={IDBranch}',
        ProfitabilityAuction: '/Customer/ProfitabilityAuction?NUC={NUC}&IDBranch={IDBranch}',
        ProfitabilityDiference:
            '/Customer/ProfitabilityDiference?ScoreUS={ScoreUS}&ScoreUSLastMonth={ScoreUSLastMonth}',
    },
    urlLoan: {
        Group: 'Group',
        Family: 'catalog/Family/{groupId}/families?groupname={group}',
        Subfamily: 'catalog/SubFamily/{familyId}/subfamilies?familyname={family}',
        Brand: 'catalog/Brand/{SubfamiliaId}/brands',
        Article: 'catalog/Item/{brandId}/Item',
        BlockOrActive: 'Support',
        Add: 'Support',
        FieldDinamic:
            'ControlArticulos/getFieldDynamic?IDGroup={idgroup}&IDFamily={idfamily}&IDSubfamily={idsub}&IDBrand={idbrand}&IDItem={idart}',
        Search: 'ControlArticulos/Search?busqueda={search}&nivel={level}',
        SearchById: 'ControlArticulos/GetById?RowId={id}',
        StatusControl: 'ControlArticulos',
        Levels: 'GetDinamicLevels?Level={LEVEL}&ID={ID}',
        FieldControl: 'ControlArticulos/getFieldDynamic?Id={id}&Nivel={level}',
        SearchClient: 'Customer/SearchCustomer?Search={SEARCH}',
        SearchClientByName:
            'Customer/SearchCustomerByName?Name={name}&Name={paterno}&Name={materno}',
        SearchLoan: 'Quote/GetCategory?Search={SEARCH}&Filter=1', //Quitar
        DeadLines:
            'Quote/DeadLines?IDPromotion={idPromotion}&IDBranch={idBranch}&Loan={loan}&Value={appraisal}',
        DeadLinesTimes: 'Quote/Plazos',
        Nationality: 'Quote/Nationality',
        MaxMin: 'Quote/GetMaxMin',
        Stailments: 'Quote/GetPawnContract',
        Deadlines: 'Quote/Deadlines',
        Save: 'Customer/PostCoOwnerBeneficiary',
        SaveData: 'Customer/GetCoOwnerBeneficiary?NUC={nuc}',
        PaymentQ: 'Quote/Pawn',
    },
    urlOrganizations: {
        OrganizationsList: 'GetOrganizationsList?IdOrganization={IdOrganization}',
        InformationBranch: '/admin/EnterpriseBranch/InfoBranch?IDBranch={IdBranch}',
        PrincipalInformation: '/admin/EnterpriseBranch/PrincipalInformation/{IdBranch}',
        AdditionalInformation: '/admin/EnterpriseBranch/AdditionalInformation/{IdBranch}',
        OtherInformation: '/admin/EnterpriseBranch/OtherInformation/{IdBranch}',
        PostalCode: '/admin/EnterpriseBranch/InfoLocationBranchByCP?PostalCode={PostalCode}',
        State: '/admin/EnterpriseBranch/States?IDState={IdState}',
        Cities: '/admin/EnterpriseBranch/Cities?IDState={IdState}',
        MunicipalityTownhall:
            '/admin/EnterpriseBranch/MunicipalityTownhall?idState={IdState}&idCity={IdCity}',
        Suburb: '/admin/EnterpriseBranch/Suburb?idState={IdState}&idCity={IdCity}&idMunicipalityTownhall={idMunicipalityTownhall}',
        CodeState: '/admin/EnterpriseBranch/States',
        PostEnterpriseBranch: 'admin/EnterpriseBranch',
    },
    urlWeb: {
        ValidateCodeAdmin: 'UserAdministrator/ValidateCodeAdmin',
        ValidateEmailAdmin: 'UserAdministrator/ValidateEmailAdmin',
        GetModulesByRol: 'RoleModule/GetModulesByRol',
        getUserRoles: 'Role/GetUserRoles',
        UpdateUserRole: 'Role/UpdateUserRole',
        UpdateEmployeePicture: 'Useremployee/UpdateEmployeePicture',
        UpdateEmployeeBranches: 'UserEmployee/UpdateEmployeeBranches',
        GetSearchLevelsAndBranchUser: 'Branch/GetSearchLevelsAndBranch',
        GetLevelTreeBranch: 'Levels/GetLevelTreeBranch',
        GetBranchesByEmployee: 'UserEmployee/GetBranchesByEmployee',
        UpdateRolByEmployee: 'UserRole/UpdateRolByEmployee',
        GetRolByCompany: 'Role/GetRolByCompany',
        GetRolByEmployee: 'UserRole/GetRolByEmployee',
        UpdateEmployeeInfo: 'UserEmployee/UpdateEmployeeInfo',
        GetInformationUser: 'UserEmployee/GetInformationUser',
        getEmployeeLogsFile: 'UserEmployee/getEmployeeLogsFile?employeeId={employeeId}',
        DeleteEmployeeById: 'UserEmployee/DeleteEmployeeById?employeeId={employeeId}',
        ActivateEmployeeById: 'UserEmployee/ActivateEmployeeById?employeeId={employeeId}',
        DeactivateEmployeeById: 'UserEmployee/DeactivateEmployeeById?employeeId={employeeId}',
        GetEmployeeLogsById: 'UserEmployee/GetEmployeeLogsById?employeeId={employeeId}',
        GetPasswordRamdom: 'UserEmployee/GetPasswordRamdom',
        UpdateAccountPassword: 'UserEmployee/UpdateAccountPassword',
        GetEmployeeById: 'UserEmployee/GetEmployeeById?employeeId={employeeId}',
        GetUserBCompanyIdPaged: 'UserEmployee/GetUserBCompanyIdPaged',
        PostAddExcessMotive: 'ExcessMotive/addExcessMotive',
        GetReturnType: 'ExcessMotive/GetReturnType',
        addFlow: 'FlowCash/addFlow',
        CreateCashFunding: 'FlowCash/CreateCashFunding',
        GetEmployeeByBranch: 'UserEmployee/GetEmployeeByBranch?branchId={branchId}',
        GetAllDeadline: 'Promotions/AllPromotions',
        GetDeadlineSimulator: 'Promotions/AmortizationTableSimulator',
        PostDeadline: 'Promotions/AddPromotions',
        UpdateDeadline: 'Promotions/UpdatePromotions',
        DeleteDeadline: 'Promotions/DeletePromotions',
        GetPromotionsById: 'Promotions/GetPromotionsById?Id={Id}',
        GetPromotionsBranchIdArticle: 'Promotions/GetPromotionsByBranchIdArticleId',
        GetSimilarPromotions: 'Promotions/GetSimilarPromotions',
        GetAdminWithModules: 'UserAdministrator/GetModulesAdministrator',
        GetEmployeeWithModules: 'UserEmployee/GetModulesEmployee',
        RecoveryAdminPasswordUpdate: 'UserEmployee/UpdateAccountPassword',

        GetCarEmploye: 'ShoopingCarValuator/GetDetailsCarValuator?carShoopingId={carShoopingId}',
        AddBackEmployee: 'ShoopingCarValuator/CreateDischargeEndorement',
        GetUserAuthentication:
            'UserAdministrator/GetAuthenticationUserAdministrator?email={email}&password={password}',
        getValidateUser: 'UserAdministrator/GetValidateUserAdministrator?email={email}',
        PostValidateAdminUsername:
            'UserAdministrator/ValidateRecoveryPassword?UserRegisterAdmin={UserRegisterAdmin}',
        PostCreateUser: 'UserAdministrator/CreateUserAdministrator',
        PostCreatePreRegistrerUserAdministrator:
            'UserAdministrator/CreatePreRegistrerUserAdministrator',
        PostReSendEmailValidation: 'UserAdministrator/ResendEmailValidation',
        UpdatePreRegistrerUserAdministrator:
            'UserAdministrator/UpdatePreRegistrerUserAdministrator',
        ResetPassword:
            'UserAdministrator/UpdateRecoveryPassword?rowId={rowId}&newPassword={Password}&verificationNewPassword={NewPassword}',
        GetEmployeeAuthentication:
            'UserEmployee/GetAuthenticationUserEmployee?UserName={UserName}&Password={Password}',
        getValidateEmployee: 'UserEmployee/GetValidateUserName?userName={UserName}',
        EmployeePasswordRecovery: 'UserEmployee/EmployeePasswordRecovery',
        PostValidateAdminEmployeename:
            'UserEmployee/ValidateUserEmployee?AdminUserName={AdminUserName}',
        PostCreateUserEmployee: 'UserEmployee/CreateUserEmployee',
        GetRolsTypesByCompany: 'Role/GetRolsTypesByCompany?CompanyId={CompanyId}',
        ResetPasswordClient: 'UserEmployee/UpdatePasswordEmployee',
        GetClientAuthentication:
            'UserClient/GetAuthenticationUserClient?UserName={UserName}&Password={Password}',
        getValidateClient: 'UserClient/GetValidateUserClient?UserName={UserName}',
        PostValidateAdminClientname: 'UserClient/ValidateUserClient?AdminUserName={AdminUserName}',
        PostCreateClient: 'UserClient/CreateUserClient',
        Group: 'CatGruop/GetGruopsByBranch?branchId={BrachId}',
        Family: 'CatFamily/GetFamilyByBranchGroup?branchId={BrachId}&groupId={GroupId}',
        SubFamily: 'CatSubFamily/GetSubFamilyByBranchFamily?branchId={BrachId}&familyId={FamilyId}',
        Brand: 'CatBrand/GetBrandByBranchSubFamily?branchId={BrachId}&subFamilyId={SubFamilyId}',
        Items: 'CatItems/GetItemByBranchBrand?branchId={BrachId}&brandId={BrandId}',
        LevelStatusUpdating: 'CatGruop/UpdateStatusLevel',
        CatLabelSize: 'CatLabelSize/GetAllLabelSize',
        CatTipoArticle: 'CatTipoArticuloDetalle/GetAllCatTipoArticuloDetalle',
        AddFieldDinamic: 'ControlItems/CreateItem',
        AddFlow: 'FlowCash/AddFlow',
        GetFlowCashSearch: 'FlowCash/GetFlowCashSearch',
        GetAllFlowExcessFoundrySearch: 'FlowCash/GetFlowExcessSearch',
        GetAllFlowExcessMotive: 'ExcessMotive/GetExcessMotiveSearch',
        PutFlowAprobation: 'FlowCash/UpdateSetAprobation',
        PutExcessAprobation: 'ExcessMotive/UpdateExcessMotive',
        PutFlowDeposited: 'FlowCash/UpdateSetDeposited',
        PutFlowReceived: 'FlowCash/UpdateSetReceived',
        GetAllFlowById: 'FlowCash/GetFlowCashById?requestId={requestId}',
        UpdateExceesMotive: 'ExcessMotive/UpdateExcessMotiveAmount',
        GetExcedentById: 'ExcessMotive/GetExcessMotiveById?ExcessId={ExcessId}',
        GetBalanceById: 'Company/GetCompanyTreeBalance?CompanyId={CompanyId}',
        GetAllBranchByBussiness:
            'Branch/GetAllBranchByBussines?bussinesId={bussinesId}&branchName={branchName}&pageNumber={pageNumber}&pageSize={pageSize}',
        AddLevel: 'CatGruop/CreateCategory',
        GetTree: 'GetCompanyTree?CompanyId={CompanyId}',
        GetBalanced: 'Branch/GetBalanceBranchById?branchId={branchId}',
        GetAccount: 'EmployeeAccount/GetAccountByBranch?BranchId={BranchId}',
        SearchLevel: 'CatGruop/GetCategory?Search={Search}&IdBranch={BranchId}&Filter=1',
        EmpoloyeeAcount: 'EmployeeAccount/GetAccountByBranch?BranchId={BranchId}',
        RequestCashFlow: 'FlowCash/AddFlow',
        RequestExcessMotive: 'ExcessMotive/AddExcessMotive',
        BalanceCashFlow: 'Branch/GetBalanceBranchById?branchId={BranchId}',
        BalanceExcess: 'Branch/GetExtractionBranchById?branchId={BranchId}',
        CatExcessMotive: 'CatExcessMotive/GetAllExcessMotive',
        TableDinamics: 'CatDetailItem/GetCatItemDetailByLevel?LevelId={LevelId}&Level={Level}',
        CatalogExcedent: 'CatStatusExcess/GetStatusExcessAll',
        CatStatusReques: 'CatStatusRequestDown/GetAllBackgroundRequest',
        FlowCash: 'FlowCash/AddFlow',
        GetBranchId: 'Branch/GetInfoBranch?branchId={branchId}',
        GetBranch: 'Branch/GetAllBranchPaginated?companyId={companyId}&branchId={branchId}',
        CatCityMunicipality: 'CatCity/GetAllCityByMunicipality?municipalityId={municipalityId}',
        UpdateLocation: 'Pledge/UpdatePledgeLocation',
        LocationsRack:
            'Rack/GetRackByBranchId?pageNumber={pageNumber}&PageSize={pageSize}&branchId={branchId}',
        LocationsRackById: 'Rack/GetRowsbyRackId?rackId={rackId}',
        LocationsInsertRack: 'Rack/AddRack',
        LocationsUpdateRack: 'Rack/UpdateRack',
        LocationsFloor:
            'Floor/GetAllFloor?pageNumber={pageNumber}&pageSize={pageSize}&branchId={branchId}',
        LocationsFlorBy: 'Floor/FloorById?id={id}',
        LocationsInsertFloor: 'Floor/AddFloor',
        LocationsUpdateFloor: 'Floor/UpdateFloor',
        LocationsPension:
            'Pension/GetAllPensionByBranchId?branchId={branchId}&pageSize={pageSize}&pageNumber={pageNumber}',
        LocaltionPensionById: 'Pension/GetPensionDetails?pensionId={pensionId}',
        LocationsInsertPension: 'Pension/AddPension',
        LocationsUpdatePension: 'Pension/UpdatePension',
        LocationsVault:
            'Voult/GetAllVoult?branchId={branchId}&pageNumber={pageNumber}&pageSize={pageSize}',
        LocationsVaultRows: 'Voult/GetLetterRowsByVoult?voultId={voultId}',
        LocationsVaultDrawers: 'Voult/GetDrawersLetter?letterId={letterId}',
        LocationsVaultSquabs: 'Voult/GetSquabsDrawer?drawerId={drawerId}',
        LocationsInsertVault: 'Voult/AddVoult',
        LocationsCountContainer: 'Voult/GetCountsContainers?branchId={branchId}',
        LocationsCreateContainer: 'CatContainer/GetAllCatContainers',
        LocationsDeleteRack: 'Rack/DeleteRack',
        LocationsDeleteFloor: 'Floor/DeleteFloor',
        LocationsDeletePension: 'Pension/DeletePension',
        LocationsDeleteVault: 'Voult/DeleteVoult',
        LocationsProducts: 'Pledge/GetPledgeLocationByBranch',
        LocationsById: 'Pledge/GetByPledgeId?PledgeId={PledgeId}',
        OrganizationTree: 'Company/GetCompanyTree?CompanyId={IdOrganization}',
        GetBranchArticle: 'CatBranchArticle/GetArticleFinder', //
        GetClientInfoById: 'Client/GetInfoClientForDischarge',
        GetAllClientsInfo: 'Client/GetInfoClientForHomeAppraiser',
        GetAllClient: 'Client/GetSearchAllClients',
        Client: 'Client/GetInfoClient?ClientId={ClientId}',
        EndeavorClientById: 'Pawn/GetContractsByClientEmployees',
        EndeavorClientsBranches: 'Pawn/GetBranchesByContractsClient?clientId={clientId}',
        CardProducts: 'ShoopingCarValuator/GetDetailsCarValuator?carShoopingId={carShoopingId}',
        DeleteCardProducts: 'ShoopingCarValuator/DeleteTemporalyPawn',
        GetAllCoOwner: 'CoOwner/GetAllCoOwner?clientid={clientid}&status={status}',
        PutCoOwner: 'CoOwner/UpdateCoOwner',
        PostCoOwner: 'CoOwner/AddCoOwner',
        GetQuoterPromotionsTable: 'Promotions/GetQuoterPromotionsTable',
        PayQuoter: 'ShoopingCarValuator/GonaToPayment',
        GenerateCarId: 'ShoopingCarValuator/CreateCarValuator',
        GetStates: 'CatBranchArticle/GetArticlesTab?branchId={BranchId}&articleId={ArticleId}',
        Properties:
            'CatBranchArticle/GetArticleStatistics?branchId={BranchId}&articleId={ArticleId}',
        CaculateAuctionPass: 'CatBranchArticle/GetArticleSale',
        CatStates: 'Pledge/GetStateConservation',
        DetailsStadistics: 'CatBranchArticle/GetArticleStatistics',
        DetailsStadisticsGlobal: 'CatBranchArticle/GetArticleStatisticsGlobal',

        History: 'CatBranchArticle/GetValuationHistory',
        InfoClientByNUC: 'Client/GetClientByNUC?NUC={NUC}',
        InfoClientByName:
            'Client/GetClientByName?clientName={clientName}&lastName={lastName}&secondLastName={secondLastName}',
        GetInfoClientbyId: 'Client/GetInfoClient?clientId={clientId}',
        GetHomeClient: 'Client/GetHomeClient?clientId={clientId}',
        CreateCardClient: 'ShoopingCarClient/CreateCarClient?ClientId={ClientId}',
        AddBackPackClient: 'ShoopingCarClient/AddBackPackClient',
        DeleteItemBackPackClient: 'ShoopingCarClient/DeleteItemBackPackClient',
        PaymentCard: 'Card/CreateCard',
        DeleteCard: 'Card/DeleteCard',
        GetDetailCard: 'Card/GetAllCardDataByClient?clientId={clientId}',
        GetDetailsCarShopping: 'ShoopingCarClient/GetDetailsCarShopping?carClientId={ClientId}',
        PostCarShoopingPayment: 'ShoopingCarClient/PostCarShoopingPayment?command={command}',
        GeneratePayment: 'ShoopingCarClient/PostCarShoopingPayment',
        ArticleProperties: 'CatBranchArticle/GetArticleProperties',
        GetNacionality: 'CatCountry/GetNationality',
        PostCartTemporal: 'ShoopingCarValuator/CreateTemporalyPawn',
        SearchArticles: 'CatBranchArticle/GetByBranchArticle',
        AddNewCoOwner: 'CoOwner/AddCoOwner',
        UpdateCoowner: 'CoOwner/UpdateCoOwner',
        DeleteCoowner: 'CoOwner/UpdateCoOwner',
        AddNewBeneficiary: 'Beneficiary/AddBeneficiary',
        EditBeneficiary: 'Beneficiary/UpdateBeneficiary',
        DeleteBeneficiary: 'Beneficiary/DeleteBeneficiary',
        GetAllBenefist: 'Client/GetAllBenefistCoOwner?clientId={clientId}',
        DeleteCarClient: 'ShoopingCarClient/DeleteItemBackPackClient',
        DeleteCarValuator: 'ShoopingCarValuator/DeleteDischargeEndorement',
        DetailsCar: 'ShoopingCarClient/GetDetailsCarShopping?carClientId={carClientId}',
        LevelGroup: 'CatBranchGroup/GetByBranchGroup?branchId={branchId}',
        LevelFamily: 'CatBranchFamily/GetByBranchFamily',
        LevelSubFamily: 'CatBranchSubFamily/GetByBranchSubFamily',
        LevelBrand: 'CatBranchBrand/GetByBranchBrand',
        LevelArticle: 'CatBranchArticle/GetByBranchArticle',
        ValidateRecoverPasswd: 'UserAdministrator/GetRecoveryPasswordStatus?rowId={rowId}',
        GetAllLevels: 'Levels/GetAllAndSearchLevels',
        CreateLevel: 'Levels/CreateLevel',
        UpdateLevel: 'Levels/UpdateLevel',
        DeleteUpdateLevels: 'Levels/DeleteUpdateLevels',
        UpStatusOrganization: 'Levels/UpdateStatusLevel',
        SearchLevels: 'Levels/GetSearchLevels',
        GetStorageByLocationId: 'Levels/GetStorageByLocationId',
        GetRoles: 'Role/GetAllRoleByEnterprise',
        ValidateRole: 'Role/GetValidateRolName',
        ValidateRoleEdit: 'Role/GetValidateNewRolName',
        GetRoleSize: 'Role/GetCompanySize',
        GetSelectRoleType:
            'Role/GetAllRolesExceptDefaultSize?companySizeId={companySizeId}&roleTypeId={roleTypeId}',
        UpdateRoleState: 'Role/UpdateRole',
        GetSizeCompany: 'Role/GetDefaultStructure',
        PutSizeCompany: 'Role/CreateCompanySize',
        CreateStructure: 'Role/CreateStructure',
        GetContactCompany: 'Company/GetDataCompany',
        GetMotiveDelete: 'CatMotiveDelete/GetAllCatMotiveDelete',
        GetDataContactPonzu: 'ContactCompany/ContactPonzu',
        GetPrincipalContactCompany: 'ContactCompany/GetPrincipalContactCompany',
        GetAllContactData: 'ContactCompany/GetAllContactCompany',
        PostMotiveDelete: 'MotiveDeleteCompany/AddMotiveDeleteCompany',
        DeleteReasonCompany: 'MotiveDeleteCompany/DeleteMotiveCompany',
        PutDataContact: 'ContactCompany/UpdateContactCompany',
        PostDataContactAdd: 'ContactCompany/CreateContactCompany',
        PostEmail: 'MotiveDeleteCompany/CompanyDeleteEmail',
        CreateUserEmployee: 'UserEmployee/CreateUserEmployee',
        GetOptionsUserNames: 'UserEmployee/GetListUserName',
        GetAllUsers: 'UserEmployee/GetAllByCompanyQuery',
        GetUserName: 'UserEmployee/GetValidateUserName',
        DuplicateRole: 'Role/CreateDuplicateRole',
        UpdateStatusUser: 'UserEmployee/UpdateStatusUser',
        SearchUsers: 'UserEmployee/GetEmployeeUserSearch',
        GetEmailInUse: 'UserEmployee/ValidateAssigmentEmail',
        GetPhoneInUse: 'UserEmployee/ValidateAssigmentPhone',
        PostChangePassword: 'UserEmployee/ModifyPassword',
        GetRolByTypeRoleId: 'Role/GetUnassignedRolesByCompanyId',
        GetRolsInformation: 'Role/GetRolsInformation',
        GetEmployeeUserSearch:
            'UserEmployee/GetEmployeeUserSearch?CompanyId={CompanyId}&Search={Search}',
        GetAllModulesByRoleType: 'Role/GetAllModulesByRoleType',
        UserAssignmentRole: 'Role/UserAssignmentRole',
        SearchEmployeeUser: 'UserEmployee/GetEmployeeUserSearch',
        GetBranchByRole:
            'Branch/GetLevelTreeBranch?companyId={companyId}&pageNumber={pageNumber}&pageSize={pageSize}',
        SearchLevelsAndBranchs: 'Branch/GetSearchLevelsAndBranch',
        DeleteSingleRole: 'Role/DeleteSingleRole',
        PostGeneralInfoRole: 'Role/PostGeneralInfoRole',
        GetRoleInformation: 'Role/GetAllRoleInformation',
        PutRoleInformation: 'Role/PutGeneralInfoRole',
        GetAllBranches: 'Branch/GetAllBranchByCompanyId',
        CreateBranch: 'Branch/CreateBranch',
        UserInformationById: 'UserEmployee/GetUserEmployeeById?userEmployeeId={userEmployeeId}',
        CreateEmployeeImage: 'UserEmployee/CreateEmployeeImage',
        PutAccessToSystem: 'UserEmployee/UpdateAccessToSystem',
        GetAccessToSystemUser: 'UserEmployee/GetAccessToSystem',
        GetPasswordRandom: 'UserEmployee/GetPasswordRamdom',
        GetImageUser: 'UserEmployee/GetImagesByuserId',
        PutDataUserEmployee: 'UserEmployee/UpdateUserEmployee',
        PostCreateLoginProblem: 'UserEmployee/CreateLoginProblem',
        getBranchTree: 'Branch/GetLevelTreeBranch?companyId={companyId}',
        GetCatCountry: 'CatCountry/GetSomeNations',
        GetTieredLevel: 'Levels/GetSimpleTieredLevel',
        PutUpdateBranchStatus: 'Branch/UpdateBranchStatus',
        GetInformationBranch: 'Branch/GetInformationBranch',
        GetBranchUsers: 'Branch/GetUsersByBranchId',
        GetCatCity: 'CatCity/GetAllCityByMunicipality',
        GetCatState: 'CatState/GetAllStateByCountry',
        GetCatMunicipality: 'CatMunicipality/GetAllMunicipalityByState',
        GetCatSuburb: 'CatColony/GetColonysByMunicipality',
        GetPostalInfo: 'CatColony/GetColonysByPostalCode',
        PutMainInfo: 'Branch/UpdatePrincipalBranchInformation',
        PutOtherInfo: 'Branch/UpdateOtherInformation',
        PutComplementaryInfo: 'Branch/UpdateAdditionalInformation',
        CreateUserBranch: 'Branch/CreateUsersBranch',
        PostValidateRol: 'Role/GetValidateRolName',
        GroupDeadlines: 'CatBranchGroup/GetByBranchGroupList?branchId={BrachId}',
        FamilyDeadlines:
            'CatBranchFamily/GetByBranchFamilyList?branchId={BrachId}&groupId={GroupId}',
        SubfamilyDeadlines:
            'CatBranchSubFamily/GetByBranchSubFamilyList?branchId={BrachId}&familyId={FamilyId}',
        BrandDeadlines:
            'CatBranchBrand/GetByBranchBrandList?branchId={BrachId}&subFamilyId={SubFamilyId}',
        ItemsDeadline:
            'CatBranchArticle/GetByBranchArticleList?branchId={BrachId}&brandId={BrandId}',
        SearchDeadlinesLevels:
            'CatBranchArticle/GetArticleFindeNoPaginated?branchId={BranchId}&search={Search}',
        TreeBranchLocation: 'CatContainer/GetTreeBranchContainer?companyId={CompanyId}',
        GetBranchSchedule: 'Branch/GetBranchHourOperationByBranchId',
        PutBranchSchedule: 'Branch/UpdateBranchHourOperation',
        GetServicesComplements: 'ServicesComplementsBranch/GetServicesComplents',
        PostServicesComplements: 'ServicesComplementsBranch/AddServicesComplement',
        PutServicesComplements: 'ServicesComplementsBranch/UpdateServicesComplement',
        GetCashFlowSetup: 'FlowCash/GetCashFlowSetup',
        PostCashFlowSetup: 'FlowCash/AddCashFlowSetupBranch',
        PutCashFlowSetup: 'FlowCash/UpdateCashFlowSetupBranch',
        GetBranchDistance: 'Branch/GetBranchDistance',
        GetCashNotify: 'FlowCash/GetNotificationFlowCash?BranchId={BranchId}',
        UpdateCashFlowNotify: 'FlowCash/UpdateNotificationFlownCash',
        GetScheduleEndeavor: 'Branch/GetBranchHourEndeavorByBranchId',
        GetScheduleDischarge: 'Branch/GetBranchHourDischargeByBranchId',
        PutScheduleEndeavor: 'Branch/UpdateBranchHourEndeavor',
        PutScheduleDischarge: 'Branch/UpdateBranchHourDischarge',
        GetLoginProblems: 'UserEmployee/GetLoginProblems',
        CreateProblems: 'UserEmployee/CreateLoginProblem',
        GetBranchClose: 'Branch/GetBranchClose?BranchId={BranchId}',
        PostCloseBranch: 'Branch/AddBranchClose',
        GetValidateLoginUsers: 'UserAdministrator/GetAuthenticationUserAdministrator',
        UserAccess: 'UserAccess/GetAuthenticationUserAccess',
        AuthenticationOperative: 'UserEmployee/GetAuthenticationUserEmployee',
        GetValidateUserAdministrator: 'UserAdministrator/GetValidateUserAdministrator',
        CatCompanyBenefits: 'CatCompanyBenefit/CatCompanyBenefits',
        CreateUserAdministrator: 'UserAdministrator/CreateUserAdministrator',
        CatTypeBusiness: 'CatTypeBussiness/CatTypeBussiness',
        CatTypeSales: 'CatPointSale/CatPointSale',
        ModulesAdministrator: 'UserAdministrator/GetModulesAdministrator',
        ResendEmailValidation: 'UserAdministrator/ResendEmailValidation',
        GetModulesEmployee: 'UserEmployee/GetModulesEmployee',
        SearchQuoter: 'CatBranchArticle/GetSearchArticleListQuery',
        AdminUserProfileSettings: 'UserAdministrator/GetAdministratorUserProfileSettings',
        getAdminHomePercentage: 'Company/GetPercentageCompanyInformation?companyId={companyId}',
        getHomeAdmin: 'getHomeAdmin',
        posUserProfileSettings: 'UserEmployee/GetPosUserProfileSettings',
        GetSearchAllUsers: 'UserAccess/GetSearchAllUsers',
        DeleteAllUsers: 'UserAccess/DeleteAllUsers',
        TreeGroups: 'Tree/GetGroupsByCompanyId',
        TreeBranch: 'Tree/GetSearchBranchByName',
        GetPasswordRecoveryExpiration: 'UserEmployee/GetResetRecordValidation',
        UpdatePasswordRecoveryExpiration: 'UserAdministrator/UpdatePasswordRecoveryExpiration',
        GetDashboardEmployee: 'UserEmployee/GetDashboardEmployee',
        DataUsersUpdate: 'UserEmployee/GetInformationUser',
        UpdateUsers: 'UserEmployee/UpdateInformationUser',
        PostAddrticleCatalog: 'CatArticle/CreateTemporalyArticle',
        GroupManagement: 'CatGruop/GetSearchManagerAllByName?searchName={searchName}',
        GetSearchArticle: 'CatGruop/GetSearchManagerAllPagedByName',
        catGroup: 'CatGruop/GetGruopsManager',
        catFamily: 'CatFamily/GetFamilyManagerByGroupId?groupId={groupId}',
        catSubFamily: 'CatSubFamily/GetSubFamilyManagerByCatFamilyId?familyId={familyId}',
        catBrand: 'CatBrand/GetBrandManagerBySubFamilyId?subFamilyId={subFamilyId}',
        catArticle: 'catItems/GetItemManagerByCatBrandId?brandId={brandId}',
        UpdateStatus: 'UserAccess/UpdateStatusAllUsers',
        GetHighLevelRequests: 'CatArticle/GetHighLevelRequests',
        Getcarcontracts: 'ShoopingCarValuator/Getcarcontracts',
        ClientList: 'Client/Getsearchclient',
        PromotionsQuoter: 'Promotions/GetTermSelectionList',
        UpdateSplitCombineTemporalyPawn: 'ShoopingCarValuator/UpdateSplitCombineTemporalyPawn',
        AddClient: 'ShoopingCarValuator/AddClientTemporalyPawn',
        DeleteTemporalyPawn: 'ShoopingCarValuator/DeleteTemporalyPawn',
        GetAllNations: 'CatCountry/GetAllNations',
        GetAllLadaCountry: 'CatCountry/GetAllLadaCountry',
        DeleteItemPledge: 'ShoopingCarValuator/DeleteItemPladge',
        GetModuleByTypePermissions: 'Module/GetModuleByTypePermissions',
        CreateRol: 'Role/CreateRol',
        UpdateRol: 'Role/UpdateRol',
        UpdateCoOwner: 'CoOwner/UpdateCoOwner',
        DeleteCoOwner: 'CoOwner/DeleteCoOwner',
        GetInfoContract: 'ShoopingCarValuator/GetInfoContract',
        AddCartPayment: 'ShoopingCarValuator/AddShoppingCartPayment',
        AddPayment: 'ShoopingCarValuator/PostPaymentAtaskate',
        GetAllCoOwners: 'CoOwner/GetAllCoOwner',
        AddcoownerBeneficiary: 'ShoopingCarValuator/AddcoownerBeneficiary',
        ValidPrechrageimage: 'ShoopingCarValuator/ValidPrechrageimage',
        UploadImagesArticle: 'ShoopingCarValuator/UploadImagesArticle',
        GetAllRoleInformation: 'Role/GetAllRoleInformation',
        AddCoOwner: 'CoOwner/AddCoOwner',
        GetSingleSearchAllUsers: 'UserAccess/GetSingleSearchAllUsers',
        DeleteUserRol: 'UserRole/DeleteUserRol',
        GetAsignedUserRole: 'UserRole/GetSingleSearchUsersByRol',
        AddProofAddressClient: 'Client/AddProofAddressClient',
        ValidProofAddressClient: 'Client/ValidProofAddressClient',
        AddFinger: 'Fingers/AddFinger',
        GetCatFingers: 'Fingers/GetCatFingers',
        GetAllCatDocumentType: 'CatDocumentType/GetAllCatDocumentType',
        NewClient: 'Client/AddClient',
        PutPromotions: 'ShoopingCarValuator/AddPromotionTemporalyPawn',
        GetNewGroupBranch: 'Company/GetNewGroupBranch',
        GetAffectedUsers: 'UserRole/GetAffectedUsers',
        GetAssignmentClient: 'Discharge/GetAssessmentClinet',
        ClientProfile: 'Client/GetClientsProfile',
        GetClientDetails: 'Client/GetClientDetails',
        ValidClientIdImage: 'Client/ValidClientIdImage',
        AddIdentificationDocument: 'Client/AddIdentificationDocument',
        GetPersonalCredit: 'Client/GetPersonalCredit',
        GetAllMaritalStatus: 'CatMaritalStatus/GetAllMaritalStatus',
        GetAllEconomicActivity: 'CatEconomicActivity/GetAllEconomicActivity',
        GetAllGender: 'CatGender/GetAllGender',
        GetAllGenderPersonalLoan: 'CatGender/GetAllGender',
        GetAllIdentificationType: 'CatIdentificationType/GetAll',
        GetAllMarketingClient: 'CatMarketingClient/GetAllMarketingClient',
        GetAllValidateIdType: 'CatValidateIdType/GetAllValidateIdType',
        AddCheckIdentity: 'PersonalLoans/AddCheckIdentity',
        UpdateSaveDocumentsId: 'PersonalLoans/UpdateSaveDocumentsId',
        GetValidateLoan: 'PersonalLoans/GetValidateLoan?loanId={loanId}',
        ValidateBankCardType: 'CatBankCardType/GetvalidateBankCardType',
        AddPaymentMethodCompany: 'CompanyPaymentMethod/AddPaymentMethodCompany',
        GetCompanyPaymentMethod: 'CompanyPaymentMethod/GetPaymentMethodByCompanyId',
        GetAllCatEmploymentSituation: 'CatEmploymentSituation/GetAllCatEmploymentSituation',
        GetAllCatLaborOld: 'CatLaborOld/GetAllCatLaborOld',
        AddEmploymentSituation: 'PersonalLoans/AddEmploymentSituation',
        UpdateEmploymentSituation: 'PersonalLoans/UpdateEmploymentSituation',
        CreatePersonalLoan: 'PersonalLoans/CreatePersonalLoan',
        UpdatePersonLoan: 'PersonalLoans/UpdatePersonLoan',
        ValidateUserMarketPlace: 'UserClient/GetClientExistenceValidation?email={email}',
        CreateClientMarket: 'UserClient/CreateUserClient',
        ValidateCodeUserClient: 'UserClient/ValidateCodeUserClient',
        SendCodeUserClient: 'UserClient/SendCodeUserClient',
        GetMyCompanyDetails: 'Company/GetMyCompanyDetails',
        GetCompanyTaxData: 'Company/GetCompanyTaxData',
        UpdateTaxInformation: 'Company/UpdateTaxInformation',
        GetStatusPrivacyCompany: 'Company/GetStatusPrivacyCompany',
        UpdateImageCompanyId: 'Company/UpdateImageCompanyId',
        DownloadCompanyInformation: 'Company/DownloadCompanyInformation',
        CreateNewCatalogId: 'CatDetailItem/CreateNewCatalogId',
        GetDetailsCharacteristics: 'CatDetailItem/GetDetailsCharacteristics',
        UpdateNewCatalogId: 'CatDetailItem/UpdateNewCatalogId',
        GetAllCatArticleTypeDetails: 'CatTipoArticuloDetalle/GetAllCatTipoArticuloDetalle',
        GetInheritedFeatures: 'CatDetailItem/GetInheritedFeatures',
        Getcharacteristicsdinamics: 'CatDetailItem/Getcharacteristicsdinamics',
        GetNewCatalogId: 'CatDetailItem/GetNewCatalogId',
        ValidateEmailClient: 'UserClient/ValidateEmailClient?email={email}',
        EmailClientCodeValidator: 'UserClient/EmailClientCodeValidator?rowid={rowid}&code={code}',
        UpdatePasswordClient: 'UserClient/UpdatePasswordClient',
        GetAuthenticationUserClient: 'UserClient/GetAuthenticationUserClient',
        GetInformationOnCompanyBusinessLines: 'Company/GetInformationOnCompanyBusinessLines',
        GetContractedPlans: 'Company/GetContractedPlans',
        GetSalesChannels: 'Company/GetSalesChannels',
        GetAllCatFrequency: 'CatFrequency/GetAllCatFrequency',
        GetAllCatPersonalLoansPeriod: 'CatPersonalLoanPeriod/GetAllCatPersonalLoanPeriod',
        GetQuoteCreditPersonalLoans: 'PersonalLoans/GetQuoteCreditPersonalLoans',
        GetTableAmortization: 'PersonalLoans/GetTableAmortization',
        GetActivateLevelOrItem: 'CatDetailItem/GetActivateLevelOrItem',
        GetHolidaysByLevelId: 'Company/GetHolidaysByLevelId',
        GetOpeningHoursByLevelId: 'Company/GetOpeningHoursByLevelId',
        GetLevelsByCompanyId: 'Branch/GetLevelsByCompanyId',
        GetListLevelsByLevelId: 'Levels/GetListLevelsByLevelId',
        GetSearchLevelsAndBranch: 'Branch/GetSearchLevelsAndBranch',
        GetAllTotalAvailables: 'PersonalLoans/GetAllTotalAvailables',
        GetDetailsCredit: 'PersonalLoans/GetDetailsCredit',
        GetListLoansSearch: 'PersonalLoans/GetListLoansSearch',
        GetListLoanPersonal: 'PersonalLoans/GetListLoanPersonal',
        GetCreditRequest: 'CreditsAndPawns/{solicitudeId}/CreditRequest',
        GetAdditionalCustomerInformation: 'PersonalLoans/GetAdditionalCustomerInformation',
        GetColonysByPostalCode: 'CatColony/GetColonysByPostalCode',
        GetAllStateByCountry: 'CatState/GetAllStateByCountry',
        GetAllMunicipalityByState: 'CatMunicipality/GetAllMunicipalityByState',
        GetColonysByMunicipality: 'CatColony/GetColonysByMunicipality',
        GetAddressBranchById: 'BranchAddress/GetAddressBranchById',
        GetBranchEmployeeSearch: 'UserEmployee/GetBranchEmployeeSearch',
        GetUserLevelById: 'UserEmployee/GetUserByLevelId',
        GetAllTransactions: 'FlowCash/GetAllSearchTransactions',
        GetAllCashflow: 'FlowCash/GetAllCashFlow',
        GetUserDetails: 'FlowCash/GetUsersCashFlow',
        GetExpenses: 'FlowCash/GetSearchSpent',
        GetStatusApplicationValidate: 'CatPersonalLoanPeriod/GetStatusSolicitudeValidate',
        GetAllCatBank: 'CatBank/GetAllCatBank',
        GetCreditLoanSummary: 'PersonalCreditPayment/GetCreditLoanSummary',
        AddWireTransferPersonalLoan: 'PersonalCreditPayment/AddWireTransferPersonalLoan',
        GetDataPersonalLoadFilters: 'PersonalLoans/GetDataPersonalLoadFilters',
        GetPersonalLoansByFilters: 'PersonalLoans/GetPersonalLoansbyFilter',
        GetCreditAndPawnsHistory: 'CatPersonalLoanPeriod/Gethistorycreditandpawn',
        GetRequestedCreditSolicitude: 'CatPersonalLoanPeriod/approbateCreditSolicitude',
        GetPercentageAmortization: 'CatPersonalLoanPeriod/GetPorcentAmortization',
        GetInventaryStorage: 'Company/GetInventaryStorage',
        GetSearchInventaryStorage: 'Branch/GetSearchInventaryStorage',
        GetPawnByBranchTransactionId: 'Pawn/GetPawnByBranchTransactionId?id={id}',
        SendConfirmationVoucher: 'PersonalLoans/EmailPaymentMade',
        GetStatisticalnformationCreditProfile:
            'PersonalLoans/GetStatisticalnformationCreditProfile',
        GetCardCreditsClientProfile: 'PersonalLoans/GetCardCreditsClientProfile',
        GetDataFilterCustomerProfile: 'PersonalLoans/GetDataFilterCustomerProfile',
        GetResultFiltroCustomerProfile: 'PersonalLoans/GetResultFiltroCustomerProfile',
        GetAdminCashflowData: 'Manager/Branches',
        GetPaymentTypes: 'CatPaymentType/GetAllCatPaymentType',
        GetExpenseTypes: 'CatExpenseType/GetExpenseTypes',
        GetBranchProviders: 'ExpenseProvider/GetBranchProviders',
        PostCreateExpense: 'Expense/AddExpense',
        GetExpenseInvoice: 'Expense/GetInvoiceByBranchTransactionId',
        GetExpenseDetails: 'Expense/GetExpenseByBranchTransactionId',
        PutExpenseAuth: 'Expense/UpdateStatus',
        GetFastPaymentPersonalLoans: 'PersonalCreditPayment/GetFastPaymentPersonalLoans',
        AddFastPayment: 'PersonalCreditPayment/AddFastPayment',
        GetClientCards: 'Client/GetClientCards',
        CreateStorageByLevelId: 'Storage/CreateStorageByLevelId',
        GetLastByCompanyId: 'Levels/GetLastByCompanyId',
        GetFrecuencyPayments: 'TermPersonalLoan/GetFrecuencyPayments',
        GetClientCreditCards: 'ClientMethodPayment/GetClientCreditCards',
        AddPaymentMethod: 'ClientMethodPayment/AddPaymetMethod',
        UpdatePaymentMethod: 'ClientMethodPayment/UpdatePaymetMethod',
        DeletePaymentMethod: 'ClientMethodPayment/DeletePaymetMethod',
        GetCreditMovementHistory: 'PersonalLoans/GetCreditMovementHistory',
        GetSolicitudeTermDetails: 'TermPersonalLoan/GetSolicitudeTermDetails',
        GetPawnContracts: 'Client/GetPawnContracts',
        GetByIdPersonalCreditAmount: 'PersonalLoans/GetByIdPersonalCreditAmount',
        GetByIdCreditLoanDetail: 'PersonalLoans/GetByIdCreditLoanDetail',
        GetRequestedAmountRange: 'TermPersonalLoan/GetRequestedAmountRange',
        GetAmountFrequency: 'TermPersonalLoan/GetAmountFrequency',
        GetTermQuoteCreditPersonalLoans: 'TermPersonalLoan/GetQuoteCreditPersonalLoans',
        GetCatGroup: 'CatGruop/GetGroups',
        GetFamilyManager: 'CatFamily/GetFamilyManagerByGroupId',
        GetSubfamilyManager: 'CatSubFamily/GetSubFamilyManagerByCatFamilyId',
        GetBrand: 'CatBrand/GetBrandManagerBySubFamilyId',
        GetItems: 'CatItems/GetItemByCatBrandId',
        GetSearchBar: 'CatGruop/GetSearchManagerCompanyByName',
        GetContractStatusActive: 'CatContractStatus/GetContractStatusActive',
        GetContractDetailsForProfile: 'Client/GetContractDetailsForProfile',
        AddDischargeEndorsement: 'ShoopingCarValuator/AddDischargeEndorsement',
        GetDownLoadPawnContract: 'Client/GetDownLoadPawnContract',
        GetCarByCarId: 'ShoopingCarValuator/GetCarByCarId?carshopingId={carshopingId}',
        GetBranchByLevelId: 'Branch/GetBranchByLevelId',
        UpdateBranch: 'Branch/UpdateBranch',
        CreateBranchStorage: 'Branch/CreateBranchStorage',
        UpdateBranchStorage: 'Branch/UpdateBranchStorage',
        GetBranchStorageByCompanyId: 'Branch/GetBranchStorageByCompanyId',
        PostNewArticle: 'CatArticle/RequestArticle',
        GetPersonalCreditProducts: 'TermPersonalLoan/GetPersonalCreditProducts',
        GetProductsCustomerUser: 'TermPersonalLoan/GetProductsCustomerUser',
        GetStorage: 'Storage/GetStorageByLevelId?LevelId={LevelId}&StorageId={StorageId}',
        UpdateStorage: 'Storage/UpdateStorageByStorageId',
        UpdateEndorsements: 'ShoopingCarValuator/AddCapitalPayment',
        AddExtensionTemporaly: 'ShoopingCarValuator/AddExtensionTemporaly',
        DeletePersonalLoan: 'CatPersonalLoanPeriod/DeletePersonalLoan',
        GetCatDocuments: 'TermPersonalLoan/GetCatDocuments',
        GetCatDisbursement: 'TermPersonalLoan/GetCatDisbursement',
        GetGroupsByCompanyId: 'Levels/GetGroupsByCompanyId',
        GetCreditDetailCustomerProfile: 'PersonalLoans/GetCreditDetailCustomerProfile',
        GetFilterLoanProducts: 'TermPersonalLoan/GetFilterLoanProducts',
        GetLoanProductsSearchEngine: 'TermPersonalLoan/GetLoanProductsSearchEngine',
        GetUsersByLocationId: 'Levels/GetUsersByLocationId',
        ChangeLevelStatus: 'Levels/UpdateStatusLevel',
        CreditPaymentProcess: 'PersonalCreditPayment/AddCreditPaymentProcess',
        EmailPaymentMadeSeveralCreditsLoans: 'PersonalLoans/EmailPaymentMadeSeveralCreditsLoans',
        GetAuctionGroups: 'CatCompanyGroup/GetCompanyGroups',
        GetAuctionFamilies: 'CatCompanyFamily/GetCompanyFamiliesByGroupId',
        GetAuctionSubFamilies: 'CatCompanySubFamily/GetCompanySubFamiliesByFamilyId',
        GetAuctionBrands: 'CatCompanyBrand/GetCompanyBrandsBySubFamilyId',
        GetAuctionItems: 'CatCompanyItem/GetCompanyItemsByBrandId',
        PutEditCatalogue: 'CatGruop/UpdateCompanyItem',
        DeleteExtensionTemporaly: 'ShoopingCarValuator/DeleteExtensionTemporaly',
        ActiveDesactiveTermCredit: 'TermPersonalLoan/ActiveDesactiveTermCredit',
        GetTermPersonalLoan: 'TermPersonalLoan/GetTermPersonalLoan',
        UpdateTermPersonalLoan: 'TermPersonalLoan/UpdateTermPersonalLoan',
        DeleteDischargeEndorsement: 'ShoopingCarValuator/DeleteDischargeEndorsement',
        GetHolydaysByLocationId: 'Levels/GetHolydaysByLocationId',
        GetOpenHoursByLocationId: 'Levels/GetOpenHoursByLocationId',
        GetCatPaymentTypes: 'TermPersonalLoan/GetCatPaymentsTypes',
        GetChargeAndPenaltiesDefault: 'TermPersonalLoan/GetChargeAndPenaltiesDefault',
        GetTypeChargeAndPenalties: 'TermPersonalLoan/GetTypeChargeAndPenalties',
        GetTypeAmountChargePenalties: 'TermPersonalLoan/GetTypeAmountChargePenalties',
        GetCatConditionsTerms: 'TermPersonalLoan/GetCatConditionsTerms',
        GetTypeEventCondition: 'TermPersonalLoan/GetTypeEventCondition',
        GetCreditDetail: 'PersonalLoans/GetCreditDetail',
        GetCreditDetailPayment: 'PersonalLoans/GetCreditDetailPayment',
        GetCreditDetailHistory: 'PersonalLoans/GetCreditDetailHistory',
        GetQuoterCompaniesLoans: 'CatPersonalLoanPeriod/Getquotercompaniesloans',
        CreateTermPersonalLoan: 'TermPersonalLoan/CreateTermPeronalLoan',
        AutomaticLoanAcceptanceCommand: 'PersonalCreditPayment/AutomaticLoanAcceptanceCommand',
        ChangeCatalogStatus: 'CatGruop/UpdateStatusLevelCompany',
        SendingReceipt: 'ShoopingCarValuator/SendingReceipt',
        GetRoleTypes: 'Role/GetTypeOfRolesByCompany',
        GetStatesByCountryId: 'CatState/GetAllStateByCountry',
        GetMunicipality: 'CatMunicipality/GetAllMunicipalityByState',
        GetColony: 'CatColony/GetColonysByMunicipality',
        PostCreateNewUser: 'UserEmployee/CreateUserEmployee',
        GetAdditionalCreditDocuments: 'PersonalLoans/GetAdditionalCreditDocuments',
        CreateRequestedCredit: 'PersonalLoans/CreateRequestedCredit',
        GetRequestedSummary: 'ShoopingCarClient/GetRequestedSummary',
        UpdateCompanyInformation: 'Company/UpdateCompanyInformation',
        GetRolesAndPermissions: 'Role/GetAllRoleByEnterprise',
        PutUpdateRoleStatus: 'Role/UpdateRoleStatus',
        DeleteRole: 'Role/DeleteSingleRole',
        PostDuplicateRole: 'Role/CopyRole',
        GetRoleUsers: 'useremployee/getemployeebycompany',
        GetAllModules: 'module/GetModuleByTypePermissions',
        PostNewRole: 'role/NewRole',
        PutUpdateRole: 'role/UpdateRole',
        PostCreateProvider: 'ExpenseProvider/AddExpenseProvider',
        GetUpdatePeriods: 'UserEmployee/GetUpdatePeriods',
        CreateUserCommand: 'UserEmployee/CreateUserCommand',
        GetQuoterCompaniesLoansAditionalInf:
            'CatPersonalLoanPeriod/GetQuoterCompaniesLoansAditionalInf',
        ValidateNameTerm: 'TermPersonalLoan/ValidateNameTerm',
        UpdateGroup: 'Levels/UpdateGroup',
        GetRoleById: 'role/GetRolById',
        GetQuoterOthersLoansCompanies: 'CatPersonalLoanPeriod/GetQuoterOthersLoansCompanies',
        DeleteCompanyPaymentMethod: 'CompanyPaymentMethod/DeletePaymentMethod',
        PutPaymentMethodDefault: 'CompanyPaymentMethod/SetDefault',
        GetSimilarQuotersLoansCompanies: 'CatPersonalLoanPeriod/GetSimilarQuotersLoansCompanies',
        GetQuoterCompaniesLoansPublic: 'LoadQuoterPersonalPublic/Getquotercompaniesloans',
        PostSendPasswordEmail: 'UserEmployee/EmailEmployeePasswordReset',
        ClientHasApplicationHistory: 'PersonalLoans/ClientHasApplicationHistory',
        LoadClientDataHistory: 'PersonalLoans/LoadClientDataHistory',
        UpdatePersonalDataApplication: 'PersonalLoans/UpdatePersonalDataApplication',
        DeleteApplicationSummary: 'PersonalLoans/DeleteApplicationSummary',
        GetPersonalLoanIdQuery: 'PersonalLoans/GetPersonalLoanIdQuery',
        GetContractStatusTypes: 'CatContractStatus/GetContractStatus',
        GetProductsForInventoryPledge: 'Pawn/GetProductsForInventoryPledge',
        GetContractsForInventoryPledge: 'Pawn/GetContractsForInventoryPledge',
        GetUserStorages: 'UserEmployee/GetStoragesByEmployee',
        GetInventorySale: 'Pledge/GetInventorySale',
        GetAllStatusConditionCredit: 'PreAapprovedLoans/GetAllStatusConditionCredit',
        GetPreApprovalConditionsLoan: 'PreAapprovedLoans/GetPreApprovalConditionsLoan',
        GetSummaryCondition: 'PreAapprovedLoans/GetSummaryCondition',
        GetValidateCurp: 'PersonalCreditPayment/GetValidateCurp',
    },
});
