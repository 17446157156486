import React from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {BRANCH_TABLE} from '@components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {TableItemsContainer, TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {IBranchTableProps} from '@components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';
import {BranchItem} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {putTreeNewLevel} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/PutLevelTreeNewAction';

const BranchTable = ({
    checkedOptions,
    setCheckedOptions,
    branchData,
    checkedStorages,
    setCheckedStorages,
    isCreate,
}: IBranchTableProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);

    const handleCheckBoxPaymentMethods = (id: number, isBranch: boolean) => {
        if (isBranch) {
            setCheckedOptions((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(id)) {
                    updatedSet.delete(id);
                } else {
                    updatedSet.add(id);
                }
                if (!isCreate) {
                    dispatch(
                        putTreeNewLevel(token, {
                            employeeId,
                            branches: Array.from(updatedSet),
                            storages: checkedStorages,
                        })
                    );
                }
                return updatedSet;
            });
        } else {
            setCheckedStorages((prev) => {
                let updatedStorages = [];
                if (prev.includes(id)) {
                    updatedStorages = prev.filter((i) => i !== id);
                } else {
                    updatedStorages = [...prev, id];
                }

                if (!isCreate) {
                    dispatch(
                        putTreeNewLevel(token, {
                            employeeId,
                            branches: Array.from(checkedOptions),
                            storages: updatedStorages,
                        })
                    );
                }

                return updatedStorages;
            });
        }
    };

    const currentIds = branchData.map((branch) => branch.idBranch);
    const allSelectedIds = [...checkedStorages, ...Array.from(checkedOptions)];
    const areAllChecked = currentIds.every((id) => allSelectedIds.includes(id));

    const selectAllBranches = () => {
        const updatedCheckedBranches = areAllChecked
            ? new Set([])
            : new Set(
                  branchData
                      .filter((branch) => branch.branchType === 'Branch')
                      .map((branch) => branch.idBranch)
              );
        const updatedCheckStorages = areAllChecked
            ? []
            : branchData
                  .filter((branch) => branch.branchType === 'Storage')
                  .map((branch) => branch.idBranch);

        setCheckedOptions(updatedCheckedBranches);
        setCheckedStorages(updatedCheckStorages);
        dispatch(
            putTreeNewLevel(token, {
                employeeId,
                branches: Array.from(updatedCheckedBranches),
                storages: updatedCheckStorages,
            })
        );
    };

    const branches = branchData.filter((e) => e.branchType === 'Branch');
    const storages = branchData.filter((e) => e.branchType === 'Storage');

    const mapBranchData = (branchData: BranchItem[], isBranch: boolean) => {
        return branchData.map((i: BranchItem) => (
            <TableRowItems
                key={i.idBranch}
                GridColumn="60px 1fr 1fr 1fr 1fr"
                Border="none"
                Height="4.3rem"
            >
                <Checkbox
                    checked={
                        isBranch
                            ? checkedOptions.has(i.idBranch)
                            : checkedStorages.includes(i.idBranch)
                    }
                    onChange={() =>
                        handleCheckBoxPaymentMethods(i.idBranch, i.branchType === 'Branch')
                    }
                />
                <Text>{i.branchName}</Text>
                <ContainerFlex Align="center" Justify="start" Gap="10px">
                    {i.sellsOnline && branchType(false)}
                    {i.isPos && branchType(true)}
                </ContainerFlex>
                <TextEllipsis FontWeight="300" Width="95%" MarginTop="24px">
                    {i.address}
                </TextEllipsis>

                <Text>{i.userCount}</Text>
            </TableRowItems>
        ));
    };

    const branchType = (isPOS: boolean) => {
        return (
            <ContainerFlex
                Justify="center"
                Width="6rem"
                backG={isPOS ? '#E4F7EC' : '#DBF4FF'}
                Border={isPOS ? '1px solid #B1E7C9' : '1px solid #99E0FF'}
                Height="1.5rem"
                Radius="32px"
            >
                <Text Color={isPOS ? '#174A2E' : '#004461'} FontSize="0.85rem" FontWeight="500">
                    {isPOS ? 'POS' : 'En línea'}
                </Text>
            </ContainerFlex>
        );
    };

    return (
        <ContainerFlex FlexDir="column" Height="auto">
            <ContainerFlex
                Display="grid"
                GridColumns="60px 1fr 1fr 1fr 1fr"
                Width="100%"
                Bb="1px solid #E8E9EA"
                Height="3rem"
            >
                <Checkbox checked={areAllChecked} onChange={selectAllBranches} />
                <Text FontWeight="700">{BRANCH_TABLE.NAME}</Text>
                <Text FontWeight="700" MarginLeft="5rem">
                    {BRANCH_TABLE.TYPE}
                </Text>
                <Text FontWeight="700" MarginLeft="40%">
                    {BRANCH_TABLE.ADDRESS}
                </Text>
                <Text FontWeight="700">{BRANCH_TABLE.USERS}</Text>
            </ContainerFlex>

            <TableItemsContainer Width="100%">
                {mapBranchData(branches, true)}
                {mapBranchData(storages, false)}
            </TableItemsContainer>
        </ContainerFlex>
    );
};

export default BranchTable;
