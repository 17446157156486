import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DivisorLine} from '@/components/MyAccount/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {PERSONAL_LOAN_CONFIRMATION} from '@components/PersonalLoans/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IGetConfirmationStepReducerInitialState} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

const ConfirmationLoanSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {dataSummary} = useSelector(
        (state: RootState) =>
            state.GetConfirmationStepReducer as IGetConfirmationStepReducerInitialState
    );

    const navigateAndRest = () => {
        dispatch(ActualDataStepLoans(1));
        navigate(routers.MyAccount);
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Gap="24px"
            MaxW="393px"
            MaxH="288px"
            Radius="16px"
            Border="solid 1px #0D166B"
            Padding="24px"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex FlexDir="column" Gap="16px" Justify="flex-start" Align="flex-start">
                <Text Color="#2A2C2F" FontSize="1.25rem" FontWeight="600" FontFamily="Nunito">
                    {PERSONAL_LOAN_CONFIRMATION.SUMMARY_TITLE}
                </Text>
                <ContainerFlex Justify="space-between" Align="center">
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        FlexDir="column"
                        Gap="4px"
                    >
                        <Text Color="#2A2C2F" FontSize="1rem" FontFamily="Nunito">
                            {PERSONAL_LOAN_CONFIRMATION.REQUESTED_LOAN}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="1rem"
                            FontWeight="700"
                            FontFamily="Nunito"
                            LetterSpacing="0.3px"
                        >
                            {dataSummary?.approvedAmount
                                ? numberToCurrencyWithoutCents(dataSummary?.approvedAmount)
                                : 0}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        FlexDir="column"
                        Gap="4px"
                    >
                        <Text Color="#2A2C2F" FontSize="1rem" FontFamily="Nunito">
                            {PERSONAL_LOAN_CONFIRMATION.REQUEST_NUMBER}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="1rem"
                            FontWeight="700"
                            FontFamily="Nunito"
                            LetterSpacing="0.3px"
                        >
                            {dataSummary?.solicitudeNumber || ''}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="space-between" Align="center">
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        FlexDir="column"
                        Gap="4px"
                    >
                        <Text Color="#2A2C2F" FontSize="1rem" FontFamily="Nunito">
                            {PERSONAL_LOAN_CONFIRMATION.FEES}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="1rem"
                            FontWeight="700"
                            FontFamily="Nunito"
                            LetterSpacing="0.3px"
                        >
                            {dataSummary?.numberPayments || ''}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        FlexDir="column"
                        Gap="4px"
                    >
                        <Text Color="#2A2C2F" FontSize="1rem" FontFamily="Nunito">
                            {PERSONAL_LOAN_CONFIRMATION.ANUAL_INTEREST_RATE}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="1rem"
                            FontWeight="700"
                            FontFamily="Nunito"
                            LetterSpacing="0.3px"
                        >
                            {dataSummary?.interestRate + '%' || ''}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <DivisorLine width="100%" height="1px" />
            <ButtonGenerals Width="100%" onClick={navigateAndRest}>
                {PERSONAL_LOAN_CONFIRMATION.TO_MY_CREDIT}
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default ConfirmationLoanSummary;
