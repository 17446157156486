import {FooterType} from '@/components/AuctionPass/GeneralScreen/FootherModal';
import {ClosedModalProps} from '@/components/AuctionPass/GeneralScreen/Operational/interfaces';

export interface ADD_IDATAFACE {
    id: number;
    nameGroup: string;
    nameFamily: string;
    nameSubFamily: string;
    nameBrand: string;
    name: string;
    markId: number;
    status: boolean;
    iconUrl: string;
    children: ADD_IDATAFACE[];
    validity: string;
    percent: string;
}
export interface AuctionEditProps extends ClosedModalProps, IEditModalAuction {
    footerType?: FooterType;
}

export interface IEditModalAuction {
    title: string;
    description: string;
    subtitle: string;
    bottomTitle: string;
    treeData: ADD_IDATAFACE[];
}

export interface ITreeComponent {
    id: number;
    children: ITreeComponent[];
    label: string;
    validity?: string;
    percent?: string;
}
export interface ISentDataNew {
    data: ITreeComponent[];
    level: number;
    fatherId: number;
    isView: boolean;
}
export function mapToTreeComponent<T>(
    data: T[],
    idKey: keyof T,
    childrenKey: keyof T,
    labelKeys: Array<keyof T>,

    validityKey?: keyof T,
    percentKey?: keyof T
): ITreeComponent[] {
    return data.map((item) => ({
        id: item[idKey] as unknown as number,
        children: mapToTreeComponent(
            (item[childrenKey] as unknown as T[]) || [],
            idKey,
            childrenKey,
            labelKeys,
            validityKey,
            percentKey
        ),
        label: labelKeys
            .map((key) => item[key])
            .find((value) => value && value !== '') as unknown as string,
        validity: validityKey ? (item[validityKey] as unknown as string) : undefined,
        percent: percentKey ? (item[percentKey] as unknown as string) : undefined,
    }));
}
