import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import {ProductEditProps} from '@components/InventoryManagement/interfaces';

const ProductEdit: React.FC<ProductEditProps> = ({onClickEdit}) => {
    return (
        <ContainerFlex Align="start" Justify="end" Gap="1rem" PaddingT="0.125rem">
            <Text
                Color="#5A5AFF"
                FontWeight="700"
                Cursor="pointer"
                FontSize="0.875rem"
                onClick={onClickEdit}
            >
                {INVENTORY_PRODUCT_DETAIL.EDIT}
            </Text>
        </ContainerFlex>
    );
};
export default ProductEdit;
