import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import QuoterForm from '@components/GlobalQuoter/QuoterForm';
import QuoterData from '@components/GlobalQuoter/QuoterData';
import barIcon from '@images/bar_icon.svg';
import {GENERAL_MESSAGE} from '@/components/GlobalQuoter/TempConstants';
import {IQuoterProps} from '@components/GlobalQuoter/interface';

const Quoter: React.FC<IQuoterProps> = ({isPublicQuoter}) => {
    return (
        <ContainerFlex
            Height="max-content"
            MinH="485px"
            Width="1160px"
            backG="#FFFFFF"
            Radius="16px"
            Padding="32px 60px 16px 60px"
            Gap="16px"
            Justify="flex-start"
            Align="flex-start"
            FlexDir="column"
        >
            <ContainerFlex Gap="48px" Justify="flex-start" Align="flex-start">
                <QuoterForm isExpressQuoter={false} isPublicQuoter={isPublicQuoter} />
                <QuoterData isExpressQuoter={false} isPublicQuoter={isPublicQuoter} />
            </ContainerFlex>
            <ContainerFlex Gap="8px" Justify="center" Align="center">
                <Image src={barIcon} />
                <Text FontSize="0.875rem" Color="#54575C" FontFamily="Nunito">
                    {GENERAL_MESSAGE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Quoter;
