import {AxiosError, AxiosResponse} from 'axios';
import {
    VALIDATE_LOANS_DATA_ERROR,
    VALIDATE_LOANS_DATA_START,
    VALIDATE_LOANS_DATA_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDataValidateLoansProps} from '@components/PersonalLoans/interfaces';

const initialState: IDataValidateLoansProps = {
    dataLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

const GetValidateLoan = (
    state: IDataValidateLoansProps = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
): IDataValidateLoansProps => {
    switch (action.type) {
        case VALIDATE_LOANS_DATA_START:
            return {...state, loading: true, error: false};
        case VALIDATE_LOANS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                dataLoans: action.payload.data.data,
            };
        case VALIDATE_LOANS_DATA_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};
export default GetValidateLoan;
