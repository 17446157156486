import styled from '@emotion/styled';
import arrowBottom from '@images/arrowBottom.svg';
import {
    IPropsIcons,
    IPropsNumber,
    IPropsSelect,
    IPropsContentLevels,
    IPropsContainerItems,
} from '@Articles/interfacesStyles';

export const NavContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 2rem;
    cursor: pointer;
    a:last-child {
        color: #4a4a4a;
    }
    a {
        display: flex;
        align-items: center;
        background-color: #fff;
        cursor: pointer;
        text-decoration: none;
    }
    a i {
        font-weight: 400;
        color: #a7a9ac;
        font-size: 14px;
        background-color: #fff;
        cursor: pointer;
        text-decoration: none;
    }

    > * {
        font: normal normal normal 12px/14px Work Sans;
        letter-spacing: 0px;
        color: #a7a9ac;
        opacity: 1;
        cursor: pointer;
    }

    a:hover {
        text-decoration: none;
        cursor: pointer;
    }
`;

export const Select = styled.select<IPropsSelect>`
    font-family: Work Sans;
    padding: ${(props) => props.Padding};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Br};
    font-size: ${(props) => props.FontSize};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    color: ${(props) => props.Color};
    appearance: none;
    background: url(${arrowBottom}) no-repeat;
    background-position:
        right 13px top 50%,
        0 0;

    option:disabled {
        color: #a7a9ac;
        height: 30px;
    }
    &:hover {
        border: ${(props) => props.HBorder};
    }

    &:focus {
        box-shadow: ${(props) => props.FocusBoxShadow};
        outline: ${(props) => props.FocusOutline};
    }

    option {
        color: ${(props) => props.OptionColor};
    }
`;
Select.defaultProps = {
    Padding: '0.8rem',
    Border: '1px solid #E4E7E9',
    Br: '5px',
};

export const Numbers = styled.div<IPropsNumber>`
    background-color: ${(props) => props.BGColor && props.BGColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: ${(props) => props.FontSize && props.FontSize};
    color: ${(props) => props.Color && props.Color};
    width: ${(props) => props.Width && props.Width};
    height: ${(props) => props.Height && props.Height};
    margin: ${(props) => props.Margin && props.Margin};
    border: ${(props) => props.Border && props.Border};
    padding: ${(props) => props.Padding && props.Padding};
    box-shadow: ${(props) => props.Shadow};
`;

Numbers.defaultProps = {
    Width: '24px',
    Height: '24px',
    FontSize: '18px',
    Margin: '0 0.694rem 0 0',
};

export const Icons = styled.i<IPropsIcons>`
    font-size: ${(props) => props.FontSize};
    font-style: ${(props) => props.FontStyle};
    color: ${(props) => props.Color};
    height: ${(props) => props.Height};
    font-weight: ${(props) => props.FontWeight};
    cursor: ${(props) => props.Cursor};
    margin-left: ${(props) => props.MarginLeft};
    margin-right: ${(props) => props.MarginRight};
    margin-bottom: ${(props) => props.MarginBottom};
    margin-top: ${(props) => props.MarginTop};
    margin: ${(props) => props.Margin};
    opacity: ${(props) => props.Opacity};
    display: ${(props) => props.Display && props.Display};
    justify-content: ${(props) => props.Justify && props.Justify};
    align-items: ${(props) => props.Align && props.Align};
    position: ${(props) => props.Position};

    &:hover {
        div {
            display: ${(props) => (props.ToolTip ? 'flex' : 'none')};
        }
    }
`;

Icons.defaultProps = {
    Color: '#5A5AFF',
};

export const ContainerItems = styled.div<IPropsContainerItems>`
    min-height: ${(props) => props.MinHeight && props.MinHeight};
    max-height: ${(props) => props.MaxHeight && props.MaxHeight};
    width: ${(props) => props.Width && props.Width};
    overflow: ${(props) => props.OverFlow && props.OverFlow};
    margin-right: ${(props) => props.MarginRight && props.MarginRight};
    margin-left: ${(props) => props.MarginRight && props.MarginLeft};
    margin-bottom: ${(props) => props.MarginBottom && props.MarginBottom};
    margin-top: ${(props) => props.MarginTop && props.MarginTop};
    margin: ${(props) => props.Margin && props.Margin};
    padding: ${(props) => props.Padding && props.Padding};
    border: ${(props) => props.Border && props.Border};
    border-radius: ${(props) => props.Radius && props.Radius};
    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;

export const principalContent = {
    Align: 'left',
    FlexDir: 'column',
    Justify: 'flex-start',
    Margin: '0.8rem 0 0 0',
};

export const Card = {
    FlexDir: 'column',
    Height: 'auto',
    Align: 'left',
    Radius: '1rem',
    backG: '#FFF',
    Gap: '1rem',
    Padding: '1rem',
    Title: {FontSize: '1.25rem', Color: '#54575C', FontWeight: '500', oFlow: 'none'},
    Subtitle: {Color: '#54575C', FontSize: '0.875rem', FontWeight: '400', Width: 'fit-content'},
};

export const IconDinamics = {
    className: 'material-icons',
    FontSize: '1.5rem',
    Color: '#5A5AFF',
};

export const buttonFields = {
    Color: '#5A5AFF',
    Border: '1px solid #5A5AFF',
    Padding: '0.25rem 1rem',
    BorderRadius: '2rem',
    Cursor: 'pointer',
    Width: 'fit-content',
};

export const IconTooltip = {
    className: 'material-icons',
    FontSize: '1.5rem',
    Color: '#A1A5AA',
    Cursor: 'pointer',
    Justify: 'start',
    Align: 'center',
    Position: 'relative',
    tooltip: {
        ZIndex: '1',
        Width: '37.75rem',
        Position: 'absolute',
        PositionLeft: '1.4rem',
        PositionTop: '-2.2rem',
        Height: '',
        Display: 'none',
    },
    clipTooltip: {
        backG: '#0D166B',
        Width: '1rem',
        Height: '1rem',
        ClipPath: 'polygon(100% 0%, 0% 50%, 100% 100%)',
    },
    tooltipContent: {
        FlexDir: 'column',
        backG: '#0D166B',
        Height: '',
        BoxShadow: '0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)',
        Radius: '1rem',
        Align: 'start',
        Padding: '1rem',
        Gap: '0.5rem',
    },
    titleTooltip: {Color: '#D8FF03', FontSize: '0.875rem'},
    descriptionTooltip: {Color: '#FFF', FontSize: '0.875rem', wSpace: 'normal'},
};

export const inputName = {
    FontWeight: '500',
    Color: '#6D6E71',
};

export const errorInput = {
    Color: '#ef4f55',
    FontSize: '0.75rem',
};
export const Title = {
    Height: 'fit-content',
    Justify: 'left',
    Padding: '1.143rem 1.714rem',
    Border: '',
    Bb: '1px solid #E4E7E9',
};
export const Level = {
    Color: '#ffff',
    Width: '24px',
    Height: '24px',
    BGColor: '#7AC143',
    FontSize: '18px',
    Margin: '0 1rem 0 0',
};

export const CreateLevel = {
    Color: '#7AC143',
    FontWeight: '400',
    Cursor: 'pointer',
    FontSize: '1rem',
};

export const NewLevel = {
    Height: 'fit-content',
    Justify: 'left',
    Bb: '1px solid #E4E7E9',
    Cursor: 'Pointer',
};

export const Items = {
    Height: 'fit-content',
    Justify: 'space-between',
    Border: '',
};

export const Header = {
    Justify: 'left',
    Padding: '1.286rem 2rem',
    backG: '#F3F3F3',
    Radius: '5px',
    Height: '3.714rem',
};

export const Inputs = {
    Width: '92%',
    Padding: '0 0 0 0.3rem',
    FontSize: '0.875rem',
    Margin: '0.2rem',
};

export const ContainerAdd = {
    Padding: '0 0 0 0.2rem',
    Border: '1px solid #E4E7E9',
    Radius: '5px',
    Justify: 'left',
    Width: '92%',
    Height: '30px',
};

export const IconAdd = {FontSize: '0.7rem'};
export const NumberAdd = {
    FontSize: '0.7rem',
    MarginLeft: '0.071rem',
    FontStyle: 'normal',
};

export const PropsSideBar = {
    Margin: '0.5rem 1.143rem 0 0.571rem',
    MinHeight: '38.5rem',
    MaxHeight: '100vh',
    OverFlow: 'auto',
    Width: '20%',
    Justify: 'left',
    Border: '1px solid #E4E7E9',
    Radius: '5px',
};

export const StyledLinkQuoter = styled.a`
    color: #35cf44;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
`;

export const ContentLevels = styled.p<IPropsContentLevels>`
    font-weight: ${(props) => props.FontWeight};
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    text-transform: ${(props) => props.Transform};
    margin: ${(props) => props.Margin};
    height: ${(props) => props.Height};
    padding: ${(props) => props.Padding};
    opacity: ${(props) => props.Opacity};
    justify-content: ${(props) => props.Justify};
    cursor: ${(props) => props.Cursor};
    text-decoration: ${(props) => props.TextDecoration};
    border: ${(props) => props.Border};
    font-family: ${(props) => props.FontFamily};

    &::first-letter {
        text-transform: uppercase;
    }
`;

ContentLevels.defaultProps = {
    Margin: 0,
    Height: 'fit-content',
    Justify: 'right',
    Border: '',
    Cursor: 'pointer',
};

export const contentImg = {
    Width: 'auto',
    Height: 'auto',
    RABorder: '50%',
    Align: 'start',
    htmlFor: 'file',
    BackG: 'none',

    imgStart: {
        Width: '4rem',
        Height: '4rem',
        Cursor: 'pointer',
        ObjectFit: 'cover',
        AspRatio: '1',
    },

    imgAdd: {
        Width: '4rem',
        Height: '4rem',
        Cursor: 'pointer',
        ObjectFit: 'cover',
        AspRatio: '1',
    },
};

export const Dropdown = styled.div`
    position: relative;
    top: 0.25rem;
    background: #fff;
    border: 1px solid #e8e9ea;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 18.75rem;
    overflow-y: auto;
`;

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

export const DropdownContainer = styled.div`
    position: relative;
    height: 0px;
    margin: 0;
    box-sizing: border-box;
`;

export const AddedPill = styled.div`
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
    height: 2rem;
    background: #f4f5f5;
    border-radius: 0.5rem;
`;

export const NameSampleText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.25rem 0.5rem;
`;

export const articleNameLabelStyle = {
    FontSize: '1rem',
    FontWeight: '400',
    Color: '#2A2C2F',
    LetterSpacing: '0.019rem',
    wSpace: 'nowrap',
};
