import {
    SET_SELECTED_EMPLOYEE_ID,
    USER_PROFILE_ERROR,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SOUCCES,
} from '@components/MyCompanyUser/Redux/types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    userData: null,
    selectedEmployId: 0,
    message: '',
    error: false,
};

type TUserProfileAdminAction =
    | {type: typeof SET_SELECTED_EMPLOYEE_ID; payload: number}
    | {type: typeof USER_PROFILE_REQUEST}
    | {type: typeof USER_PROFILE_SOUCCES; payload: AxiosResponse}
    | {type: typeof USER_PROFILE_ERROR; error: AxiosError};

export const UserProfileAdmin = (state = initialState, action: TUserProfileAdminAction) => {
    switch (action.type) {
        case SET_SELECTED_EMPLOYEE_ID:
            return {
                ...state,
                selectedEmployId: action.payload,
            };
        case USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case USER_PROFILE_SOUCCES:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: false,
            };
        case USER_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.error.message || 'Error fetching user profile',
            };
        default:
            return state;
    }
};
