import React, {useState, useRef, ChangeEvent} from 'react';
import {countryCodes} from '@/components/NewUserEmployee/Operational/constants';
import {PhoneInputProps} from '@/components/NewUserEmployee/Operational/interface';

import {
    DialCodeButton,
    DialCodeWrapper,
    DropdownContainer,
    DropdownOption,
    FlagImg,
    PhoneInputContainer,
    StyledInput,
} from '@/components/NewUserEmployee/Operational/styles';

const PhoneInput: React.FC<PhoneInputProps> = ({
    onChange,
    defaultDialCode = '+52',
    error,
    onBlur,
    disabled,
    name,
    value: externalValue,
}) => {
    const [phoneNumber, setPhoneNumber] = useState<string>(externalValue || '');
    const [dialCode, setDialCode] = useState<string>(defaultDialCode);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const formatPhoneNumber = (value: string): string => {
        const cleaned = value.replace(/\D/g, '');
        let formatted = cleaned;

        if (cleaned.length > 0) {
            if (cleaned.length <= 2) {
                formatted = `(${cleaned}`;
            } else if (cleaned.length <= 6) {
                formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
            } else {
                formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(
                    6,
                    10
                )}`;
            }
        }

        return formatted;
    };

    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        const cleaned = value.replace(/\D/g, '');

        if (cleaned.length <= 10) {
            const formatted = formatPhoneNumber(cleaned);
            setPhoneNumber(formatted);
            onChange?.({
                dialCode,
                number: cleaned,
            });
        }
    };

    const handleDialCodeSelect = (code: string): void => {
        setDialCode(code);
        setIsDropdownOpen(false);
        onChange?.({
            dialCode: code,
            number: phoneNumber.replace(/\D/g, ''),
        });
        inputRef.current?.focus();
    };

    const getCountryFlag = (code: string): string => {
        return countryCodes.find((country) => country.code === code)?.flag || '';
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <PhoneInputContainer hasError={error}>
            <DialCodeWrapper ref={dropdownRef}>
                <DialCodeButton
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    disabled={disabled}
                >
                    <FlagImg src={getCountryFlag(dialCode)} alt={`flag-for ${dialCode}`} />
                    {dialCode}
                </DialCodeButton>
                <DropdownContainer isOpen={isDropdownOpen}>
                    {countryCodes.map(({code, flag}) => (
                        <DropdownOption key={code} onClick={() => handleDialCodeSelect(code)}>
                            <FlagImg src={flag} alt={`flag-for ${code}`} />
                            {code}
                        </DropdownOption>
                    ))}
                </DropdownContainer>
            </DialCodeWrapper>

            <StyledInput
                ref={inputRef}
                type="text"
                value={phoneNumber}
                onChange={handlePhoneChange}
                onBlur={onBlur}
                name={name}
                placeholder="(56) 20303-0680"
                error={error}
            />
        </PhoneInputContainer>
    );
};

export default PhoneInput;
