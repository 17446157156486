import React, {useState} from 'react';

import {FeatureUnified} from '@Articles/interface';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import plusIcon from '@images/addnewIcon.svg';
import {ClickAwayListener} from '@mui/material';
import {ARTICLE_VISUALIZATION} from '@Articles/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {Container, Dropdown, DropdownContainer, articleNameLabelStyle} from '@Articles/style';

const DropdownFeatureList = ({
    features,
    isChecked,
    onToggle,
}: {
    features: FeatureUnified[];
    isChecked: (feature: FeatureUnified) => boolean;
    onToggle: (feature: FeatureUnified) => void;
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    return (
        <Container>
            <ContainerFlex
                Cursor="pointer"
                Gap="0.5rem"
                onClick={() => setIsDropdownOpen(true)}
                Height="1.5rem"
                Width="11.75rem"
            >
                <Image src={plusIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                <Text
                    Color="#5A5AFF"
                    Cursor="inherit"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                >
                    {ARTICLE_VISUALIZATION.NEW_CHARARTERISTIC}
                </Text>
            </ContainerFlex>
            <DropdownContainer>
                {isDropdownOpen && (
                    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
                        <Dropdown>
                            {features &&
                                features.length > 0 &&
                                features.map((feature: FeatureUnified, index: number) => (
                                    <ContainerFlex
                                        key={index}
                                        Justify="start"
                                        Gap="0.5rem"
                                        Padding="0.5rem"
                                    >
                                        <Checkbox
                                            checked={isChecked(feature)}
                                            onChange={() => onToggle(feature)}
                                        />
                                        <Text {...articleNameLabelStyle}>{feature.name}</Text>
                                    </ContainerFlex>
                                ))}
                            {features.length === 0 && (
                                <Text {...articleNameLabelStyle} Padding="0.5rem">
                                    {ARTICLE_VISUALIZATION.NO_CHARACTERISTICS}
                                </Text>
                            )}
                        </Dropdown>
                    </ClickAwayListener>
                )}
            </DropdownContainer>
        </Container>
    );
};

export default DropdownFeatureList;
