import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IMutateConditionsReducerInitialState} from '@/components/PreApproval/interfaces';
import {ContainerFlex, Text, ContainerGrid, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    CONDITION_PER_PAGE,
    PRE_APPROVAL_TABLE_HEADERS,
    PRE_APPROVAL_TEXTS,
} from '@/components/PreApproval/constants';
import FiltersSection from '@/components/PreApproval/FiltersSection';
import EmptyPreApproval from '@/components/PreApproval/EmptyPreApproval';
import {ApplicationsPagination} from '@/components/CreditsAndPawns/ApplicationsPagination';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import threePoints from '@images/threeDotsBlack.svg';
import {
    getConditions,
    getTotalConditions,
} from '@/components/PreApproval/Redux/Actions/MutateConditionsActions';

const ConditonPreApprovalTable = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {status} = useSelector((state: RootState) => state.ConditionStatusReducer);
    const [activeStatus, setActiveStatus] = useState(0);
    const {conditions, conditionSummary} = useSelector(
        (state: RootState) => state.MutateConditionsReducer as IMutateConditionsReducerInitialState
    );
    const enterpriceId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId as number;
    });
    const totalItems = conditions.totalItems;
    const totalPages = Math.ceil(totalItems / CONDITION_PER_PAGE);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (status[0]) setActiveStatus(status[0].idStatus);
    }, [status]);

    useEffect(() => {
        if (token && enterpriceId) dispatch(getTotalConditions(token, enterpriceId));
    }, [enterpriceId, token]);

    useEffect(() => {
        if (token && activeStatus !== 0)
            dispatch(getConditions(token, activeStatus, 1, CONDITION_PER_PAGE));
    }, [activeStatus, token]);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="16px 24px"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="24px"
            Gap="24px"
            Height="fit-content"
        >
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="8px" Height="61px">
                <Text Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                    {PRE_APPROVAL_TEXTS.TABLE_TITLE}
                </Text>
            </ContainerFlex>
            {conditionSummary.summaryTotal > 0 && (
                <FiltersSection activeStatus={activeStatus} setActiveStatus={setActiveStatus} />
            )}
            <ContainerFlex Justify="flex-start" Align="flex-start" FlexDir="column">
                <ContainerGrid
                    GridColumns="0.84fr 1fr 1fr 0.41fr 0.1fr"
                    Height="44px"
                    Padding="8px 16px"
                    Gap="16px"
                >
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.NAME}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.DEMOGRAPHIC_CONDITIONS}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.BEHAVIOR_CONDITIONS}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.APPLIED_TO}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {PRE_APPROVAL_TABLE_HEADERS.ACTIONS}
                    </Text>
                </ContainerGrid>
                {conditions.data.length === 0 ? (
                    <EmptyPreApproval status={activeStatus} />
                ) : (
                    conditions.data.map((condition) => (
                        <ContainerGrid
                            GridColumns="0.84fr 1fr 1fr 0.41fr 0.1fr"
                            Height="fit-content"
                            Padding="24px 16px"
                            Gap="16px"
                            BorderTp="1px solid #E8E9EA"
                            key={condition.conditionId}
                        >
                            <Text Self="flex-start" FontSize="0.875rem">
                                {condition.conditionName}
                            </Text>
                            <ContainerFlex Justify="start" Align="start" Gap="4px" FlexWrap="wrap">
                                {condition.demographicConditions.map((demographic) => (
                                    <Text
                                        FontSize="0.875rem"
                                        BGColor="#DBF4FF"
                                        Color="#004461"
                                        Padding="2px 8px"
                                        BorderRadius="4px"
                                        FontWeight="700"
                                        wSpace="nowrap"
                                        key={demographic.demographicId}
                                    >
                                        {demographic.demographicName}
                                    </Text>
                                ))}
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" Gap="4px" FlexWrap="wrap">
                                {condition.conditionsBehaviors.map((conditionsBehavior) => (
                                    <Text
                                        FontSize="0.875rem"
                                        BGColor="#FFF5DB"
                                        Color="#614500"
                                        Padding="2px 8px"
                                        BorderRadius="4px"
                                        FontWeight="700"
                                        wSpace="nowrap"
                                        key={conditionsBehavior.behaviorId}
                                    >
                                        {conditionsBehavior.behaviorName}
                                    </Text>
                                ))}
                            </ContainerFlex>
                            <Text Self="flex-start" FontSize="0.875rem">
                                {condition.numberLoans}{' '}
                                {condition.numberLoans === 1
                                    ? PRE_APPROVAL_TEXTS.LOAN
                                    : PRE_APPROVAL_TEXTS.LOANS}
                            </Text>
                            <ButtonGenerals
                                BackGC="transparent"
                                Color="transparent"
                                FontSize="0.875rem"
                                Padding="0"
                                HBackG="transparent"
                                Width="24px"
                                Height="24px"
                            >
                                <Image src={threePoints} alt="add" Cursor="pointer" />
                            </ButtonGenerals>
                        </ContainerGrid>
                    ))
                )}
                {conditions.data.length > 8 && (
                    <ApplicationsPagination
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        quantityPerPage={CONDITION_PER_PAGE}
                        totalPages={totalPages}
                        totalItems={totalItems}
                    />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ConditonPreApprovalTable;
