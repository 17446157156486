import React, {useState} from 'react';
import {ContainerFlex, Text, Image, ContainerCellGrid} from '@components/Shopify/Ecommerce/styles';
import {
    AUCTION_TITLES,
    EditIntegratedPromotions,
    EditModalAuction,
    EditModalAuctionArticle,
    TreeForAuctionArticles,
    TreeForAuctionIncrease,
    TreeForIntegratedPromotions,
} from '@components/AuctionPass/GeneralScreen/constants';
import Article from '@/images/Article.svg';
import Edit from '@/images/edit.svg';
import Auction from '@/images/Frame.svg';
import Promotion from '@/images/cactus-color.svg';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {AuctionEditContainer} from '@/components/AuctionPass/GeneralScreen/AuctionEditContainer';
import {FooterType} from '@/components/AuctionPass/GeneralScreen/FootherModal';
import TooltipSpan from '@/components/AuctionPass/AuctionSettings/tooltip';
import {
    TOOLTIP_ARTICLE,
    TOOLTIP_AUCTION,
    TOOLTIP_PROMOTIONS,
} from '@/components/AuctionPass/AuctionSettings/Operational/constants';
type ModalProps = typeof EditModalAuction & {
    footerType?: FooterType;
};

export const CardForEdit = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentModalProps, setCurrentModalProps] = useState<ModalProps>({
        ...EditModalAuction,
        footerType: 'ArticleReview',
    });
    const [currentTree, setCurrentTree] = useState(TreeForIntegratedPromotions);

    const openArticleModal = () => {
        setCurrentModalProps({
            ...EditModalAuctionArticle,
            footerType: 'ArticleReview',
        });
        setCurrentTree(TreeForAuctionArticles);
        setIsModalOpen(true);
    };

    const openAuctionModal = () => {
        setCurrentModalProps({
            ...EditModalAuction,
            footerType: 'AuctionPass',
        });
        setCurrentTree(TreeForAuctionIncrease);
        setIsModalOpen(true);
    };

    const openPromotionModal = () => {
        setCurrentModalProps({
            ...EditIntegratedPromotions,
            footerType: 'IntegratedPromotions',
        });
        setCurrentTree(TreeForIntegratedPromotions);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ContainerFlex Justify="start" Align="start" ColumnGap="32px">
            <ContainerFlex
                Justify="start"
                Align="start"
                backG="white"
                Radius="10px"
                Border="1px solid #E8E9EA"
                Width="100%"
                FlexDir="column"
                Height="166px"
            >
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    ColumnGap="8px"
                    Radius="10px 10px 0 0"
                    backG="#D0F6FF"
                    Padding="8px"
                    Height="56px"
                >
                    <ContainerFlex Justify="space-between" Align="center">
                        <ContainerFlex Width="80%" Gap="8px">
                            <Image alt="card" src={Article} height="33px" Width="33px" />
                            <Text FontSize="1.25rem" FontWeight="700" wSpace="" LHeight="1">
                                {AUCTION_TITLES.CARD1.TITLE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end" Width="30%">
                            <TooltipSpan
                                title={TOOLTIP_ARTICLE.TITLE}
                                description={TOOLTIP_ARTICLE.DESCRIPTION}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Gap="8px"
                    Height="55px"
                    Padding="8px 16px"
                    Bb="1px solid #E8E9EA"
                >
                    <ContainerFlex FlexDir="column" Gap="8px" Align="start">
                        <Text Color="#414042">{AUCTION_TITLES.CARD1.CONFIGURE}</Text>
                        <Text FontSize="0.75rem">{AUCTION_TITLES.CARD1.DESCRIPTION}</Text>
                    </ContainerFlex>
                    <Image onClick={openArticleModal} Cursor="pointer" alt="card" src={Edit} />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Align="start"
                    Padding="8px 16px"
                    Height="56px"
                >
                    <ContainerCellGrid>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#414042">{AUCTION_TITLES.CARD2.APPLY}</Text>
                            <Text FontSize="0.75rem">{AUCTION_TITLES.CARD2.APPLY_CHANGES}</Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end">
                            <GreenSwitch />
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Justify="start"
                Align="start"
                backG="white"
                Radius="10px"
                Border="1px solid #E8E9EA"
                Width="100%"
                FlexDir="column"
                Height="166px"
            >
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    ColumnGap="8px"
                    Radius="10px 10px 0 0"
                    backG="#F9FFD6"
                    Padding="8px 16px"
                    Height="56px"
                >
                    <ContainerFlex Justify="space-between" Align="center">
                        <ContainerFlex Width="80%" Gap="8px">
                            <Image alt="card" src={Auction} height="33px" Width="33px" />
                            <Text FontSize="1.25rem" FontWeight="700" wSpace="" LHeight="1">
                                {AUCTION_TITLES.CARD2.TITLE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end" Width="30%">
                            <TooltipSpan
                                title={TOOLTIP_AUCTION.TITLE}
                                description={TOOLTIP_AUCTION.DESCRIPTION}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Height="55px"
                    Padding="8px 16px"
                    Bb="1px solid #E8E9EA"
                >
                    <ContainerFlex FlexDir="column" Gap="8px" Align="start">
                        <Text Color="#414042">{AUCTION_TITLES.CARD2.CONFIGURE}</Text>
                        <Text FontSize="0.75rem">{AUCTION_TITLES.CARD2.DESCRIPTION}</Text>
                    </ContainerFlex>
                    <Image onClick={openAuctionModal} Cursor="pointer" alt="card" src={Edit} />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Align="start"
                    Padding="8px 16px"
                    Height="56px"
                >
                    <ContainerCellGrid>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#414042">{AUCTION_TITLES.CARD2.APPLY}</Text>
                            <Text FontSize="0.75rem">{AUCTION_TITLES.CARD2.APPLY_CHANGES}</Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end">
                            <GreenSwitch />
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Justify="start"
                Align="start"
                backG="white"
                Radius="10px"
                Border="1px solid #E8E9EA"
                Width="100%"
                FlexDir="column"
                Height="166px"
            >
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    ColumnGap="8px"
                    Radius="10px 10px 0 0"
                    backG="#D1FFD1"
                    Padding="8px 16px"
                    Height="56px"
                >
                    <ContainerFlex Justify="space-between" Align="center">
                        <ContainerFlex Width="80%" Gap="8px">
                            <Image alt="card" src={Promotion} height="33px" Width="33px" />
                            <Text FontSize="1.25rem" FontWeight="700" wSpace="" LHeight="1">
                                {AUCTION_TITLES.CARD3.TITLE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end" Width="30%">
                            <TooltipSpan
                                title={TOOLTIP_PROMOTIONS.TITLE}
                                description={TOOLTIP_PROMOTIONS.DESCRIPTION}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Gap="8px"
                    Height="55px"
                    Padding="8px 16px"
                    Bb="1px solid #E8E9EA"
                >
                    <ContainerFlex FlexDir="column" Gap="8px" Align="start">
                        <Text Color="#414042">{AUCTION_TITLES.CARD3.CONFIGURE}</Text>
                        <Text FontSize="0.75rem">{AUCTION_TITLES.CARD3.DESCRIPTION}</Text>
                    </ContainerFlex>
                    <Image onClick={openPromotionModal} Cursor="pointer" alt="card" src={Edit} />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Align="start"
                    Padding="8px 16px"
                    Height="56px"
                >
                    <ContainerCellGrid>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#414042">{AUCTION_TITLES.CARD2.APPLY}</Text>
                            <Text FontSize="0.75rem">{AUCTION_TITLES.CARD2.APPLY_CHANGES}</Text>
                        </ContainerFlex>
                        <ContainerFlex Align="center" Justify="end">
                            <GreenSwitch />
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
            <AuctionEditContainer
                isOpen={isModalOpen}
                closeModal={closeModal}
                {...currentModalProps}
                treeData={currentTree}
            />
        </ContainerFlex>
    );
};
