import {
    ADD_CODE_MARKET_LOGIN_USER,
    ADD_COUNT_MARKET_LOGIN_USER,
    RESET_CODE_MARKET_LOGIN_USER,
} from '@components/MarketForgottenLogin/Redux/types';

export const addCodeLoginUser = (result) => {
    return {
        type: ADD_CODE_MARKET_LOGIN_USER,
        payload: result,
    };
};
export const addCountLoginUser = (result) => {
    return {
        type: ADD_COUNT_MARKET_LOGIN_USER,
        payload: result,
    };
};
export const resetCodeLoginUser = () => {
    return {
        type: RESET_CODE_MARKET_LOGIN_USER,
    };
};
