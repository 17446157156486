import React from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import Rating from '@images/rating.svg';

const ProductReviews: React.FC = () => {
    return (
        <ContainerFlex FlexDir="Column" Align="start" Justify="start">
            <ContainerFlex Align="start" Justify="start" Gap="1rem" PaddingT="0.125rem">
                <Text Color="#2A2C2F" FontWeight="700" FontSize="1rem">
                    {INVENTORY_PRODUCT_DETAIL.SOLD_BY}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Align="start"
                Justify="start"
                Radius="1rem"
                backG="#FAFAFA"
                Padding="0.25rem 0.5rem 0.25rem 0.5rem"
                Width="24.188rem"
            >
                <ContainerFlex Align="start" Justify="start" Gap="1rem" PaddingL="0.5rem">
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="1rem">
                        {INVENTORY_PRODUCT_DETAIL.SOLD_BY_NAME} - {INVENTORY_PRODUCT_DETAIL.STARS}
                        <Image src={Rating} alt="add" MarginLeft="0.25rem" />
                    </Text>
                    <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="1rem">
                        {INVENTORY_PRODUCT_DETAIL.SHOW_COMMENTS}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ProductReviews;
