import {Controller, useFormContext} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {DOCUMENTCLIENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import CreditScore from '@components/PersonalLoans/NewLoanProduct/CreditScore';
import {TypeDocuments} from '@components/PersonalLoans/NewLoanProduct/TypeDocuments';
import {IDocumentsClientsProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {CheckboxGender} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/Fields/CheckboxGender';
import {InputAge} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/Fields/InputAge';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IGenderTerms} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';

export const DocumentsClients: React.FC<IDocumentsClientsProps> = ({
    clientAndDocumentsRef,
    isSubmitted,
}) => {
    const {
        resetField,
        control,
        register,
        formState: {errors},
        trigger,
        watch,
    } = useFormContext();
    const errorMessage = errors.ageMax?.message?.toString() || '';
    const formState = watch();
    useEffect(() => {
        if (isSubmitted) {
            trigger('ageMax');
        }
    }, [formState.ageMin, formState.ageMax]);
    const [genderData, setGenderData] = useState<IGenderTerms[]>([]);
    const [hasRunOnce, setHasRunOnce] = useState<boolean>(false);
    const dataGender = useSelector((state: RootState) => {
        return state.GetAllGenderPersonalLoan?.dataGender?.data;
    });
    const handleGenderCheckBox = (id: number) => {
        const updatedArray = genderData.map((item) => {
            if (item.genderId === id) {
                return {...item, isChecked: !item.isChecked};
            }
            return item;
        });
        setGenderData(updatedArray);
    };
    useEffect(() => {
        if (!dataGender) return;
        const sortedDataArray = dataGender.sort((a, b) => (a.id === 2 ? -1 : b.id === 2 ? 1 : 0));
        const newArray = sortedDataArray.map((item) => {
            return {...item, genderId: item.id, isChecked: false};
        });
        setGenderData(newArray);
    }, [dataGender]);
    useEffect(() => {
        if (!hasRunOnce && genderData.length > 0) {
            resetField('genderTerms', {defaultValue: genderData});
            setHasRunOnce(true);
        }
    }, [genderData, hasRunOnce]);
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1.5rem"
            Radius="1.5rem"
            Padding="1.25rem"
            Border="1px solid #E8E9EA"
            Height="max-content"
            ref={clientAndDocumentsRef}
        >
            <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                {DOCUMENTCLIENTS.TITLE}
            </Text>
            <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                {DOCUMENTCLIENTS.SELECTTYPE}
            </Text>
            <ContainerFlex Justify="start" Gap="1rem" Height="6.25rem">
                <ContainerCellGrid FlexDir="column" Align="start" Width="max-content" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.GENDER}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="16px" Height="40px">
                        {genderData.map((item, index) => (
                            <CheckboxGender
                                key={index}
                                control={control}
                                name={`genderTerms[${index}].isChecked`}
                                label={item.genderName}
                                isChecked={item.isChecked}
                                handleChange={() => handleGenderCheckBox(item.genderId)}
                            />
                        ))}
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="27.25rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.AGE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="0.5rem" Height="2.5rem">
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.BETWEEN}
                        </Text>
                        <InputAge register={register} name="ageMin" />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.AND}
                        </Text>
                        <InputAge register={register} name="ageMax" />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.YEARS}
                        </Text>
                    </ContainerFlex>
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="22.688rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.SCORE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <CreditScore
                        control={control}
                        resetField={resetField}
                        initialSelectedRows={[]}
                    />
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        <Controller
                            name="reviewCreditVoul"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Checkbox {...field} onChange={onChange} />
                            )}
                        />
                        <Text Color="#2A2C2F">{DOCUMENTCLIENTS.CHECKCREDITS}</Text>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <TypeDocuments control={control} resetField={resetField} register={register} />
        </ContainerFlex>
    );
};
