import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ProductEdit from '@components/InventoryManagement/ProductEdit';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import ProductPriceModal from '@components/InventoryManagement/ProductPriceModal';

const ProductPrice: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ContainerFlex FlexDir="Column">
                <ContainerFlex Align="start" Justify="start" Gap="1rem" PaddingT="0.125rem">
                    <Text
                        Color="#54575C"
                        FontWeight="400"
                        FontSize="1rem"
                        TextDecoration="line-through"
                    >
                        {INVENTORY_PRODUCT_DETAIL.WAS_PRICE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start">
                    <Text Color="#1D1E20" FontWeight="700" FontSize="1.75rem">
                        {INVENTORY_PRODUCT_DETAIL.NOW_PRICE}
                    </Text>
                    <ProductEdit
                        onClickEdit={() => {
                            setShowModal(true);
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ProductPriceModal showModal={showModal} setShowModal={setShowModal} />
        </>
    );
};
export default ProductPrice;
