import React, {useEffect, useRef, useState, useCallback} from 'react';
import {Image} from '@Shopify/Ecommerce/styles';
import {IActionMenu} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ClickAwayListener} from '@mui/material';
import MoreMenuIcon from '@images/moreMenu.svg';
import {MenuWrapper} from '@components/ActionMenu/styles';

export const ActionMenuOption = ({menuOptions, isOpen, onOptionClick}: IActionMenu) => {
    const [menuPosition, setMenuPosition] = useState<{top: string; left: string}>({
        top: '0px',
        left: '0px',
    });
    const [menuReadyToShow, setMenuReadyToShow] = useState(false);
    const iconRef = useRef<HTMLImageElement | null>(null);

    const updateMenuPosition = useCallback(() => {
        if (iconRef.current) {
            const rect = iconRef.current.getBoundingClientRect();
            setMenuPosition({
                top: `${rect.top - 10}px`,
                left: `${rect.left + 10}px`,
            });
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            setMenuReadyToShow(false);
            updateMenuPosition();
            setMenuReadyToShow(true);
        } else {
            setMenuReadyToShow(false);
        }
    }, [isOpen, updateMenuPosition]);

    useEffect(() => {
        const handleScroll = () => {
            if (isOpen) {
                setMenuReadyToShow(false);
                onOptionClick();
            }
        };

        if (isOpen) {
            document.addEventListener('scroll', handleScroll, true);
        }

        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };
    }, [isOpen, onOptionClick]);

    return (
        <ContainerFlex Position="relative" Align="start" Height="2rem" Width="1.25rem">
            <Image
                ref={iconRef}
                src={MoreMenuIcon}
                Self="center"
                alt="menu-icon"
                Width="0.25rem"
                Height="1rem"
                Cursor="pointer"
                onClick={() => {
                    if (!isOpen) {
                        updateMenuPosition();
                    }
                    onOptionClick();
                }}
            />
            {isOpen && menuReadyToShow && (
                <ClickAwayListener
                    onClickAway={() => {
                        onOptionClick();
                    }}
                >
                    <MenuWrapper Top={menuPosition.top} Left={menuPosition.left}>
                        {menuOptions.map((option, index) => (
                            <ContainerFlex
                                Align="center"
                                Gap="0.5rem"
                                Height="2.5rem"
                                Justify="start"
                                Cursor="pointer"
                                HBackground="#f0f0ff"
                                HBorderLeft="2px solid #5a5aff"
                                Padding="0.5rem"
                                key={index}
                                onClick={() => {
                                    option.action();
                                    onOptionClick();
                                }}
                            >
                                {option.icon && (
                                    <Image
                                        src={option.icon}
                                        alt="menu-icon"
                                        Width="1.125rem"
                                        Height="1.125rem"
                                        Margin="0 0 0.313rem"
                                    />
                                )}
                                <Text
                                    Color={option.textColor}
                                    Cursor="pointer"
                                    FontSize="1rem"
                                    FontWeight="400"
                                >
                                    {option.name}
                                </Text>
                            </ContainerFlex>
                        ))}
                    </MenuWrapper>
                </ClickAwayListener>
            )}
        </ContainerFlex>
    );
};
