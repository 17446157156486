import React, {useState, useEffect} from 'react';
import {StyledTreeNode} from '@/components/General/CheckboxTree/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {TreeProps} from '@General/CheckboxTree/interface';

import {CheckTree} from '@/components/OrganizationTreeView/styles';
export const TreeBranch = ({
    checked,
    branchesId,
    levelsId,
    setChecked,
    setBranch,
    setLevel,
    setBranchData,
    isEdit,
    isUnselected,
}: TreeProps) => {
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceName as string
    );
    const [expanded, setExpanded] = useState<string[]>([
        String(TreeComponent.data[0].idLevel + companyName),
    ]);
    interface Node {
        idLevel: number;
        idBranch: number;
        levelName: string;
        levelDescription: string;
        searchType: number;
        status: boolean;
        step: number;
        idFather: number;
        check: boolean;
        branchDescription?: string;
        branches?: Branch[];
        nextLevel: Node[];
    }

    interface Branch {
        idLevel: number;
        idBranch: number;
        status: boolean;
        branchName: string;
        branchDescription: string;
        searchType: number;
        check: boolean;
    }
    interface SaveBranch {
        idBranch: number;
        branchName: string;
    }
    interface TreeNode {
        value: string;
        label: string;
        children?: TreeNode[];
        levelDescription: string;
        searchType: number;
        status: boolean;
        step: number;
        idFather: number;
        check: boolean;
        branchDescription?: string;
        branches?: [];
    }
    interface TreeRenderNode {
        value: string;
        label: string;
        children?: TreeNode[];
        levelDescription: string;
        searchType: number;
        status: boolean;
        step: number;
        idFather: number;
        check: boolean;
        branchDescription?: string;
        branches?: [];
    }
    const [tree, setTree] = useState<TreeRenderNode[]>([]);
    function extractLevelIdsFromAllId(
        allId: string[],
        treeData: TreeNode[]
    ): {branchesId: number[]; levelsId: number[]; branchesData: SaveBranch[]} {
        const branchesIdSet: Set<number> = new Set();
        const levelsIdSet: Set<number> = new Set();
        const branchesData: SaveBranch[] = [];

        function traverse(node: TreeNode, isRoot: boolean = false) {
            if (!isRoot && allId.includes(node.value.toString())) {
                if (node.branchDescription) {
                    branchesIdSet.add(Number(node.value));
                    branchesData.push({
                        idBranch: Number(node.value),
                        branchName: node.branchDescription,
                    });
                } else {
                    levelsIdSet.add(Number(node.value.replaceAll(companyName, '')));
                }
            }

            if (node.children) {
                for (const child of node.children) {
                    traverse(child);
                }
            }
        }

        for (const node of treeData) {
            traverse(node, Number(node.value) === 1);
        }

        const branchesId = Array.from(branchesIdSet);
        const levelsId = Array.from(levelsIdSet);

        return {branchesId, levelsId, branchesData};
    }
    function transformBranchesToChildren(branches: Branch[]): TreeNode[] {
        return branches.reduce<TreeNode[]>((acc, branch) => {
            if (branch.idBranch && branch.branchName) {
                acc.push({
                    value: String(branch.idBranch),
                    label: branch.branchName,
                    levelDescription: branch.branchDescription,
                    branchDescription: branch.branchDescription,
                    searchType: branch.searchType,
                    status: branch.status,
                    step: 0,
                    idFather: 0,
                    check: branch.check,
                });
            }
            return acc;
        }, []);
    }

    function transformToTreeData(data: Node[], idOffset: number = 0): TreeNode[] {
        return data.map((item) => {
            const {idLevel, levelName, branches, nextLevel} = item;
            const treeNode: TreeNode = {
                value: String(Number(idLevel) + idOffset + companyName),
                label: levelName,
                levelDescription: item.levelDescription,
                searchType: item.searchType,
                status: item.status,
                step: item.step,
                idFather: item.idFather,
                check: item.check,
            };

            if (branches && branches.length > 0) {
                treeNode.children = transformBranchesToChildren(branches);
            }

            if (nextLevel && nextLevel.length > 0) {
                treeNode.children = (treeNode.children || []).concat(
                    transformToTreeData(nextLevel)
                );
            }
            return treeNode;
        });
    }

    const getAllValues = (treeData: TreeNode[]) => {
        let values: string[] = [];
        treeData.forEach((node) => {
            if (node.value) {
                values.push(String(node.value));
            }
            if (node.children) {
                values = values.concat(getAllValues(node.children));
            }
        });
        return values;
    };

    useEffect(() => {
        if (
            isEdit === false &&
            branchesId.length === 0 &&
            levelsId.length === 0 &&
            checked.length === 0
        ) {
            const Check = getAllValues(tree);
            setChecked(Check);
        }
    }, [tree, checked, TreeComponent.data]);
    useEffect(() => {
        if (TreeComponent.data) {
            const {branchesId, levelsId, branchesData} = extractLevelIdsFromAllId(checked, tree);
            const uniqueArray = Array.from(new Set(levelsId));

            setBranch(branchesId);
            setLevel(uniqueArray);
            setBranchData(branchesData);
        }
    }, [checked]);

    useEffect(() => {
        if (
            tree.length > 0 &&
            isEdit === false &&
            checked.length === 0 &&
            levelsId.length === 0 &&
            branchesId.length === 0
        ) {
            const {branchesId, levelsId, branchesData} = extractLevelIdsFromAllId(checked, tree);
            setBranch(branchesId);
            setLevel(levelsId);
            setBranchData(branchesData);
        }
    }, [checked, tree]);
    useEffect(() => {
        if (TreeComponent.data) {
            const treeData: TreeRenderNode[] = transformToTreeData(TreeComponent.data);
            setTree(treeData);
        }
    }, [TreeComponent.data]);
    return (
        <StyledTreeNode>
            {tree.length > 0 ? (
                <CheckTree
                    nodes={tree}
                    checked={checked}
                    expanded={expanded}
                    onCheck={async (check: string[]) => {
                        setChecked(check);
                    }}
                    disabled={isUnselected}
                    onExpand={(expand: string[]) => setExpanded(expand)}
                    checkModel="all"
                    icons={{
                        check: <i className="fa fa-check-square" aria-hidden="true" />,
                        expandClose: <i className="fa fa-caret-right" aria-hidden="true" />,
                        expandOpen: <i className="fa fa-caret-down" aria-hidden="true" />,
                    }}
                />
            ) : (
                <></>
            )}
        </StyledTreeNode>
    );
};
