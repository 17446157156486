import * as yup from 'yup';
import {QuitNumberFormat} from '@/hooks/currentFormatUtils';

import {
    ERROR_PRODUCT_CONDITION,
    ERROR_PRODUCT_PRICE,
} from '@components/InventoryManagement/constants';

export const productConditionSchema = yup.object().shape({
    condition: yup.string().required(ERROR_PRODUCT_CONDITION.REQUIRED),
});

export const productPriceSchema = yup.object().shape({
    price: yup
        .string()
        .required(ERROR_PRODUCT_PRICE.REQUIRED)
        .test(
            'min-value',
            ERROR_PRODUCT_PRICE.MIN_VALUE.MESSAGE,
            (value) =>
                QuitNumberFormat(value) === undefined ||
                QuitNumberFormat(value) >= ERROR_PRODUCT_PRICE.MIN_VALUE.VALUE
        )
        .test(
            'max-value',
            ERROR_PRODUCT_PRICE.MAX_VALUE.MESSAGE,
            (value) =>
                QuitNumberFormat(value) === undefined ||
                QuitNumberFormat(value) <= ERROR_PRODUCT_PRICE.MAX_VALUE.VALUE
        ),
});

export const productCharacteristic = yup.object().shape({
    characteristic: yup.string().required(ERROR_PRODUCT_CONDITION.REQUIRED),
});
