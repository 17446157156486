import {GET_BRANCH_STORAGE_BYCOMPANY} from '@components/InventoryManagement/Redux/types';
import {
    LocationStoragesState,
    LocationStoragesAction,
} from '@components/InventoryManagement/Redux/interfaces';

const initialState: LocationStoragesState = {
    locations: [],
    loading: false,
    error: false,
};

const GetBranchStorageByCompanyId = (
    state = initialState,
    action: LocationStoragesAction
): LocationStoragesState => {
    switch (action.type) {
        case GET_BRANCH_STORAGE_BYCOMPANY.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_BRANCH_STORAGE_BYCOMPANY.SUCCESS:
            return {
                ...state,
                locations: action.payload,
                loading: false,
                error: false,
            };
        case GET_BRANCH_STORAGE_BYCOMPANY.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default GetBranchStorageByCompanyId;
