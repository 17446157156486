import {useNavigate} from 'react-router-dom';
import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import personalSalesIcon from '@components/PublicGlobalQuoter/icons/personalSalesIcon.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {PERSONAL_SALES_BANNER} from '@components/PublicGlobalQuoter/constants';
import {DivisorLine} from '@components/MyAccount/styles';

export const PersonalSalesBanner = () => {
    const navigate = useNavigate();
    return (
        <>
            <ContainerFlex
                Radius="1rem"
                Border="1px solid #E8E9EA"
                backG={`#F7FDFF url(${personalSalesIcon}) no-repeat bottom left`}
                Padding="1rem 1.5rem"
                OverFlow="hidden"
                Justify="space-between"
            >
                <ContainerFlex
                    Padding="0px 20px 0px 120px"
                    Gap="0.25rem"
                    FlexDir="column"
                    Align="start"
                >
                    <Text Color="#1D1E20" FontSize="1.125rem" FontWeight="700">
                        {PERSONAL_SALES_BANNER.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{PERSONAL_SALES_BANNER.DESCRIPTION}</Text>
                </ContainerFlex>
                <ContainerFlex MaxW="215px">
                    <ButtonGenerals
                        BackGC="#F0F0FF"
                        FontSize="1rem"
                        FontWeight="500"
                        Color="#fff"
                        Border="none"
                        HBackG="none"
                        Width="100%"
                        Cursor="pointer"
                        onClick={() => navigate('/Login/MarketUser')}
                    >
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            FontFamily="Nunito"
                            LetterSpacing="0.3px"
                            Cursor="pointer"
                        >
                            {PERSONAL_SALES_BANNER.BTN_LOGIN}
                        </Text>
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
            <DivisorLine width="100%" height="1px" />
        </>
    );
};
