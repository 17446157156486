import React, {useEffect} from 'react';

import {ContainerFlex} from '@EcommerceShopify/styles';
import DynamicViewSwitcher from '@General/Atoms/ListViewSwitch/DynamicListViewSwitch';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    changeCommercialInventoryPage,
    requestInventoryItems,
} from '@components/InventoryManagement/Redux/Actions/getCommercialInventory';
import CommercialProductCard from '@components/InventoryManagement/CommercialProductCard';
import Pagination from '@General/Atoms/ListPagination/Pagination';
import CommercialInventoryList from '@components/InventoryManagement/CommercialInventoryList';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {GetCommercialInventoryBody} from '@components/InventoryManagement/interfaces';
import CommercialInventoryFilters from '@/components/InventoryManagement/CommercialInventoryFilters';
import CommercialInventoryFilterButtons from '@/components/InventoryManagement/CommercialInventoryFilterButtons';

const Commercial = () => {
    const dispatch = useDispatch();

    const userData = useSelector((state: RootState) => state.getUsersValidation.userData);
    const inventory = useSelector((state: RootState) => state.getCommercialInventory);
    const filters = useSelector((state: RootState) => state.commercialInventoryFilters);

    useEffect(() => {
        if (userData && userData.enterpriceDetails) {
            let bodyRequest: GetCommercialInventoryBody = {
                pageStart: inventory.pageNumber,
                pageSize: inventory.pageSize,
                companyId: userData.enterpriceDetails[0].enterpriceId,
            };
            if (filters.inventoryFilter.length > 0) {
                const inventoryFilterObj = Object.fromEntries(
                    filters.inventoryFilter.map((filter) => [filter.label, filter.value])
                );
                bodyRequest = {
                    ...bodyRequest,
                    filterInventory: {...inventoryFilterObj},
                };
            }
            if (!(filters.filterByPrice.minPrice === 0 && filters.filterByPrice.maxPrice === 0)) {
                bodyRequest = {
                    ...bodyRequest,
                    filterByPrice: filters.filterByPrice,
                };
            }
            dispatch(requestInventoryItems(userData.token, bodyRequest));
        }
    }, [inventory.pageNumber, filters]);

    const renderTableView = () =>
        inventory.loading ? <Loading width="7.5rem" padding="2.5rem" /> : <CommercialProductCard />;

    const renderListView = () =>
        inventory.loading ? (
            <Loading width="7.5rem" padding="2.5rem" />
        ) : (
            <CommercialInventoryList />
        );

    const handlePageChange = (currentPage: number) => {
        dispatch(changeCommercialInventoryPage(currentPage));
    };

    return (
        <ContainerFlex FlexDir="column" Justify="start" Gap="1rem">
            <ContainerFlex
                Align="flex-start"
                FlexDir="column"
                Padding="1.5rem"
                Justify="start"
                Gap="1rem"
                Border="1px solid #E8E9EA"
                backG="#FFF"
                Radius="1.5rem"
                Height="13rem"
            >
                <CommercialInventoryFilterButtons activeFilters={filters.inventoryFilter} />
                <CommercialInventoryFilters />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="flex-start" Gap="1rem">
                <DynamicViewSwitcher
                    data={inventory.data}
                    loading={false}
                    renderTableView={renderTableView}
                    renderListView={renderListView}
                />
                <Pagination
                    onPageChange={handlePageChange}
                    pageNumber={inventory.pageNumber}
                    pageSize={inventory.pageSize}
                    totalPages={inventory.totalPages}
                    totalItems={inventory.totalItems}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Commercial;
