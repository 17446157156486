import * as yup from 'yup';
import {PRICE_ERRORS} from '@components/InventoryManagement/constants';

export const schemaCustomPrice = yup
    .object()
    .shape({
        customFrom: yup
            .number()
            .typeError(PRICE_ERRORS.FROM_NUMBER)
            .nullable()
            .min(1, PRICE_ERRORS.MIN_VALUE)
            .max(1000000, PRICE_ERRORS.MAX_VALUE)
            .test('valid-range-from', PRICE_ERRORS.INVALID_RANGE, function (value) {
                const customTo = this.parent.customTo;
                if (value && customTo) {
                    return value < customTo;
                }
                return true;
            }),
        customTo: yup
            .number()
            .typeError(PRICE_ERRORS.TO_NUMBER)
            .nullable()
            .min(1, PRICE_ERRORS.MIN_VALUE)
            .max(1000000, PRICE_ERRORS.MAX_VALUE)
            .test('valid-range-to', PRICE_ERRORS.INVALID_RANGE, function (value) {
                const customFrom = this.parent.customFrom;
                if (value && customFrom) {
                    return value > customFrom;
                }
                return true;
            }),
    })
    .test('at-least-one', PRICE_ERRORS.REQUIRED, function (values) {
        const {customFrom, customTo} = values || {};
        if (!customFrom && !customTo) {
            return this.createError({
                path: 'customFrom',
                message: PRICE_ERRORS.REQUIRED,
            });
        }
        return true;
    });
