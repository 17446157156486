import React, {useState, useEffect, useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, AppDispatch} from '@config/store';
import {
    addCodeMarketLogin,
    addCountMarketLogin,
    resetCodeMarketLogin,
} from '@components/MarketLogin/redux/actions/codevalideAction';
import {PostCode, ResetVerifyCode} from '@components/MarketLogin/redux/actions/codeVarifyAction';
import {PostNewCode} from '@components/MarketLogin/redux/actions/newValidationCodeAction';
import {schemaValide} from '@/components/MarketLogin/ValidationYup';
import {EMAIL_VERIFICATION} from '@components/MarketLogin/constants';
import {Input, ButtonImg, ImageLogo} from '@/components/MarketLogin/Styles';
import {ImageLogo as LoginImageLogo} from '@LoginClient/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import fox from '@images/FoxMail.svg';
import buttonReset from '@images/resetValidationButton.svg';
import {TextModule} from '@components/MarketLogin/SubModules/ModuleText';
import {getUserData} from '@Login/Redux/Actions/ChangeScreen';
import {ResetUserDataMarket} from '@components/Login/MarketPlaceLogin/Redux/Actions/ValidateEmailForMarketPlace';

export const CodeVerification = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.creaateUserData.token);
    const count = useSelector((state: RootState) => state.codeValideReducer.count);
    const code = useSelector((state: RootState) => state.codeValideReducer.code);
    const clientId = useSelector((state: RootState) => state.creaateUserData);
    const dataUser = useSelector((state: RootState) => state.creaateUserData?.userData);
    const message = useSelector((state: RootState) => state.verifyCode.error);

    const {
        control,
        handleSubmit,
        formState: {errors},
        setError,
    } = useForm({
        resolver: yupResolver(schemaValide),
        defaultValues: {inputs: code},
    });

    const [timer, setTimer] = useState(() => (count ? parseInt(count, 10) : 30));
    const [showReset, setShowReset] = useState(false);
    const countdownRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timer > 0) {
            countdownRef.current = setInterval(() => {
                setTimer((prevTimer) => {
                    const newTime = prevTimer - 1;
                    dispatch(addCountMarketLogin(newTime.toString()));
                    return newTime;
                });
            }, 1000);
        } else {
            setShowReset(true);
        }
        return () => {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        };
    }, [timer, dispatch]);

    const onSubmit = async (data: {inputs: string}) => {
        const codeString = code.join('');
        const json = {
            clientUserId: clientId.userData,
            code: codeString,
        };
        await dispatch(PostCode(token, json, navigate));
    };

    useEffect(() => {
        if (message) {
            setError('inputs', {
                message: message,
            });
        }
    }, [message]);

    const handleReset = () => {
        setTimer(30);
        setShowReset(false);
        dispatch(resetCodeMarketLogin());
        dispatch(PostNewCode(token, dataUser));
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            const codeload = [...code];
            codeload[index] = value;
            dispatch(addCodeMarketLogin(codeload));
            const nextInput = document.getElementById(`input-${index + 1}`);
            if (value !== '' && nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[\d]/.test(e.key)) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        dispatch(
            getUserData({
                firstName: '',
                paternalSurname: '',
                maternalSurname: '',
                phoneNumber: '',
                email: '',
                password: '',
                repeatPassword: '',
            })
        );
        dispatch(ResetUserDataMarket());
    }, []);
    const renderInput = (index: number) => (
        <Controller
            key={index}
            name={`inputs.${index}`}
            control={control}
            render={({field}) => (
                <Input
                    id={`input-${index}`}
                    type="text"
                    maxLength={1}
                    hasError={!!errors.inputs}
                    {...field}
                    autoComplete="off"
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                        field.onChange(e);
                        handleChange(e, index);
                    }}
                />
            )}
        />
    );

    return (
        <ContainerFlex
            FlexDir="column"
            Gap="24px"
            Position="relative"
            Radius="10px"
            Width="auto"
            Height="521px"
            backG="#fff"
            Border="1px solid #5A5AFF"
            Padding="32px"
        >
            <ButtonImg>
                <LoginImageLogo
                    onClick={() => {
                        navigate('/Login/MarketClient');
                        dispatch(ResetVerifyCode());
                    }}
                    Width="100%"
                    Height="100%"
                    src={buttonReset}
                    Cursor="pointer"
                />
            </ButtonImg>
            <ImageLogo Width="30%" Height="30%" src={fox} />
            <TextModule />
            <ContainerForm
                Gap="24px"
                Height="auto"
                FlexDir="column"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ContainerFlex FlexDir="column" Height="auto" Gap="8px">
                    <ContainerFlex ColumnGap="16px">
                        {Array.from({length: 4}, (_, i) => renderInput(i))}
                    </ContainerFlex>
                    <ContainerFlex>
                        {errors.inputs && typeof errors.inputs.message === 'string' && (
                            <Text FontFamily="Nunito" Color="#FF6357" FontSize="0.75rem">
                                {errors.inputs.message}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex>
                    <ButtonGeneral
                        FontSize="1rem"
                        padding="8px 16px"
                        height="40px"
                        width="100%"
                        text="Verificar"
                    />
                </ContainerFlex>
            </ContainerForm>
            <ContainerFlex>
                {timer > 0 && (
                    <Text FontFamily="Nunito" FontSize="16px" Color="#2A2C2F" FontWeight="600">
                        {EMAIL_VERIFICATION.TIMER_TEXT} {timer}
                        {EMAIL_VERIFICATION.SECONDS}
                    </Text>
                )}
                {showReset && (
                    <Text
                        Cursor="pointer"
                        FontSize="16px"
                        Color="#5A5AFF"
                        FontWeight="600"
                        onClick={handleReset}
                    >
                        {EMAIL_VERIFICATION.REQUEST_NEW_CODE}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
