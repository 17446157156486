import React, {useEffect, useRef, useState} from 'react';
import {Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    ButtonContent,
    DropdownButton,
    DropdownContainer,
    DropdownItem,
    DropdownList,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/StyleDropList';
import {DropdownProps} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';
import ArrowIcon from '@/images/arrowDonw.svg';
import ArrowTop from '@/images/arrowTop.svg';

const Dropdown: React.FC<DropdownProps> = ({items, selectedItem, onSelect}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleToggle = () => setIsOpen((prev) => !prev);
    const handleSelect = (item: string) => {
        onSelect(item);
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const displayText = () => {
        if (!selectedItem) return '';
        const selectedItemObj = items.find((item) => item.name === selectedItem);
        if (!selectedItemObj) return selectedItem;

        const totalItems = items.length;
        if (totalItems > 1) {
            return `${selectedItemObj.name}, ${totalItems}+`;
        }
        return selectedItemObj.name;
    };

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownButton onClick={handleToggle}>
                <ButtonContent>
                    <Text FontSize="1rem">{displayText()}</Text>
                    <Image
                        width="24px"
                        height="24px"
                        src={isOpen ? ArrowTop : ArrowIcon}
                        alt={isOpen ? 'close-dropdown' : 'open-dropdown'}
                    />
                </ButtonContent>
            </DropdownButton>
            {isOpen && (
                <DropdownList>
                    {items.map((item) => (
                        <DropdownItem key={item.id} onClick={() => handleSelect(item.name)}>
                            <Text FontSize="1rem">{item.name}</Text>
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </DropdownContainer>
    );
};

export default Dropdown;
