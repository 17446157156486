import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerGrid, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';
import {IDocument} from '@components/CreditQuoter/interfaces';

const DetailsQuoterDocuments = () => {
    const QuoterCompaniesLoans = useSelector((state: RootState) => {
        return state.GetQuoterCompaniesLoansAditionalInf?.dataQuoter;
    });
    return (
        <ContainerFlex
            FlexDir="column"
            Gap="24px"
            Padding="24px"
            Radius="16px"
            Border="1px solid #E8E9EA"
            backG="#fff"
        >
            <ContainerFlex
                Justify="start"
                Align="start"
                Bb="1px solid #E8E9EA"
                Padding="0px 0px 24px 0px"
            >
                <Text FontWeight="500" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.25rem">
                    {CREDIT_QUOTER.DOCUMENTS_DETAILS.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerGrid GridColumns="repeat(4,1fr)" Justify="center">
                {QuoterCompaniesLoans?.documents?.map((item: IDocument, index: number) => (
                    <ContainerFlex Gap="16px" key={index}>
                        <ContainerFlex Justify="start" Align="start" Width="24px">
                            <Image
                                Width="24px"
                                Height="24px"
                                alt="id-card-icon"
                                src={CREDIT_QUOTER.DOCUMENTS_DETAILS.ID_ICON}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="8px">
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {item.nameDocument}
                            </Text>
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {item.documentDetail}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerGrid>
        </ContainerFlex>
    );
};

export default DetailsQuoterDocuments;
