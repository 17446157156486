import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_FREQUENCY_OPTIONS_ERROR,
    GET_FREQUENCY_OPTIONS_SUCCESS,
    GET_FREQUENCY_OPTIONS_START,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {IFrequencyItem, IFrequencyProps} from '@/components/GlobalQuoter/interface';
import {WEEKLY_ID} from '@components/GlobalQuoter/TempConstants';

const initialState: IFrequencyProps = {
    frequencyOptions: [],
    isLoading: false,
    error: false,
};

const globalQuoterFrequencyReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case GET_FREQUENCY_OPTIONS_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_FREQUENCY_OPTIONS_SUCCESS: {
            const weeklyFrequency = action.payload.data.data.filter(
                (item: IFrequencyItem) => item.frequencyId === WEEKLY_ID
            );
            const restFrequencies = action.payload.data.data.filter(
                (item: IFrequencyItem) => item.frequencyId !== WEEKLY_ID
            );
            const options = [...weeklyFrequency, ...restFrequencies];
            return {
                ...state,
                frequencyOptions: options.map((item: IFrequencyItem) => ({
                    value: item.frequencyId,
                    label: item.frequencyName,
                })),
                isLoading: false,
            };
        }
        case GET_FREQUENCY_OPTIONS_ERROR:
            return {
                ...state,
                frequencyOptions: [],
                isLoading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default globalQuoterFrequencyReducer;
