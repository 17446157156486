import React from 'react';

import {useDispatch} from 'react-redux';
import {
    setEditWarehouse,
    updateWarehouses,
} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {StorageData} from '@components/LocationsBranch/StorageData';
import {ActionMenu} from '@components/LocationsBranch/Menu';
import {SearchInput} from '@Customer/Styles';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {
    COPY_NAME,
    STORAGE,
    FROM_SECTION,
    TOOLTIP_STORAGE,
    STORAGE_NAME_INPUT,
} from '@components/LocationsBranch/constants';
import Tooltip from '@mui/material/Tooltip';
import {
    ILevel,
    IRow,
    IStorageFloor,
    IStorageLevelListProps,
} from '@components/LocationsBranch/interfaces';
import HelpIcon from '@images/help.svg';
import ClearIcon from '@images/createCancel.svg';
import AddIconBlue from '@images/addIcon.svg';
import {getNextRowId} from '@/components/LocationsBranch/utils/GetNextId';

export const StorageLevelList = ({
    warehouse,
    storageFloor,
    floorIndex,
    selectedStorageType,
    setSelectedStorage,
    onAddNewLevel,
    onAddNewRow,
    onClickNewRow,
    setEditId,
    onDuplicateLevel,
    onDeleteLevel,
    onEditLevel,
    setRowLevelInput,
    formProps,
}: IStorageLevelListProps) => {
    const {register, watch, setValue, errors} = formProps;
    const name = watch(STORAGE_NAME_INPUT);

    const dispatch = useDispatch();
    const renderEmptyLevels = (count: number) =>
        Array.from({length: count}).map((_, i) => (
            <ContainerFlex
                key={i}
                Justify="start"
                Align="start"
                backG="#F0F0FF"
                Radius="1rem"
                FlexWrap="wrap"
                Height="14rem"
            />
        ));

    const getEmptyLevelsCount = () => {
        if (!storageFloor?.levels || storageFloor.levels.length === 0) {
            return 3;
        }
        const remainder = storageFloor.levels.length % 3;
        return remainder !== 0 ? 3 - remainder : 0;
    };

    const handleReset = () => {
        setValue(STORAGE_NAME_INPUT, '');
    };

    const updateLevelRows = (selecetedlevel: ILevel, updatedRows: IRow[]) => {
        const foundLevel =
            storageFloor &&
            storageFloor.levels &&
            storageFloor.levels.find((level) => level.id === selecetedlevel.id);
        const newLevel = foundLevel && {...foundLevel, rows: [...updatedRows]};
        const newFloors: IStorageFloor[] = selectedStorageType
            ? selectedStorageType.storageFloor.map((floor) => {
                  if (newLevel && floor.id === newLevel?.parentLevel) {
                      return {
                          ...floor,
                          levels:
                              floor &&
                              floor.levels &&
                              floor.levels.map((level) =>
                                  level.id === newLevel.id ? newLevel : level
                              ),
                      };
                  }
                  return floor;
              })
            : [];
        const newStorageType = {
            ...selectedStorageType,
            storageFloor: newFloors,
        };
        const newWarehouse = {
            ...warehouse,
            storageTypes: warehouse.storageTypes?.map((storage) =>
                storage.id === selectedStorageType?.id ? newStorageType : storage
            ),
        };
        dispatch(updateWarehouses(newWarehouse));
        setSelectedStorage(newStorageType);
    };

    const onDuplicateRow = (selecetedlevel: ILevel, row: IRow) => {
        const newId = getNextRowId(selecetedlevel.rows);
        const updatedRows = [
            ...(selecetedlevel.rows || []),
            {...row, id: newId, name: `${row.name} ${COPY_NAME}`},
        ];
        updateLevelRows(selecetedlevel, updatedRows);
    };

    const onDeleteRow = (selecetedlevel: ILevel, selectedRow: IRow) => {
        const updatedRows = (selecetedlevel.rows || []).filter((row) => row.id !== selectedRow.id);
        updateLevelRows(selecetedlevel, updatedRows);
    };

    const onEditRow = (selecetedlevel: ILevel, selectedRow: IRow) => {
        dispatch(
            setEditWarehouse({
                warehouse,
                fromSection: FROM_SECTION.ROW_NAME,
                showEditModal: true,
                storageType: selectedStorageType,
                storageFloor,
                levelId: selecetedlevel.id,
                selectedRow,
            })
        );
    };

    return (
        <ContainerFlex
            Display="grid"
            GridColumns="1fr 1fr 1fr"
            Justify="start"
            Align="flex-start"
            Gap="1rem"
            MarginTop="1rem"
            FlexWrap="wrap"
        >
            {storageFloor &&
                storageFloor.levels &&
                storageFloor.levels.map((level, index) => (
                    <ContainerFlex
                        Padding="0.75rem 0.5rem 0.5rem 0.5rem"
                        FlexDir="column"
                        Align="flex-start"
                        Flex="0 calc(33.333% - 1rem)"
                        Radius="0.5rem"
                        backG={level.name ? '#FFFFFF' : '#F0F0FF'}
                        Height={'14rem'}
                        Justify="start"
                        key={index}
                    >
                        {level.name && (
                            <>
                                <ContainerFlex Height={'1.5rem'}>
                                    <ContainerFlex Justify="start" Flex="3">
                                        <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500">
                                            {level.name}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Justify="start" Flex="6">
                                        <Text FontSize="0.75rem">
                                            {level.productQuantity} {STORAGE.PRODUCTS}
                                        </Text>
                                    </ContainerFlex>
                                    <ContainerFlex Justify="end" Flex="3">
                                        <Text FontSize="0.75rem">{level.storageDescription}</Text>
                                        <ActionMenu
                                            warehouse={warehouse}
                                            onEdit={() => {
                                                onEditLevel(level.id);
                                            }}
                                            onDuplicate={() => {
                                                onDuplicateLevel(level);
                                            }}
                                            onDelete={() => {
                                                onDeleteLevel(level.id);
                                            }}
                                        />
                                    </ContainerFlex>
                                </ContainerFlex>
                                <ContainerFlex
                                    Cursor="pointer"
                                    Justify="start"
                                    Height="2.5rem"
                                    backG="#FFFFFF"
                                    onClick={() => {
                                        onClickNewRow(level.id, true, floorIndex);
                                    }}
                                >
                                    <Image src={AddIconBlue} alt="add" color="#5A5AFF" />
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="500"
                                        Cursor="pointer"
                                        FontSize="1rem"
                                    >
                                        {STORAGE.NEW}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Display={'block'} OverFlow="auto" Height={'none'}>
                                    {level.rows &&
                                        level.rows.map((row, index) => (
                                            <StorageData
                                                key={index}
                                                storageType={row}
                                                warehouse={warehouse}
                                                onEdit={() => {
                                                    onEditRow(level, row);
                                                }}
                                                onDuplicate={() => {
                                                    if (storageFloor.id) onDuplicateRow(level, row);
                                                }}
                                                onDelete={() => {
                                                    if (storageFloor.id) onDeleteRow(level, row);
                                                }}
                                            />
                                        ))}
                                </ContainerFlex>
                                {level.showRowLevelInput && (
                                    <ContainerFlex
                                        Position="relative"
                                        FlexDir="column"
                                        Height={'4.5rem'}
                                        PaddingT="0.5rem"
                                        Justify="start"
                                    >
                                        <SearchInput
                                            GridColumn={name ? '75% 15% 10%' : '90% 10%'}
                                            FontSize="1rem"
                                            Right="none"
                                        >
                                            <input
                                                type="text"
                                                {...register(STORAGE_NAME_INPUT)}
                                                onKeyDown={(e) => {
                                                    onAddNewRow(e, floorIndex);
                                                }}
                                                onFocus={() => {
                                                    setEditId(level.id);
                                                }}
                                                onBlur={() => {
                                                    setRowLevelInput(level.id, false, floorIndex);
                                                    handleReset();
                                                }}
                                                autoFocus
                                            />
                                            <Tooltip title={TOOLTIP_STORAGE}>
                                                <Image
                                                    src={HelpIcon}
                                                    alt="help"
                                                    Cursor="pointer"
                                                    Width="1.25rem"
                                                    Height="1.25rem"
                                                />
                                            </Tooltip>
                                            {name && (
                                                <Image
                                                    src={ClearIcon}
                                                    alt="icon-clear"
                                                    Cursor="pointer"
                                                    Width="0.875rem"
                                                    Height="0.875rem"
                                                    onClick={handleReset}
                                                />
                                            )}
                                        </SearchInput>
                                        {errors?.storageName?.message && (
                                            <Text Color="#FF6357" FontSize="0.75rem" Self="start">
                                                {errors.storageName.message}
                                            </Text>
                                        )}
                                    </ContainerFlex>
                                )}
                            </>
                        )}
                        {level.showNewLevel && (
                            <ContainerFlex
                                Position="relative"
                                FlexDir="column"
                                Height={'3.5rem'}
                                Justify="start"
                            >
                                <SearchInput
                                    GridColumn={name ? '75% 15% 10%' : '90% 10%'}
                                    FontSize="1rem"
                                    Right="none"
                                >
                                    <input
                                        type="text"
                                        {...register(STORAGE_NAME_INPUT)}
                                        onKeyDown={(e) => {
                                            onAddNewLevel(e, floorIndex);
                                        }}
                                        autoFocus
                                        onFocus={() => {
                                            setEditId(level.id);
                                        }}
                                        onBlur={() => {
                                            onDeleteLevel(level.id);
                                            handleReset();
                                        }}
                                    />
                                    <Tooltip title={TOOLTIP_STORAGE}>
                                        <Image
                                            src={HelpIcon}
                                            alt="help"
                                            Cursor="pointer"
                                            Width="1.25rem"
                                            Height="1.25rem"
                                        />
                                    </Tooltip>
                                    {name && (
                                        <Image
                                            src={ClearIcon}
                                            alt="icon-clear"
                                            Cursor="pointer"
                                            Width="0.875rem"
                                            Height="0.875rem"
                                            onClick={handleReset}
                                        />
                                    )}
                                </SearchInput>
                                {errors?.storageName?.message && (
                                    <Text Color="#FF6357" FontSize="0.75rem" Self="start">
                                        {errors.storageName.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                ))}
            {renderEmptyLevels(getEmptyLevelsCount())}
        </ContainerFlex>
    );
};
