import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import ProductEdit from '@components/InventoryManagement/ProductEdit';
import ProductConditionModal from '@components/InventoryManagement/ProductConditionModal';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import Star from '@images/greenStar.svg';

const ProductHeader: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ContainerFlex Align="start" Justify="start">
                <ContainerFlex Align="start" Justify="start">
                    <Text
                        Border="solid 1px #B1E7C9"
                        BGColor="#E4F7EC"
                        Color="#174A2E"
                        FontWeight="700"
                        FontSize="0.875rem"
                        BorderRadius="2rem"
                        Padding="0.125rem 0.5rem"
                    >
                        <Image src={Star} alt="add" />
                        <Text
                            Color="#174A2E"
                            FontWeight="700"
                            FontSize="0.875rem"
                            MarginLeft="0.5rem"
                        >
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_CONDITION}
                        </Text>
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="end" PaddingT="0.125rem">
                    <ProductEdit
                        onClickEdit={() => {
                            setShowModal(true);
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start" Height="2rem">
                <Text Color="#1D1E20" FontWeight="700" FontSize="1.5rem">
                    {INVENTORY_PRODUCT_DETAIL.PRODUCT_TITLE}
                </Text>
            </ContainerFlex>
            <ProductConditionModal showModal={showModal} setShowModal={setShowModal} />
        </>
    );
};
export default ProductHeader;
