import React from 'react';

import {CONTRACT_PLEDGE_STATUS} from '@components/InventoryManagement/constants';
import calendarIcon from '@images/calendarDark.svg';
import personIcon from '@images/iconPersonDark.svg';
import moneyIcon from '@images/moneyDark.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import useFormatDate from '@/hooks/useFormatDate';
import {ContractInfoProps} from '@components/InventoryManagement/interfaces';

const ContractInfo: React.FC<ContractInfoProps> = ({pledge, item}) => {
    return (
        <>
            <ContainerFlex
                Align="start"
                Justify="start"
                Padding="1.5rem 1rem 1rem 1rem"
                Height="3.5rem"
            >
                <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                    {item.contractNumber}
                    {item.itemsCount && (
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            FontSize="0.875rem"
                            MarginLeft="0.5rem"
                        >
                            {item.itemsCount}
                        </Text>
                    )}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="Column"
                Align="start"
                Justify="start"
                PaddingL="1rem"
                Height="2.813rem"
            >
                <Text Color="#1D1E20" FontWeight="600" FontSize="1.25rem">
                    {numberToCurrency(item.loan)}
                </Text>
                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem" PaddingT="0.25rem">
                    {item.message}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="Column"
                Align="start"
                Justify="start"
                Padding="1rem"
                PaddingL="1rem"
                Gap="0.5rem"
            >
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={personIcon} alt="clientName" Width="1.25rem" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {item.clientName}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={moneyIcon} alt="amount" Width="1.25rem" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {CONTRACT_PLEDGE_STATUS.LAST_ENDORSEMENT}: {item.lastEndorsement}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={calendarIcon} alt="expiry" Width="1.25rem" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {CONTRACT_PLEDGE_STATUS.EXPIRES}:{' '}
                        {item.contractStatus === CONTRACT_PLEDGE_STATUS.DISCHARGED
                            ? '--'
                            : useFormatDate(item.expires)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default ContractInfo;
