import {
    CREATE_REQUEST_LOAN_START,
    CREATE_REQUEST_LOAN_SUCCESS,
    CREATE_REQUEST_LOAN_ERROR,
    GET_CREDIT_LOAN_SUMMARY_START,
    GET_CREDIT_LOAN_SUMMARY_SUCCESS,
    GET_CREDIT_LOAN_SUMMARY_ERROR,
    GET_CLIENT_CREDIT_HISTORY_START,
    GET_CLIENT_CREDIT_HISTORY_SUCCESS,
    GET_CLIENT_CREDIT_HISTORY_ERROR,
    GET_PERSONAL_INFOMARTION_ID_START,
    GET_PERSONAL_INFOMARTION_ID_SUCCESS,
    GET_PERSONAL_INFOMARTION_ID_ERROR,
    DELETE_ABOUT_PERSONAL_DATA_START,
    DELETE_ABOUT_PERSONAL_DATA_SUCCESS,
    DELETE_ABOUT_PERSONAL_DATA_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IPersonalLoanRequestSummaryReducer} from '@components/PersonalLoans/interfaces';
import {AxiosResponse, AxiosError} from 'axios';

const initialState: IPersonalLoanRequestSummaryReducer = {
    solicitudeId: null,
    numberSolicitude: null,
    amountRequested: null,
    acoutnPayments: null,
    frecuencyId: null,
    frecuencyName: null,
    hasApplicationHistory: false,
    personalInformationId: undefined,
    IdCompany: null,
    NameCompany: null,
    UrlCompany: null,
    isLoading: false,
    error: false,
};

const personalLoanRquestSummaryReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case CREATE_REQUEST_LOAN_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case CREATE_REQUEST_LOAN_SUCCESS:
            return {
                ...state,
                solicitudeId: action.payload.data.data,
                isLoading: false,
                error: false,
            };
        case CREATE_REQUEST_LOAN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case GET_CREDIT_LOAN_SUMMARY_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_SUCCESS:
            return {
                ...state,
                numberSolicitude: action.payload.data.data.numberSolicitude,
                amountRequested: action.payload.data.data.amountRequested,
                acoutnPayments: action.payload.data.data.acoutnPayments,
                frecuencyId: action.payload.data.data.frecuencyId,
                frecuencyName: action.payload.data.data.frecuencyName,
                IdCompany: action.payload.data.data.idCompany,
                NameCompany: action.payload.data.data.nameCompany,
                UrlCompany: action.payload.data.data.urlCompany,
                isLoading: false,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case GET_CLIENT_CREDIT_HISTORY_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_CLIENT_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                hasApplicationHistory: action.payload.data.data.hasApplicationHistory,
                isLoading: false,
                error: false,
            };
        case GET_CLIENT_CREDIT_HISTORY_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case GET_PERSONAL_INFOMARTION_ID_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_PERSONAL_INFOMARTION_ID_SUCCESS:
            return {
                ...state,
                personalInformationId: action.payload.data.data.personalInformationId,
                isLoading: false,
                error: false,
            };
        case GET_PERSONAL_INFOMARTION_ID_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case DELETE_ABOUT_PERSONAL_DATA_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case DELETE_ABOUT_PERSONAL_DATA_SUCCESS:
            return {
                state: initialState,
            };
        case DELETE_ABOUT_PERSONAL_DATA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default personalLoanRquestSummaryReducer;
