import carrouselLoanLogo from '@images/carrousel_loan_logo.svg';
import carrouselLoanLogo2 from '@images/carrousel_loan_logo_2.svg';
import carrouselLoanLogo3 from '@images/carrousel_loan_logo_3.svg';

export const TITLES = {
    LOAN_LIST: 'Lista de préstamos',
};

export const SHOW_BTN = {
    MORE: 'Ver más préstamos',
    LESS: 'Ver menos préstamos',
};

export const LOAN_CARD_ACTN_BTN = {
    APPLY: 'Solicitar',
    DETAILS: ' Más información',
};

export const LOAN_CARD_TAG = {
    POPULAR: 'Elegido por ',
    PERSONS: ' personas',
    PERSON: ' persona',
};

export const loans = [
    {
        company: 'Fundación Donde',
        needTag: true,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Banco de préstamos',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Trancredit',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Prestamil Prestamos Personales',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Global',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Fundación Donde',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Fundación Donde',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
    {
        company: 'Fundación Donde',
        needTag: false,
        loanInfo: {
            amount: 10000,
            term: 12,
            rate: 3.5,
            total: 10500,
        },
    },
];

export const FREQUENCY_OPTIONS = [
    {
        label: 'Semanal',
        value: 1,
    },
    {
        label: 'Quincenal',
        value: 2,
    },
    {
        label: 'Mensual',
        value: 3,
    },
    {
        label: 'Bimesual',
        value: 4,
    },
    {
        label: 'Trimestral',
        value: 5,
    },
    {
        label: 'Semestral',
        value: 6,
    },
];

export const FILTER_BOX = {
    LABELS: {
        AMOUNT: 'Cantidad',
        PAYMENT_NUM: 'Número de pagos',
        PAYMENT_FREQ: 'Frecuencia de pagos',
        MORE_FILTERS: 'Más filtros',
    },
    ICONS: {
        AMOUNT: 'money symbol icon',
        PAYMENT_NUM: 'calendar quantity icon',
        PAYMENT_FREQ: 'calendar frequency icon',
    },
    FORM_NAME: {
        PAYMENT_NUM: 'accountPayments',
        PAYMENT_FREQ: 'frecuencyId',
    },
};

export const carrouselData = [
    {
        logo: carrouselLoanLogo,
        alt: 'loan logo',
        amount: 10000,
        term: 6,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo2,
        alt: 'loan logo',
        amount: 20000,
        term: 12,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo3,
        alt: 'loan logo',
        amount: 30000,
        term: 24,
        rate: 30,
    },

    {
        logo: carrouselLoanLogo,
        alt: 'loan logo',
        amount: 10000,
        term: 6,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo2,
        alt: 'loan logo',
        amount: 20000,
        term: 12,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo3,
        alt: 'loan logo',
        amount: 30000,
        term: 24,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo,
        alt: 'loan logo',
        amount: 10000,
        term: 6,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo2,
        alt: 'loan logo',
        amount: 20000,
        term: 12,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo3,
        alt: 'loan logo',
        amount: 30000,
        term: 24,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo,
        alt: 'loan logo',
        amount: 10000,
        term: 6,
        rate: 30,
    },
    {
        logo: carrouselLoanLogo2,
        alt: 'loan logo',
        amount: 20000,
        term: 12,
        rate: 30,
    },
];

export const CARROUSEL_CARD = {
    LABELS: {
        REQUEST: 'Solicitar',
        LOAN_AMOUNT: 'Te prestamos',
        LOAN_TERM: 'Plazos',
        LOAN_RATE: 'interés anual',
    },
};

export const quantityFormOptions = [
    {label: 'Préstamo personal', value: 1, isDisabled: false},
    {label: 'Empeño', value: 2, isDisabled: true},
];

export const QUOTE_DATA_LABELS = {
    QUOTE_YOUR_LOAN: 'Cotiza tu crédito',
    SIMULATE_LOAN: 'Simula un préstamo personal o empeño.',
    LOAN_AMOUNT: '¿Cuánto dinero necesitas?',
    PAYMENT_FREQ: 'Frequencia de pago',
    PAYMENT_NUM: 'Numeros de pago',
    REQUEST_NOW: 'Solicitar ahora',
    SEE_LOANS: 'Ver préstamos',
    MORE_THAN_5: '+5',
    SCROLL_ID: 'loanCardsListContainer',
};

export const IMAGE_ALT = {
    SMALL_CIRCLE_LOGO: 'logo de empresa',
    DOWN_ARROW: 'flecha hacia abajo',
    BANK_LOGO: 'logo de banco',
    THUMB_UP: 'pulgar arriba',
    FILTER_ICON: 'icono de filtro',
    ARROW_LEFT: 'flecha izquierda',
    ARROW_RIGHT: 'flecha derecha',
};

export const LOAN_INFO = {
    LABELS: {
        MAX_AMOUNT: 'Monto max.',
        PAYMENT_NUM: 'N.º de pagos',
        LOAN_FREQ: 'Frecuencia',
        LOAN_RATE: 'Interés',
    },
    VALUES: {
        MAX_AMOUNT: '$10,000',
        PAYMENT_NUM: '12',
        LOAN_FREQ: 'Quincenal',
        LOAN_RATE: '% anual',
    },
};

export const LOAN_PAYMENT_INFO = {
    ESTIMATED_DEFAULT_PAYMENT: 'mensual',
    ESTIMATED_INITIAL_PAYMENT: 'Tu pago',
    ESTIMATED_FINAL_PAYMENT: 'estimado:',
    PAYMENT_AMOUNT: '$1,667',
    ANUAL_RATE: 'Interés anual - ',
    COMPANY: {
        LABEL: 'Empresas',
        VALUE: '10',
    },
    OFFERS: {
        LABEL: 'Ofertas de préstamos',
        VALUE: '18',
    },
    YOUR_PAYMENT: 'Tu pago ',
    ESTIMATED: ' estimado:',
};

export const GENERAL_MESSAGE =
    'Compare ofertas de préstamos personales de los principales empresas. Esta calculadora se ofrece únicamente con fines ilustrativos.';

export const disburstmentTypeOptions = [
    {label: 'Billetera Digital Ataskate', value: 1},
    {label: 'Efectivo', value: 2},
    {label: 'Transferencia bancaria', value: 3},
];

export const companyOptions = [
    {label: 'Multiapoyo', value: 1},
    {label: 'Monte de piedad', value: 2},
    {label: 'Monte pio', value: 3},
    {label: 'Prestamás', value: 4},
];

export const FILTER_LABELS_AND_VALUES = {
    AMOUNT: {
        LABEL: 'Monto máximo',
        PLACEHOLDER: 'Monto especifico',
        VALUE: 'maxAmount',
    },
    PAYMENTS: {
        LABEL: 'Número de pagos',
        VALUE_MIN: 'paymentMin',
        VALUE_MAX: 'paymentMax',
    },
    TERM: {
        LABEL: 'Plazos',
        VALUE: 'term',
    },
    RATE: {
        LABEL: 'Tasa de interés',
        VALUE_MIN: 'rateMin',
        VALUE_MAX: 'rateMax',
    },
    COMPANY: {
        LABEL: 'Empresa',
        VALUE: 'company',
        ALL_TAG: 'Todas las empresas',
    },
    DISBURSTMENT_TYPE: {
        LABEL: 'Tipos de desembolso',
        VALUE: 'disburstmentType',
        ALL_TAG: 'Todos los desembolsos',
    },
};

export const FOOTER_ACTIONS_BTN = {
    CANCEL: 'Cancelar',
    APPLY: 'Aplicar',
};

export const SELECT_ALL = 'Seleccionar todas';

const QUANTITY_OPTIONS_ARR = Array.from({length: 28}, (_, i) => i + 1);
export const ARRAY_WITH_VALUES = QUANTITY_OPTIONS_ARR.map((value) => {
    return {
        label: (value * 3).toString(),
        value: value * 3,
    };
});

export const DEFAULT_VALUES = {
    VISUAL_AMOUNT: '10000',
    PARSED_AMOUNT: 10000,
    FREQUENCY_ID: 2,
    FREQUENCY_NAME: 'Mensual',
    ACCOUNT_PAYMENTS: ARRAY_WITH_VALUES.find((item) => item.value === 12)?.value ?? null,
    TYPE_DROPDOWN: quantityFormOptions[0],
};

export const MESSAGE_NO_RESULTS = {
    FIRST_PARAGRAPH: 'Parece que por el momento no hay',
    SECOND_PARAGRAPH: 'préstamos con estas condiciones.',
    FINAL_PARAGRAPH: 'Intenta modificando tu solicitud',
};

export const WEEKLY_ID = 3;
