import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    VALIDATE_EMAIL_ADMIN,
    VALIDATE_EMAIL_ADMIN_SUCCESS,
    VALIDATE_EMAIL_ADMIN_ERROR,
} from '@components/SignUp/Redux/types';
import {ISignUpDataUser} from '@/components/SignUp/interfaces';

export const getNewEmailVerification = () => {
    return {
        type: VALIDATE_EMAIL_ADMIN,
    };
};

export const getNewEmailVerificationSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_EMAIL_ADMIN_SUCCESS,
        payload: result,
    };
};
export const getNewEmailVerificationError = (error: AxiosError) => {
    return {
        type: VALIDATE_EMAIL_ADMIN_ERROR,
        error: error,
    };
};

export function getEmailVerificationUsersAdmin(data: ISignUpDataUser) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getNewEmailVerification());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateEmailAdmin}`,
                {
                    headers,
                    params: data,
                }
            );
            dispatch(getNewEmailVerificationSuccess(response));
        } catch (error) {
            dispatch(getNewEmailVerificationError(error as AxiosError));
        }
    };
}
