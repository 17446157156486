import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    ARCHIVELOANS,
    LOAN_PRODUCT_PER_PAGE,
    STATUS_PRODUCTS,
} from '@components/LoansProducts/constants';
import {ActiveDesactiveTermCreditStatus} from '@components/LoansProducts/Redux/Actions/ActiveDesactiveTermCredit';
import {getProductsCustomerUser} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import {getAllTotalAvailables} from '@components/LoansProducts/Redux/Actions/getPersonalCreditProductsActions';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ModalArchiveProps} from '@components/LoansProducts/interfaces';

export const ModalArchiveLoans = ({setShowArchiveModal}: ModalArchiveProps) => {
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const enterpriceId = useSelector((state: RootState) => {
        return Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId);
    });
    const {selectedCredit, errorData} = useSelector((state: RootState) => {
        return state.ActiveDesactiveTermCredit;
    });
    const {activeStatus, page} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const dispatch = useDispatch();
    const ArchiveTermCredits = async () => {
        try {
            if (selectedCredit) {
                await dispatch(
                    ActiveDesactiveTermCreditStatus(
                        {
                            termCreditId: selectedCredit?.termId,
                            statusTermId: STATUS_PRODUCTS.ARCHIVE,
                        },
                        token,
                        setShowArchiveModal
                    )
                );
                await dispatch(
                    getProductsCustomerUser(
                        token,
                        enterpriceId,
                        activeStatus,
                        LOAN_PRODUCT_PER_PAGE,
                        page
                    )
                );
                await dispatch(getAllTotalAvailables(token, enterpriceId));
            }
        } catch (error) {
            return errorData;
        }
    };

    return (
        <Modal Justify="center" Align="center">
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Width="600px"
                Height="231px"
                Padding="1.5rem"
                Border="1px solid #E8E9EA"
                backG="#FFFFFF"
                Radius="1.5rem"
                Gap="1.5rem"
            >
                <Text
                    Color="#2A2C2F"
                    oFlow="normal"
                    wSpace="normal"
                    FontSize="1.5rem"
                    FontWeight="700"
                >
                    {ARCHIVELOANS.TITLE}
                </Text>
                <Text Color="#000000" oFlow="normal" wSpace="normal" Height="48px">
                    {ARCHIVELOANS.DESCRIPTION}
                </Text>
                <ContainerFlex
                    Justify="center"
                    Gap="1rem"
                    Height="2.5rem"
                    Align="end"
                    MarginTop="0.75rem"
                >
                    <ButtonGenerals
                        Width="11.938rem"
                        SecondaryButton="#FFFFFF"
                        FontWeight="700"
                        type="button"
                        onClick={() => {
                            setShowArchiveModal(false);
                        }}
                    >
                        {ARCHIVELOANS.CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals
                        Width="10.875rem"
                        BackGC="#A82424"
                        FontWeight="700"
                        type="button"
                        onClick={async () => {
                            await ArchiveTermCredits();
                        }}
                    >
                        {ARCHIVELOANS.ARCHIVE}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
