import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useParams} from 'react-router-dom';

import {ContainerFlex, ContainerImg, ContainerCellGrid, Image} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import AtaskateLogo from '@images/AtaskateLogo.svg';
import {validateLinkExpired} from '@/components/Login/Redux/Actions/validationLinkExpiredAction';
import {FormNewPassword} from '@components/Login/PasswordForgotAdmin/ConfirmPass/FormNewPassword';
import {SectionExpiredLink} from '@components/Login/PasswordForgotAdmin/ConfirmPass/SectionExpiredLink';

export const NewPassword = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const data = useSelector((state: RootState) => state.validateLink);

    useEffect(() => {
        if (params.user) dispatch(validateLinkExpired(params.user));
    }, []);

    return (
        <ContainerFlex backG="#ACACFF" Height="100vh" OverFlow="auto">
            <ContainerFlex
                Width="39%"
                Height={data.loadingLinkValidation ? '60%' : 'auto'}
                backG="#FFF"
                BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                Radius="24px"
                Padding="24px"
                Gap="16px"
                FlexDir="column"
                Justify="start"
            >
                <ContainerImg Height="53px" AlignItems="start">
                    <Image src={AtaskateLogo} alt="ataskate-logo" />
                </ContainerImg>

                {data.loadingLinkValidation && (
                    <ContainerCellGrid Height="75%" Align="center" Justify="center">
                        <LoadingAtaskate width="10%" padding="3.5%" />
                    </ContainerCellGrid>
                )}
                {!data.loadingLinkValidation && !data.isLinkAvailable && (
                    <SectionExpiredLink rowId={params.user as string} />
                )}
                {!data.loadingLinkValidation && data.isLinkAvailable && (
                    <FormNewPassword employeeId={data.employeeId} token={data.token} />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
