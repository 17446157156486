import * as yup from 'yup';

const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).+$/;
export const schema = yup.object().shape({
    Password: yup
        .string()
        .required('Ingrese una contraseña')
        .matches(PASSWORD_REGEX, 'La contraseña no cumple los parámetros')
        .min(8, 'La contraseña debe incluir minimo 8 caracteres'),
    ConfirmPassword: yup
        .string()
        .required('Ingrese una contraseña')
        .oneOf([yup.ref('Password')], 'Las contraseñas no coinciden'),
    Version: yup.number(),
});
