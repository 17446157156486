import {GET_CONTRACT_STATUSES} from '@components/InventoryManagement/Redux/types';
import {
    ContractStatuses,
    ContractStatusesAction,
} from '@components/InventoryManagement/Redux/interfaces';

const initialState: ContractStatuses = {
    statuses: [],
    loading: false,
    error: false,
};

const GetContractStatuses = (
    state = initialState,
    action: ContractStatusesAction
): ContractStatuses => {
    switch (action.type) {
        case GET_CONTRACT_STATUSES.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CONTRACT_STATUSES.SUCCESS:
            return {
                ...state,
                statuses: action.payload,
                loading: false,
                error: false,
            };
        case GET_CONTRACT_STATUSES.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default GetContractStatuses;
