import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import iconError from '@/images/iconError.svg';
import iconSuccessPwd from '@/images/iconSuccessPwd.svg';
import {Icon} from '@/components/MarketForgottenLogin/Operational/styles';
import {PERSONAL_FORM} from '@/components/SignUp/constants';

interface IError {
    error: boolean;
    password: string;
}

export const ErrorPassword = ({error, password}: IError) => {
    const passwordIcon = password.length < 8 ? iconError : iconSuccessPwd;
    const passwordIconColor = password.length < 8 ? '#FF6357' : '#309C60';
    const errorIcon = error ? iconError : iconSuccessPwd;
    const errorIconColor = error ? '#FF6357' : '#309C60';

    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <Icon
                    src={passwordIcon}
                    alt={password.length < 8 ? 'Close icon' : 'Done icon'}
                    color={passwordIconColor}
                />
                <Icon
                    src={errorIcon}
                    alt={error ? 'Close icon' : 'Done icon'}
                    color={errorIconColor}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                <Text
                    Color={password.length < 8 ? '#FF6357' : '#309C60'}
                    FontSize="0.7rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                >
                    {PERSONAL_FORM.MESSAGE_PASSWORD_MIN}
                </Text>
                <Text
                    Color={error ? '#FF6357' : '#309C60'}
                    FontSize="0.7rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                >
                    {PERSONAL_FORM.MESSAGE_PASSWORD_MATCH}
                    {PERSONAL_FORM.MESSAGE_PASSWORD_MATCH_TWO}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
