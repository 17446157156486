import {
    GET_CONDITION_STATUS_ARRAY_START,
    GET_CONDITION_STATUS_ARRAY_SUCCESS,
    GET_CONDITION_STATUS_ARRAY_ERROR,
} from '@/components/PreApproval/Redux/Types';
import {AxiosResponse} from 'axios';
import {IConditionStatusReducerInitialState, IStatus} from '@/components/PreApproval/interfaces';

const initialState: IConditionStatusReducerInitialState = {
    status: [],
    loading: false,
    error: false,
};

const ConditionStatusReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse<{data: IStatus[]}>}
) => {
    switch (action.type) {
        case GET_CONDITION_STATUS_ARRAY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CONDITION_STATUS_ARRAY_SUCCESS:
            return {
                ...state,
                status: action.payload.data.data,
                loading: false,
            };
        case GET_CONDITION_STATUS_ARRAY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default ConditionStatusReducer;
