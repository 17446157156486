import {
    GET_CONDITIONS_START,
    GET_CONDITIONS_SUCCESS,
    GET_CONDITIONS_ERROR,
    GET_TOTAL_CONDITIONS_START,
    GET_TOTAL_CONDITIONS_SUCCESS,
    GET_TOTAL_CONDITIONS_ERROR,
} from '@/components/PreApproval/Redux/Types';
import {AxiosResponse} from 'axios';
import {IMutateConditionsReducerInitialState} from '@/components/PreApproval/interfaces';

export const initialState: IMutateConditionsReducerInitialState = {
    conditions: {
        data: [],
        pageNumber: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
    },
    conditionSummary: {
        companyId: 0,
        summaryTotal: 0,
    },
    loading: false,
    error: false,
};

const MutateConditionsReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_CONDITIONS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CONDITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conditions: action.payload.data,
            };
        case GET_CONDITIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_TOTAL_CONDITIONS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_TOTAL_CONDITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                conditionSummary: action.payload.data.data,
            };
        case GET_TOTAL_CONDITIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default MutateConditionsReducer;
