import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {TimerStatus} from '@components/PersonalLoans/CreditStatus/CreditValidation/TimerStatus';
import ConfirmationLoanSummary from '@components/PersonalLoans/ConfirmationLoanSummary';
import {PERSONAL_LOAN_CONFIRMATION} from '@components/PersonalLoans/constants';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {
    GetConfirmationStepData,
    GetAdditionalLoansOptions,
} from '@components/PersonalLoans/Redux/Actions/GetConfirmationStepActions';
import {
    IGetConfirmationStepReducerInitialState,
    IPersonalLoanRequestSummaryReducer,
} from '@components/PersonalLoans/interfaces';

const PersonalLoansStepConfirmation = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const personalCreditId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalCreditId;
    });
    const {dataLoans} = useSelector((state: RootState) => {
        return state.GetValidateLoan;
    });
    const {solicitudeId} = useSelector(
        (state: RootState) =>
            state.personalLoanRquestSummaryReducer as IPersonalLoanRequestSummaryReducer
    );
    const {dataSummary} = useSelector(
        (state: RootState) =>
            state.GetConfirmationStepReducer as IGetConfirmationStepReducerInitialState
    );
    useEffect(() => {
        if (token && (personalCreditId || dataLoans?.personalCreditId || solicitudeId)) {
            const personalInformationId =
                personalCreditId || dataLoans?.personalCreditId || solicitudeId;
            if (personalInformationId) {
                dispatch(GetConfirmationStepData(token, personalInformationId));
                dispatch(GetAdditionalLoansOptions(token, personalInformationId));
            }
        }
    }, [token, personalCreditId, dataLoans?.personalCreditId]);

    return (
        <ContainerFlex Justify="space-between" Align="flex-start">
            <ContainerFlex
                FlexDir="column"
                Gap="16px"
                MaxW="497px"
                Justify="flex-start"
                Align="flex-start"
            >
                <Text FontSize="1.75rem" FontWeight="700" Color="#0D166B" FontFamily="Nunito">
                    {dataSummary?.nameClient + PERSONAL_LOAN_CONFIRMATION.GREET}
                </Text>
                <TimerStatus
                    timeResponse={dataSummary?.time || '00:00:00'}
                    alignTitle="flex-start"
                />
                <Text FontWeight="500" Color="#1D1E20" FontFamily="Nunito">
                    {PERSONAL_LOAN_CONFIRMATION.LET_YOU_KNOW.replace(
                        PERSONAL_LOAN_CONFIRMATION.EMAIL_CONST,
                        dataSummary?.emailClient || ''
                    )}
                </Text>
            </ContainerFlex>
            <ConfirmationLoanSummary />
        </ContainerFlex>
    );
};

export default PersonalLoansStepConfirmation;
