export const STATUS_USER_ADMIN_REQUEST = 'STATUS_USER_ADMIN_REQUEST';
export const STATUS_USER_USER_SOUCCES = 'STATUS_USER_USER_SOUCCES';
export const STATUS_USER_USER_ERROR = 'STATUS_USER_USER_ERROR';

export const RECENT_ACCESS_REQUEST = 'RECENT_ACCESS_REQUEST';
export const RECENT_ACCESS_SOUCCES = 'RECENT_ACCESS_SOUCCES';
export const RECENT_ACCESS_ERROR = 'RECENT_ACCESS_ERROR ';

export const STATUS_ACTIVE_USER_REQUEST = 'STATUS_ACTIVE_USER_REQUEST';
export const STATUS_ACTIVE_USER_SOUCCES = 'STATUS_ACTIVE_USER_SOUCCE';
export const STATUS_ACTIVE_USER_ERROR = 'STATUS_ACTIVE_USER_ERROR ';

export const STATUS_OF_USER_REQUEST = 'STATUS_OF_USER_REQUEST';
export const STATUS_OF_USER_SOUCCES = 'STATUS_OF_USER_SOUCCES';
export const STATUS_OF_USER_ERROR = 'STATUS_OF_USER_ERROR';

export const DELETE_USER_REQUEST = 'STATUS_OF_USER_REQUEST';
export const DELETE_OF_USER_SOUCCES = 'STATUS_OF_USER_SOUCCES';
export const DELETE_OF_USER_ERROR = 'STATUS_OF_USER_ERROR';

export const lOGS_FILE_REQUEST = 'lOGS_FILE_REQUEST_REQUEST';
export const lOGS_FILE_USER_SOUCCES = 'lOGS_FILE_USER_SOUCCES';
export const lOGS_FILE_USER_ERROR = 'lOGS_FILE_USER_ERROR';
export const RESET_DOWNLOAD_DATA = 'RESET_DOWNLOAD_DATA';

export const GET_BRANCH_TREE_REQUEST = 'GET_BRANCH_TREE_REQUEST';
export const GET_BRANCH_TREE_SOUCCES = 'GET_BRANCH_TREE_SOUCCES';
export const GET_BRANCH_TREE_ERROR = 'GET_BRANCH_TREE_ERROR';

export const GET_LEVEL_TREE_REQUEST = 'GET_LEVEL_TREE_REQUEST';
export const GET_LEVEL_TREE_SOUCCES = 'GET_LEVEL_TREE_SOUCCES';
export const GET_LEVEL_TREE_ERROR = 'GET_LEVEL_TREE_ERROR';

export const PUT_LEVEL_TREE_REQUEST = 'PUT_LEVEL_TREE_REQUEST';
export const PUT_LEVEL_TREE_SOUCCES = 'PUT_LEVEL_TREE_SOUCCES';
export const PUT_LEVEL_TREE_ERROR = 'PUT_LEVEL_TREE_ERROR';

export const SEARCH_LEVEL_TREE_REQUEST = 'SEARCH_LEVEL_TREE_REQUEST';
export const SEARCH_LEVEL_TREE_SOUCCES = 'SEARCH_LEVEL_TREE_SOUCCES';
export const SEARCH_LEVEL_TREE_ERROR = 'SEARCH_LEVEL_TREE_ERROR';

export const PUT_PICTURE = 'PUT_PICTURE_REQUEST';
export const PUT_PICTURE_SOUCCES = 'PUT_PICTURE_SOUCCES_SOUCCES';
export const PUT_PICTURE_ERROR = 'PUT_PICTURE_ERROR_ERROR';

export const GET_STORAGES = 'GET_STORAGES';
export const GET_STORAGES_SUCCESS = 'GET_STORAGES_SUCCESS';
export const GET_STORAGES_ERROR = 'GET_STORAGES_ERROR';
