import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CLIEND_PERSONAL_DATA_START,
    GET_CLIEND_PERSONAL_DATA_SUCCESS,
    GET_CLIEND_PERSONAL_DATA_ERROR,
} from '@/components/PersonalLoans/Redux/Types/Types';
import {IPreLoadPersonalDataReducerInitialState} from '@/components/PersonalLoans/interfaces';

const initialState: IPreLoadPersonalDataReducerInitialState = {
    name: '',
    lastName: '',
    maindenName: '',
    catGenderId: null,
    birthDate: '',
    curp: '',
    phone: '',
    countryCode: null,
    street: '',
    exteriorNumber: '',
    interiorNumber: '',
    catColonyId: null,
    catMunicipalityId: null,
    catStateId: null,
    postalCode: '',
};

const PreLoadPersonalDataReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case GET_CLIEND_PERSONAL_DATA_START:
            return {
                ...state,
            };
        case GET_CLIEND_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                name: action.payload.data.data.name,
                lastName: action.payload.data.data.lastName,
                maindenName: action.payload.data.data.maindenName,
                catGenderId: action.payload.data.data.catGenderId,
                birthDate: action.payload.data.data.birthDate,
                curp: action.payload.data.data.curp,
                phone: action.payload.data.data.phone,
                countryCode: action.payload.data.data.countryCode,
                street: action.payload.data.data.street,
                exteriorNumber: action.payload.data.data.exteriorNumber,
                interiorNumber: action.payload.data.data.interiorNumber,
                catColonyId: action.payload.data.data.catColonyId,
                catMunicipalityId: action.payload.data.data.catMunicipalityId,
                catStateId: action.payload.data.data.catStateId,
                postalCode: action.payload.data.data.postalCode,
            };
        case GET_CLIEND_PERSONAL_DATA_ERROR:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default PreLoadPersonalDataReducer;
