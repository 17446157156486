import {useNavigate} from 'react-router-dom';
import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import offerCreditsBackground from '@images/offerCreditsBackground.png';
import arrowRightIcon from '@/components/CreditQuoter/icons/arrowRightIcon.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {OFFER_CREDITS_BANNER} from '@components/PublicGlobalQuoter/constants';
import {routers} from '@/appRoute';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const OfferCreditsBanner = () => {
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const routeCTA = token ? routers.SignUpAdmin : routers.SignUp;
    return (
        <ContainerFlex
            Height="178px"
            backG={`url(${offerCreditsBackground}) no-repeat`}
            BackgroundSize="cover"
            Justify="end"
            Padding="3rem 2rem"
        >
            <ContainerFlex
                Width="849px"
                backG="#FFF"
                Radius="1rem"
                Padding="0.5rem 4rem"
                Justify="space-between"
            >
                <ContainerFlex
                    Align="start"
                    Justify="start"
                    Gap="0.5rem"
                    FlexDir="column"
                    Width="max-content"
                >
                    <Text FontSize="1.75rem" Color="#1D1E20" FontWeight="700">
                        {OFFER_CREDITS_BANNER.TITLE}
                    </Text>
                    <Text Color="#1D1E20" FontWeight="700">
                        {OFFER_CREDITS_BANNER.DESCRIPTION}
                    </Text>
                </ContainerFlex>
                <ButtonGenerals
                    SecondaryButton="true"
                    FontSize="1rem"
                    FontWeight="700"
                    Cursor="pointer"
                    Padding="8px 16px"
                    MHeight="40px"
                    BackGC="#F0F0FF"
                    Color="#5A5AFF"
                    HBackG="#F0F0FF"
                    Gap="8px"
                    onClick={() => navigate(routeCTA)}
                >
                    {OFFER_CREDITS_BANNER.OFFER_BTN}
                    <Image src={arrowRightIcon} Width="24px" Height="24px" alt="arrow-right-icon" />
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};
