import axios, {AxiosResponse} from 'axios';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    CHANGE_COMMERCIAL_INVENTORY_PAGE,
    GET_COMMERCIAL_INVENTORY,
    GET_COMMERCIAL_INVENTORY_ERROR,
    GET_COMMERCIAL_INVENTORY_SUCCESS,
} from '@components/InventoryManagement/Redux/types';
import {
    ChangePageAction,
    GetCommercialInventoryAction,
    GetCommercialInventoryBody,
    GetCommercialInventoryResponse,
} from '@components/InventoryManagement/interfaces';

export const getCommercialInventory = (): GetCommercialInventoryAction => ({
    type: GET_COMMERCIAL_INVENTORY,
});
export const getCommercialInventorySuccess = (
    result: AxiosResponse<GetCommercialInventoryResponse>
): GetCommercialInventoryAction => {
    return {
        type: GET_COMMERCIAL_INVENTORY_SUCCESS,
        payload: result,
    };
};
export const getCommercialInventoryError = (): GetCommercialInventoryAction => ({
    type: GET_COMMERCIAL_INVENTORY_ERROR,
});

export const changeCommercialInventoryPage = (page: number): ChangePageAction => ({
    type: CHANGE_COMMERCIAL_INVENTORY_PAGE,
    pageNumber: page,
});

export const requestInventoryItems = (token: string, bodyRequest: GetCommercialInventoryBody) => {
    const headers = {
        Accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCommercialInventory());
        try {
            const response = await axios.post<
                GetCommercialInventoryResponse,
                AxiosResponse<GetCommercialInventoryResponse>,
                GetCommercialInventoryBody
            >(`${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInventorySale}`, bodyRequest, {
                headers,
            });
            dispatch(getCommercialInventorySuccess(response));
        } catch (error) {
            dispatch(getCommercialInventoryError());
        }
    };
};
