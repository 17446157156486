import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    setSelectedStorageListItem,
    setSelectedStorageItemType,
} from '@components/InventoryManagement/Redux/Actions/InventoryLocationStorage';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {INVENTORY_LOCATION} from '@components/InventoryManagement/constants';
import backArrowIcon from '@images/backArrowIcon.svg';
import {IInventoryLocationStorageListItemType} from '@components/InventoryManagement/interfaces';

const InventoryLocationStorageListLevels = () => {
    const dispatch = useDispatch();
    const {selectedStorageListItem, selectedStorageItemType} = useSelector((state: RootState) => {
        return state.InventoryLocationStorage;
    });

    const onGoBack = () => {
        dispatch(setSelectedStorageListItem(null));
        dispatch(setSelectedStorageItemType(null));
    };

    const onSelectedStorageItemType = (
        selectedStorageItemType: IInventoryLocationStorageListItemType
    ) => {
        dispatch(setSelectedStorageItemType(selectedStorageItemType));
    };

    return (
        <ContainerFlex
            backG="#FAFAFF"
            FlexDir="column"
            Align="start"
            Justify="start"
            Radius="1rem"
            Padding="1rem 1.5rem 1rem 1.5rem"
            Gap="1rem"
        >
            <ContainerFlex Justify="start" Align="center" Height="1.5rem" onClick={onGoBack}>
                <Text FontSize="1rem" Color="#5A5AFF" FontWeight="500" Cursor="pointer">
                    <Image
                        src={backArrowIcon}
                        alt="add"
                        Width="1rem"
                        Height="1rem"
                        Margin="0 0.5rem 0 0"
                    />
                    {INVENTORY_LOCATION.HOME}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="center" FlexDir="column" Height="1.5rem">
                <ContainerFlex Justify="start" Align="center" Gap="0.5rem">
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                        {selectedStorageListItem?.name}
                    </Text>
                    <Text FontSize="0.75rem" FontWeight="400">
                        {selectedStorageListItem?.quantity} {INVENTORY_LOCATION.ARTICLES}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="center" Gap="0.5rem">
                    <Text FontSize="0.75rem" FontWeight="400">
                        {INVENTORY_LOCATION.SUB_TITLE_LEVEL} 1
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Justify="start" FlexDir="column" OverFlowY="auto" Height="12.5rem">
                <ContainerFlex
                    Display="grid"
                    GridColumns="1fr 1fr 1fr"
                    Justify="start"
                    Align="flex-start"
                    Gap="1rem"
                    MarginTop="1rem"
                    FlexWrap="wrap"
                >
                    {selectedStorageListItem?.levels.map((level, index) => (
                        <ContainerFlex
                            key={index}
                            Padding="0.75rem 0.5rem 0.5rem 0.5rem"
                            FlexDir="column"
                            Align="flex-start"
                            Flex="0 calc(33.333% - 1rem)"
                            Radius="0.5rem"
                            Height="11rem"
                            Justify="start"
                            backG="#FFFFFF"
                        >
                            <ContainerFlex Height="1.5rem" Gap="0.5rem">
                                <ContainerFlex Justify="start" Flex="3">
                                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500">
                                        {level.name}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="start" Flex="6">
                                    <Text FontSize="0.75rem" Color="#54575C">
                                        {level.quantity} {INVENTORY_LOCATION.ARTICLES}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="end" Flex="3">
                                    <Text FontSize="0.75rem" Color="#54575C">
                                        {INVENTORY_LOCATION.SUB_TITLE_LEVEL} 2
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            {level.products.map((product, index) => (
                                <ContainerFlex
                                    key={index}
                                    Cursor="pointer"
                                    Justify="start"
                                    Align="start"
                                    ColumnGap="0.5rem"
                                    HBackground="#f0f0ff"
                                    HBorderLeft="0.125rem solid #5a5aff"
                                    FlexDir="Column"
                                    Gap="1rem"
                                    Height="2.5rem"
                                    onClick={() => onSelectedStorageItemType(product)}
                                    Bl={
                                        selectedStorageItemType?.id === product.id
                                            ? '0.125rem solid #5a5aff'
                                            : ''
                                    }
                                    backG={
                                        selectedStorageItemType?.id === product.id
                                            ? '#f0f0ff'
                                            : '#FFFFFF'
                                    }
                                >
                                    <ContainerFlex
                                        Justify="start"
                                        Align="start"
                                        ColumnGap="0.5rem"
                                        FlexDir="row"
                                        Gap="0.5rem"
                                        Padding="0 0.5rem"
                                        Display="grid"
                                        GridColumns="65% 35%"
                                    >
                                        <ContainerFlex Justify="start">
                                            <Text
                                                Color="#2A2C2F"
                                                FontWeight="400"
                                                Cursor="pointer"
                                                FontSize="1rem"
                                            >
                                                {product.name}
                                            </Text>
                                        </ContainerFlex>
                                        <ContainerFlex Justify="end">
                                            <Text
                                                Color="#54575C"
                                                FontWeight="400"
                                                Cursor="pointer"
                                                FontSize="0.75rem"
                                            >
                                                {product.quantity} {INVENTORY_LOCATION.ARTICLES}
                                            </Text>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                </ContainerFlex>
                            ))}
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default InventoryLocationStorageListLevels;
