import axios from 'axios';
import {
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,
} from '@components/SignUp/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';

export const PostVerifyCodeResquestClient = () => {
    return {
        type: VERIFY_CODE_REQUEST,
    };
};
export const PostVeryfyCodeSuccessClient = (data) => {
    return {
        type: VERIFY_CODE_SUCCESS,
        payload: data,
    };
};
export const PostVeryfyCodeFailureClient = (err) => {
    return {
        type: VERIFY_CODE_ERROR,
        payload: err,
    };
};
export const verifyNewCodeAdmin = (code, setErrors) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer',
    };

    return async (dispatch) => {
        dispatch(PostVerifyCodeResquestClient());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateCodeAdmin}`,
                {headers, params: {code}}
            );
            dispatch(PostVeryfyCodeSuccessClient(response));
        } catch (error) {
            dispatch(PostVeryfyCodeFailureClient(error));
            setErrors(true);
        }
    };
};
