import React, {useState, useEffect} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    INHERIT_TYPE,
    isOptional,
    LAST_LOCATION,
    NEWGROUP,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import * as style from '@components/LocationsBranch/stylesLocations';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {BusinessLines} from '@components/LocationsBranch/BusinessLines';
import {
    IBranchHolidays,
    IShowModalHolidays,
    GroupEditForm,
    User,
} from '@components/LocationsBranch/interfaces';
import {newGroup} from '@components/LocationsBranch/Redux/Action/NewGroup';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {HolidaysSchedule} from '@components/LocationsBranch/HolidaysSchedule';
import {newGroupShema} from '@components/LocationsBranch/createLocationsErrors';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {HOLIDAYS, holidaysHours, scheduleHours} from '@components/LocationsBranch/constants';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormInitialization';
import {
    getCompanyGroupsById,
    updateCompanyGroupsById,
} from '@components/LocationsBranch/Redux/Action/GetGroupsByCompanyId';
import {
    IOpeningHours,
    SelectedLevel,
    IInventories,
    IHolidaysHours,
    UpdateGroup,
} from '@components/LocationsBranch/Redux/interfaces';
import {getHolidaysLocation} from '@components/LocationsBranch/Redux/Action/GetHolidaysByLocationId';
import {getScheduleLocation} from '@components/LocationsBranch/Redux/Action/GetScheduleByLocationId';
import {getLocationValues} from '@components/LocationsBranch/utils/GetLocationValues';
import {getUsersByLocation} from '@components/LocationsBranch/Redux/Action/GetUsersByLocationId';
import {fetchStorageByLocationId} from '@components/LocationsBranch/Redux/Action/GetStorageByLocationId';
import {catCompany} from '@components/SignUp/interfaces';
import {reverseTransformData} from '@components/LocationsBranch/utils/TransformStorageData';
import {getUserIds} from '@components/LocationsBranch/utils/GetUserIds';
import {resetSchedule} from '@components/LocationsBranch/Redux/Action/GetSchedule';

export const NewGroup = ({setShowHolidaysModal, selectionId}: IShowModalHolidays) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState({addUser: false, locations: false});
    const isEditing = !!selectionId;

    const {groups, token, company, addGroup, level, getGroups, inventory, userList} = useSelector(
        (state: RootState) => ({
            groups: state.getCreateGroupBranch,
            level: state.getLevels.level,
            token: state.getUsersValidation.userData?.token as string,
            company: state.getUsersValidation.userData?.enterpriceDetails?.[0]
                ?.enterpriceId as number,
            addGroup: state.newGroup,
            getGroups: state.getGroupsByCompanyId,
            inventory: state.Warehouse.warehouses,
            userList: state.GetUserByLevelId.allUserData,
        })
    );

    const {
        register,
        control,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameGroup: '',
            businessLines: [],
            users: [],
            openingHours: [],
            holidays: [],
            storages: [],
        },
        resolver: yupResolver(newGroupShema(!!selectionId)),
    });

    const {initialize} = useInitializeForm();

    const getEditGroup = () => {
        if (!level.levelId && !selectionId) return;
        const searchId = selectionId ?? level.levelId;
        const payload = {
            companyId: company,
            levelId: searchId,
        };
        dispatch(getCompanyGroupsById(token, payload));
    };
    const initializeStorage = () => {
        const {
            levelName = '',
            fatherId = 0,
            companyId = 0,
            description = '',
            user = [],
            openingHours = [],
            holidays = [],
            storages = null,
        } = getGroups.groups || {};

        const source: GroupEditForm = {
            name: levelName,
            levelId: level.levelId,
            description,
            openingHours,
            holidays,
            storagesDetail: storages,
            users: user,
            companyId,
            fatherId,
        };
        initialize(source, 'nameGroup', setValue);
    };

    const onSetUsers = (users: User[]) => {
        const source: GroupEditForm = {
            users,
        };
        initialize(source, '', setValue);
    };

    const onSetStorage = (storagesDetail: IInventories[]) => {
        if (storagesDetail) {
            const source: GroupEditForm = {
                storagesDetail,
            };
            initialize(source, '', setValue);
        }
    };

    const onSetHolidaysSchedule = (
        holidays?: IBranchHolidays[],
        openingHours?: IOpeningHours[]
    ) => {
        const source: GroupEditForm = {
            ...(holidays && {holidays}),
            ...(openingHours && {openingHours}),
        };
        initialize(source, '', setValue);
    };

    const onSetHolidays = (holidays: IBranchHolidays[]) => {
        if (!holidays) return;
        reset({...watch(), holidays: []});
        return onSetHolidaysSchedule(holidays);
    };

    const onSetSchedule = (schedule: IOpeningHours[]) => {
        if (!schedule) return;
        reset({...watch(), openingHours: []});
        return onSetHolidaysSchedule(undefined, schedule);
    };

    const onClickTransfer = async (transferSelection: SelectedLevel, type: string) => {
        const data = getLocationValues(transferSelection);
        const transferActions: Record<string, () => Promise<void>> = {
            [INHERIT_TYPE.SCHEDULE]: async () => {
                dispatch(resetSchedule());
                dispatch(getHolidaysLocation(data, token, onSetHolidays));
                dispatch(getScheduleLocation(data, token, onSetSchedule));
            },
            [INHERIT_TYPE.USER]: async () => {
                await dispatch(getUsersByLocation(data, token, onSetUsers));
            },
            [INHERIT_TYPE.LOCATION]: async () => {
                await dispatch(fetchStorageByLocationId(data, token, onSetStorage));
            },
        };

        const action = transferActions[type];
        if (action) {
            await action();
        }
    };

    useEffect(() => {
        if (isNaN(level.levelId) || !selectionId) return;
        getEditGroup();
    }, [selectionId]);

    useEffect(() => {
        if (getGroups.groups) {
            initializeStorage();
        }
    }, [getGroups.groups]);

    const parseToGroupStorage = (inventory: IInventories[]): UpdateGroup[] => {
        return inventory.map((item) => ({
            id: item.storageId,
            nameStorage: item.storageName,
            description: item.storageDescrption,
            storages: parseToGroupStorage(item.inventories),
        }));
    };

    const createGroup = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        const users = getUserIds(userList);

        const json = {
            levelName: values.nameGroup,
            fatherId: level && level.levelId,
            companyId: company,
            description: values.nameGroup,
            businessLines: values.businessLines.reduce(
                (acc: number[], {status, id}: catCompany) => (status ? [...acc, id] : acc),
                []
            ),
            users,
            ...isOptional('openingHours', openingHours),
            ...isOptional('holidays', holidayHours),
            ...isOptional('storages', parseToGroupStorage(reverseTransformData(inventory))),
        };
        dispatch(newGroup(token, navigate, json));
    };

    const updateGroup = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        const users = getUserIds(userList);
        const json = {
            levelName: values.nameGroup,
            levelId: Number(selectionId),
            companyId: company,
            description: values.nameGroup,
            users,
            ...isOptional('openingHoursP', openingHours),
            ...isOptional('holidaysP', holidayHours),
            ...isOptional('storages', parseToGroupStorage(reverseTransformData(inventory))),
        };
        dispatch(updateCompanyGroupsById(token, navigate, json));
    };

    const onsubmit = (values: FieldValues) => {
        const openingHours =
            values.openingHours && scheduleHours(Object.values(values.openingHours));
        const holidayHours = values.holidays && holidaysHours(Object.values(values.holidays));
        if (!selectionId) createGroup(values, openingHours, holidayHours);
        else updateGroup(values, openingHours, holidayHours);
    };

    const cancelOperation = async () => {
        navigate('/LocationBranch');
    };

    return (
        <>
            <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
                {groups.loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    <>
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700">
                            {selectionId ? getGroups.groups?.levelName : NEWGROUP.TITLE}
                        </Text>
                        <NameLocation
                            newName={addGroup}
                            nameLocation={NEWGROUP.NAME_FORM}
                            register={register}
                            errors={errors}
                            watch={watch}
                            reset={reset}
                        />
                        <RoutesLocations lastLevel={LAST_LOCATION.NEW_GROUP} />
                        {!selectionId && (
                            <BusinessLines
                                setValue={setValue}
                                errors={errors}
                                nameBusiness={NEWGROUP.LINES}
                                register={register}
                            />
                        )}
                        <UserGroup
                            modal={modal}
                            setModal={setModal}
                            setValue={setValue}
                            onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.USER)}
                        />
                        <HolidaysSchedule
                            register={register}
                            control={control}
                            errors={errors}
                            setShowHolidaysModal={setShowHolidaysModal}
                            watch={watch}
                            reset={reset}
                            setValue={setValue}
                            isOptional={true}
                            isEdit={isEditing}
                            onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.SCHEDULE)}
                        />
                        <LocationStorage
                            isOptional={true}
                            onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.LOCATION)}
                            isEdit={isEditing}
                            errors={errors}
                        />
                        {addGroup.loading ? (
                            <ContainerFlex Height="2.5rem">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Gap="1.5rem" Height="2.5rem">
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.CANCEL}
                                    clic={() => cancelOperation()}
                                    color="#5A5AFF"
                                    backGround="#FFFFFF"
                                    hText="#5A5AFF"
                                    hShadow=""
                                    height="2.5rem"
                                    cursor="pointer"
                                    border="1px solid #5A5AFF"
                                    hBackG="#E5E5FF"
                                />
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.SAVE}
                                    type="submit"
                                    height="2.5rem"
                                    cursor="pointer"
                                />
                            </ContainerFlex>
                        )}
                    </>
                )}
            </ContainerForm>
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
        </>
    );
};
