import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CONFIRMATION_LOAN_START,
    GET_CONFIRMATION_LOAN_SUCCESS,
    GET_CONFIRMATION_LOAN_ERROR,
    GET_ADDITIONAL_LOANS_OPTIONS_START,
    GET_ADDITIONAL_LOANS_OPTIONS_SUCCESS,
    GET_ADDITIONAL_LOANS_OPTIONS_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {
    IAdditionalOffer,
    IDataSummary,
    IGetConfirmationStepReducerInitialState,
} from '@components/PersonalLoans/interfaces';

const initialState: IGetConfirmationStepReducerInitialState = {
    dataSummary: null,
    loadingSummary: false,
    errorSummary: false,
    dataList: null,
    loadingList: false,
    errorList: false,
};

const GetConfirmationStepReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
) => {
    switch (action.type) {
        case GET_CONFIRMATION_LOAN_START:
            return {
                ...state,
                loadingSummary: true,
                errorSummary: false,
            };
        case GET_CONFIRMATION_LOAN_SUCCESS:
            return {
                ...state,
                dataSummary: action.payload.data.data as IDataSummary,
                loadingSummary: false,
                errorSummary: false,
            };
        case GET_CONFIRMATION_LOAN_ERROR:
            return {
                ...state,
                loadingSummary: false,
                errorSummary: true,
            };
        case GET_ADDITIONAL_LOANS_OPTIONS_START:
            return {
                ...state,
                loadingList: true,
                errorList: false,
            };
        case GET_ADDITIONAL_LOANS_OPTIONS_SUCCESS:
            return {
                ...state,
                dataList: action.payload.data.data as IAdditionalOffer[],
                loadingList: false,
                errorList: false,
            };
        case GET_ADDITIONAL_LOANS_OPTIONS_ERROR:
            return {
                ...state,
                loadingList: false,
                errorList: true,
            };
        default:
            return state;
    }
};

export default GetConfirmationStepReducer;
