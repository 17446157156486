import {INVENTORY_LOCATION_STORAGE} from '@components/InventoryManagement/Redux/types';
import {
    InventoryLocationStorageRedux,
    InventoryLocationStorageAction,
} from '@components/InventoryManagement/Redux/interfaces';

const initialState: InventoryLocationStorageRedux = {
    selectedStorage: null,
    selectedStorageListItem: null,
    selectedStorageItemType: null,
    showLocationModal: false,
};

const InventoryLocationStorage = (
    state = initialState,
    action: InventoryLocationStorageAction
): InventoryLocationStorageRedux => {
    switch (action.type) {
        case INVENTORY_LOCATION_STORAGE.SELECT_STORAGE:
            return {
                ...state,
                selectedStorageListItem: action.payload.selectedStorageListItem,
            };
        case INVENTORY_LOCATION_STORAGE.SHOW_MODAL:
            return {
                ...state,
                showLocationModal: action.payload.showLocationModal,
            };
        case INVENTORY_LOCATION_STORAGE.SELECT_STORAGE_TYPE:
            return {
                ...state,
                selectedStorageItemType: action.payload.selectedStorageItemType,
            };
        default:
            return state;
    }
};
export default InventoryLocationStorage;
