import React, {useState} from 'react';
import {ContainerFlex, Text, Image, Input} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import SearchImg from '@/images/search.svg';
import closed from '@/images/closeBlueIcone.svg';
import {
    TEXT_GENERAL,
    data_example,
} from '@/components/AuctionPass/AuctionSettings/Operational/constants';
import {CustomSelect} from '@components/AuctionPass/AuctionSettings/Operational/InputSelect';
import {Controller, useForm} from 'react-hook-form';
import {FilterForAuction} from '@/components/AuctionPass/Filters/Filters';

export const SearchFilterAuction = ({onSearch}: {onSearch: (searchTerm: string) => void}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [imageSrc, setImageSrc] = useState(SearchImg);
    const {control} = useForm({});
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        onSearch(value);
        setImageSrc(value ? closed : SearchImg);
    };
    const handleImageClick = () => {
        if (imageSrc === closed) {
            setSearchTerm('');
            setImageSrc(SearchImg);
            onSearch('');
        }
    };

    const openFilterModal = () => {
        setIsFilterModalOpen(true);
    };

    const closeFilterModal = () => {
        setIsFilterModalOpen(false);
    };

    return (
        <>
            <ContainerFlex>
                <ContainerFlex Justify="start" Gap="24px">
                    <ContainerFlex Width="14.375rem">
                        <Controller
                            name="options"
                            control={control}
                            render={({field}) => (
                                <CustomSelect
                                    {...field}
                                    placeholder={TEXT_GENERAL.SElECT}
                                    options={data_example}
                                    onChange={(value) => field.onChange(value)}
                                    value={field.value}
                                />
                            )}
                        />
                    </ContainerFlex>

                    <ContainerFlex
                        onClick={openFilterModal}
                        Cursor="pointer"
                        Width="130px"
                        Padding=" 8px 16px"
                        Radius="32px"
                        Border="1px solid #E8E9EA"
                    >
                        <Text Cursor="pointer">{TEXT_GENERAL.FILTER}</Text>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex Width="16.875rem">
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="calc(100% - 24px) 24px"
                        Margin="0 0 4px 0"
                        BoxShadowH=""
                        BoxShadowF=""
                        Padding="0 1rem 0 0"
                        BackG="#FFFFFF"
                        Radius="32px"
                        InputPadding="0 1rem"
                    >
                        <Input
                            type="text"
                            placeholder={TEXT_GENERAL.SEARCH}
                            value={searchTerm}
                            onChange={handleInputChange}
                        />
                        <Image
                            alt="icone-search"
                            Width="24px"
                            Height="24px"
                            src={imageSrc}
                            Cursor="pointer"
                            onClick={handleImageClick}
                        />
                    </SearchInput>
                </ContainerFlex>
                <FilterForAuction isOpen={isFilterModalOpen} closeModal={closeFilterModal} />
            </ContainerFlex>
        </>
    );
};
