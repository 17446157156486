import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import {IAccessPassProps} from '@/components/NewUserEmployee/Operational/interface';
import {PasswordAcces} from '@/components/NewUserEmployee/Operational/styles';
import {
    getPasswordRandom,
    resetRandomPassWord,
} from '@components/NewUserEmployee/Redux/Actions/GetPasswordRandoActions';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {ISelect} from '@components/Quoter/inferfaces';
import {PassWordInputs} from '@components/NewUserEmployee/PassWordsInputs';
import {getUpdatePeriods} from '@components/NewUserEmployee/Redux/Actions/GetUpdatePeriodsActions';

export const PassAcess: React.FC<IAccessPassProps> = ({
    control,
    formState: {errors},
    setValue,
    watch,
    reset,
    register,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const [periodOptions, setPeriodOptions] = useState<ISelect[]>([]);
    const passwordGenerated = useSelector(
        (state: RootState) => state.getRandomPasswordReducer.data
    );
    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const getFieldValue = (value: unknown): string => {
        if (typeof value === 'string') return value;
        return '';
    };

    const handleGeneratePassword = () => {
        if (token) {
            dispatch(getPasswordRandom(token));
        }
    };
    useEffect(() => {
        if (passwordGenerated) {
            setValue('password', passwordGenerated);
            setValue('repeatPassword', passwordGenerated);
        }
    }, [passwordGenerated]);
    useEffect(() => {
        if (token) {
            dispatch(getUpdatePeriods(token));
            dispatch(resetRandomPassWord());
            reset();
        }
    }, []);
    const periods = useSelector((state: RootState) => state.getUpdatePeriodsReducer.data);
    useEffect(() => {
        const periodsArray: ISelect[] = periods.map((period) => {
            return {value: period.id, label: period.name};
        });
        setPeriodOptions(periodsArray);
    }, [periods]);
    return (
        <ContainerFlex FlexDir="column" Height="" Gap="16px" Justify="start" Align="start">
            <ContainerFlex {...PasswordAcces}>
                <ContainerFlex FlexDir="column" Gap="16px">
                    <ContainerFlex Justify="start">
                        <Text FontSize="1.25rem" FontWeight="600" Color="#2A2C2F">
                            {TEXT_NEW_USER.ACCESS_TTILE}
                        </Text>
                    </ContainerFlex>
                    <PassWordInputs
                        periodOptions={periodOptions}
                        setPeriodOptions={setPeriodOptions}
                        getFieldValue={getFieldValue}
                        handleGeneratePassword={handleGeneratePassword}
                        register={register}
                        control={control}
                        errors={errors}
                        watch={watch}
                        viewPassword={viewPassword}
                        setViewPassword={setViewPassword}
                        setValue={setValue}
                        reset={reset}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
