import {COMMERCIAL_INVENTORY_FILTER_LABELS} from '@/components/InventoryManagement/constants';
import {
    CommercialFilterPayload,
    CommercialFilterState,
    CommercialInventoryFilter,
} from '@/components/InventoryManagement/interfaces';
import {
    CLEAR_COMMERCIAL_FILTER_BUTTONS,
    SET_COMMERCIAL_FILTER_BY_PRICE,
    SET_COMMERCIAL_FILTER,
} from '@/components/InventoryManagement/Redux/types';

const initialState: CommercialFilterState = {
    inventoryFilter: [],
    filterByPrice: {
        maxPrice: 0,
        minPrice: 0,
    },
};

export const commercialInventoryFilters = (
    state = initialState,
    action: CommercialFilterPayload
): CommercialFilterState => {
    switch (action.type) {
        case SET_COMMERCIAL_FILTER: {
            return {
                ...state,
                inventoryFilter: updateInventoryFilters(
                    state.inventoryFilter,
                    action.filter,
                    action.excludeLabels,
                    action.defaultValue
                ),
            };
        }
        case SET_COMMERCIAL_FILTER_BY_PRICE:
            return {...state, filterByPrice: action.price};
        case CLEAR_COMMERCIAL_FILTER_BUTTONS: {
            const newInventoryFilter = state.inventoryFilter.filter((value) => {
                return (
                    value.label !== COMMERCIAL_INVENTORY_FILTER_LABELS.PROMOTIONS &&
                    value.label !== COMMERCIAL_INVENTORY_FILTER_LABELS.NEWEST &&
                    value.label !== COMMERCIAL_INVENTORY_FILTER_LABELS.AGING
                );
            });
            return {...state, inventoryFilter: newInventoryFilter.slice()};
        }
        default:
            return state;
    }
};

const updateInventoryFilters = (
    inventoryFilter: CommercialInventoryFilter[],
    newFilter: CommercialInventoryFilter,
    excludeLabels: string[],
    defaulValue: number
) => {
    const newInventoryFilter = inventoryFilter.filter(
        (value) => ![...excludeLabels, newFilter.label].includes(value.label)
    );
    if (newFilter.value !== defaulValue) {
        newInventoryFilter.push(newFilter);
    }

    return newInventoryFilter;
};

export default commercialInventoryFilters;
