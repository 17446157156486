import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import DropdownWithIcon from '@components/GlobalQuoter/DropdownWithIcon';
import {
    FILTER_BOX,
    quantityFormOptions,
    QUOTE_DATA_LABELS,
    ARRAY_WITH_VALUES,
} from '@/components/GlobalQuoter/TempConstants';
import calendarFrequency from '@images/calendar_frequency.svg';
import calendarQuantity from '@images/calendar_quantity.svg';
import {QuantityInput} from '@components/GlobalQuoter/styles';
import {setVisualFormValues} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFormActions';
import {
    getQuoterCompaniesLoans,
    resetMediaLoanInfo,
} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';
import RadioButton from '@components/Home/style';
import unCheckedIcon from '@/images/unCheckedIcon.svg';
import checkedIcon from '@/images/checkedIcon.svg';
import {IFrequencyProps, IOption, IQuoterProps} from '@/components/GlobalQuoter/interface';
import QuoterHeader from '@/components/GlobalQuoter/QuoterHeader';
import {getFrequencyOptions} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFrequencyActions';
import {CreateAccount} from '@components/PublicGlobalQuoter/CreateAccount';

const QuoterForm: React.FC<IQuoterProps> = ({isExpressQuoter, isPublicQuoter}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {frequencyOptions} = useSelector(
        (state: RootState) => state.globalQuoterFrequencyReducer as IFrequencyProps
    );
    const visualState = useSelector((state: RootState) => state.globalQuoterFormReducer);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (isNaN(+inputValue.replace(/[^0-9]/g, ''))) return;
        dispatch(
            setVisualFormValues({
                ...visualState,
                visualAmount: inputValue,
                parsedAmount: parseFloat(inputValue.replace(/[^0-9]/g, '')),
                accountPayments: null,
                frequencyId: null,
            })
        );
        dispatch(resetMediaLoanInfo());
    };

    const handleChangefrecuency = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                frequencyId: newValue,
            })
        );
    };

    const handleChangePaymentNum = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                accountPayments: newValue,
            })
        );
    };

    const handleRadioButtonChange = (value: string) => {
        const newValue = quantityFormOptions.find((option) => option.value === Number(value));
        dispatch(
            setVisualFormValues({
                ...visualState,
                typeDropdown: newValue as IOption,
            })
        );
    };

    useEffect(() => {
        dispatch(getFrequencyOptions());
    }, []);

    useEffect(() => {
        const {parsedAmount, accountPayments, frequencyId} = visualState;
        const newToken = token ?? null;
        if (accountPayments && frequencyId && parsedAmount) {
            dispatch(getQuoterCompaniesLoans(newToken, parsedAmount, frequencyId, accountPayments));
        }
    }, [token, visualState.parsedAmount, visualState.accountPayments, visualState.frequencyId]);

    return (
        <ContainerFlex
            Width="100%"
            MinW={isExpressQuoter ? '324px' : '520px'}
            Gap="48px"
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
        >
            {!isExpressQuoter && (
                <QuoterHeader
                    title={QUOTE_DATA_LABELS.QUOTE_YOUR_LOAN}
                    subTitle={QUOTE_DATA_LABELS.SIMULATE_LOAN}
                    isExpressQuoter={false}
                    isPublicQuoter={isPublicQuoter}
                />
            )}
            <ContainerFlex
                FlexDir="column"
                Gap={isExpressQuoter ? '24px' : '32px'}
                Justify="flex-start"
                Align="flex-start"
            >
                <ContainerFlex FlexDir="column" Gap="4px" Justify="flex-start" Align="flex-start">
                    <Text FontSize="1rem" FontFamily="Nunito" Color="#2A2C2F" LetterSpacing="0.3px">
                        {QUOTE_DATA_LABELS.LOAN_AMOUNT}
                    </Text>
                    <ContainerFlex
                        Justify="flex-start"
                        Align="center"
                        Gap={isExpressQuoter ? '24px' : '8px'}
                        Bb={!isExpressQuoter && '1px solid #E8E9EA'}
                        FlexDir={isExpressQuoter && 'column'}
                    >
                        <ContainerFlex
                            Flex="1.5"
                            Bb={isExpressQuoter && '1px solid #E8E9EA'}
                            Justify="start"
                            Align="start"
                            Padding="12px 16px"
                        >
                            <QuantityInput
                                value={visualState.visualAmount}
                                onChange={handleChange}
                                prefix="$"
                                allowNegative={false}
                                decimalSeparator="."
                                thousandSeparator={true}
                            />
                        </ContainerFlex>
                        <ContainerFlex Gap="16px" Flex="2" Justify="start" Align="start">
                            {quantityFormOptions?.map((option, index) => (
                                <RadioButton
                                    key={index}
                                    value={String(option.value)}
                                    onChange={handleRadioButtonChange}
                                    labelText={option.label}
                                    externalSelectedValue={String(visualState.typeDropdown.value)}
                                    unCheckedIcon={unCheckedIcon}
                                    checkedIcon={checkedIcon}
                                    isDisabled={option.isDisabled}
                                />
                            ))}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="flex-start"
                    Align={isExpressQuoter ? 'Start' : 'center'}
                    Gap={isExpressQuoter ? '24px' : '16px'}
                    FlexDir={isExpressQuoter && 'column'}
                >
                    <DropdownWithIcon
                        label={FILTER_BOX.LABELS.PAYMENT_FREQ}
                        icon={calendarFrequency}
                        iconAlt={FILTER_BOX.ICONS.PAYMENT_FREQ}
                        options={frequencyOptions}
                        value={visualState.frequencyId}
                        name={FILTER_BOX.FORM_NAME.PAYMENT_FREQ}
                        onChange={handleChangefrecuency}
                        initialValue={
                            frequencyOptions?.find(
                                (frequency) => frequency.value === visualState.frequencyId
                            )?.label
                        }
                    />
                    <DropdownWithIcon
                        label={FILTER_BOX.LABELS.PAYMENT_NUM}
                        icon={calendarQuantity}
                        iconAlt={FILTER_BOX.ICONS.PAYMENT_NUM}
                        options={ARRAY_WITH_VALUES}
                        initialValue={ARRAY_WITH_VALUES[0].label}
                        value={visualState.accountPayments}
                        name={FILTER_BOX.FORM_NAME.PAYMENT_NUM}
                        onChange={handleChangePaymentNum}
                    />
                </ContainerFlex>
                {isPublicQuoter && <CreateAccount />}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default QuoterForm;
