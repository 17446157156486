import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';

const ProductAccesories: React.FC = () => {
    return (
        <>
            <ContainerFlex FlexDir="Column" Gap="1.5rem">
                <ContainerFlex Height="1.5rem">
                    <ContainerFlex Align="start" Justify="start" Width="70%">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1.25rem">
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_ACCESORIES}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" Justify="end" Width="30%">
                        <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="0.875rem">
                            {INVENTORY_PRODUCT_DETAIL.EDIT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="Column" Align="start" Justify="start">
                    <ContainerFlex Align="start" Justify="start" FlexWrap="wrap">
                        {INVENTORY_PRODUCT_DETAIL.PRODUCT_ACCESORIES_ITEM.map((item, index) => (
                            <Text
                                key={index}
                                BGColor="#F0F0FF"
                                FontWeight="700"
                                FontSize="0.875rem"
                                BorderRadius="2rem"
                                Padding="0.125rem 0.5rem"
                                MarginRight="0.5rem"
                                MarginTop="0.5rem"
                            >
                                <Text Color="#5A5AFF" FontWeight="700" FontSize="0.875rem">
                                    {item}
                                </Text>
                            </Text>
                        ))}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default ProductAccesories;
