import React from 'react';
import InventoryFilterButton from '@/components/InventoryManagement/InventoryFilterButton';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import itemIcon from '@images/ticketItem.svg';
import checkIcon from '@images/checkMarkDark.svg';
import {
    COMMERCIAL_INVENTORY_FILTER_LABELS,
    INVENTORY_COMMERCIAL,
} from '@/components/InventoryManagement/constants';
import {useDispatch} from 'react-redux';
import {
    clearCommercialFilterButtons,
    setCommercialFilterByAging,
    setCommercialFilterByNewest,
    setCommercialFilterByPromotions,
} from '@/components/InventoryManagement/Redux/Actions/commercialInventoryFilters';
import {CommercialInventoryFilter} from '@/components/InventoryManagement/interfaces';
import {changeCommercialInventoryPage} from '@/components/InventoryManagement/Redux/Actions/getCommercialInventory';

export default function CommercialInventoryFilterButtons({
    activeFilters,
}: {
    activeFilters: CommercialInventoryFilter[];
}) {
    const dispatch = useDispatch();
    const isSelectedPromotions = activeFilters.some(
        (filter) => filter.label === COMMERCIAL_INVENTORY_FILTER_LABELS.PROMOTIONS
    );
    const isSelectedAging = activeFilters.some(
        (filter) => filter.label === COMMERCIAL_INVENTORY_FILTER_LABELS.AGING
    );
    const isSelectedNewest = activeFilters.some(
        (filter) => filter.label === COMMERCIAL_INVENTORY_FILTER_LABELS.NEWEST
    );

    const setSelectedFilter = (filterBy: string) => {
        dispatch(changeCommercialInventoryPage(1));
        switch (filterBy) {
            case INVENTORY_COMMERCIAL.PROMOTIONS:
                dispatch(setCommercialFilterByPromotions());
                break;
            case INVENTORY_COMMERCIAL.NEWEST:
                dispatch(setCommercialFilterByNewest());
                break;
            case INVENTORY_COMMERCIAL.AGING:
                dispatch(setCommercialFilterByAging());
                break;
            default:
                dispatch(clearCommercialFilterButtons());
                break;
        }
    };

    return (
        <ContainerFlex Align="flex-start" Radius="0.5rem" Height="2.5rem">
            <InventoryFilterButton
                imageSrc={itemIcon}
                text={INVENTORY_COMMERCIAL.PROMOTIONS}
                radius="0.5rem 0 0 0.5rem"
                selected={isSelectedPromotions ? INVENTORY_COMMERCIAL.PROMOTIONS : ''}
                onSelect={setSelectedFilter}
            />
            <InventoryFilterButton
                imageSrc={checkIcon}
                text={INVENTORY_COMMERCIAL.NEWEST}
                selected={isSelectedNewest ? INVENTORY_COMMERCIAL.NEWEST : ''}
                onSelect={setSelectedFilter}
            />
            <InventoryFilterButton
                imageSrc={checkIcon}
                text={INVENTORY_COMMERCIAL.AGING}
                radius="0 0.5rem 0.5rem 0"
                selected={isSelectedAging ? INVENTORY_COMMERCIAL.AGING : ''}
                onSelect={setSelectedFilter}
            />
        </ContainerFlex>
    );
}
