import React from 'react';

import {ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import {InventoryFilterButtonProps} from '@components/InventoryManagement/interfaces';

const InventoryFilterButton = ({
    imageSrc,
    text,
    selected,
    onSelect,
    radius = '0',
}: InventoryFilterButtonProps) => {
    const isSelected = selected === text;
    const buttonBorder = `1px solid ${isSelected ? '#5A5AFF' : '#E8E9EA'}`;
    const background = isSelected ? '#F0F0FF' : '#FFF';

    const handleSelect = (selection: string) => {
        if (selected === selection) {
            onSelect('');
        } else {
            onSelect(selection);
        }
    };

    return (
        <ContainerFlex
            Gap="0.5rem"
            Padding="0.75rem 1rem"
            Justify="center"
            Radius={radius}
            backG={background}
            Border={buttonBorder}
            Cursor="pointer"
            onClick={() => handleSelect(text)}
        >
            <Image
                src={imageSrc}
                alt="filter-image"
                Width="1.5rem"
                Height="1.5rem"
                Cursor="inherit"
            />
            <Text
                FontSize="1rem"
                FontWeight="400"
                Color="#1D1E20"
                LetterSpacing="0.019rem"
                Cursor="inherit"
            >
                {text}
            </Text>
        </ContainerFlex>
    );
};

export default InventoryFilterButton;
