import styled from '@emotion/styled';

export const Dropdown = styled.div`
    position: relative;
    top: 0.25rem;
    background: #fff;
    border: 1px solid #e8e9ea;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 18.75rem;
    overflow-y: auto;
    padding-bottom: 0.5rem;
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const DropdownContainer = styled.div`
    position: relative;
    height: 0px;
    margin: 0;
    box-sizing: border-box;
`;

export const StatusPill = styled.div<{backgroundColor: string; border: string}>`
    border-radius: 2rem;
    padding: 0.125rem 0.5rem;
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) => props.border};
`;

export const LabelStyle = {
    FontSize: '1rem',
    FontWeight: '700',
    Color: '#1D1E20',
    LetterSpacing: '0.019rem',
};

export const renewalMenuStyles = {
    Padding: '0.5rem',
    Width: '100%',
    Color: '#2A2C2F',
    Align: 'center',
    FontSize: '1rem',
    FontWeight: '400',
    LetterSpacing: '0.019rem',
    Cursor: 'pointer',
    BorderL: '2px solid transparent',
    HColor: '#5A5AFF',
    HBackColor: '#F0F0FF',
    HBorderL: '2px solid #5A5AFF',
    letterSpacing: '0.019rem',
};

export const cardButtonStyle = {
    color: '#5A5AFF',
    backGround: '#FFFFFF',
    hText: '#5A5AFF',
    cursor: 'pointer',
    border: '1px solid #5A5AFF',
    hBackG: '#E5E5FF',
    width: '100%',
    height: '2rem',
};

export const cardButtonTextStyle = {
    Color: '#5A5AFF',
    FontWeight: '700',
    Cursor: 'pointer',
    FontSize: '1rem',
};

export const CheckboxWrapper = styled.div`
    background-color: '#FFFFFF';
    border-radius: 0.375rem;
`;
