import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {VALIDE_CODE} from '@/components/SignUp/constants';

export function TextModule() {
    const email = useSelector((state: RootState) => state.addEmailSigUp.email);
    return (
        <ContainerFlex Gap="8px" Height="auto" FlexDir="column">
            <Text
                FontFamily="Nunito"
                Padding="0 10px"
                FontSize="1.5rem"
                FontWeight="700"
                Color="#1D1E20"
            >
                {VALIDE_CODE.TITLE}
            </Text>
            <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                {VALIDE_CODE.MESSAGE}
            </Text>
            <Text FontFamily="Nunito" FontSize="1rem" FontWeight="400" Color="#2A2C2F">
                {VALIDE_CODE.EMAIL}{' '}
                <Text FontFamily="Nunito" MarginLeft="10px" FontWeight="bold">
                    {email}
                </Text>
            </Text>
        </ContainerFlex>
    );
}
