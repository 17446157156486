import {
    ConservationStateActionError,
    ConservationStateActionSuccess,
} from '@/components/InventoryManagement/interfaces';
import {
    GET_CONSERVATION_STATES,
    GET_CONSERVATION_STATES_ERROR,
    GET_CONSERVATION_STATES_SUCCESS,
} from '@/components/InventoryManagement/Redux/types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getConservationStates = () => ({
    type: GET_CONSERVATION_STATES,
});
export const getConservationStatesSuccess = (
    response: AxiosResponse
): ConservationStateActionSuccess => {
    return {
        type: GET_CONSERVATION_STATES_SUCCESS,
        response,
    };
};
export const getConservationStatesError = (error: AxiosError): ConservationStateActionError => ({
    type: GET_CONSERVATION_STATES_ERROR,
    error,
});

export function fetchConservationStates(token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getConservationStates());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CatStates}`,
                {headers}
            );
            dispatch(getConservationStatesSuccess(response.data));
        } catch (error) {
            if (error instanceof AxiosError) {
                dispatch(getConservationStatesError(error));
            }
        }
    };
}
