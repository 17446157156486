import {
    GET_CONDITION_STATUS_ARRAY_START,
    GET_CONDITION_STATUS_ARRAY_SUCCESS,
    GET_CONDITION_STATUS_ARRAY_ERROR,
} from '@/components/PreApproval/Redux/Types';
import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';
import {IStatus} from '@/components/PreApproval/interfaces';
import {URL} from '@/config/constants';

export const getCOnditonStatusArrayStart = () => {
    return {
        type: GET_CONDITION_STATUS_ARRAY_START,
    };
};

export const getConditionStatusArraySuccess = (payload: AxiosResponse<{data: IStatus[]}>) => {
    return {
        type: GET_CONDITION_STATUS_ARRAY_SUCCESS,
        payload,
    };
};

export const getConditionStatusArrayError = () => {
    return {
        type: GET_CONDITION_STATUS_ARRAY_ERROR,
    };
};

export const getConditionStatusArray = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCOnditonStatusArrayStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllStatusConditionCredit}`,
                {headers}
            );
            dispatch(getConditionStatusArraySuccess(response));
        } catch (error) {
            dispatch(getConditionStatusArrayError());
        }
    };
};
