import React from 'react';
import {ICompanyInformation} from '@/components/CreditQuoter/interfaces';
import {ContainerFlex, Text} from '@/components//Shopify/Ecommerce/styles';

const CompanyAvatarDefault: React.FC<ICompanyInformation> = ({
    companyName,
    Width,
    Height,
    color,
    Radius,
    Margin,
}) => {
    const firstLetter = companyName.charAt(0).toUpperCase();
    return (
        <ContainerFlex
            Width={Width ?? '52px'}
            MinW={Width ?? '52px'}
            Height={Height ?? '52px'}
            Radius={Radius ?? '50%'}
            Border="1px solid #E8E9EA"
            backG={color}
            Margin={Margin ?? ''}
        >
            <Text FontFamily="Nunito" FontWeight="700" FontSize="1.50rem">
                {firstLetter}
            </Text>
        </ContainerFlex>
    );
};

export default CompanyAvatarDefault;
