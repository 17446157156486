import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {RootState} from '@/config/store';
import {InventoryProductItem} from '@components/InventoryManagement/Redux/interfaces';
import useToast from '@/hooks/useToast';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {InventoryProductCardsProps} from '@components/InventoryManagement/interfaces';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';
import ProductCard from '@components/InventoryManagement/ProductCard';

const InventoryProductCards: React.FC<InventoryProductCardsProps> = ({onPageChange}) => {
    const navigate = useNavigate();
    const {ToastContainer} = useToast();
    const [openOption, setOpenOption] = useState<InventoryProductItem | null>(null);
    const {inventoryData} = useSelector((state: RootState) => ({
        inventoryData: state.GetProductsForInventory.data,
    }));
    const inventoryProducts = inventoryData?.data ?? {items: []};
    const goToDetail = (id: number) => {
        navigate(`${id}/ProductDetail`);
    };
    const handlePageChange = (newPage: number) => {
        if (onPageChange) onPageChange(newPage);
    };

    const handleOptionClick = (item: InventoryProductItem) =>
        setOpenOption((prevItem) => (prevItem === item ? null : item));

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Gap="1rem"
            backG="#FAFAFA"
            Radius="1rem"
            FlexWrap="wrap"
        >
            {inventoryProducts?.items?.length > 0 &&
                inventoryProducts.items.map((item, index) => {
                    return (
                        <ProductCard
                            key={index}
                            product={item}
                            inventoryProducts={inventoryProducts}
                            openOption={openOption}
                            handleOptionClick={handleOptionClick}
                            goToDetail={goToDetail}
                        />
                    );
                })}
            {inventoryProducts?.items?.length > 5 &&
                inventoryData?.totalItems >= inventoryData?.pageSize && (
                    <ContainerFlex Gap="0.5rem" Height="2.5rem">
                        <Pagination
                            pageNumber={inventoryData.pageNumber ?? 0}
                            totalPages={inventoryData.totalPages ?? 1}
                            totalItems={inventoryData.totalItems ?? 0}
                            pageSize={inventoryData.pageSize ?? 0}
                            onPageChange={
                                inventoryData.totalItems > 0 ? handlePageChange : () => {}
                            }
                        />
                    </ContainerFlex>
                )}
            <ToastContainer />
        </ContainerFlex>
    );
};
export default InventoryProductCards;
