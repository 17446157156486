import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    GetCompanyLevelsByCompanyId,
    resetGroups,
} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {INIT_LEVEL, LOCATIONGROUP} from '@components/LocationsBranch/constants';
import {SelectLocations} from '@components/LocationsBranch/SelectLocations';
import Locations from '@components/LocationsBranch/Locations';
import {resetSchedule} from '@components/LocationsBranch/Redux/Action/GetSchedule';
import {resetAddedUsers} from '@components/LocationsBranch/Redux/Action/AddUser';
import {resetAddress} from '@components/LocationsBranch/Redux/Action/AddAddressLocation';
import {resetStorage} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {resetCatAddress} from '@components/LocationsBranch/Redux/Action/CatAddress';
import {resetUsersByLevel} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import {resetBranchByLevel} from '@components/LocationsBranch/Redux/Action/GetBranchByLevel';
import {resetNewStorage} from '@components/LocationsBranch/Redux/Action/NewStorage';
import {resetGroup} from '@/components/LocationsBranch/Redux/Action/GetGroupsByCompanyId';

export const LocationBranch = () => {
    const [showLocationsModal, setShowLocationsModal] = useState(false);
    const [levelId, setLevelId] = useState<number>(0);
    const dispatch = useDispatch();
    const {companyId, token} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token,
        companyId: state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId,
    }));

    const onClickNewButton = (id: number) => {
        setLevelId(id);
        setShowLocationsModal(true);
    };

    useEffect(() => {
        if (token && companyId) {
            dispatch(resetGroups());
            dispatch(GetCompanyLevelsByCompanyId(companyId, token, INIT_LEVEL));
        }
    }, [dispatch, companyId, token]);

    useEffect(() => {
        dispatch(resetAddedUsers());
        dispatch(resetSchedule());
        dispatch(resetAddress());
        dispatch(resetStorage());
        dispatch(resetCatAddress());
        dispatch(resetUsersByLevel());
        dispatch(resetBranchByLevel());
        dispatch(resetNewStorage());
        dispatch(resetGroup());
    }, []);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1rem"
            Padding="1rem"
            FlexBasis="min-content"
        >
            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                {LOCATIONGROUP.TITLE}
            </Text>
            <Locations onClickNewButton={(id: number) => onClickNewButton(id)} />
            {showLocationsModal && levelId && (
                <SelectLocations
                    showLocationsModal={showLocationsModal}
                    setShowLocationsModal={setShowLocationsModal}
                    levelId={levelId}
                />
            )}
        </ContainerFlex>
    );
};
