import React from 'react';
import {dummyPagination, dummyProducts} from '@/components/LocateProducts/constants';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';
import LocateProductsTable from '@/components/LocateProducts/LocateProductsTable';
import {TableWrapper} from '@/components/LocateProducts/styles';

export default function ProductsContainer() {
    const handlePageChange = (page: number) => {
        return; //TODO: implement when service integration
    };
    return (
        <TableWrapper>
            <LocateProductsTable products={dummyProducts} />
            <Pagination
                onPageChange={handlePageChange}
                pageNumber={dummyPagination.pageNumber}
                pageSize={dummyPagination.pageSize}
                totalPages={dummyPagination.totalPages}
                totalItems={dummyPagination.totalItems}
            />
        </TableWrapper>
    );
}
