import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IActionMenuOption, ILocationsGroupsItem} from '@components/LocationsBranch/interfaces';
import {setSelectedOptionList} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {GetCompanyLevelsByCompanyId} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {ActionMenuOption} from '@components/ActionMenu/ActionMenuOption';
import EditIcon from '@images/editIcon.svg';
import {TYPE_LOCATION} from '@/components/LocationsBranch/constants';
import {fetchSelectTypeGroup} from '@components/LocationsBranch/Redux/Action/CreateNewGroup';
import {LAST_LEVEL_BY_COMPANY} from '@components/LocationsBranch/Redux/types';
import {routers} from '@/appRoute';
import {setLevelId} from '@components/LocationsBranch/Redux/Action/GelLevels';

export const LocationsGroupsItem = ({
    selectedGroup,
    internalId,
    inheritType,
    isMenuOpen,
    handleMenuClick,
}: ILocationsGroupsItem) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {companyId, token} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token,
        companyId: state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId,
    }));

    const onClickGroupLevel = (levelId: number) => {
        if (companyId && token) {
            dispatch(setSelectedOptionList(null));
            dispatch(
                GetCompanyLevelsByCompanyId(
                    companyId,
                    token,
                    levelId,
                    {inheritType, groupNumber: internalId, addLevelGroup: true},
                    internalId,
                    levelId
                )
            );
        }
    };

    const goToEditGroup = async () => {
        const json = {
            creationDate: new Date().toString(),
            description: TYPE_LOCATION.GROUP.NAME,
            locationId: TYPE_LOCATION.GROUP.TYPE,
            locationName: TYPE_LOCATION.GROUP.NAME,
            status: TYPE_LOCATION.GROUP.STATUS,
        };
        dispatch(fetchSelectTypeGroup(json));
        const {type: actionType} = dispatch(setLevelId(selectedGroup.levelId));
        if (actionType === LAST_LEVEL_BY_COMPANY.ID_SET_LEVEL)
            navigate(
                routers.EditLocation.replace(':locationName', TYPE_LOCATION.GROUP.NAME).replace(
                    ':id',
                    String(selectedGroup.levelId)
                )
            );
    };

    const menuOptions: IActionMenuOption[] = [
        {
            id: `option-menu-${internalId}`,
            name: 'Editar',
            action: goToEditGroup,
            textColor: '#2A2C2F',
            icon: EditIcon,
        },
    ];

    return (
        selectedGroup && (
            <ContainerFlex
                Justify="start"
                Padding="0 0.5rem 0 0.5rem"
                FlexDir="column"
                Height="3.375rem"
                Cursor="pointer"
            >
                <ContainerFlex
                    Cursor="inherit"
                    Justify="start"
                    Align="start"
                    HBackground="#f0f0ff"
                    HBorderLeft="0.125rem solid #5A5AFF"
                    Bl={selectedGroup.selected ? '0.125rem solid #5A5AFF' : 'none'}
                    backG={selectedGroup.selected ? '#f0f0ff' : '#fff'}
                    Padding="0.5rem"
                    onClick={() => onClickGroupLevel(selectedGroup.levelId)}
                >
                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Cursor="inherit">
                        <Text FontSize="1rem" Color="#2A2C2F" FontWeight="400" Cursor="inherit">
                            {selectedGroup.levelName}
                        </Text>
                        <Text Color="#71767D" FontSize="0.75rem" Cursor="inherit">
                            {selectedGroup.countLevels}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="end"
                        Align="center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <ActionMenuOption
                            id={selectedGroup.levelId}
                            menuOptions={menuOptions}
                            isOpen={isMenuOpen}
                            onOptionClick={() => handleMenuClick(selectedGroup)}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        )
    );
};
