import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_VALIDATION} from '@components/PersonalLoans/CreditStatus/constants';
import {ITimerStatusProps} from '@components/PersonalLoans/CreditStatus/interfaces';

export const TimerStatus = ({timeResponse, alignTitle}: ITimerStatusProps) => {
    const timeToSeconds = (timeString: string) => {
        const [hours, minutes, seconds] = timeString.split(':').map((digit) => Number(digit));
        return hours * 3600 + minutes * 60 + seconds;
    };
    const [time, setTime] = useState<number>(timeToSeconds(timeResponse));
    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const getTime = (timeInSeconds: number) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return {
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        };
    };
    const {hours, minutes, seconds} = getTime(time);

    useEffect(() => {
        setTime(timeToSeconds(timeResponse));
    }, [timeResponse]);

    return (
        <>
            <ContainerFlex
                backG="#F0F0FF"
                FlexDir="column"
                Padding="1rem"
                Radius="1rem"
                Width="386px"
                Gap="0.125rem"
            >
                <Text Color="#1D1E20" FontWeight="700" Height="100%" Self={alignTitle}>
                    {CREDIT_VALIDATION.EMAIL.GET_ANSWER}
                </Text>
                <ContainerFlex>
                    <ContainerFlex Width="92px" Bb="2px solid #FFF" MarginR="0.75rem">
                        <Text
                            Color="#0D166B"
                            FontSize="4rem"
                            FontWeight="700"
                            LetterSpacing="0.5rem"
                        >
                            {hours}
                        </Text>
                    </ContainerFlex>
                    <Text
                        Color="#0D166B"
                        FontSize="4rem"
                        FontWeight="700"
                        Align="center"
                        Width="15px"
                        MarginRight="0.75rem"
                        MarginB="0.875rem"
                    >
                        {':'}
                    </Text>
                    <ContainerFlex Width="92px" Bb="2px solid #FFF" MarginR="0.75rem">
                        <Text
                            Color="#0D166B"
                            Width="92px"
                            FontSize="4rem"
                            FontWeight="700"
                            LetterSpacing="0.5rem"
                        >
                            {minutes}
                        </Text>
                    </ContainerFlex>
                    <Text
                        Color="#0D166B"
                        FontSize="4rem"
                        FontWeight="700"
                        Align="center"
                        Width="15px"
                        MarginRight="0.75rem"
                        MarginB="0.875rem"
                    >
                        {':'}
                    </Text>
                    <ContainerFlex Width="92px" Bb="2px solid #FFF">
                        <Text
                            Color="#0D166B"
                            Width="92px"
                            FontSize="4rem"
                            FontWeight="700"
                            LetterSpacing="0.5rem"
                        >
                            {seconds}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Gap="0.938rem" Justify="space-between">
                    <Text Color="#1D1E20" Width="92px" Justify="center">
                        {CREDIT_VALIDATION.COUNTER.HOURS}
                    </Text>
                    <Text Color="#1D1E20" Width="92px" Justify="center">
                        {CREDIT_VALIDATION.COUNTER.MINUTES}
                    </Text>
                    <Text Color="#1D1E20" Width="92px" Justify="center">
                        {CREDIT_VALIDATION.COUNTER.SECONDS}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
