import axios from 'axios';

import {isPrimitiveString} from '@/hooks/constants';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';
import {GET_INVENTORY_PRODUCTS} from '@components/InventoryManagement/Redux/types';
import {
    InventoryProductsResult,
    InventoryProductsFilter,
} from '@components/InventoryManagement/Redux/interfaces';
import {GET_INVENTORY_ERRORS} from '@components/InventoryManagement/constants';

const getProductsForInventoryStart = () => ({
    type: GET_INVENTORY_PRODUCTS.START,
});

const getProductsForInventorySuccess = (result: InventoryProductsResult) => ({
    type: GET_INVENTORY_PRODUCTS.SUCCESS,
    payload: result,
});

export const getProductsForInventoryError = (errorMessage: string | null) => ({
    type: GET_INVENTORY_PRODUCTS.ERROR,
    errorMessage,
});

export const setActualPage = (pageNumber: number) => ({
    type: GET_INVENTORY_PRODUCTS.ACTUAL_PAGE,
    payload: pageNumber,
});

export const GetProdutsForInventoryPledge = (
    searchId: number,
    catTypeLocationId: number,
    pageNumber: number,
    pageSize: number,
    token: string,
    filters?: InventoryProductsFilter | null,
    signal?: AbortSignal
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const payload = {
        searchId,
        catTypeLocationId,
        pageNumber,
        pageSize,
        ...(filters && {filterInventory: filters}),
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getProductsForInventoryStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetProductsForInventoryPledge}`,
                payload,
                {headers, signal}
            );
            const result: InventoryProductsResult = response.data;
            dispatch(getProductsForInventorySuccess(result));
        } catch (error) {
            let errorMessage = GET_INVENTORY_ERRORS.GENERIC_ERROR;
            if (axios.isAxiosError(error)) {
                const message = isPrimitiveString(error.response?.data)
                    ? String(error.response?.data)
                    : '';
                errorMessage = message || GET_INVENTORY_ERRORS.PRODUCTS_ERROR;
            }
            dispatch(getProductsForInventoryError(errorMessage));
        }
    };
};
