export const TOGGLE_FILTER_MODAL = 'TOGGLE_FILTER_MODAL';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const SET_VISUAL_FILTER_VALUES = 'SET_VISUAL_FILTER_VALUES';
export const CLEAR_FILTERS_VALUES = 'CLEAR_FILTERS_VALUES';

export const GET_LOAN_LIST_START = 'GET_LOAN_LIST_START';
export const GET_LOAN_LIST_SUCCESS = 'GET_LOAN_LIST_SUCCESS';
export const GET_LOAN_LIST_ERROR = 'GET_LOAN_LIST_ERROR';

export const RESET_MEDIA_LOAN_INFO = 'RESET_MEDIA_LOAN_INFO';

export const SET_VISUAL_FORM_VALUES = 'SET_VISUAL_FORM_VALUES';
export const SET_FILTER_LIST = 'SET_FILTER_LIST';
export const RESET_VISUAL_FORM_VALUES = 'RESET_VISUAL_FORM_VALUES';

export const GET_FREQUENCY_OPTIONS_START = 'GET_FREQUENCY_OPTIONS_START';
export const GET_FREQUENCY_OPTIONS_SUCCESS = 'GET_FREQUENCY_OPTIONS_SUCCESS';
export const GET_FREQUENCY_OPTIONS_ERROR = 'GET_FREQUENCY_OPTIONS_ERROR';
