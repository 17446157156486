import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {AuctionSettings} from '@/components/AuctionPass/AuctionSettings/AuctionSetting';
import {SearchFilterAuction} from '@/components/AuctionPass/AuctionSettings/Search';
import {TEXT_GENERAL} from '@/components/AuctionPass/AuctionSettings/Operational/constants';

const handleSearch = (searchTerm: string) => {};

export const AuctionSettingsComponet = () => {
    return (
        <ContainerFlex FlexDir="column" Gap="16px" Height="max-contet">
            <ContainerFlex Justify="start">
                <Text FontWeight="700" Color="#54575C" FontSize="1.5rem">
                    {TEXT_GENERAL.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Align="end">
                <SearchFilterAuction onSearch={handleSearch} />
            </ContainerFlex>
            <AuctionSettings />
        </ContainerFlex>
    );
};
