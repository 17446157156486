import {routers} from '@/appRoute';

export const HEADERS_CLIENT_HOME = {
    LOG_BUTTON: 'Ingresa',
    HELLO: 'Hola, ',
    ACCOUNT: 'Tu cuenta',
};
export const ENDORSEMENT = 'Préstamos y empeños';
export const PAYMENT = 'Haz tus pagos';

export const TITLES = {
    header: {
        title: 'Préstamos y empeños',
        link: 'Haz tus pagos',
    },
    mainBanner: {
        title: 'Es más que un telefono',
        subtitle: 'Llévatelo de la manera más fácil a un precio único.',
        cta: 'Cotiza ya tu crédito',
    },
    cards_one: {
        question: '¿Necesitas dinero?',
        description: 'Solicita un préstamo, empeño o ambos',
        cta: 'Cotiza ahora',
        icon: '24',
        ctaLink: routers.GlobalQuoter,
    },
    cards_two: {
        question: '¿Quieres pagar tu préstamo?',
        description: 'Haz tus pagos sin iniciar sesión',
        cta: 'Haz tus pagos',
        icon: '24',
    },
    cards_three: {
        question: '¿Quieres saber cuanto debes?',
        description: 'Inicia sesión para ver tus prestamos',
        cta: 'Inicia sesión',
        icon: '24',
    },
};
