import axios from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {isPrimitiveString} from '@/hooks/constants';
import {GET_CONTRACTS_INVETORY} from '@components/InventoryManagement/Redux/types';
import {
    InventoryProductsResult,
    InventoryProductsFilter,
} from '@components/InventoryManagement/Redux/interfaces';
import {GET_INVENTORY_ERRORS} from '@components/InventoryManagement/constants';

const getContractsForInventoryStart = () => ({
    type: GET_CONTRACTS_INVETORY.START,
});

const getContractsForInventorySuccess = (result: InventoryProductsResult) => ({
    type: GET_CONTRACTS_INVETORY.SUCCESS,
    payload: result,
});

const getContractsForInventoryError = (errorMessage: string | null) => ({
    type: GET_CONTRACTS_INVETORY.ERROR,
    errorMessage,
});

export const GetContractsForInventoryPledge = (
    searchId: number,
    catTypeLocationId: number,
    pageNumber: number,
    pageSize: number,
    token: string,
    filters?: InventoryProductsFilter | null,
    signal?: AbortSignal
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const payload = {
        searchId,
        catTypeLocationId,
        pageNumber,
        pageSize,
        ...(filters && {filterInventoryByContracts: filters}),
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getContractsForInventoryStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContractsForInventoryPledge}`,
                payload,
                {headers, signal}
            );
            const result: InventoryProductsResult = response.data;
            dispatch(getContractsForInventorySuccess(result));
        } catch (error) {
            let errorMessage = GET_INVENTORY_ERRORS.GENERIC_ERROR;
            if (axios.isAxiosError(error)) {
                const message = isPrimitiveString(error.response?.data)
                    ? String(error.response?.data)
                    : '';
                errorMessage = message || GET_INVENTORY_ERRORS.PRODUCTS_ERROR;
            }
            dispatch(getContractsForInventoryError(errorMessage));
        }
    };
};
