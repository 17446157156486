import React from 'react';
import IconCheck from '@images/check.svg';
import IconIndeterminate from '@images/IndeterminateCheck.svg';
import {Image} from '@Shopify/Ecommerce/styles';
import styled from '@emotion/styled';

interface CheckboxScoreProps {
    checked: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledCheckboxWrapper = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
`;

const StyledCheckbox = styled.input`
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #5a5aff;
    border-radius: 6px;
    cursor: pointer;
    display: grid;
    place-content: center;
    background-color: transparent;

    &:disabled {
        cursor: not-allowed;
        border-color: #a8a8a8;
    }

    &:checked {
        background-color: transparent; /* Remove the background color to let the icon be visible */
    }

    &:hover:not(:disabled) {
        border-color: #5a5aff;
    }
`;

const CheckboxIconWrapper = styled.div`
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckboxScore = (props: CheckboxScoreProps) => {
    const {checked, indeterminate = false, disabled = false, onChange} = props;

    return (
        <StyledCheckboxWrapper>
            <StyledCheckbox
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <CheckboxIconWrapper>
                {indeterminate && !checked && !disabled ? (
                    <Image src={IconIndeterminate} alt="Indeterminate" width="18px" height="18px" />
                ) : checked ? (
                    <Image src={IconCheck} alt="Checked" width="18px" height="18px" />
                ) : null}
            </CheckboxIconWrapper>
        </StyledCheckboxWrapper>
    );
};

export default CheckboxScore;
