import {IVisualGlobalQuoterForm} from '@components/GlobalQuoter/interface';
import {
    SET_VISUAL_FORM_VALUES,
    RESET_VISUAL_FORM_VALUES,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {DEFAULT_VALUES} from '@/components/GlobalQuoter/TempConstants';

const initialState: IVisualGlobalQuoterForm = {
    visualAmount: DEFAULT_VALUES.VISUAL_AMOUNT,
    parsedAmount: DEFAULT_VALUES.PARSED_AMOUNT,
    frequencyId: DEFAULT_VALUES.FREQUENCY_ID,
    accountPayments: DEFAULT_VALUES.ACCOUNT_PAYMENTS,
    typeDropdown: DEFAULT_VALUES.TYPE_DROPDOWN,
};
const globalQuoterFormReducer = (
    state = initialState,
    action: {
        payload: IVisualGlobalQuoterForm;
        type: string;
    }
) => {
    switch (action.type) {
        case SET_VISUAL_FORM_VALUES:
            return {
                visualAmount: action.payload.visualAmount,
                parsedAmount: action.payload.parsedAmount,
                frequencyId: action.payload.frequencyId,
                accountPayments: action.payload.accountPayments,
                typeDropdown: action.payload.typeDropdown,
            };
        case RESET_VISUAL_FORM_VALUES:
            return initialState;
        default:
            return state;
    }
};

export default globalQuoterFormReducer;
