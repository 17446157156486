import React from 'react';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import globalQuoterBanner from '@images/global_quoter_banner.svg';
import Quoter from '@components/GlobalQuoter/Quoter';
import LoanList from '@components/GlobalQuoter/LoanList';
import BannerQuoter from '@components/CreditQuoter/BannerQuoter';
import {OfferCreditsBanner} from '@components/PublicGlobalQuoter/OfferCreditsBanner';

export const PublicGlobalQuoter = () => {
    return (
        <>
            <ContainerFlex
                FlexDir="column"
                Justify="flex-start"
                Align="flex-start"
                Position="relative"
            >
                <ContainerFlex
                    backG={`url(${globalQuoterBanner}) no-repeat`}
                    BackgroundSize="cover"
                    Justify="center"
                    Align="center"
                    FlexDir="column"
                    Gap="24px"
                    Padding="32px 60px 24px 60px"
                    MaxH="695px"
                >
                    <Quoter isExpressQuoter={false} isPublicQuoter={true} />
                </ContainerFlex>
                <LoanList isPublicQuoter={true} />
                <OfferCreditsBanner />
                <BannerQuoter />
            </ContainerFlex>
        </>
    );
};
