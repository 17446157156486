import badgeIcon from '@/components/CreditQuoter/icons/badgeIcon.svg';
import idCardIcon from '@/components/CreditQuoter/icons/idCardIcon.svg';
import receiptIcon from '@/components/CreditQuoter/icons/receiptIcon.svg';
import arrowUp from '@/images/arrowUpIcon.svg';
import arrowDown from '@/images/arrowDownIcon.svg';
import {ILoanQuoter} from '@/components/CreditQuoter/interfaces';
export const CREDIT_QUOTER = {
    TITLE: 'Cotiza tu crédito',
    GO_BACK_TO_RESULTS: 'Regresar a resultados',
    VISIT_BRANCH: 'Visitar tienda',
    BTN_REQUEST_NOW: 'Solicitar ahora',
    BTN_SEE_TABLE: 'Ver tabla de amortización',
    FIELDS: {
        AMOUNT: '¿Cuánto dinero necesitas?',
        FREQUENCY: 'Frecuencia de pagos',
        PERIODS: 'Número de pagos',
    },
    QUOTE_ERROR: 'No existe un plazo disponible para este monto',
    AMORTIZATION_TABLE_CTA: 'Tabla de amortización',
    PAYMENTS_GENERATOR_TEXT: (value: string) => `Realizarás ${value} pagos de:`,
    ANUAL_FEE: 'Interés anual',
    RATE: 'Tasa',
    FREQUENCY: 'Frecuencia ',
    COMPANY: 'Empresa',
    RATING: 'Ver calificaciones',
    REQUIREMENTS: {
        TITLE: 'Requisitos',
        AGE_TITLE: 'Mayor de 18 años',
        AGE_SUBTITLE: 'Edades entre los ',
        AGE_EVELYN: ' - ',
        AGE_TIME: ' años',
        PAYMENT_METHOD_TITLE: 'Métodos de pago',
        PAYMENT_METHOD_SUBTITLE: 'Transferencia bancaria, tarjeta débito, tarjeta crédito,',
        DEPOSIT_TITLE: 'Depósito de dinero',
        DEPOSIT_SUBTITLE: 'En cuenta bancaria, efectivo o billetera Ataskate',
        SPACE: ', ',
        ONE: 1,
    },
    DETAILS: {
        TITLE: 'Detalle del préstamo',
        LOAN: 'Préstamos entre',
        LOAN_VAL: '$ ',
        LOAN_EVELYN: ' - ',
        RATE: 'Tasa de interés',
        RATE_VAL: '%',
        RANGE: 'Rango tiempo',
        RANGE_VAL: 'Entre',
        RANGE_AND: ' y ',
        RANGE_MONTHS: 'meses',
        RANGE_MONTH: 'mes',
        TYPE_OF_CREDIT: 'Tipo de crédito',
        TYPE_OF_CREDIT_VAL: 'Crédito personal',
        CURRENCY: 'Moneda',
        CURRENCY_VAL: 'MXN',
        COMMISSION: 'Comisiones',
        COMMISSION_VAL: '0%',
        METHOD: 'Proceso',
        METHOD_VAL: 'Online, en sucursal',
        REQUEST_NOW_CTA: 'Solicitar préstamo',
        ONE: 1,
        TWO: 2,
    },
    DOCUMENTS_DETAILS: {
        TITLE: '¿Qué necesito para solicitar mi préstamo?',
        ID: 'Documento de identidad',
        ID_ICON: idCardIcon,
        ID_VAL: 'NIE, pasaporte',
        INCOME: 'Comprobante de ingresos',
        INCOME_ICON: receiptIcon,
        INCOME_VAL: 'Boletas, comprobante bancario',
        ADDRESS: 'Comprobante de domicilio',
        ADDRESS_ICON: receiptIcon,
        ADDRESS_VAL: 'Recibo de la luz, contrato',
        MILITARY: 'Cartilla de Servicio Militar',
        MILITARY_ICON: badgeIcon,
        MILITARY_VAL: 'Copia de la cartilla',
    },
    CONDITIONS_DETAILS: {
        TITLE: 'Condiciones',
        EXPAND_ICON: arrowDown,
        COLLAPSE_ICON: arrowUp,
        TEXT: 'El crédito personal se encuentra sujeto a las siguientes condiciones y comisiones:',
        TEXT2: ' • Aprobación crediticia: La concesión del crédito está condicionada a la aprobación del historial crediticio del solicitante.',
        TEXT3: ' • Definición del Contrato de Préstamo: El contrato es un acuerdo entre el Prestamista y el Cliente, en el cual se otorga un monto con la obligación de pago según los términos acordados.',
        TEXT4: ' • Monto del Préstamo: El monto será el aprobado tras análisis crediticio, sujeto a modificación en función del riesgo.',
        TEXT5: ' • Forma de Pago: Los pagos deben realizarse según el calendario y métodos acordados, de lo contrario habrá penalidades si aplica.',
        TEXT6: ' • Requisitos adicionales: El solicitante debe presentar siempre documentos de identidad.-',
    },
};

export const AMORTIZATION_TABLE = {
    TITLE: 'Tabla de amortización',
    TABLE_HEADERS: {
        QUOTE_NUMBER: 'No.cuota',
        CAPITAL_PAYMENT: 'Pago a capital',
        INTEREST: 'Interés',
        AMOUNT_QUOTE: 'Pago de cuota',
        OUTSTANDING_BALANCE: 'Saldo',
    },
    QUOTE_DATA: {
        ANNUAL_INTEREST: 'Interés anual -',
        FREQUENCY: 'Frecuencia -',
        FIXED: 'Fija',
    },
};
export const DEFAULT_COMPANY_OFFER = {
    companyId: 0,
    companyName: '',
    companyLogo: '',
    amountMax: 0,
    countPayments: 0,
    frecuencyId: 0,
    frecuencyName: '0',
    anualRate: 0,
    isSponsored: false,
    labelPersonCounter: 0,
    startsCount: 0,
    disbursementsCompany: [],
    frequencyTermCreditId: 0,
    termPersonalLoanId: 0,
    companyAvatarColor: '#fefefe',
};

export const BANNER_QUOTER = {
    PAYMENT: 'Paga tus créditos',
    PAYMENT_TEXT: 'Paga refrendos, cuotas y más',
    PAYMENT_CTA: 'Paga ahora',
    NEED_MONEY: '¿Necesitas más dinero?',
    NEED_MONEY_TEXT: 'Solicita un préstamo, empeño o ambos',
    NEED_MONEY_CTA: 'Empeña un producto',
};

export const COMPANY_LOANS_QUOTER = {
    TITLE: 'Otros préstamos de la misma empresa',
    LOANS_QUOTER: {
        RATING: 3,
        AMOUNT: 'monto del préstamo',
        PAYMENTS: 'N.º de pagos',
        FREQUENCY: 'Frecuencia',
        RATE: 'Interés',
        CTA: 'Cotizar',
        PAYMENT_EVELYN: ' - ',
        ANUAL: '% anual',
    },
};

export const COMPANY_LOANS_QUOTER_MAP: ILoanQuoter[] = [
    {
        amount: '$ 3,599 - $10,000',
        payments: '6, 12',
        frequency: 'Semanal, quincenal',
        rate: '50% anual',
        rating: 3,
        brandIcon: '',
        companyName: 'Yo te presto',
        color: '#fefefe',
    },
    {
        amount: '$20,000 - $50,000',
        payments: '6, 12',
        frequency: 'Semanal, quincenal',
        rate: '50% anual',
        rating: 3,
        brandIcon: '',
        companyName: 'Yo te presto',
        color: '#fefefe',
    },
    {
        amount: '$ 3,599 - $10,000',
        payments: '6, 9, 12, 15, 18, 21, 24, 27, 30, ...',
        frequency: 'Semanal, quincenal, mensual, bimestral, trimestral, semestral',
        rate: '32% anual',
        rating: 3,
        brandIcon: '',
        companyName: 'Yo te presto',
        color: '#fefefe',
    },
];

export const COMPANY_AVATAR_DEFAULT_COLORS = [
    '#F0F0FF',
    '#FFE9E8',
    '#D0F6FF',
    '#FFE5FB',
    '#F9FFD6',
    '#D1FFD1',
];
