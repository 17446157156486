import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ProductSpecificationsItem from '@components/InventoryManagement/ProductSpecificationsItem';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';

const ProductSpecifications: React.FC = () => {
    return (
        <>
            <ContainerFlex FlexDir="Column" Gap="1.5rem">
                <ContainerFlex Height="1.5rem">
                    <ContainerFlex Align="start" Justify="start" Width="70%">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1.25rem">
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_SPECIFICATIONS}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" Justify="end" Width="30%">
                        <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="0.875rem">
                            {INVENTORY_PRODUCT_DETAIL.EDIT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="Column" Align="start" Justify="start">
                    {INVENTORY_PRODUCT_DETAIL.PRODUCT_SPECIFICATIONS_ITEMS.map((item, index) => (
                        <ProductSpecificationsItem
                            key={index}
                            title={item.title}
                            value={item.value}
                            lastItem={
                                index ===
                                INVENTORY_PRODUCT_DETAIL.PRODUCT_SPECIFICATIONS_ITEMS.length - 1
                            }
                        />
                    ))}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default ProductSpecifications;
