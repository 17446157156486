import React from 'react';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {BREAD_LOCATE_PRODUCTS, LOCATE_PRODUCTS_PAGE} from '@/components/LocateProducts/constants';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import LocateProductsFilters from '@/components/LocateProducts/LocateProductsFilters';
import ProductsContainer from '@/components/LocateProducts/ProductsContainer';
import InventoryLocation from '@components/InventoryManagement/InventoryLocation';

export default function LocateProducts() {
    return (
        <ContainerFlex
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem"
            Justify="start"
            Gap="1rem"
        >
            <Breadcrumb
                fontSize="0.75rem"
                fontWeight="700"
                color="#0D166B"
                items={BREAD_LOCATE_PRODUCTS}
            />
            <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="1rem">
                <Text FontSize="1.75rem" FontWeight="700" Color="#2A2C2F">
                    {LOCATE_PRODUCTS_PAGE.TITLE}
                </Text>
                <LocateProductsFilters />
                <ProductsContainer />
            </ContainerFlex>
            <InventoryLocation />
        </ContainerFlex>
    );
}
