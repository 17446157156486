import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import InventoryLocationStorage from '@components/InventoryManagement/InventoryLocationStorage';
import {InventoryLocationContainerProps} from '@components/InventoryManagement//interfaces';

const InventoryLocationContainer: React.FC<InventoryLocationContainerProps> = ({storage}) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Border="solid 0.063rem #F4F5F5"
            Radius="1rem"
            Gap="1rem"
        >
            <InventoryLocationStorage storage={storage} />
        </ContainerFlex>
    );
};
export default InventoryLocationContainer;
