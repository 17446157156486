import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import FilterBox from '@components/GlobalQuoter/FilterBox';
import LoanCardsListContainer from '@components/GlobalQuoter/LoanCardsListContainer';
import {TITLES} from '@/components/GlobalQuoter/TempConstants';
import FilterModal from '@components/GlobalQuoter/FilterModal/FilterModal';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ILoanListProps} from '@components/GlobalQuoter/interface';

const LoanList: React.FC<ILoanListProps> = ({isPublicQuoter}) => {
    const {isFilterModalOpen} = useSelector((state: RootState) => state.globalQuoterFilterRedcuer);
    useEffect(() => {
        if (isFilterModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isFilterModalOpen]);
    return (
        <>
            {isFilterModalOpen && <FilterModal />}
            <ContainerFlex
                FlexDir="column"
                Justify="flex-start"
                Align="flex-start"
                Padding="48px 60px"
                Gap="24px"
                Height="fit-content"
            >
                <Text FontSize="1.75rem" Color="#1D1E20" FontWeight="500" MinH="34px">
                    {TITLES.LOAN_LIST}
                </Text>
                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
                    <FilterBox />
                    <LoanCardsListContainer isPublicQuoter={isPublicQuoter} />
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};

export default LoanList;
