import React, {useState, useCallback, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    TableContainer,
    TableHeaderRow,
    TableBody,
    TableCell,
} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image} from '@EcommerceShopify/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    CONTRACT_INVENTORY_LIST,
    RESIZE,
    CONTRACT_SORT_IDENTIFIERS,
    Sorting,
    listMaxWidth,
} from '@components/InventoryManagement/constants';
import {InventoryContractsListProps} from '@components/InventoryManagement/interfaces';
import {Contract} from '@components/InventoryManagement/Redux/interfaces';
import InventoryContractListRow from '@components/InventoryManagement/InventoryContractListRow';
import ListColumnHeader from '@components/InventoryManagement/ListColumnHeader';
import sortIcon from '@images/ListSortArrows.svg';
import sortAscIcon from '@images/ListSortArrowsAsc.svg';
import sortDescIcon from '@images/ListSortArrowsDesc.svg';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';

const InventoryContractsList = ({onPageChange}: InventoryContractsListProps) => {
    const contractsData = useSelector((state: RootState) => {
        return state.GetContractsForInventory.data;
    });
    const inventoryContracts = contractsData?.data;
    const [openOption, setOpenOption] = useState<Contract | null>(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [inventoryData, setIventoryData] = useState(inventoryContracts.contracts);

    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState('');

    const [isSmallView, setIsSmallView] = useState(false);

    const handleResize = () => {
        setIsSmallView(window.innerWidth <= listMaxWidth);
    };

    useEffect(() => {
        window.addEventListener(RESIZE, handleResize);
        handleResize();

        return () => {
            window.removeEventListener(RESIZE, handleResize);
        };
    }, []);

    const handleSort = useCallback(
        (column: string) => {
            const newDirection =
                sortColumn === column && sortDirection === Sorting.Asc ? Sorting.Desc : Sorting.Asc;
            setSortColumn(column);
            setSortDirection(newDirection);
        },
        [sortColumn, sortDirection]
    );

    const renderColSortIcon = useCallback(
        (column: string) => {
            const isActive = sortColumn === column;
            const iconMap = {
                [Sorting.Asc]: sortAscIcon,
                [Sorting.Desc]: sortDescIcon,
            };
            const icon = isActive ? iconMap[sortDirection] : sortIcon;

            return (
                <Image
                    src={icon}
                    alt={`sort-${isActive ? sortDirection : ''}`}
                    Width="0.875rem"
                    Height="0.875rem"
                    Cursor="pointer"
                    onClick={() => handleSort(column)}
                />
            );
        },
        [sortColumn, sortDirection, handleSort]
    );

    const handleSelectAll = () => {
        setIsAllSelected((prevIsAllSelected) => {
            const newIsAllSelected = !prevIsAllSelected;
            return newIsAllSelected;
        });
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => ({
                ...item,
                selected: !isAllSelected,
            }));
            return newInventoryData;
        });
    };

    const handleItemSelect = (id: number) => {
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => {
                if (item.contractNumber === id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            });
            const allSelected = newInventoryData.every((item) => item.selected);
            setIsAllSelected(allSelected);
            return newInventoryData;
        });
    };

    const handlePageChange = (newPage: number) => {
        onPageChange(newPage);
    };

    const handleOptionClick = (item: Contract) =>
        setOpenOption((prevItem) => (prevItem === item ? null : item));

    useEffect(() => {
        if (inventoryData) {
            const allSelected = inventoryData.every((item) => item.selected);
            setIsAllSelected(allSelected);
        }
    }, [inventoryData]);

    return (
        <ContainerFlex
            FlexDir="column"
            Padding="1.5rem"
            Justify="start"
            Align="flex-start"
            Radius="1.5rem"
            Gap="1rem"
            backG="#FFF"
        >
            <TableContainer>
                <TableHeaderRow
                    borderBottom="1px solid #E8E9EA"
                    gridColumns="1.125rem 16.5rem repeat(5, minmax(5rem, 1fr)) 1.5rem"
                    gap="1rem"
                >
                    <TableCell className="mediaPersist">
                        <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
                    </TableCell>
                    {isSmallView ? null : (
                        <>
                            <ListColumnHeader label={CONTRACT_INVENTORY_LIST.CONTRACT} />
                            <ListColumnHeader
                                label={CONTRACT_INVENTORY_LIST.CLIENT}
                                renderSortIcon={() =>
                                    renderColSortIcon(CONTRACT_SORT_IDENTIFIERS.client)
                                }
                            />
                            <ListColumnHeader label={CONTRACT_INVENTORY_LIST.VALUE} />
                            <ListColumnHeader label={CONTRACT_INVENTORY_LIST.LOAN} />
                            <ListColumnHeader label={CONTRACT_INVENTORY_LIST.EXPIRYDATE} />
                            <ListColumnHeader label={CONTRACT_INVENTORY_LIST.CONTRACT_STATUS} />
                            <TableCell />
                        </>
                    )}
                </TableHeaderRow>
                <TableBody overflowY="scroll">
                    {inventoryData &&
                        inventoryData.length > 0 &&
                        inventoryData.map((inventory) => (
                            <InventoryContractListRow
                                key={inventory.contractNumber}
                                contract={inventory}
                                handleItemSelect={handleItemSelect}
                                handleItemMenuClick={handleOptionClick}
                                isMenuOpen={openOption === inventory}
                            />
                        ))}
                </TableBody>
            </TableContainer>
            {contractsData && contractsData.totalItems >= contractsData.pageSize && (
                <ContainerFlex Gap="0.5rem" Height="2.5rem">
                    <Pagination
                        pageNumber={contractsData.pageNumber ?? 0}
                        totalPages={contractsData.totalPages ?? 1}
                        totalItems={contractsData.totalItems ?? 0}
                        pageSize={contractsData.pageSize ?? 0}
                        onPageChange={contractsData.totalItems > 0 ? handlePageChange : () => {}}
                    />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default InventoryContractsList;
