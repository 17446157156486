import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {CommercialFeatureCardProps} from '@components/InventoryManagement/interfaces';

export default function CommercialCardFeature({key, alt, icon, title}: CommercialFeatureCardProps) {
    return (
        <ContainerFlex key={key} Align="start" Justify="start" Height="1.25rem">
            <Image src={icon} alt={alt} />
            <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                {title}
            </Text>
        </ContainerFlex>
    );
}
