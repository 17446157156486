import React, {useEffect, useState, useRef} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ContractContainer} from '@Quoter/ContractCointainer';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {searchgetPromotions} from '@Quoter/PromotionsRedux/Actions/GetPromotions';
import {IPromotions} from '@Quoter/PromotionsRedux/Redux/GetPromotions';
import {PromotionContainer} from '@Quoter/PromotionsContainer/PromotionsContainer';
import {IPromotionTerm} from '@Quoter/inferfaces';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';
import {cartContracts} from '@components/ShoppingCart/interface';
import {errorClient} from '@components/ShoppingCart/Redux/Actions/SearchClientList';
import {ErrorPromotion, ErrorPromotionDeadline} from '@Quoter/PromotionsContainer/PromotionsError';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {changeActualStep} from '@Quoter/Redux/Actions/saveEndeavors';
import {ErrorPromotions} from '@Quoter/PromotionsContainer/ErrorPromotions';
import useDebounce from '@/hooks/useDebounce';
import LoadingOverlay from '@/components/General/Atoms/LoadingOverlay/LoadingOverlay';
import {PutPromotionsStep} from '@Quoter/Redux/Actions/PutAddPromotions';

export const PromotionsStep = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const branch = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails
    );
    const Promotions: IPromotions = useSelector((state: RootState) => state.getPromotions);
    const [selectContract, setSelectContract] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const loadingPromotion = Promotions.loading;
    const [selectArticle, setSelectArticle] = useState([0]);
    const [loan, setLoan] = useState(0);
    const [value, setValue] = useState(0);
    const [errorPromotion, setErrorPromotion] = useState(false);
    const [errorClients, setErrorClient] = useState(false);

    const [promotion, setPromotion] = useState<{contractId: number; id: number}[]>([]);
    const {emptyClient, client}: IClientData = useSelector((state: RootState) => state.clientList);
    const contracts: cartContracts[] = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const [contractNames, setContractNames] = useState('');
    const abortControllerRef = useRef<AbortController | null>(null);
    const debouncedPage = useDebounce(currentPage, 300);

    const generatePawnsData = () => {
        const promotionMap = new Map<number, number>(promotion.map((p) => [p.contractId, p.id]));
        const pawns = contracts
            .filter((contract) => promotionMap.has(contract.contractId))
            .map((contract) => ({
                carShoppingId: contract.carShoppingId,
                contractId: contract.contractId,
                contractName: contract.contractName,
                promotionId: promotionMap.get(contract.contractId) || 0,
                pledges: contract.pledges.map((pledge) => ({
                    pledgeId: pledge.pledgeId,
                    articleId: pledge.articleId,
                    articleName: pledge.articleName,
                    articleValue: pledge.articleValue,
                    articleLoan: pledge.articleLoan,
                    articleSalePrice: pledge.articleSalePrice,
                })),
            }));

        return {pawns};
    };

    const OnSave = () => {
        if (token && !errorPromotion && !emptyClient && !errorClients) {
            const pawn = generatePawnsData();
            setErrorPromotion(false);
            dispatch(PutPromotionsStep(pawn, token, changeActualStep));
        }
        dispatch(changeActualStep(3));
    };
    const handlePromotionAssignment = (contractId: number, promotionId: number) => {
        const existingAssignmentIndex = promotion.findIndex(
            (assignment) => assignment.contractId === contractId
        );
        if (existingAssignmentIndex !== -1) {
            const updatedAssignments = [...promotion];
            updatedAssignments[existingAssignmentIndex].id = promotionId;
            setPromotion(updatedAssignments);
        } else {
            setPromotion((prevAssignments) => [...prevAssignments, {contractId, id: promotionId}]);
        }
    };

    const fetchPromotions = (page: number) => {
        if (!branch || !token) return;

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        const SendData = {
            branchId: branch?.[0]?.branchId,
            ArticlesId: selectArticle,
            loan,
            value,
            pageSize: 5 * page,
            pageNumber: 1,
        };

        dispatch(searchgetPromotions(token, SendData, abortController.signal));
    };

    useEffect(() => {
        if (!branch || !loan || !value || !token) return;
        fetchPromotions(debouncedPage);
    }, [debouncedPage, branch, selectArticle, loan, value, token]);

    const handleScroll = () => {
        const container = document.getElementById('promotions-container');
        if (!container) return;

        const {scrollTop, scrollHeight, clientHeight} = container;

        if (scrollHeight - scrollTop <= clientHeight + 50) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    useEffect(() => {
        const container = document.getElementById('promotions-container');
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const checkContratDeadline = () => {
        if (promotion.length !== contracts.length && contracts) {
            const contractsWithoutTerm = contracts.filter(
                (contract) =>
                    !promotion
                        .map((assignment) => assignment.contractId)
                        .includes(contract.contractId)
            );
            setContractNames(
                contractsWithoutTerm
                    .map((contract) => contract.contractName.toLowerCase())
                    .join(', ')
            );
            setErrorPromotion(true);
            if (contractsWithoutTerm && contractsWithoutTerm.length > 0)
                setSelectContract(contractsWithoutTerm[0]?.contractId);
        } else {
            setErrorPromotion(false);
        }
    };
    const handleNextStep = () => {
        checkContratDeadline();
        if (client.name.length === 0) {
            dispatch(errorClient(true));
        }
        if (
            client.name.length > 0 &&
            !emptyClient &&
            promotion.length === (contracts && contracts.length)
        ) {
            OnSave();
        }
    };
    useEffect(() => {
        setErrorClient(false);
        if (client.name.length === 0) {
            setErrorClient(true);
        }
    }, [client]);
    useEffect(() => {
        if (promotion.length === contracts.length) setErrorPromotion(false);
    }, [promotion]);

    useEffect(() => {
        if (contracts && contracts.length && promotion && promotion.length) setPromotion([]);
    }, [contracts]);

    return (
        <ContainerFlex
            FlexDir="column"
            Height="auto"
            Justify="start"
            Align="start"
            Gap="8px"
            Padding="16px"
            Radius="32px"
            Border="1px solid #E8E9EA"
        >
            <Text FontSize="1.25rem" FontWeight="500">
                Selección de plazo
            </Text>
            {contracts && contracts.length > 0 ? (
                <>
                    {errorClients && <ErrorPromotion />}
                    {emptyClient && <ErrorPromotionDeadline />}
                    {errorPromotion && <ErrorPromotions contractNames={contractNames} />}

                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        FlexDir="column"
                        Gap="8px"
                        Height=""
                    >
                        <ContractContainer
                            setSelectContract={(e) => {
                                setSelectContract(e);
                                setCurrentPage(1);
                            }}
                            selectContract={selectContract}
                            setSelectArticle={setSelectArticle}
                            selectArticle={selectArticle}
                            setLoan={setLoan}
                            setValue={setValue}
                        />
                        <LoadingOverlay isLoading={loadingPromotion}>
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                Gap="1.5rem"
                                Height="21.25rem"
                                FlexDir="column"
                                OverFlow="auto"
                                Position="relative"
                                id="promotions-container"
                            >
                                {Promotions.data &&
                                    Promotions.data.length > 0 &&
                                    Promotions.data.map((data: IPromotionTerm, index: number) => (
                                        <PromotionContainer
                                            setPromotion={setPromotion}
                                            data={data}
                                            key={data.id}
                                            selectContract={selectContract}
                                            promotion={promotion}
                                            handlePromotionAssignment={handlePromotionAssignment}
                                            error={errorPromotion}
                                            index={index}
                                        />
                                    ))}
                            </ContainerFlex>
                        </LoadingOverlay>
                    </ContainerFlex>
                    <ContainerFlex ColumnGap="16px">
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            Padding="8px 16px"
                            Width="auto"
                            Cursor="pointer"
                            onClick={() => dispatch(changeActualStep(1))}
                        >
                            <Text FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                                Cancelar
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Radius="32px"
                            backG="#5A5AFF"
                            Padding="8px 16px"
                            Width="auto"
                            Cursor="pointer"
                            onClick={handleNextStep}
                        >
                            <Text FontWeight="500" Color="#FFFFFF" Cursor="pointer">
                                Siguiente paso
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </>
            ) : (
                <LoadingAtaskate />
            )}
        </ContainerFlex>
    );
};
