import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import backArrowIcon from '@images/backArrowDark.svg';
import {routers} from '@/appRoute';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import {ContainerTabs} from '@General/ModuleTabs/styles/styles';
import ProductPage from '@components/InventoryManagement/ProductPage';

const InventoryProductDetail: React.FC = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem "
            Justify="start"
            Gap="1rem"
        >
            <Text
                FontSize="1rem"
                Color="#5A5AFF"
                Cursor="pointer"
                onClick={() => navigate(routers.Inventory)}
            >
                <Image
                    src={backArrowIcon}
                    alt="add"
                    Width="1rem"
                    Height="1rem"
                    MarginRight="0.5rem"
                />
                <Text FontSize="1rem" Color="#5A5AFF" FontWeight="700">
                    {INVENTORY_PRODUCT_DETAIL.BACK}
                </Text>
            </Text>
            <ContainerTabs
                tabs={INVENTORY_PRODUCT_DETAIL.TAB_SECTIONS}
                Gap="1rem"
                FlexDir="column"
                Align="center"
                Justify="start"
            >
                <ProductPage />
                <div>{INVENTORY_PRODUCT_DETAIL.TEMPORARY_TAB_CONTENT}</div>
            </ContainerTabs>
        </ContainerFlex>
    );
};
export default InventoryProductDetail;
