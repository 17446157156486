export const COMPANY = {
    TITLE: 'Tu empresa',
    SUBTITILE: 'Cuéntanos un poco sobre tu empresa',
    COMPANYNAME: 'Nombre de tu empresa',
    ADITIONALINFO: 'Información adicional',
    TYPEBUSINESS: '¿Qué tipo de negocio es?',
    EXPERIENCE: 'Cuéntanos un poco más para ofrecerte la mejor experiencia',
    PLANNAME: 'Plan para ',
    BTNCONTINUE: 'Continuar',
};

export const COMPLEMENTS = {
    ADDONSTITLE: 'Complementa con todo el poder de Ataskate',
    ADDONSDESCRIPTION:
        'Los planes disponibles contienen lo esencial para empezar; te sugerimos estos complementos recomendados.',
    ADDONSLEGEND: 'Los complementos aplican para todas las líneas de negocio.',
    MONTHLYSUBSCRIPTION: 'Subscripción mensual',
    SUBTOTAL: 'Subtotal',
    DISCOUNT: 'Descuento por adquirir 2 planes',
    MONTHLYTOTAL: 'Total',
    TRIALPERIODLEGEND:
        'Una vez concluido el periodo de prueba, será necesario registrar un método de pago ',
    SUBTITILE: 'Cuéntanos un poco sobre tu empresa',
    COMPANYNAME: 'Nombre de tu empresa',
    ADITIONALINFO: 'Información adicional',
    TYPEBUSINESS: '¿Qué tipo de negocio es?',
    EXPERIENCE: 'Cuéntanos un poco más para ofrecerte la mejor experiencia',
    PLANNAME: 'Plan para ',
    BTNCONTINUE: 'Continuar',
};

export const BUSINESS = {
    TITLE: '¿Dónde vendes?',
    EXPERIENCE: 'Cuéntanos un poco más para ofrecerte la mejor experiencia',
    SUGESTION: 'Podrás gestionarlos más adelante',
    ONLINE: 'En línea',
    MOD_ONLINE: 'Módulo en línea',
    MOD_POS: 'Módulo de POS',
    BTNCONTINUE: 'Continuar',
};

export const PROGRESSBAR = {
    YOURACCOUNT: 'Tu cuenta',
    YOURCOMPANY: 'Tu Empresa',
    DETAILS: 'Detalles',
    BACKGROUND: 'bg',
    COLOR: 'color',
    SHADOW: 'shadow',
};

export const PROGRESSITEMS = [
    {step: 1, title: PROGRESSBAR.YOURACCOUNT},
    {step: 2, title: PROGRESSBAR.YOURCOMPANY},
    {step: 3, title: PROGRESSBAR.DETAILS},
];

export const PERSONAL_FORM = {
    TITLE: 'Crear tu cuenta',
    SUBTITLE: 'Cuéntanos un poco sobre ti',
    NAME: 'Nombre(s)',
    FATHERS_LASTNAME: 'Apellido Paterno',
    MOTHER_LASTNAME: 'Apellido Materno',
    LASTNAME: 'Apellido(s)',
    EMAIL: 'Correo electrónico',
    PASSWORD: 'Contraseña',
    CONFIRMPASS: 'Confirma la contraseña',
    BTNCONTINUE: 'Continuar',
    LOGIN: 'Ya tengo una cuenta',
    MESSAGE_PASSWORD_MIN: 'La contraseña debe incluir minimo 8 caracteres',
    MESSAGE_PASSWORD_MATCH: 'Debe incluir una mayúscula, una minúscula, un número y un carácter ',
    MESSAGE_PASSWORD_MATCH_TWO: 'especial !@#$%^&*.',
    MESSAGE_CONFIRM_NO_MATCH: 'Las contraseñas coinciden',
    MESSAGE_FORMAT: 'formato',
    RETURN_PAGE: ' Atrás',
    TERM: 'Términos y condiciones de uso ',
    AND: 'y el ',
    CONDITIONS: 'Aviso de privacidad de Multiapoyo.',
};

export const VALIDE_CODE = {
    TITLE: 'Verifica tu correo',
    MESSAGE: 'Hemos enviado el código de verificación de 4 ',
    EMAIL: 'dígitos a tu correo ',
    BUTTON: 'Verificar',
    APPLICATION: 'Volver a solicitar código en',
};

export const VALIDECODE = {
    EXPIRED: ' El código ha caducado',
    MESSAGE: 'El código no es valido',
    IS_NUMBER: 'Solo se permiten números',
};

export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.])[A-Za-z\d!@#$%^&*.]{8,}$/u;

export const VALIDATIONS = {
    MAX_MIN: 'Se aceptan entre 2 y 50 caracteres',
    NAME: 'Ingrese su nombre',
    LAST_NAME: 'Ingrese su apellido paterno',
    SECOND_LAST_NAME: 'Ingrese su apellido materno',
    ALPHANUMERIC: 'Solo se acepta caracteres tipo texto',
    EMAIL: 'Ingrese un correo electrónico',
    FORMAT: 'El formato de correo no se reconoce, intenta de nuevo.',
    FORMAT_TWO: 'Ej. tunombre@correo.com',
    LONG: 'La longitud máxima es de 254 caracteres',
    PASSWORD: 'Ingrese una contraseña',
    MATCHES_PASS:
        'La contraseña debe incluir al menos una letra mayúscula, una letra minúscula, un número y un carácter especial',
    MIN_PASS: 'La contraseña debe incluir minimo 8 caracteres',
    NOT_MATCHING: 'Las contraseñas no coinciden',
    NAME_COMAPNY: 'Ingrese el nombre de la empresa',
};
