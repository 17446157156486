import React, {useState} from 'react';
import {IconContainer} from '@/components/Quoter/styles';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {MAXIMUN_LEVEL} from '@components/RequestNewCatalogLevel/operational/constant';
import {DinamicPaddingCheckTree} from '@components/RequestNewCatalogLevel/constanst';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ISentDataNew, ITreeComponent} from '@components/AuctionPass/GeneralScreen/interface';
import {EXTRA_TEXT} from '@components/AuctionPass/GeneralScreen/constants';

interface ExtendedISentDataNew extends ISentDataNew {
    onCheckChange: (checkedItems: Set<number>) => void;
    checkedItems: Set<number>;
}

export const NewTreeComponent = (props: ExtendedISentDataNew) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Gap={props.level === 0 ? '16px' : ''}
            FlexDir="column"
            Height="auto"
        >
            {props.data.length > 0 &&
                props.data.map((tree: ITreeComponent, index: number) => (
                    <TreeNode
                        node={tree}
                        level={props.level + 1}
                        isView={props.isView}
                        fatherId={props.fatherId}
                        key={index}
                        onCheckChange={props.onCheckChange}
                        checkedItems={props.checkedItems}
                    />
                ))}
        </ContainerFlex>
    );
};

interface TreeNodeProps {
    node: ITreeComponent;
    level: number;
    isView: boolean;
    fatherId: number;
    onCheckChange: (checkedItems: Set<number>) => void;
    checkedItems: Set<number>;
}

const TreeNode = (props: TreeNodeProps) => {
    const [nextChild, setNextChild] = useState(false);
    const itemsCount = props.node.children?.length || 0;
    const bothEmpty = !props.node.validity && !props.node.percent;
    const getAllChildrenIds = (node: ITreeComponent): number[] => {
        let ids: number[] = [];
        if (node.children) {
            node.children.forEach((child) => {
                ids.push(child.id);
                if (child.children) {
                    ids = [...ids, ...getAllChildrenIds(child)];
                }
            });
        }
        return ids;
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckedItems = new Set(props.checkedItems);
        const childrenIds = getAllChildrenIds(props.node);

        if (e.target.checked) {
            newCheckedItems.add(props.node.id);
            childrenIds.forEach((id) => newCheckedItems.add(id));
        } else {
            newCheckedItems.delete(props.node.id);
            childrenIds.forEach((id) => newCheckedItems.delete(id));
        }
        props.onCheckChange(newCheckedItems);
    };

    return (
        <>
            <TableRowItems
                GridColumn="1fr 24px"
                Cursor="auto"
                Height="40px"
                Width="100%"
                BackGH=""
                BorderRadius="6px 6px 0px 0px"
                Border={props.level === 1 ? '1px solid #D4D6D8' : ''}
                BackGround={nextChild ? '#FAFAFA' : '#FFFFFF'}
            >
                <TableItem
                    Justify="start"
                    PaddingLeft={DinamicPaddingCheckTree(props.level)}
                    Gap="16px"
                >
                    <Checkbox
                        checked={props.checkedItems.has(props.node.id)}
                        onChange={handleCheckboxChange}
                    />
                    <ContainerFlex Width="max-content">
                        <Text FontWeight="" FontSize="1rem" title={props.node.label}>
                            {props.node.label}
                        </Text>
                    </ContainerFlex>

                    {(bothEmpty || props.node.percent) && itemsCount > 0 && (
                        <ContainerFlex Width="" Gap="4px" Justify="start">
                            <Text Color="#71767D" FontSize="0.75rem" FontWeight="400">
                                {EXTRA_TEXT.ARTICLE}
                            </Text>
                            <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="700">
                                ({itemsCount})
                            </Text>
                        </ContainerFlex>
                    )}

                    {props.node.percent && (
                        <ContainerFlex Justify="end" PaddingR="16px">
                            <ContainerFlex Radius="32px" backG="#E5E5FF" Width="40px" Height="32px">
                                <Text FontWeight={nextChild ? '700' : '400'}>
                                    {props.node.percent}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    )}

                    {props.node.validity && (
                        <ContainerFlex Width="" Justify="start">
                            <Text Color="#71767D" FontSize="0.75rem" FontWeight="400">
                                {props.node.validity}
                            </Text>
                        </ContainerFlex>
                    )}
                </TableItem>

                <TableItem Justify="end" Padding="0 16px">
                    {props.level !== MAXIMUN_LEVEL && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1.5rem"
                            Color={nextChild ? '#5A5AFF' : '#54575C'}
                            Justify="start"
                            onClick={() => {
                                setNextChild(!nextChild);
                            }}
                        >
                            {nextChild ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}
                </TableItem>
            </TableRowItems>
            {nextChild && props.node.children && (
                <NewTreeComponent
                    data={props.node.children}
                    level={props.level}
                    isView={props.isView}
                    fatherId={props.node.id}
                    onCheckChange={props.onCheckChange}
                    checkedItems={props.checkedItems}
                />
            )}
        </>
    );
};
