import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import InventoryLocationModal from '@components/InventoryManagement/InventoryLocationModal';
import {setOpenLocationModal} from '@components/InventoryManagement/Redux/Actions/InventoryLocationStorage';

const InventoryLocation = () => {
    const dispatch = useDispatch();
    const {showLocationModal} = useSelector((state: RootState) => {
        return state.InventoryLocationStorage;
    });
    const setShowModal = () => {
        dispatch(setOpenLocationModal(!showLocationModal));
    };

    return (
        <>
            <InventoryLocationModal showModal={showLocationModal} setShowModal={setShowModal} />
        </>
    );
};
export default InventoryLocation;
