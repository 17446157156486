import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import smileQuoterIcon from '@components/PublicGlobalQuoter/icons/smileQuoterIcon.svg';
import openArrowIcon from '@components/PublicGlobalQuoter/icons/openArrowIcon.svg';
import {CREATE_ACCOUNT_DESCRIPTION} from '@components/PublicGlobalQuoter/constants';

export const CreateAccount = () => {
    return (
        <ContainerFlex
            Width="518px"
            Justify="start"
            Align="start"
            Radius="1rem"
            Border="1px solid #F4F5F5"
            BoxS="0px 0px 10px 0px rgba(0, 0, 0, 0.06)"
            Padding="1.5rem 1rem"
            backG="#F7FDFF"
        >
            <ContainerFlex Gap="0.5rem" Justify="start" Height="max-content" Width="452px">
                <Image src={smileQuoterIcon} alt="smile-icon" Width="48px" Height="48px" />
                <Text Color="#2A2C2F" FontSize="0.875rem" Width="max-content">
                    {CREATE_ACCOUNT_DESCRIPTION}
                </Text>
            </ContainerFlex>
            <Image
                src={openArrowIcon}
                Width="24px"
                Height="24px"
                alt="open-arrow-icon"
                Cursor="pointer"
            />
        </ContainerFlex>
    );
};
