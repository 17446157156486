import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import AdditionalLoansOptionsCard from '@components/PersonalLoans/AdditionalLoansOptionsCard';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import arrowRightIcon from '@/components/CreditQuoter/icons/arrowRightIcon.svg';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {ADDITIONAL_LOAN_OPTIONS} from '@components/PersonalLoans/constants';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IGetConfirmationStepReducerInitialState} from '@components/PersonalLoans/interfaces';
import mujerConLentes from '@/images/Mujerconlentes.png';
import hombreConLentes from '@/images/Hombreconlentes.png';

const ConfirmationLoanAdditonalInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {dataList} = useSelector(
        (state: RootState) =>
            state.GetConfirmationStepReducer as IGetConfirmationStepReducerInitialState
    );

    const navigateAndRest = (url: string) => {
        dispatch(ActualDataStepLoans(1));
        navigate(url);
    };

    return (
        <ContainerFlex
            Gap="16px"
            Justify="flex-start"
            Align="flex-start"
            Padding="0 60px"
            // MaxW="1162px"
        >
            <ContainerFlex
                Gap="16px"
                FlexDir="column"
                Justify="flex-start"
                Align="flex-start"
                backG="#FFFFFF"
                Radius="16px"
                Padding="16px"
                // MaxW="806px"
            >
                <ContainerFlex
                    Gap="4px"
                    FlexDir="column"
                    Justify="flex-start"
                    Align="flex-start"
                    Height="52px"
                >
                    <Text
                        FontSize="1.25rem"
                        FontFamily="Nunito"
                        Color="#1D1E20"
                        FontWeight="600"
                        LetterSpacing="0.3px"
                    >
                        {ADDITIONAL_LOAN_OPTIONS.NEED_QUICK_MONEY}
                    </Text>
                    <Text FontFamily="Nunito" LetterSpacing="0.3px" Color="#1D1E20">
                        {ADDITIONAL_LOAN_OPTIONS.REQUEST_WITH_OTHER_BUSINESSES}
                    </Text>
                </ContainerFlex>
                {dataList && dataList.length > 0
                    ? dataList?.map((loan, index) => (
                          <AdditionalLoansOptionsCard key={index} {...loan} />
                      ))
                    : null}
            </ContainerFlex>
            <ContainerFlex
                Gap="24px"
                FlexDir="column"
                Justify="flex-start"
                Align="flex-start"
                MaxW="339px"
            >
                <ContainerFlex
                    BGImage={`url(${mujerConLentes})`}
                    backG="#F9FFD6"
                    Radius="16px"
                    Height="203px"
                    Padding="24px 0px 24px 24px"
                    MaxW="339px"
                    Position="relative"
                    Justify="flex-start"
                    Align="flex-start"
                    BackgroundSize="cover"
                >
                    <ContainerFlex
                        FlexDir="column"
                        Gap="16px"
                        Justify="flex-start"
                        Align="flex-start"
                        Width="70%"
                    >
                        <ContainerFlex
                            FlexDir="column"
                            Gap="16px"
                            Justify="flex-start"
                            Align="flex-start"
                        >
                            <Text
                                FontWeight="700"
                                Color="#0D166B"
                                FontFamily="Nunito"
                                FontSize="1.125rem"
                            >
                                {ADDITIONAL_LOAN_OPTIONS.PAWN_AND_GET_THE_MONEY_YOU_NEED}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#0D166B"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {ADDITIONAL_LOAN_OPTIONS.QUOTE_YOUR_PAWN_WITH_US}
                            </Text>
                        </ContainerFlex>
                        <ButtonGenerals
                            FontSize="1rem"
                            FontWeight="700"
                            Cursor="pointer"
                            Padding="0"
                            MHeight="40px"
                            BackGC="transparent"
                            Color="#5A5AFF"
                            HBackG="transparent"
                            Gap="8px"
                            Border="none"
                            onClick={() => navigateAndRest(routers.Home)}
                        >
                            {ADDITIONAL_LOAN_OPTIONS.QUOTE_YOUR_PAWN_WITH_US_TWO}
                            <Image
                                src={arrowRightIcon}
                                Width="24px"
                                Height="24px"
                                alt="arrow-right-icon"
                            />
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    BGImage={`url(${hombreConLentes})`}
                    backG="#D0F6FF"
                    Radius="16px"
                    Height="203px"
                    Padding="24px 0px 24px 24px"
                    MaxW="339px"
                    Justify="flex-start"
                    Align="flex-start"
                    BackgroundSize="cover"
                >
                    <ContainerFlex
                        FlexDir="column"
                        Gap="16px"
                        Justify="center"
                        Align="start"
                        Width="65%"
                    >
                        <ContainerFlex FlexDir="column" Gap="4px" Justify="start" Align="start">
                            <Text
                                FontWeight="700"
                                Color="#0D166B"
                                FontFamily="Nunito"
                                FontSize="1.125rem"
                            >
                                {ADDITIONAL_LOAN_OPTIONS.NEED_MORE_MONEY}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#0D166B"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {ADDITIONAL_LOAN_OPTIONS.REQUEST_WITH_OTHER_BUSINESSES_TWO}
                            </Text>
                        </ContainerFlex>
                        <ButtonGenerals
                            FontSize="1rem"
                            FontWeight="700"
                            Cursor="pointer"
                            Padding="0"
                            MHeight="40px"
                            BackGC="transparent"
                            Color="#5A5AFF"
                            HBackG="transparent"
                            Gap="8px"
                            Border="none"
                            onClick={() => navigateAndRest(routers.GlobalQuoter)}
                        >
                            {ADDITIONAL_LOAN_OPTIONS.QUOTE_YOUR_LOAN_WITH_US}
                            <Image
                                src={arrowRightIcon}
                                Width="24px"
                                Height="24px"
                                alt="arrow-right-icon"
                            />
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ConfirmationLoanAdditonalInfo;
