import {
    POST_SEND_PASSWORD_EMAIL,
    POST_SEND_PASSWORD_EMAIL_SUCCESS,
    POST_SEND_PASSWORD_EMAIL_ERROR,
} from '@components/UserProfileResetPassword/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    isAvailable: false,
    loading: false,
    error: null,
};

export const PostSendPasswordEmailReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case POST_SEND_PASSWORD_EMAIL:
            return {...state, loading: true, error: false};
        case POST_SEND_PASSWORD_EMAIL_SUCCESS:
            return {...state, loading: false, isAvailable: action.payload.data.data};
        case POST_SEND_PASSWORD_EMAIL_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};
