import * as yup from 'yup';
import {VALIDATIONS} from '@/components/SignUp/constants';

const SECOND_EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const NAMES_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&.*])[A-Za-z\d!@#$%^&.*]{8,}$/;

export const schemaUser = yup.object().shape({
    names: yup
        .string()
        .required(VALIDATIONS.NAME)
        .matches(NAMES_REGEX, VALIDATIONS.ALPHANUMERIC)
        .min(2, VALIDATIONS.MAX_MIN)
        .max(50, VALIDATIONS.MAX_MIN),
    paternalSurname: yup
        .string()
        .required(VALIDATIONS.LAST_NAME)
        .matches(NAMES_REGEX, VALIDATIONS.ALPHANUMERIC)
        .min(2, VALIDATIONS.MAX_MIN)
        .max(50, VALIDATIONS.MAX_MIN),
    maternalSurname: yup
        .string()
        .required(VALIDATIONS.SECOND_LAST_NAME)
        .matches(NAMES_REGEX, VALIDATIONS.ALPHANUMERIC)
        .min(2, VALIDATIONS.MAX_MIN)
        .max(50, VALIDATIONS.MAX_MIN),
    email: yup
        .string()
        .required(VALIDATIONS.EMAIL)
        .email(VALIDATIONS.FORMAT)
        .email(VALIDATIONS.FORMAT_TWO)
        .matches(SECOND_EMAIL_REGEX, VALIDATIONS.FORMAT)
        .max(254, VALIDATIONS.LONG),
    password: yup
        .string()
        .required(VALIDATIONS.PASSWORD)
        .matches(PASSWORD_REGEX, VALIDATIONS.MATCHES_PASS)
        .min(8, VALIDATIONS.MIN_PASS),
    confirmPassword: yup
        .string()
        .required(VALIDATIONS.PASSWORD)
        .oneOf([yup.ref('password')], VALIDATIONS.NOT_MATCHING),
});

export const schemaYourCompany = yup.object().shape({
    company: yup
        .string()
        .required(VALIDATIONS.NAME_COMAPNY)
        .matches(NAMES_REGEX, VALIDATIONS.ALPHANUMERIC)
        .min(2, VALIDATIONS.MAX_MIN)
        .max(50, VALIDATIONS.MAX_MIN),
});
