export const PRE_APPROVAL_TEXTS = {
    TITLE: 'Pre aprobados',
    DESCRIPTION:
        'Aquí podrás crear las condiciones para la pre aprobación de tus productos de préstamo.',
    BUTTON: 'Condición',
    CREATE_FIRST_CONDITION: 'Crea una condición',
    NO_ARCHIVED: 'No hay condiciones archivadas',
    TABLE_TITLE: 'Condiciones',
    LOANS: 'préstamos',
    LOAN: 'préstamo',
};

export const PRE_APPROVAL_TABLE = {
    TITLE: 'Sugerencias Ataskate',
    SUB: 'Selecciona las condiciones sugeridas por Ataskate.',
};

export const PRE_APPROVAL_TABLE_HEADERS = {
    NAME: 'Nombre',
    DEMOGRAPHIC_CONDITIONS: 'Condiciones demográficas',
    BEHAVIOR_CONDITIONS: 'Condiciones de comportamiento',
    APPLIED_TO: 'Aplicado a',
    ACTIONS: '',
};

export const PRE_APPROVAL_TABLE_DATA = [
    {
        name: 'Pre aprobados Navidad',
        demographicConditions: ['Edad', 'Score crediticio', 'Genero', ' Cliente', 'Ingresos'],
        behaviorConditions: ['Préstamos solicitados anteriormente', ' Tiempo entre solicitudes'],
        appliedTo: '8 préstamos',
        selected: 'Seleccionar',
    },
    {
        name: 'Pre aprobados Noche Vieja',
        demographicConditions: ['Edad', 'Score crediticio', ' Cliente'],
        behaviorConditions: [' Tiempo entre solicitudes'],
        appliedTo: '2 préstamos',
        selected: 'Seleccionar',
    },
];

export const CONDITION_PER_PAGE = 8;

export const TABLE_PRE_APPROVAL_FILTER_STATUS = [
    {label: 'Activos', value: 1},
    {label: 'Archivados', value: 2},
];
