import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';

import {RootState} from '@/config/store';
import {ContainerFlex, ContainerCellGrid, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {IconSvg} from '@Quoter/Icons/styles';

import {ADRESSDATA} from '@components/PersonalLoans/constants';
import {LoansAdressProps} from '@components/PersonalLoans/interfaces';

import {
    GetPostalInfo,
    GetPostalInfoReset,
} from '@components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';

export const AdressLoans = ({
    control,
    register,
    setValue,
    trigger,
    watch,
    errors,
}: LoansAdressProps) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const preLoadData = useSelector((state: RootState) => state.PreLoadPersonalDataReducer);
    const cityInfo = useSelector((state: RootState) => state.GetPostalInfo.postalInfo);
    const dispatch = useDispatch();
    const colonyOptions = cityInfo?.colonys?.map(
        (colony: {colonyId: number; colonyName: string}) => ({
            label: colony.colonyName,
            value: colony.colonyId,
        })
    );
    const GetPostalCode = (value: string) => {
        if (value.length === 5) {
            dispatch(GetPostalInfo(value, token));
        } else if (value.length < 5) {
            dispatch(GetPostalInfoReset());
            setValue('addressPersonalLoans.catColonyId', {value: 0, label: ''});
            setValue('addressPersonalLoans.catMunicipaliyId', {value: 0, label: ''});
            setValue('addressPersonalLoans.catStateId', {value: 0, label: ''});
        }
    };

    useEffect(() => {
        if (preLoadData.postalCode && preLoadData.postalCode.length === 5) {
            dispatch(GetPostalInfo(preLoadData.postalCode, token));
        }
    }, [preLoadData]);

    useEffect(() => {
        if (
            preLoadData.catColonyId &&
            preLoadData.catMunicipalityId &&
            preLoadData.catStateId &&
            cityInfo
        ) {
            const colony = cityInfo?.colonys?.find(
                (colony: {colonyId: number}) => colony.colonyId === preLoadData.catColonyId
            );

            setValue('addressPersonalLoans.catColonyId', {
                value: colony?.colonyId,
                label: colony?.colonyName,
            });
            setValue('addressPersonalLoans.catMunicipaliyId', {
                value: colony?.municipalityId,
                label: colony?.municipalityName,
            });
            setValue('addressPersonalLoans.catStateId', {
                value: cityInfo?.stateId,
                label: cityInfo?.stateName,
            });
        }
    }, [cityInfo, preLoadData]);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="auto" Gap="1rem">
            <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" oFlow="none">
                {ADRESSDATA.LIVE}
            </Text>
            <ContainerFlex FlexWrap="wrap" Justify="start" Align="start" Gap="1rem">
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="22rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.STREET}
                    </Text>
                    <Input
                        Width="100%"
                        {...register('addressPersonalLoans.street')}
                        error={errors?.addressPersonalLoans?.street?.message}
                    />
                    {errors?.addressPersonalLoans?.street?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans?.street?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="10.438rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.NOEXT}
                    </Text>
                    <Input
                        Width="100%"
                        {...register('addressPersonalLoans.externalNumber')}
                        error={errors.addressPersonalLoans?.externalNumber?.message}
                    />
                    {errors.addressPersonalLoans?.externalNumber?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans?.externalNumber?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="10.438rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" wSpace="pre">
                        {ADRESSDATA.NOINT}{' '}
                        <Text wSpace="pre" FontWeight="400" FontSize="1rem" Color="#54575C">
                            {ADRESSDATA.OPTIONAL}
                        </Text>
                    </Text>
                    <Input
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('addressPersonalLoans.interiorNumber')}
                        error={errors.addressPersonalLoans?.interiorNumber?.message}
                    />
                    {errors?.addressPersonalLoans?.interiorNumber?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans.interiorNumber?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>

                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.CP}
                    </Text>
                    <Input
                        maxLength={5}
                        type="tel"
                        Width="100%"
                        {...register('addressPersonalLoans.postalCode')}
                        onChange={(e) => {
                            GetPostalCode(e.target.value);
                        }}
                        error={errors?.addressPersonalLoans?.postalCode?.message}
                    />
                    {errors?.addressPersonalLoans?.postalCode?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans.postalCode?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.COLONY}
                    </Text>
                    <Controller
                        control={control}
                        name="addressPersonalLoans.catColonyId"
                        render={({field: {...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder=""
                                options={colonyOptions}
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                hasError={
                                    errors?.addressPersonalLoans?.catColonyId?.value?.message
                                        ? true
                                        : false
                                }
                            />
                        )}
                    />
                    {errors?.addressPersonalLoans?.catColonyId?.value?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans.catColonyId.value.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.CITYHALL}
                    </Text>
                    <Controller
                        control={control}
                        name="addressPersonalLoans.catMunicipaliyId"
                        render={({field: {...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder=""
                                isSearchable={false}
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                options={[
                                    {
                                        label: cityInfo?.municipalityName,
                                        value: cityInfo?.municipalityId,
                                    },
                                ]}
                                hasError={
                                    errors?.addressPersonalLoans?.catMunicipaliyId?.value?.message
                                        ? true
                                        : false
                                }
                            />
                        )}
                    />
                    {errors?.addressPersonalLoans?.catMunicipaliyId?.value?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans.catMunicipaliyId.value.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Height="auto"
                    Width="21.875rem"
                    Gap="0.25rem"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {ADRESSDATA.STATE}
                    </Text>
                    <Controller
                        control={control}
                        name="addressPersonalLoans.catStateId"
                        render={({field: {...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder=""
                                isSearchable={false}
                                loadingMessage={() => 'Cargando...'}
                                noOptionsMessage={() => 'Sin opciones'}
                                options={[
                                    {
                                        label: cityInfo?.stateName,
                                        value: cityInfo?.stateId,
                                    },
                                ]}
                                hasError={
                                    errors?.addressPersonalLoans?.catStateId?.value?.message
                                        ? true
                                        : false
                                }
                            />
                        )}
                    />
                    {errors?.addressPersonalLoans?.catStateId?.value?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <IconSvg Height="1rem" width="1rem">
                                <path
                                    d="M4.26634 13.1981L3.33301 12.2647L7.06634 8.53141L3.33301 4.79808L4.26634 3.86475L7.99967 7.59808L11.733 3.86475L12.6663 4.79808L8.93301 8.53141L12.6663 12.2647L11.733 13.1981L7.99967 9.46475L4.26634 13.1981Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.addressPersonalLoans.catStateId.value.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
            </ContainerFlex>
        </ContainerFlex>
    );
};
