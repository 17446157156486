import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import viewOn from '@images/ViewOn.svg';
import deleteIcon from '@images/deleteIcon.svg';
import viewOnBlue from '@images/ViewOnBlue.svg';
import {SelectedTermCredit} from '@components/LoansProducts/Redux/Actions/ActiveDesactiveTermCredit';
import {ACTIONILOANSCONS} from '@components/LoansProducts/constants';
import {DetaillsCreditProps} from '@/components/LoansProducts/interfaces';
import {getTermPersonalLoansInformation} from '@/components/LoansProducts/Redux/Actions/GetTermPersonalLoan';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';

export const DetailAndDeleteLoans = ({
    item,
    setShowArchiveModal,
    setSelectedItemId,
}: DetaillsCreditProps) => {
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const [editIconSrc, setEditIconSrc] = useState(viewOn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleEditClick = () => {
        dispatch(getTermPersonalLoansInformation(token, {personalCreditTermId: item.termId}));
        setSelectedItemId(null);
        navigate('/NewLoanProduct');
    };
    const handleDeleteClick = () => {
        dispatch(SelectedTermCredit(item));
        setShowArchiveModal(true);
        setSelectedItemId(null);
    };
    return (
        <ContainerFlex
            Position="absolute"
            FlexDir="column"
            Width="143px"
            BoxShadow="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
            Radius="0.5rem"
            backG="#FFF"
            Border="1px solid #F3F3F3"
            Height="max-content"
            PositionRight="24px"
            MarginTop="5px"
            ZIndex="1"
        >
            <ContainerFlex
                Justify="start"
                Height="40px"
                Padding="0.5rem"
                Gap="0.5rem"
                Cursor="pointer"
                HBorderLeft="2px solid #5A5AFF"
                HBackground="#F0F0FF"
                HColor="#5A5AFF"
                onClick={handleEditClick}
                onMouseEnter={() => setEditIconSrc(viewOnBlue)}
                onMouseLeave={() => setEditIconSrc(viewOn)}
            >
                <Image
                    src={editIconSrc}
                    Width="20px"
                    Height="20px"
                    alt="edit-icon"
                    Cursor="pointer"
                />
                <Text Color="#2A2C2F" Cursor="pointer">
                    {ACTIONILOANSCONS.DETAIL}
                </Text>
            </ContainerFlex>

            <ContainerFlex
                Justify="start"
                Height="40px"
                Padding="0.5rem"
                Gap="0.5rem"
                Cursor="pointer"
                HBorderLeft="2px solid #5A5AFF"
                HBackground="#F0F0FF"
                HColor="#5A5AFF"
                onClick={handleDeleteClick}
            >
                <Image
                    src={deleteIcon}
                    Width="20px"
                    Height="20px"
                    alt="delete-icon"
                    Cursor="pointer"
                />
                <Text Color="#A82424" Cursor="pointer">
                    {ACTIONILOANSCONS.ARCHIVE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
