import React from 'react';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import searchIcon from '@components/Branches/Images/AppMagnifierIcon.svg';
import {FILTER_SELECT_OPTIONS, LOCATE_PRODUCTS_PAGE} from '@/components/LocateProducts/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

export default function LocateProductsFilters() {
    const handleChangeOption = () => {
        return; //TODO: implement when filter defined
    };
    return (
        <ContainerFlex Justify="space-between" Height="fit">
            <ContainerFlex MaxW="16.875rem" Height="fit">
                <SearchInput
                    Height="2.6rem"
                    Position="none"
                    FontSize="1rem"
                    PlaceholderColor="#A1A5AA"
                    Padding="0 0 0 1rem"
                    WBoxShadow=""
                >
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={LOCATE_PRODUCTS_PAGE.SEARCH_PLACEHOLDER}
                        width="100%"
                    />
                    <Image
                        src={searchIcon}
                        alt="icon-magnifier"
                        Cursor="pointer"
                        Width="1.5rem"
                        Height="1.5rem"
                    />
                </SearchInput>
            </ContainerFlex>
            <ContainerFlex MaxW="11.563rem" Height="fit">
                <SelectGeneral
                    defaultValue={FILTER_SELECT_OPTIONS[0]}
                    options={FILTER_SELECT_OPTIONS}
                    isSearchable={false}
                    onChange={handleChangeOption}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
}
