import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Text, ContainerForm, Image} from '@/components/Shopify/Ecommerce/styles';
import {GENERAL_TITLES} from '@components/Users/UserProfile/constants';
import {DEFAULT_VALUES, schemaPersonalInfo} from '@components/Users/UserProfile/yupSchema';
import Edit from '@images/editIcon.svg';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RootState} from '@/config/store';
import {InfoUserdata} from '@/components/Users/Redux/Actions/GetInfoUserActions';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {AddUpdateInfoUser} from '@/components/Users/Redux/Actions/UpdateInfoUserAction';
import {userProfile} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import PersonalInfoForm from '@/components/Users/UserProfile/PersonalInfoForm';
import {IPersonalInfo} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {profileUser} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';
import {useNavigate} from 'react-router-dom';

export const PersonalInfo: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const dataPersonal = useSelector((state: RootState) => state.getInfoUserData.userData);
    const isLoading = useSelector((state: RootState) => state.getInfoUserData.loading);
    const addressUser = useSelector(
        (state: RootState) => state.getInfoUserData.userData?.addressUser?.[0]
    );

    const [isEdit, setIsEdit] = useState(false);
    const genderLabel = dataPersonal?.gender || '';
    const formMethods = useForm<IPersonalInfo>({
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(schemaPersonalInfo),
    });

    const {handleSubmit, reset} = formMethods;

    useEffect(() => {
        const data = {
            userId: employeeId,
            userProfile,
        };
        dispatch(InfoUserdata(token, data));
    }, [token, employeeId, dispatch]);

    useEffect(() => {
        if (dataPersonal) {
            reset({
                name: dataPersonal.name || '',
                lastName: dataPersonal.lastName || '',
                otherLastName: dataPersonal.motherLastName || '',
                date: dataPersonal.birthdate ? dataPersonal.birthdate.split('T')[0] : '',
                gender: {label: genderLabel},
                email: dataPersonal.accessCredentials?.email || '',
                otherEmail: dataPersonal.accessCredentials?.alternateEmail || '',
                cel: dataPersonal.mainPhoneNumber || '',
                otherCelphone: dataPersonal.secondaryPhoneNumber || '',
            });
        }
    }, [dataPersonal, reset]);

    const onSubmit = async (data: IPersonalInfo) => {
        if (!token || !employeeId) return;
        const dataToSend = {
            userId: employeeId,
            firstname: data.name,
            paternalLastname: data.lastName,
            maternalLastname: data.otherLastName,
            gender: data.gender.label,
            mainPhoneNumber: data.cel,
            secondaryPhoneNumber: data.otherCelphone,
            birthDate: data.date,
            userCredentials: {
                email: data.email,
                alternateEmail: data.otherEmail || '',
            },
            address: [
                {
                    codigoPostal: addressUser?.postalCode || '',
                    address: addressUser?.suburbName || '',
                    externalNumber: addressUser?.outdoorNumber || '',
                    internalNumber: addressUser?.interiorNumber || '',
                    catColoniaId: addressUser?.suburbId || '',
                },
            ],
        };
        try {
            await dispatch(AddUpdateInfoUser(dataToSend, token));

            const infoUserData = {
                userId: employeeId,
                userProfile,
            };
            await dispatch(InfoUserdata(token, infoUserData));
            await dispatch(profileUser(token, employeeId, navigate, false));
            setIsEdit(false);
        } catch (error) {
            return error;
        }
    };

    return (
        <ContainerForm FlexDir="column" Gap="16px" onSubmit={handleSubmit(onSubmit)}>
            {isLoading ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex Justify="space-between" Height="40px" Padding="0 0 16px 0">
                        <ContainerFlex Justify="start">
                            <Text FontSize="1.25rem" FontWeight="700">
                                {GENERAL_TITLES.TITLE_CONTACT_HEADER}
                            </Text>
                        </ContainerFlex>

                        {!isEdit && (
                            <ContainerFlex ColumnGap="8px" Justify="end">
                                <Image alt="save" src={Edit} />
                                <Text
                                    FontWeight="700"
                                    Color="#5A5AFF"
                                    Cursor="pointer"
                                    onClick={() => setIsEdit(true)}
                                >
                                    {GENERAL_TITLES.EDIT}
                                </Text>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>

                    <PersonalInfoForm
                        isEdit={isEdit}
                        dataPersonal={dataPersonal}
                        formMethods={formMethods}
                    />

                    {isEdit && (
                        <ContainerFlex Justify="end" Gap="16px" Height="max-content">
                            <ButtonGenerals
                                SecondaryButton="none"
                                Height="40px"
                                onClick={() => {
                                    setIsEdit(false);
                                    reset();
                                }}
                            >
                                {GENERAL_TITLES.CANCEL}
                            </ButtonGenerals>
                            <ButtonGenerals type="submit" Height="40px">
                                {GENERAL_TITLES.SAVE_CHANGES}
                            </ButtonGenerals>
                        </ContainerFlex>
                    )}
                </>
            )}
        </ContainerForm>
    );
};

export default PersonalInfo;
