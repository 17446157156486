import React from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {ContainerFlex, ContainerCellGrid, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {branchTypeMapping, TYPE_LOCATION} from '@components/LocationsBranch/constants';
import {
    LOCATIONS_LIST_HEADERS,
    LOCATIONGROUP,
    getStyleByType,
} from '@components/LocationsBranch/constants';
import {LocationsListProps} from '@components/LocationsBranch/interfaces';
import {fetchSelectTypeGroup} from '@components/LocationsBranch/Redux/Action/CreateNewGroup';
import {Branch} from '@components/LocationsBranch/Redux/interfaces';
import {setSelectedOptionList} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {LAST_LEVEL_BY_COMPANY} from '@components/LocationsBranch/Redux/types';
import {routers} from '@/appRoute';
import {setLevelId} from '@components/LocationsBranch/Redux/Action/GelLevels';

const LocationsList: React.FC<LocationsListProps> = ({typeFilter, inheritType}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {selectedGroup, selectedOptionList} = useSelector((state: RootState) => ({
        selectedGroup: state.getCompanyLevels.selectedGroup,
        selectedOptionList: state.getCompanyLevels.selectedOptionList,
    }));

    const branchTypeToFilter = branchTypeMapping[typeFilter];
    const filteredBranches =
        selectedGroup?.branchs?.filter(
            (branch) => branchTypeToFilter === null || branch.type === branchTypeToFilter
        ) ?? [];

    const goToEditBranch = async (branch: Branch) => {
        if (inheritType) {
            if (selectedOptionList?.branchId === branch.branchId) {
                dispatch(setSelectedOptionList(null));
                return;
            }
            dispatch(setSelectedOptionList(branch));
            return;
        }
        const json = {
            creationDate: new Date().toString(),
            description: TYPE_LOCATION.BRANCH.NAME,
            locationId: TYPE_LOCATION.BRANCH.TYPE,
            locationName: TYPE_LOCATION.BRANCH.NAME,
            status: TYPE_LOCATION.BRANCH.STATUS,
        };
        dispatch(fetchSelectTypeGroup(json));
        const {type} = dispatch(setLevelId(branch.levelId));
        if (type === LAST_LEVEL_BY_COMPANY.ID_SET_LEVEL)
            navigate(
                routers.EditLocation.replace(':locationName', TYPE_LOCATION.BRANCH.NAME).replace(
                    ':id',
                    String(branch.branchId)
                )
            );
    };

    const goToEditStorage = async (branch: Branch) => {
        if (inheritType) {
            if (selectedOptionList?.branchId === branch.branchId) {
                dispatch(setSelectedOptionList(null));
                return;
            }
            dispatch(setSelectedOptionList(branch));
            return;
        }
        const json = {
            creationDate: new Date().toString(),
            description: TYPE_LOCATION.STORAGE.NAME,
            locationId: TYPE_LOCATION.STORAGE.TYPE,
            locationName: TYPE_LOCATION.STORAGE.NAME,
            status: TYPE_LOCATION.STORAGE.STATUS,
        };
        dispatch(fetchSelectTypeGroup(json));
        const {type: actionType} = dispatch(setLevelId(branch.levelId));
        if (actionType === LAST_LEVEL_BY_COMPANY.ID_SET_LEVEL)
            navigate(
                routers.EditLocation.replace(':locationName', TYPE_LOCATION.STORAGE.NAME).replace(
                    ':id',
                    String(branch.branchId)
                )
            );
    };

    const checkEdiType = (branch: Branch) => {
        const actionMap = {
            [TYPE_LOCATION.BRANCH.TYPE]: () => {
                goToEditBranch(branch);
            },
            [TYPE_LOCATION.STORAGE.TYPE]: () => {
                goToEditStorage(branch);
            },
        };

        actionMap[branch.typeLocationId]();
    };

    return (
        <>
            {filteredBranches && filteredBranches.length > 0 ? (
                <ContainerFlex FlexDir="column" Align="flex-start" Radius="0.5rem" backG="#FFFF">
                    <ContainerFlex
                        Display="grid"
                        GridColumns="repeat(5, minmax(80px, 1fr)) 32px"
                        Gap="1rem"
                        Justify="start"
                        Align="center"
                        Padding="0.5rem"
                        Bb="solid 1px #E8E9EA"
                    >
                        <ContainerCellGrid Justify="start" Align="center">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                                {LOCATIONS_LIST_HEADERS.NAME}
                            </Text>
                        </ContainerCellGrid>
                        <ContainerCellGrid Justify="start" Align="center">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                                {LOCATIONS_LIST_HEADERS.TYPE}
                            </Text>
                        </ContainerCellGrid>
                        <ContainerCellGrid Justify="start" Align="center">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                                {LOCATIONS_LIST_HEADERS.ADDRESS}
                            </Text>
                        </ContainerCellGrid>
                        <ContainerCellGrid Justify="start" Align="center">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                                {LOCATIONS_LIST_HEADERS.MANAGER}
                            </Text>
                        </ContainerCellGrid>
                        <ContainerCellGrid Justify="start" Align="center">
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                                {LOCATIONS_LIST_HEADERS.USERS}
                            </Text>
                        </ContainerCellGrid>
                    </ContainerFlex>
                    {filteredBranches.map((branch) => {
                        return (
                            <ContainerFlex
                                key={branch.branchId}
                                Display="grid"
                                GridColumns="repeat(5, minmax(80px, 1fr)) 32px"
                                Justify="start"
                                Align="center"
                                Gap="1rem"
                                Padding="0.5rem"
                                Cursor="pointer"
                                onClick={() => checkEdiType(branch)}
                                Bl={
                                    selectedOptionList?.branchId === branch.branchId
                                        ? '0.125rem solid #5A5AFF'
                                        : 'none'
                                }
                                backG={
                                    selectedOptionList?.branchId === branch.branchId
                                        ? '#f0f0ff'
                                        : '#fff'
                                }
                            >
                                <ContainerCellGrid Justify="start" Align="center" Gap="0.5rem">
                                    <TextEllipsis
                                        Color="#2A2C2F"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                    >
                                        {branch.branchName}
                                    </TextEllipsis>
                                </ContainerCellGrid>
                                <ContainerCellGrid Justify="start" Align="center">
                                    <ContainerFlex
                                        Align="center"
                                        Justify="start"
                                        Gap="8px"
                                        Flex="1 0 0"
                                    >
                                        <ContainerFlex
                                            Align="center"
                                            Justify="center"
                                            Radius="14px"
                                            Padding="2px 8px"
                                            Height="1.125rem"
                                            Width="4.375rem"
                                            Border={`1px solid ${
                                                getStyleByType(branch.type).borderColor
                                            }`}
                                            backG={getStyleByType(branch.type).backgroundColor}
                                        >
                                            <Text
                                                FontWeight="500"
                                                FontSize="0.75rem"
                                                Color={getStyleByType(branch.type).color}
                                            >
                                                {branch.type}
                                            </Text>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                </ContainerCellGrid>
                                <ContainerCellGrid Justify="start" Align="center">
                                    <TextEllipsis
                                        Color="#54575C"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                    >
                                        {branch.address}
                                    </TextEllipsis>
                                </ContainerCellGrid>
                                <ContainerCellGrid Justify="start" Align="center">
                                    <TextEllipsis
                                        Color="#54575C"
                                        FontSize="0.875rem"
                                        FontWeight="400"
                                    >
                                        {branch.manager}
                                    </TextEllipsis>
                                </ContainerCellGrid>
                                <ContainerCellGrid Justify="start" Align="center">
                                    <Text FontSize="0.875rem" FontWeight="400" Color="#54575C">
                                        {branch.users}
                                    </Text>
                                </ContainerCellGrid>
                            </ContainerFlex>
                        );
                    })}
                </ContainerFlex>
            ) : (
                <ContainerFlex Padding="1.5rem">
                    <Text Color="#54575C">{LOCATIONGROUP.SELECTGROUP}</Text>
                </ContainerFlex>
            )}
        </>
    );
};

export default LocationsList;
