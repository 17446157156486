import React, {useEffect, useState} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {ContainerFlex, Text, Image, Input} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import SearchImg from '@/images/search.svg';
import Filter from '@images/filter.svg';
import {FilterCashFlow} from '@CashFlow/FiltersCashFlow';
import {DateTime} from 'luxon';
import {IFilterCashFlow, IFilterParams} from '@components/CashFlow/interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {saveFilters} from '@components/CashFlow/Redux/Actions/filtersPersistance';

interface SearchFilterTrasnProps {
    setSearch: (search: string) => void;
    search: string;
    filterData: IFilterCashFlow;
    setFilterData: (data: IFilterCashFlow) => void;
}

const DateIcon = () => (
    <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
        calendar_today
    </Icons>
);

const DropIcon = () => (
    <ContainerFlex>
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            keyboard_arrow_down
        </Icons>
    </ContainerFlex>
);

export const SearchFilterTrasn: React.FC<SearchFilterTrasnProps> = ({
    search,
    setSearch,
    filterData,
    setFilterData,
}) => {
    const [date, setDate] = useState<DateTime | null>(DateTime.now());
    const handleDateChange = (value: unknown, keyboardInputValue?: string) => {
        const newDate = value as DateTime | null;
        setDate(newDate);
    };
    const dispatch = useDispatch();

    const [showFilter, setShowFilter] = useState(false);

    const handleFilterSubmit = (data: IFilterCashFlow) => {
        setFilterData(data);
    };

    const filterPersist: IFilterParams = useSelector(
        (state: RootState) => state.filtersPersistance
    );

    const formatDate = (date: DateTime | null): string => {
        if (date) {
            return date.toFormat('dd/MM/yyyy');
        }
        return '';
    };

    const filterDate = formatDate(date);

    useEffect(() => {
        dispatch(
            saveFilters({
                date: filterDate,
                filterTransactionType: filterPersist.filters.filterTransactionType,
                filterMotionType: filterPersist.filters.filterMotionType,
                startTime: filterPersist.filters.startTime,
                endTime: filterPersist.filters.endTime,
            })
        );
    }, [date]);

    return (
        <ContainerFlex backG="" Height="40px">
            <ContainerFlex Width="21.08%">
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    Margin="0 0 4px 0"
                    BoxShadowH=""
                    BoxShadowF=""
                    Padding="0 1rem 0 0"
                    BackG="#FFFFFF"
                    Radius="32px"
                    InputPadding="0 1rem"
                >
                    <Input
                        type="text"
                        placeholder="Buscar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Image src={SearchImg} Cursor="pointer" />
                </SearchInput>
            </ContainerFlex>

            <ContainerFlex Justify="end">
                <ContainerFlex Width="20rem">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePickerCustom
                            inputFormat="dd/MM/yyyy"
                            value={date}
                            onChange={handleDateChange}
                            maxDate={DateTime.now()}
                            components={{OpenPickerIcon: DateIcon}}
                            renderInput={({inputRef, inputProps, InputProps}) => (
                                <CalendarFieldComponent error={false}>
                                    <CalendarIconContainer>
                                        {InputProps?.endAdornment}
                                    </CalendarIconContainer>
                                    <InputCalendarField
                                        ref={inputRef}
                                        {...inputProps}
                                        value={filterPersist.filters.date}
                                        placeholder="Hoy"
                                        ColorPlace="#54575C"
                                    />
                                    <DropIcon />
                                </CalendarFieldComponent>
                            )}
                        />
                    </LocalizationProvider>
                </ContainerFlex>
                <ContainerFlex
                    Radius="32px"
                    Border="1px solid #5A5AFF"
                    Padding="8px 16px"
                    Width="9.313rem"
                    Height="40px"
                    Cursor="pointer"
                    onClick={() => setShowFilter(true)}
                >
                    <Image src={Filter} Cursor="pointer" />
                    <Text FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                        Filtrar
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {showFilter && (
                <FilterCashFlow setShowFilter={setShowFilter} onSubmit={handleFilterSubmit} />
            )}
        </ContainerFlex>
    );
};
