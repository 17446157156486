import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {
    TableContainer,
    TableHeaderRow,
    TableBody,
    TableCell,
} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    COMMERCIAL_LIST,
    NO_ITEMS_FOUNDED_TEXT,
    SORT_IDENTIFIERS,
    Sorting,
} from '@components/InventoryManagement/constants';
import {CommercialInventoryItem} from '@components/InventoryManagement/interfaces';
import ListColumnHeader from '@components/InventoryManagement/ListColumnHeader';
import sortIcon from '@images/ListSortArrows.svg';
import sortAscIcon from '@images/ListSortArrowsAsc.svg';
import sortDescIcon from '@images/ListSortArrowsDesc.svg';
import CommercialInventoryListRow from '@components/InventoryManagement/CommercialInventoryListRow';

const CommercialInventoryList = () => {
    const inventoryItems: CommercialInventoryItem[] | [] = useSelector(
        (state: RootState) => state.getCommercialInventory.data
    );
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [inventoryData, setIventoryData] = useState(inventoryItems);

    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState('');

    const handleSort = useCallback(
        (column: string) => {
            const newDirection =
                sortColumn === column && sortDirection === Sorting.Asc ? Sorting.Desc : Sorting.Asc;
            setSortColumn(column);
            setSortDirection(newDirection);
        },
        [sortColumn, sortDirection]
    );

    const renderColSortIcon = useCallback(
        (column: string) => {
            const isActive = sortColumn === column;
            const iconMap = {
                [Sorting.Asc]: sortAscIcon,
                [Sorting.Desc]: sortDescIcon,
            };
            const icon = isActive ? iconMap[sortDirection] : sortIcon;

            return (
                <Image
                    src={icon}
                    alt={`sort-${isActive ? sortDirection : ''}`}
                    Width="0.875rem"
                    Height="0.875rem"
                    Cursor="pointer"
                    onClick={() => handleSort(column)}
                />
            );
        },
        [sortColumn, sortDirection, handleSort]
    );

    const handleSelectAll = () => {
        setIsAllSelected((prevIsAllSelected) => {
            const newIsAllSelected = !prevIsAllSelected;
            return newIsAllSelected;
        });
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => ({
                ...item,
                selected: !isAllSelected,
            }));
            return newInventoryData;
        });
    };

    const handleItemSelect = (id: number) => {
        setIventoryData((prevInventoryData) => {
            const newInventoryData = prevInventoryData.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            });
            return newInventoryData;
        });
    };

    return (
        <>
            {inventoryItems.length > 0 ? (
                <ContainerFlex
                    FlexDir="column"
                    Padding="1.5rem"
                    Justify="start"
                    Align="flex-start"
                    Radius="1.5rem"
                    Gap="1rem"
                    backG="#FFF"
                >
                    <TableContainer>
                        <TableHeaderRow
                            borderBottom="1px solid #E8E9EA"
                            gridColumns="1.125rem minmax(18.625rem,1fr) repeat(4, minmax(5rem, 1fr))"
                            gap="1rem"
                        >
                            <TableCell className="mediaPersist">
                                <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
                            </TableCell>
                            <>
                                <ListColumnHeader label={COMMERCIAL_LIST.PRODUCT} />
                                <ListColumnHeader
                                    label={COMMERCIAL_LIST.PRICE}
                                    renderSortIcon={() =>
                                        renderColSortIcon(SORT_IDENTIFIERS.loanAmount)
                                    }
                                />
                                <ListColumnHeader
                                    label={COMMERCIAL_LIST.STATUS}
                                    renderSortIcon={() =>
                                        renderColSortIcon(SORT_IDENTIFIERS.loanAmount)
                                    }
                                />
                                <ListColumnHeader label={COMMERCIAL_LIST.SKU} />
                                <ListColumnHeader label={COMMERCIAL_LIST.ADDED} />
                                <TableCell />
                            </>
                        </TableHeaderRow>
                        <TableBody overflowY="scroll">
                            {inventoryData &&
                                inventoryData.map((inventory) => (
                                    <CommercialInventoryListRow
                                        key={inventory.id}
                                        inventory={inventory}
                                        handleItemSelect={handleItemSelect}
                                    />
                                ))}
                        </TableBody>
                    </TableContainer>
                </ContainerFlex>
            ) : (
                <ContainerFlex Justify="center" Align="center">
                    <Text Color="#1D1E20" FontWeight="400" Cursor="pointer" FontSize="1rem">
                        {NO_ITEMS_FOUNDED_TEXT}
                    </Text>
                </ContainerFlex>
            )}
        </>
    );
};

export default CommercialInventoryList;
