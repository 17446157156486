import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import Quoter from '@components/GlobalQuoter/Quoter';
import globalQuoterBanner from '@images/global_quoter_banner.svg';
import LoanCarrousel from '@components/GlobalQuoter/LoanCarrousel';

const QuoterContainer = () => {
    return (
        <ContainerFlex
            backG={`url(${globalQuoterBanner}) no-repeat`}
            BackgroundSize="cover"
            Justify="center"
            Align="center"
            FlexDir="column"
            Gap="24px"
            Padding="32px 60px 24px 60px"
            MaxH="695px"
        >
            <Quoter isExpressQuoter={false} isPublicQuoter={false} />
            <LoanCarrousel />
        </ContainerFlex>
    );
};

export default QuoterContainer;
