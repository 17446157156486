import {GET_INVENTORY_PRODUCTS} from '@components/InventoryManagement/Redux/types';
import {
    InventoryProducts,
    InventoryProductsAction,
} from '@components/InventoryManagement/Redux/interfaces';

const initialState: InventoryProducts = {
    data: {
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        totalItems: 0,
        actualPage: 1,
        data: {
            branchId: 0,
            branchName: '',
            contractIcon: '',
            expirationIcon: '',
            items: [],
        },
    },
    loading: false,
    error: false,
    priceRangeFilter: null,
};

const GetProductsForInventory = (
    state = initialState,
    action: InventoryProductsAction
): InventoryProducts => {
    switch (action.type) {
        case GET_INVENTORY_PRODUCTS.START:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: '',
            };
        case GET_INVENTORY_PRODUCTS.SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                errorMessage: '',
            };
        case GET_INVENTORY_PRODUCTS.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case GET_INVENTORY_PRODUCTS.ACTUAL_PAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    actualPage: action.actualPage || 1,
                },
            };
        default:
            return state;
    }
};
export default GetProductsForInventory;
