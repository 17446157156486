import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {TableRow, TableCell} from '@General/Atoms/TableList/styles';
import {ContainerFlex, Image, Text, TextEllipsis} from '@EcommerceShopify/styles';
import {useInventoryActions} from '@components/InventoryManagement/hooks/useInventoryActions';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import useFormatDate from '@/hooks/useFormatDate';
import useToast from '@/hooks/useToast';
import {ContractListRowProps} from '@components/InventoryManagement/interfaces';
import {ActionMenuOption} from '@components/ActionMenu/ActionMenuOption';
import {Contract} from '@components/InventoryManagement/Redux/interfaces';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {getStyle} from '@components/InventoryManagement/constants';
import {StatusPill} from '@components/InventoryManagement/styles';
import {
    PRODUCT_OPTIONS,
    INVENTORY_PLEDGE_STATUS,
    mapInventoryProduct,
    CONTRACT_INVENTORY_LIST,
} from '@components/InventoryManagement/constants';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

const InventoryContractListRow = ({
    contract,
    handleItemSelect,
    handleItemMenuClick,
    isMenuOpen,
}: ContractListRowProps) => {
    const {handleEndorsementDischarge} = useInventoryActions();
    const {showToast, ToastContainer} = useToast();
    const {inventoryData} = useSelector((state: RootState) => ({
        inventoryData: state.GetContractsForInventory.data.data,
    }));

    const handleError = (message: string) => {
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const getItemMenuOptions = (item: Contract) => [
        {
            id: 'extension',
            name: PRODUCT_OPTIONS.EXTEND,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    mapInventoryProduct(item),
                    {extend: true},
                    handleError
                ),
        },
        {
            id: 'passToCapital',
            name: PRODUCT_OPTIONS.PASS_TO_CAPITAL,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    mapInventoryProduct(item),
                    {addPay: true},
                    handleError
                ),
        },
        {
            id: 'discharge',
            name: PRODUCT_OPTIONS.DISCHARGE,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.DISCHARGE,
                    mapInventoryProduct(item),
                    {},
                    handleError
                ),
        },
    ];
    const scoreStyle = getStyle(contract.contractStatus);
    return (
        <>
            <TableRow
                borderBottom="1px solid #E8E9EA"
                gridColumns="1.125rem 16.5rem repeat(5, minmax(5rem, 1fr)) 1.5rem"
                gap="1rem"
            >
                <TableCell>
                    <Checkbox
                        checked={contract.selected}
                        onChange={() => handleItemSelect(contract.contractNumber)}
                    />
                </TableCell>
                <TableCell>
                    <ContainerFlex Gap="0.5rem">
                        <Image
                            src={inventoryData.contractsIcon}
                            Height="3rem"
                            Width="3rem"
                            Radius="0.25rem"
                            Border="1px solid #E8E9EA"
                            alt="article"
                        />
                        <ContainerFlex FlexDir="column" Gap="0.5rem" Align="flex-start">
                            <TextEllipsis Color="#5A5AFF" FontSize="0.875rem" FontWeight="700">
                                {CONTRACT_INVENTORY_LIST.CONTRACT}: {contract.contractNumber}
                            </TextEllipsis>
                            <TextEllipsis Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                {contract.itemsCount}
                            </TextEllipsis>
                        </ContainerFlex>
                    </ContainerFlex>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <TextEllipsis Color="#5A5AFF" FontSize="0.875rem" FontWeight="700">
                        {contract.clientName}
                    </TextEllipsis>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <TextEllipsis Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {numberToCurrency(contract.appraisal)}
                    </TextEllipsis>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {numberToCurrency(contract.loan)}
                    </Text>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {useFormatDate(contract.expires)}
                    </Text>
                </TableCell>
                <TableCell padding="0.25rem 0">
                    <StatusPill
                        border={scoreStyle.border}
                        backgroundColor={scoreStyle.backgroundColor}
                    >
                        <Text FontWeight="500" FontSize="0.75rem" Color={scoreStyle.color}>
                            {contract.contractStatus}
                        </Text>
                    </StatusPill>
                </TableCell>
                <TableCell padding="0" overFlow="visible">
                    {contract.contractStatus === INVENTORY_PLEDGE_STATUS.IN_EFFECT && (
                        <ContainerFlex Cursor="pointer" Height="2rem" Width="2rem">
                            <ActionMenuOption
                                id={contract.contractNumber}
                                menuOptions={getItemMenuOptions(contract)}
                                onOptionClick={() => handleItemMenuClick(contract)}
                                isOpen={isMenuOpen}
                            />
                        </ContainerFlex>
                    )}
                </TableCell>
            </TableRow>
            <ToastContainer />
        </>
    );
};

export default InventoryContractListRow;
