import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ProductSpecificationsItemProps} from '@components/InventoryManagement/interfaces';

const ProductSpecificationsItem: React.FC<ProductSpecificationsItemProps> = ({
    value,
    title,
    lastItem,
}) => {
    return (
        <ContainerFlex
            Padding="1.125rem 1rem 1.125rem 1rem"
            Height="3.25rem"
            Bb={!lastItem ? '1px solid #E8E9EA' : 'none'}
        >
            <ContainerFlex Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="700" FontSize="0.875rem">
                    {title}
                </Text>
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem">
                    {value}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default ProductSpecificationsItem;
