export const buttonStyles = {
    Height: '2rem',
    Padding: '0.25rem 1rem',
    BackGC: '#FFF',
    Justify: 'center',
    HBackG: '#FFF',
    DBackG: '#E5E5FF',
    Border: '1px solid #5A5AFF',
    Cursor: 'pointer',
    DBorder: '1px solid #5A5AFF',
};
