import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {useForm} from 'react-hook-form';

import ButtonGeneral from '@General/Atoms/Button';
import {COMPANY} from '@components/SignUp/constants';
import {Checkbox} from '@General/Atoms/Checkbox/Checkbox';
import {catCompany, ICatTypeBusiness} from '@components/SignUp/interfaces';
import {saveCompanytData} from '@components/SignUp/Redux/Actions/saveDataFormAction';
import {ContainerFlex, Input, Text, Image, ContainerForm} from '@Shopify/Ecommerce/styles';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaYourCompany} from '@/components/SignUp/validationsYup';

export const YourCompany = () => {
    const createAccount = useSelector((state: RootState) => state.saveDataSingUp);
    const {catBusiness} = useSelector((state: RootState) => state.catPlaceAndBusiness);
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'onChange',
        defaultValues: {company: ''},
        resolver: yupResolver(schemaYourCompany),
    });

    const values = watch();
    const dispatch: AppDispatch = useDispatch();
    const [typeBussines, setTypeBussines] = useState<number[]>([]);

    const typeBooleanBusiness = (id: number) => {
        if (!typeBussines.includes(id)) setTypeBussines([...typeBussines, id]);
        if (typeBussines.includes(id))
            setTypeBussines(typeBussines.filter((item: number) => item !== id));
    };

    const onSubmit = () => {
        const business = catBusiness
            .map((item: catCompany) => {
                return typeBussines.includes(item.id)
                    ? {
                          planName: COMPANY.PLANNAME + item.bussiness,
                          price: item.price ? item.price : 0,
                      }
                    : {planName: ''};
            })
            .filter((item: catCompany) => item.planName !== '');

        dispatch(
            saveCompanytData({
                CompanyName: values.company,
                TypeBusinessId: typeBussines,
                BusinessItem: business,
            })
        );
    };

    useEffect(() => {
        if (createAccount.company && createAccount.company.CompanyName) {
            reset({company: createAccount.company.CompanyName});
            setTypeBussines(createAccount.company.TypeBusinessId);
        }
    }, []);

    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            Gap="1.5rem"
            Width="27.25rem"
            FlexDir="column"
        >
            <ContainerFlex FlexDir="column" Gap="0.5rem">
                <Text FontSize="1.5rem" FontWeight="500">
                    {COMPANY.TITLE}
                </Text>
                <Text>{COMPANY.SUBTITILE}</Text>
            </ContainerFlex>
            <ContainerFlex Align="start" FlexDir="column" Gap="0.25rem">
                <Text FontSize="0.75rem" FontWeight="500">
                    {COMPANY.COMPANYNAME}
                </Text>
                <Input {...register('company')} error={errors.company?.message} />
                {errors.company && (
                    <Text FontSize="0.75rem" Color="red">
                        {errors.company.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" Height="2.322rem">
                    {COMPANY.ADITIONALINFO}
                </Text>
                <ContainerFlex FlexDir="column" Gap="0.5rem">
                    <Text FontSize="1.5rem" FontWeight="500">
                        {COMPANY.TYPEBUSINESS}
                    </Text>
                    <Text wSpace="normal" TextAlign="center">
                        {COMPANY.EXPERIENCE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex Display="grid" Justify="space-between" GridColumns="repeat(2, 48%)">
                {catBusiness.map((item: ICatTypeBusiness, index: number) => (
                    <ContainerFlex
                        Align="start"
                        Cursor="pointer"
                        key={index}
                        onClick={() => typeBooleanBusiness(item.id)}
                        Border={
                            typeBussines.includes(item.id)
                                ? '1px solid #5A5AFF'
                                : '1px solid #F4F5F5'
                        }
                        Radius="1rem"
                        Padding="0.5rem"
                    >
                        <Checkbox checked={typeBussines.includes(item.id)} />
                        <ContainerFlex Cursor="pointer" FlexDir="column">
                            <Image
                                Cursor="pointer"
                                Width="56px"
                                src={item.image}
                                alt={item.description}
                            />
                            <Text Cursor="pointer" FontWeight="500">
                                {item.bussiness}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            <ButtonGeneral
                width="100%"
                text={COMPANY.BTNCONTINUE}
                dColor="#A1A5AA"
                disabled={
                    values.company.length < 2 ||
                    values.company.length > 50 ||
                    typeBussines.length === 0 ||
                    Object.keys(errors).length > 0
                }
            />
        </ContainerForm>
    );
};
