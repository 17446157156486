import axios from 'axios';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';
import {GET_CONTRACT_STATUSES} from '@components/InventoryManagement/Redux/types';
import {ContractStatusesResponse} from '@components/InventoryManagement/Redux/interfaces';

const getContractStatusTypesStart = () => ({
    type: GET_CONTRACT_STATUSES.START,
});

const getContractStatusTypesSuccess = (result: ContractStatusesResponse) => ({
    type: GET_CONTRACT_STATUSES.SUCCESS,
    payload: result,
});

const getContractStatusTypesError = () => ({
    type: GET_CONTRACT_STATUSES.ERROR,
});

export const GetContractStatusTypes = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getContractStatusTypesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContractStatusActive}`,
                {headers}
            );
            dispatch(getContractStatusTypesSuccess(response.data.data));
        } catch (error) {
            dispatch(getContractStatusTypesError());
        }
    };
};
