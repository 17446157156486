import React from 'react';

import {ContainerFlex, Image, TextEllipsis} from '@Shopify/Ecommerce/styles';
import ArrowLeft from '@images/arrowLeftIconGray.svg';
import {CategoryItem} from '@components/InventoryManagement/interfaces';

export function CommercialCategoryItem({label, isLastItem = false}: CategoryItem) {
    return (
        <ContainerFlex MaxW="4.93rem">
            <TextEllipsis FontWeight="400" FontSize="0.75rem" Color="#54575C" Width="100%">
                {label}
            </TextEllipsis>
            {!isLastItem && <Image src={ArrowLeft} alt="arrow-left" Height="1rem" />}
        </ContainerFlex>
    );
}
