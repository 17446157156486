import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    PASSWORD_CONFIG,
    RESET_PASSWORD,
    TIMER_FINISHED,
    TIMER_STARTED,
} from '@/components/UserProfileResetPassword/Operational/Constants';
import {RootState} from '@/config/store';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {useDispatch, useSelector} from 'react-redux';
import {PinUser} from '@/components/UserProfileResetPassword/PinFormUser';
import {PostSendPasswordEmailAction} from '@/components/UserProfileResetPassword/Redux/Actions/PostSendPasswordEmailAction';
import {IProfileUserResetPassword} from '@components/UserProfileResetPassword/Operational/interface';

export const ProfileUserResetPassword = ({
    localCounter,
    initiateTimer,
}: IProfileUserResetPassword) => {
    const isComponentLoading = useSelector((state: RootState) => state.AutoPassUserofAdmin.loading);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const userEmail = useSelector(
        (state: RootState) => state?.UserProfileAdmin?.userData?.data?.employeeInfo?.email
    );
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const maskPassword = (password: string) => {
        return '*'.repeat(password.length);
    };

    if (isComponentLoading) {
        return (
            <ContainerFlex>
                <Loading />
            </ContainerFlex>
        );
    }

    const handlePassword = async () => {
        if (userEmail && token) {
            setIsLoading(true);
            await dispatch(PostSendPasswordEmailAction({Email: userEmail}, token));
            setIsLoading(false);
            initiateTimer(TIMER_STARTED);
        }
    };

    return (
        <ContainerFlex FlexDir="column" Justify="start" Gap="16px" Height="max-content">
            <ContainerFlex
                FlexDir="column"
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
                Height=""
            >
                <ContainerFlex Justify="space-between">
                    <Text FontWeight="600" FontSize="1.25rem" Color="#2A2C2F">
                        {RESET_PASSWORD.TITLE_TWO}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Gap="16px">
                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="16px">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.PASS}
                        </Text>
                        <Text FontWeight="700" Color="#2A2C2F">
                            {maskPassword(PASSWORD_CONFIG.currentPassword)}
                        </Text>
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start" Justify="start">
                        <Text FontWeight="700">{PASSWORD_CONFIG.CHANGE_PASS}</Text>
                        {isLoading && (
                            <ContainerFlex>
                                <Loading />
                            </ContainerFlex>
                        )}
                        {!isLoading && (
                            <>
                                {localCounter === TIMER_FINISHED && (
                                    <Text
                                        FontWeight="700"
                                        FontSize="16px"
                                        Color="#0D166B"
                                        Cursor="pointer"
                                        onClick={handlePassword}
                                    >
                                        {PASSWORD_CONFIG.SEND_LINK}
                                    </Text>
                                )}

                                {localCounter > TIMER_FINISHED && (
                                    <Text FontWeight="700" FontSize="12px">
                                        {`${PASSWORD_CONFIG.TIMER}${localCounter} seg`}
                                    </Text>
                                )}
                            </>
                        )}
                    </ContainerFlex>

                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="16px">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.EDIT_PASSWORD}
                        </Text>
                        <Text>{PASSWORD_CONFIG.lastChangeDate}</Text>
                    </ContainerFlex>
                    <ContainerFlex />
                </ContainerFlex>
            </ContainerFlex>
            <PinUser />
        </ContainerFlex>
    );
};
