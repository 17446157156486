import React, {useState} from 'react';

import {buttonStyles} from '@General/Atoms/ListViewSwitch/stylesConstants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Image} from '@EcommerceShopify/styles';
import {DynamicViewSwitcherProps} from '@General/Atoms/ListViewSwitch/interfaces';
import tabularIcon from '@images/tabularLayoutIcon.svg';
import listIcon from '@images/listLayoutIcon.svg';
import {VIEW_TYPE} from '@General/Atoms/ListViewSwitch/constants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

const DynamicViewSwitcher = <T,>({
    data,
    loading,
    renderTableView,
    renderListView,
    defaultView = VIEW_TYPE.TABLE,
}: DynamicViewSwitcherProps<T>) => {
    const [view, setView] = useState<string>(defaultView);
    return (
        <>
            <ContainerFlex Justify="flex-end" Gap="0.5rem" Height="2rem">
                <ButtonGenerals
                    {...buttonStyles}
                    type="button"
                    onClick={() => setView(VIEW_TYPE.TABLE)}
                    disabled={view === VIEW_TYPE.TABLE}
                >
                    <Image src={tabularIcon} alt="tabular-icon" />
                </ButtonGenerals>
                <ButtonGenerals
                    {...buttonStyles}
                    type="button"
                    onClick={() => setView(VIEW_TYPE.LIST)}
                    disabled={view === VIEW_TYPE.LIST}
                >
                    <Image src={listIcon} alt="list-icon" />
                </ButtonGenerals>
            </ContainerFlex>
            {loading && (
                <ContainerFlex Height="15rem">
                    <LoadingAtaskate width="8.375rem" padding="2.813rem" />
                </ContainerFlex>
            )}
            {!loading && (view === VIEW_TYPE.TABLE ? renderTableView(data) : renderListView(data))}
        </>
    );
};

export default DynamicViewSwitcher;
