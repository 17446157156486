import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {InventoryLocationStorageListItemProps} from '@components/InventoryManagement/interfaces';
import {INVENTORY_LOCATION} from '@components/InventoryManagement/constants';
import {useDispatch} from 'react-redux';
import {
    setSelectedStorageItemType,
    setSelectedStorageListItem,
} from '@components/InventoryManagement/Redux/Actions/InventoryLocationStorage';

const InventoryLocationStorageListItem: React.FC<InventoryLocationStorageListItemProps> = ({
    storageItem,
}) => {
    const dispatch = useDispatch();

    const onSelectedStorageListItem = () => {
        dispatch(setSelectedStorageListItem(storageItem));
        dispatch(setSelectedStorageItemType(null));
    };

    return (
        <ContainerFlex
            Cursor="pointer"
            Justify="start"
            Align="start"
            ColumnGap="0.5rem"
            HBackground="#f0f0ff"
            HBorderLeft="0.125rem solid #5a5aff"
            FlexDir="Column"
            Gap="0.5rem"
            Padding="0.656rem 0.5rem 0.656rem 0.5rem"
            Height={'2.5rem'}
            onClick={onSelectedStorageListItem}
        >
            <ContainerFlex
                Justify="start"
                Align="start"
                ColumnGap="0.5rem"
                FlexDir="row"
                Gap="0.5rem"
                Display="grid"
                GridColumns="65% 35%"
            >
                <ContainerFlex Justify="start">
                    <Text Color="#2A2C2F" FontWeight="400" Cursor="pointer" FontSize="1rem">
                        {storageItem.name}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FontSize="0.75rem" Justify="end" Padding="0 0.5rem">
                    <Text Color="#54575C" FontWeight="400" Cursor="pointer" FontSize="0.75rem">
                        {storageItem.quantity} {INVENTORY_LOCATION.ARTICLES}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default InventoryLocationStorageListItem;
