import React from 'react';
import {useDispatch} from 'react-redux';
import {setOpenLocationModal} from '@components/InventoryManagement/Redux/Actions/InventoryLocationStorage';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {SELECT_LOCATION_BUTTON} from '@/components/LocateProducts/constants';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import arrowIcon from '@images/arrow_down_small.svg';

export default function SelectLocationButton({locations}: {locations: string[]}) {
    const dispatch = useDispatch();

    const setShowModal = () => {
        dispatch(setOpenLocationModal(true));
    };

    return (
        <ButtonGenerals
            Width="100%"
            BackGC="#FFFFFF"
            Border="solid 1px #E8E9EA"
            Padding=".72rem 1rem"
            HBackG="#FFFFFF"
            onClick={() => setShowModal()}
        >
            <ContainerFlex Justify="space-between">
                {locations.length > 0 ? (
                    <ContainerFlex Justify="start">
                        {locations.map((location, index) => {
                            return (
                                <Text
                                    key={index}
                                    Color="#2A2C2F"
                                    FontWeight={index === 0 ? '500' : '400'}
                                >
                                    {index === locations.length - 1
                                        ? location
                                        : `${location + SELECT_LOCATION_BUTTON.SPACER}`}
                                </Text>
                            );
                        })}
                    </ContainerFlex>
                ) : (
                    <Text Color="#A1A5AA">{SELECT_LOCATION_BUTTON.DEFAULT}</Text>
                )}
                <Image src={arrowIcon} alt="arrow-icon" Width="1rem" Height="1rem" />
            </ContainerFlex>
        </ButtonGenerals>
    );
}
