import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {Contract} from '@components/InventoryManagement/Redux/interfaces';
import useToast from '@/hooks/useToast';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {InventoryProductCardsProps} from '@components/InventoryManagement/interfaces';
import Pagination from '@/components/General/Atoms/ListPagination/Pagination';
import ContractCard from '@components/InventoryManagement/ContractCard';

const InventoryContractsCards: React.FC<InventoryProductCardsProps> = ({onPageChange}) => {
    const {ToastContainer} = useToast();
    const [openOption, setOpenOption] = useState<Contract | null>(null);
    const {inventoryData} = useSelector((state: RootState) => ({
        inventoryData: state.GetContractsForInventory.data,
    }));
    const inventoryContracts = inventoryData?.data ?? {contracts: []};
    const handlePageChange = (newPage: number) => {
        if (onPageChange) onPageChange(newPage);
    };

    const handleOptionClick = (item: Contract) =>
        setOpenOption((prevItem) => (prevItem === item ? null : item));

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Gap="1rem"
            backG="#FAFAFA"
            Radius="1rem"
            FlexWrap="wrap"
        >
            {inventoryContracts?.contracts?.length > 0 &&
                inventoryContracts.contracts.map((contract, index) => {
                    return (
                        <ContractCard
                            key={index}
                            contract={contract}
                            inventoryContracts={inventoryContracts}
                            openOption={openOption}
                            handleOptionClick={handleOptionClick}
                        />
                    );
                })}
            {inventoryData?.totalItems >= inventoryData?.pageSize && (
                <ContainerFlex Gap="0.5rem" Height="2.5rem">
                    <Pagination
                        pageNumber={inventoryData.pageNumber ?? 0}
                        totalPages={inventoryData.totalPages ?? 1}
                        totalItems={inventoryData.totalItems ?? 0}
                        pageSize={inventoryData.pageSize ?? 0}
                        onPageChange={inventoryData.totalItems > 0 ? handlePageChange : () => {}}
                    />
                </ContainerFlex>
            )}
            <ToastContainer />
        </ContainerFlex>
    );
};
export default InventoryContractsCards;
