import React from 'react';
import {ContainerFlex, Image, TextEllipsis} from '@Shopify/Ecommerce/styles';
import ArrowLeft from '@images/arrowLeftIconGray.svg';
import {CategoryItemProps} from '@/components/LocateProducts/interfaces';

export function CategoryItem({label, showIcon = false}: CategoryItemProps) {
    return (
        <ContainerFlex MaxW="5.2rem">
            <TextEllipsis FontWeight="400" FontSize="0.75rem" Color="#54575C" Width="100%">
                {label}
            </TextEllipsis>
            {!showIcon && <Image src={ArrowLeft} alt="arrow-left" Height="1rem" />}
        </ContainerFlex>
    );
}
