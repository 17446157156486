import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import ProductPaigeDescription from '@components/InventoryManagement/ProductPageDescription';
import ProductImages from '@components/InventoryManagement/ProductImages';

const ProductPage: React.FC = () => {
    return (
        <ContainerFlex
            Align="start"
            Margin="0rem 2.25rem 6.313rem 2.25rem"
            Justify="start"
            Gap="1rem"
            Border="1px solid #E8E9EA"
            backG="#FFFFFF"
            Radius="1.5rem"
            Padding="1.5rem"
            Height="83rem"
        >
            <ContainerFlex MinW="24.25rem">
                <ProductImages />
            </ContainerFlex>
            <ProductPaigeDescription />
        </ContainerFlex>
    );
};
export default ProductPage;
