export const GET_CONDITION_STATUS_ARRAY_START = 'GET_CONDITION_STATUS_ARRAY_START';
export const GET_CONDITION_STATUS_ARRAY_SUCCESS = 'GET_CONDITION_STATUS_ARRAY_SUCCESS';
export const GET_CONDITION_STATUS_ARRAY_ERROR = 'GET_CONDITION_STATUS_ARRAY_ERROR';

export const GET_CONDITIONS_START = 'GET_CONDITIONS_START';
export const GET_CONDITIONS_SUCCESS = 'GET_CONDITIONS_SUCCESS';
export const GET_CONDITIONS_ERROR = 'GET_CONDITIONS_ERROR';

export const GET_TOTAL_CONDITIONS_START = 'GET_TOTAL_CONDITIONS_START';
export const GET_TOTAL_CONDITIONS_SUCCESS = 'GET_TOTAL_CONDITIONS_SUCCESS';
export const GET_TOTAL_CONDITIONS_ERROR = 'GET_TOTAL_CONDITIONS_ERROR';
