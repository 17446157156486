import styled from '@emotion/styled';
import {
    DropdownOptionProps,
    FormControlLabelStyledProps,
    MenuItemStyledProps,
    SelectAllWrapperProps,
    SelectStyledProps,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const SelectScoreStyle = styled.div`
    position: relative;
    width: 100%;
    max-width: 363px;
`;
export const SelectStyled = styled.div<SelectStyledProps>`
    max-width: 329px;
    max-height: 42px;
    display: grid;
    grid-template-columns: 95% 5%;
    background-color: #ffffff;
    border: 1px solid #e8e9ea;
    border-radius: 32px;
    padding: 4px 16px;
    position: relative;
    cursor: pointer;
    align-items: center;
    overflow: hidden;

    &:focus {
        border-color: #2a2c2f;
        outline: none;
    }
`;

export const ChipStyled = styled.div`
    display: inline-flex;
    align-items: start;
    height: 24px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #f3f3f3;
    width: fit-content;
`;
export const ChipText = styled.span`
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
`;

export const MenuItemStyled = styled.div<MenuItemStyledProps>`
    display: flex;
    justify-content: start;
    padding: ${(props) => props.Padding || '8px'};
    gap: 8px;
    border-left: 2px solid transparent;
    cursor: pointer;

    &:hover {
        border-left: 2px solid #5a5aff;
        background-color: #e5e5ff;
        color: #5a5aff;
    }
`;

export const FormControlLabelStyled = styled.label<FormControlLabelStyledProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;

    span {
        color: #2a2c2f;
        font-family: 'Nunito';
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.3px;
    }
`;

export const CheckboxStyled = styled.input`
    cursor: pointer;
    margin: 0;
    width: 16px;
    height: 16px;
`;

export interface Option {
    id: string;
    name: string;
    description: string;
}

export interface DropdownProps {
    options: Option[];
}

export const DropdownContainer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 363px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000;
`;

export const DropdownOption = styled.div<DropdownOptionProps>`
    display: flex;
    justify-content: start;
    padding: ${(props) => props.Padding || '8px'};
    gap: 8px;
    border-left: 2px solid transparent;
    cursor: pointer;

    &:hover {
        border-left: 2px solid #5a5aff;
        background-color: #e5e5ff;
        color: #5a5aff;
    }
`;

export const OptionText = styled.div`
    margin-left: 8px;
`;

export const OptionDescription = styled.p`
    margin: 4px 0;
    font-size: 12px;
    color: #555;
`;

export const SelectAllWrapper = styled.div<SelectAllWrapperProps>`
    display: flex;
    padding: ${(props) => props.Padding || '8px'};
    gap: 8px;
    border-left: 2px solid transparent;
    cursor: pointer;
    height: 24px;
    &:hover {
        border-left: 2px solid #5a5aff;
        background-color: #e5e5ff;
        color: #5a5aff;
    }
`;

export const SelectAllText = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;

    span {
        color: #2a2c2f;
        font-family: 'Nunito';
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.3px;
    }
`;

export const CheckboxInput = styled.input`
    cursor: pointer;
    margin: 0;
    width: 16px;
    height: 16px;
`;
