import React, {useEffect, useRef} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@EcommerceShopify/styles';
import {
    VIEW_TYPE,
    productCardsByPage,
    PRICE_RANGES,
} from '@components/InventoryManagement/constants';
import DynamicViewSwitcher from '@General/Atoms/ListViewSwitch/DynamicListViewSwitch';
import InventoryProductsList from '@components/InventoryManagement/InventoryProductsList';
import InventoryProductCards from '@components/InventoryManagement/InventoryProductCards';
import {ProductForInventoryProps} from '@components/InventoryManagement/interfaces';
import {GetProdutsForInventoryPledge} from '@/components/InventoryManagement/Redux/Actions/GetProductsForInventoryPledge';
import {InventoryProductsFilter} from '@components/InventoryManagement/Redux/interfaces';

const ProductsForInventory: React.FC<ProductForInventoryProps> = ({
    onViewChange,
    listView,
    inventorySearch,
    priceRange,
    status,
    selectedLocationId,
}) => {
    const dispatch = useDispatch();
    const abortControllerRef = useRef<AbortController | null>(null);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token.toString() || '';
    });
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });
    const clientBranches = useSelector((state: RootState) => {
        return state.GetBranchStorageByCompanyId;
    });
    const loading = useSelector((state: RootState) => {
        return state.GetProductsForInventory.loading;
    });
    const errorMsg = useSelector((state: RootState) => {
        return state.GetProductsForInventory.errorMessage;
    });
    const currentView = listView ? VIEW_TYPE.LIST : VIEW_TYPE.TABLE;

    const fetchData = async (pageNumber: number, signal?: AbortSignal) => {
        const filters: InventoryProductsFilter = {};
        if (inventorySearch) {
            filters.ByName = {
                item1: inventorySearch,
                item2: 5,
            };
        }
        if (priceRange) {
            const filterRange = {
                item1: Number(priceRange.from),
                item2: Number(priceRange.to),
            };
            const filterMap: Record<string, keyof InventoryProductsFilter> = {
                [PRICE_RANGES.TO_500]: 'ByMaximunAmount',
                [PRICE_RANGES.MORE_THAN_1000]: 'ByMinimunAmount',
                [PRICE_RANGES.EQUAL_OR_MORE_THAN]: 'ByMinimunAmount',
            };
            const key = filterMap[priceRange.type] || 'ByRange';
            if (key === 'ByMaximunAmount' || key === 'ByMinimunAmount' || key === 'ByRange') {
                filters[key] = filterRange;
            }
        }
        if (status) {
            filters.ByStatus = {
                item1: status.value,
                item2: 0,
            };
        }
        const selectedBranch = clientBranches.locations.find(
            (location) => location.id === selectedLocationId
        );
        const catTypeLocationId = selectedBranch?.typeId || 0;
        const locationId = selectedBranch?.id || branchId;
        if (token && branchId) {
            await dispatch(
                GetProdutsForInventoryPledge(
                    Number(locationId),
                    catTypeLocationId,
                    pageNumber,
                    productCardsByPage,
                    token,
                    filters,
                    signal
                )
            );
        }
    };
    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        const newAbortController = inventorySearch ? new AbortController() : null;
        if (newAbortController) {
            abortControllerRef.current = newAbortController;
        }

        fetchData(1, newAbortController?.signal);

        return () => {
            abortControllerRef.current?.abort();
            abortControllerRef.current = null;
        };
    }, [currentView, inventorySearch, priceRange, status, selectedLocationId]);

    const renderTableView = () => {
        onViewChange(false);
        if (errorMsg) {
            return (
                <ContainerFlex FontWeight="500" Color="#2A2C2F" FontSize="0.875rem" Height="1rem">
                    {errorMsg}
                </ContainerFlex>
            );
        }
        return <InventoryProductCards onPageChange={fetchData} />;
    };

    const renderListView = () => {
        onViewChange(true);
        if (errorMsg) {
            return (
                <ContainerFlex FontWeight="500" Color="#2A2C2F" FontSize="0.875rem" Height="1rem">
                    {errorMsg}
                </ContainerFlex>
            );
        }
        return <InventoryProductsList onPageChange={fetchData} />;
    };

    return (
        <ContainerFlex FlexDir="column" Justify="flex-start" Gap="1rem">
            <DynamicViewSwitcher
                loading={loading}
                renderTableView={renderTableView}
                renderListView={renderListView}
                defaultView={currentView}
            />
        </ContainerFlex>
    );
};

export default ProductsForInventory;
