import {
    VALIDATE_LINK_EXPIRED,
    VALIDATE_LINK_EXPIRED_ERROR,
    VALIDATE_LINK_EXPIRED_SUCCESS,
} from '@components/Login/Redux/Types/Types';
import {AxiosResponse} from 'axios';

const initialState = {
    isLinkAvailable: false,
    employeeId: 0,
    loadingLinkValidation: false,
    error: false,
    token: '',
};

const validateLink = (state = initialState, action: {type: string; payload: AxiosResponse}) => {
    switch (action.type) {
        case VALIDATE_LINK_EXPIRED:
            return {...state, loadingLinkValidation: true};
        case VALIDATE_LINK_EXPIRED_SUCCESS:
            return {
                ...state,
                loadingLinkValidation: false,
                isLinkAvailable: action.payload.data.data.status,
                employeeId: action.payload.data.data.userEmployeeId,
                token: action.payload.data.token,
            };
        case VALIDATE_LINK_EXPIRED_ERROR:
            return {...state, loadingLinkValidation: false, error: true};
        default:
            return state;
    }
};
export default validateLink;
