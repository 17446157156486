import React from 'react';

import {ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import Breadcrumb from '@components/General/Moleculs/Breadcrumb/Index';
import {
    INVENTORY_PAGE,
    OPERATIONS,
    BreadInventory,
} from '@components/InventoryManagement/constants';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import arrowIcon from '@images/arrowSmallWhite.svg';
import {ContainerTabs} from '@General/ModuleTabs/styles/styles';
import Commercial from '@components/InventoryManagement/Commercial';
import Pawnbroker from '@components/InventoryManagement/Pawnbroker';

const Inventory = () => {
    return (
        <ContainerFlex
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem"
            Justify="start"
            Gap="1rem"
        >
            <Breadcrumb
                fontSize="0.75rem"
                fontWeight="700"
                color="#0D166B"
                items={BreadInventory}
            />
            <ContainerFlex Justify="space-between" Height="2.5rem">
                <Text FontSize="1.75rem" FontWeight="700" Color="#2A2C2F">
                    {INVENTORY_PAGE.TITLE}
                </Text>
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="500"
                    Cursor="pointer"
                >
                    {INVENTORY_PAGE.ACTIONS}
                    <Image
                        src={arrowIcon}
                        alt="arrow-icon"
                        Width="1rem"
                        Height="1rem"
                        Transform="rotate(180deg)"
                    />
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerTabs
                tabs={OPERATIONS.TABS_TITLE}
                Gap="1rem"
                FlexDir="column"
                Align="center"
                Justify="start"
            >
                <Commercial />
                <Pawnbroker />
            </ContainerTabs>
        </ContainerFlex>
    );
};

export default Inventory;
