import React from 'react';

import {
    INVENTORY_PRODUCT,
    INVENTORY_PLEDGE_STATUS,
} from '@components/InventoryManagement/constants';
import clipboardIcon from '@images/clipboardCheck.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import useFormatDate from '@/hooks/useFormatDate';
import {ProductContractInfoProps} from '@components/InventoryManagement/interfaces';

const ProductContractInfo: React.FC<ProductContractInfoProps> = ({pledge, item}) => {
    return (
        <>
            <ContainerFlex
                Align="start"
                Justify="start"
                Padding="1.5rem 1rem 1rem 1rem"
                Height="3.5rem"
            >
                <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                    {item.itemName}
                    {item.subtitle && (
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            FontSize="0.875rem"
                            MarginLeft="0.5rem"
                        >
                            {item.subtitle}
                        </Text>
                    )}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="Column"
                Align="start"
                Justify="start"
                PaddingL="1rem"
                Height="2.813rem"
            >
                <Text Color="#1D1E20" FontWeight="600" FontSize="1.25rem">
                    {numberToCurrency(item.loan)}
                    {item.datCarShopInventory.amountDiscountInterest && (
                        <Text
                            Border="solid 1px #B1E7C9"
                            BGColor="#E4F7EC"
                            Color="#174A2E"
                            FontWeight="700"
                            FontSize="0.875rem"
                            Margin="0.125rem 0.5rem 0.125rem 0.5rem"
                            BorderRadius="2rem"
                            Padding="0.125rem 0.5rem"
                        >
                            {item.datCarShopInventory.amountDiscountInterest}
                            {INVENTORY_PRODUCT.PERCENT_OFF}
                        </Text>
                    )}
                </Text>

                <Text Color="#54575C" FontWeight="400" FontSize="0.875rem" PaddingT="0.25rem">
                    {item.loanMessage}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="Column"
                Align="start"
                Justify="start"
                Padding="1rem"
                PaddingL="1rem"
                Gap="0.5rem"
            >
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={pledge.contractIcon} alt="contract" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {item.contractNumber}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={clipboardIcon} alt="status" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {INVENTORY_PLEDGE_STATUS.STATUS}: {item.itemStatus}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Height="1.25rem">
                    <Image src={pledge.expirationIcon} alt="calendar" />
                    <Text Color="#2A2C2F" FontWeight="400" FontSize="0.875rem" MarginLeft="0.5rem">
                        {INVENTORY_PLEDGE_STATUS.EXPIRES}:{' '}
                        {item.itemStatus === INVENTORY_PLEDGE_STATUS.DISCHARGED
                            ? '--'
                            : useFormatDate(item.expires)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default ProductContractInfo;
