import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {PRE_APPROVAL_TEXTS} from '@/components/PreApproval/constants';
import SuggestionPreApproval from '@components/PreApproval/SuggestionPreApproval';
import ConditonPreApprovalTable from '@components/PreApproval/ConditonPreApprovalTable';
import Add from '@images/add - plus white.svg';
import {getConditionStatusArray} from '@/components/PreApproval/Redux/Actions/ConditionStatusActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

const PreApproval = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });

    useEffect(() => {
        if (token) dispatch(getConditionStatusArray(token));
    }, [token]);

    return (
        <ContainerFlex
            FlexDir="column"
            Padding="24px"
            Gap="24px"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex Justify="space-between" Align="center" Height="66px">
                <ContainerFlex FlexDir="column" Gap="8px" Justify="flex-start" Align="flex-start">
                    <Text FontFamily="Nunito" FontSize="1.75rem" FontWeight="700" Color="#1D1E20">
                        {PRE_APPROVAL_TEXTS.TITLE}
                    </Text>
                    <Text FontFamily="Nunito" Color="#2A2C2F" LetterSpacing="0.3px">
                        {PRE_APPROVAL_TEXTS.DESCRIPTION}
                    </Text>
                </ContainerFlex>
                {/* TODO: Cambiar a archivo router cuando se suba la historia 209 */}
                <ButtonGenerals onClick={() => navigate('/NewLoanCondition')}>
                    <Image src={Add} alt="add" />
                    {PRE_APPROVAL_TEXTS.BUTTON}
                </ButtonGenerals>
            </ContainerFlex>
            <ConditonPreApprovalTable />
            <SuggestionPreApproval />
        </ContainerFlex>
    );
};

export default PreApproval;
