import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CONFIRMATION_LOAN_START,
    GET_CONFIRMATION_LOAN_SUCCESS,
    GET_CONFIRMATION_LOAN_ERROR,
    GET_ADDITIONAL_LOANS_OPTIONS_START,
    GET_ADDITIONAL_LOANS_OPTIONS_SUCCESS,
    GET_ADDITIONAL_LOANS_OPTIONS_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@/config/constants';

export const GetConfirmationLoanStart = () => {
    return {
        type: GET_CONFIRMATION_LOAN_START,
    };
};

export const GetConfirmationLoanSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CONFIRMATION_LOAN_SUCCESS,
        payload: result,
    };
};

export const GetConfirmationLoanError = (error: AxiosError) => {
    return {
        type: GET_CONFIRMATION_LOAN_ERROR,
        error,
    };
};

export const GetAdditionalLoansOptionsStart = () => {
    return {
        type: GET_ADDITIONAL_LOANS_OPTIONS_START,
    };
};

export const GetAdditionalLoansOptionsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ADDITIONAL_LOANS_OPTIONS_SUCCESS,
        payload: result,
    };
};

export const GetAdditionalLoansOptionsError = (error: AxiosError) => {
    return {
        type: GET_ADDITIONAL_LOANS_OPTIONS_ERROR,
        error,
    };
};

export function GetConfirmationStepData(token: string, solicitudId: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(GetConfirmationLoanStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStatusApplicationValidate}`,
                {headers, params: {solicitudId}}
            );
            dispatch(GetConfirmationLoanSuccess(response));
        } catch (error) {
            dispatch(GetConfirmationLoanError(error as AxiosError));
        }
    };
}

export function GetAdditionalLoansOptions(token: string, solicitudeId: number) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(GetAdditionalLoansOptionsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSimilarQuotersLoansCompanies}`,
                {headers, params: {solicitudeId}}
            );
            dispatch(GetAdditionalLoansOptionsSuccess(response));
        } catch (error) {
            dispatch(GetAdditionalLoansOptionsError(error as AxiosError));
        }
    };
}
