import React from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import thumbUp from '@images/thumb_up.svg';
import fillStar from '@images/fill_star.svg';
import emptyStar from '@images/empty_star.svg';
import {IMAGE_ALT, LOAN_CARD_TAG} from '@/components/GlobalQuoter/TempConstants';
import {CompanyInfoProps} from '@components/GlobalQuoter/interface';
import CompanyAvatarDefault from '@components/CreditQuoter/CompanyAvatarDefault';

const CompanyInfo = ({
    needTag,
    companyName,
    rating,
    logo,
    companyAvatarColor,
    labelPersonCounter,
    isConfirmationPage,
}: CompanyInfoProps) => {
    const ratingArray = [...Array(5)].map((_, i) => (
        <Image
            key={i}
            src={i < rating ? fillStar : emptyStar}
            alt={`rating star ${i < rating ? 'fill' : 'empty'}`}
        />
    ));
    const people = labelPersonCounter !== 1 ? LOAN_CARD_TAG.PERSONS : LOAN_CARD_TAG.PERSON;
    const labelPeopleCounter = LOAN_CARD_TAG.POPULAR + labelPersonCounter + people;
    return (
        <ContainerFlex
            FlexDir="column"
            Padding="16px 32px 16px 16px"
            Gap="8px"
            Justify="flex-start"
            Align="flex-start"
            MaxW={isConfirmationPage ? '167px' : '300px'}
        >
            {needTag ? (
                <ContainerFlex
                    Gap="4px"
                    Justify="flex-start"
                    Align="center"
                    Padding="4px"
                    Radius="8px"
                    backG="#FFE5FB"
                    Height="24px"
                    MaxW="167px"
                >
                    <Image src={thumbUp} alt={IMAGE_ALT.THUMB_UP} />
                    <Text FontSize="0.75rem" Color="#2A2C2F" FontFamily="Nunito">
                        {labelPeopleCounter}
                    </Text>
                </ContainerFlex>
            ) : (
                <ContainerFlex Width="167px" Height="24px" />
            )}
            <ContainerFlex Justify="flex-start" Align="flex-start" Gap="16px" MaxW="196px">
                {!logo ? (
                    <CompanyAvatarDefault
                        companyName={companyName}
                        Width="40px"
                        Height="40px"
                        color={companyAvatarColor}
                    />
                ) : (
                    <Image
                        src={logo}
                        alt={IMAGE_ALT.BANK_LOGO}
                        Width="40px"
                        Height="40px"
                        Radius="40px"
                    />
                )}

                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start" Gap="8px">
                    <Text
                        FontFamily="Nunito"
                        FontSize="1.25rem"
                        Color="#0D166B"
                        FontWeight="600"
                        TextDecoration="underline"
                        wSpace="pre-wrap"
                    >
                        {companyName}
                    </Text>
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        Height="12px"
                        Width="68px"
                    >
                        {ratingArray}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CompanyInfo;
