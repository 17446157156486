import * as yup from 'yup';
import {NEW_CLIENT_VALIDATION_MSSGS} from '@components/Shopify/ListClient/constants';

const rfcRegex =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
const curpRegex = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[A-Z\d]{2}$/;
export const schemaCreateClientLevelOne = yup.object().shape(
    {
        imageUser: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.IMAGE_REQUIRED)
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.IMAGE_TYPE),
        names: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NAMES_REQUIRED),
        lastName: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.LAST_NAME_REQUIRED),
        secondLastName: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.SECOND_LAST_NAME_REQUIRED),
        mainPhone: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.PHONE_REQUIRED)
            .matches(/^[0-9]*$/, NEW_CLIENT_VALIDATION_MSSGS.PHONE_NUMERIC)
            .max(10, NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN),
        mainEmail: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_REQUIRED)
            .email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT),
        civilStatusId: yup
            .object()
            .shape({
                value: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.CIVIL_STATUS_REQUIRED),
                label: yup.string(),
            })
            .required()
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.CIVIL_STATUS_REQUIRED),
        economicActivityId: yup
            .object()
            .shape({
                value: yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.ECONOMIC_ACTIVITY_REQUIRED),
                label: yup.string(),
            })
            .required()
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.ECONOMIC_ACTIVITY_REQUIRED),
        genderId: yup
            .object()
            .shape({
                value: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.GENDER_REQUIRED),
                label: yup.string(),
            })
            .required()
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.GENDER_REQUIRED),
        countryOfBirthId: yup
            .object()
            .shape({
                value: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.COUNTRY_OF_BIRTH_REQUIRED),
                label: yup.string(),
            })
            .required()
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.COUNTRY_OF_BIRTH_REQUIRED),
        rfc: yup
            .string()
            .matches(rfcRegex, NEW_CLIENT_VALIDATION_MSSGS.RFC_INVALID)
            .required(NEW_CLIENT_VALIDATION_MSSGS.RFC_REQUIRED),
        curp: yup
            .string()
            .matches(curpRegex, NEW_CLIENT_VALIDATION_MSSGS.CURP_INVALID)
            .required(NEW_CLIENT_VALIDATION_MSSGS.CURP_REQUIRED),
        nationality: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NATIONALITY_REQUIRED),
        catIdentificationTypeId: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.ID_TYPE_REQUIRED),
        identificationNumber: yup
            .string()
            .required(NEW_CLIENT_VALIDATION_MSSGS.IDENTIFICATION_NUMBER_REQUIRED),
        idDocument: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.DOCUMENT_REQUIRED),
        secondEmail: yup.string().email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT).optional(),
        landline: yup
            .string()
            .nullable()
            .test('is-valid-landline', NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN, function (value) {
                if (!value) return true;
                const isTenDigits = /^\d{10}$/.test(value);
                return isTenDigits;
            }),
        cellPhone: yup
            .string()
            .nullable()
            .test('is-valid-cellPhone', NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN, function (value) {
                if (!value) return true;
                const isTenDigits = /^\d{10}$/.test(value);
                return isTenDigits;
            }),
        anotherPhone: yup
            .string()
            .nullable()
            .test('is-valid-anotherPhone', NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN, function (value) {
                if (!value) return true;
                const isTenDigits = /^\d{10}$/.test(value);
                return isTenDigits;
            }),

        notes: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NOTES_REQUIRED),
        coownerFirstName0: yup.string().when('coownerFirstName0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerPaternalLastname0: yup.string().when('coownerPaternalLastname0', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.SURNAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerMaternalLastname0: yup.string().notRequired(),
        coownerPhone0: yup.string().when('coownerPhone0', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.PHONE_REQUIRED)
                    .min(10, NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerEmail0: yup.string().when('coownerEmail0', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_REQUIRED)
                    .email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerFirstName1: yup.string().when('coownerFirstName1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerPaternalLastname1: yup.string().when('coownerPaternalLastname1', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.SURNAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerMaternalLastname1: yup.string().notRequired(),
        coownerPhone1: yup.string().when('coownerPhone1', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.PHONE_REQUIRED)
                    .min(10, NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerEmail1: yup.string().when('coownerEmail1', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_REQUIRED)
                    .email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerFirstName2: yup.string().when('coownerFirstName2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerPaternalLastname2: yup.string().when('coownerPaternalLastname2', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.SURNAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerMaternalLastname2: yup.string().notRequired(),
        coownerPhone2: yup.string().when('coownerPhone2', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.PHONE_REQUIRED)
                    .min(10, NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerEmail2: yup.string().when('coownerEmail2', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_REQUIRED)
                    .email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerFirstName3: yup.string().when('coownerFirstName3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.NAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerPaternalLastname3: yup.string().when('coownerPaternalLastname3', (val) => {
            if (val[0] !== undefined) {
                return yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.SURNAME_REQUIRED);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerMaternalLastname3: yup.string().notRequired(),
        coownerPhone3: yup.string().when('coownerPhone3', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.PHONE_REQUIRED)
                    .min(10, NEW_CLIENT_VALIDATION_MSSGS.PHONE_MIN);
            } else {
                return yup.string().notRequired();
            }
        }),
        coownerEmail3: yup.string().when('coownerEmail3', (val) => {
            if (val[0] !== undefined) {
                return yup
                    .string()
                    .required(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_REQUIRED)
                    .email(NEW_CLIENT_VALIDATION_MSSGS.EMAIL_FORMAT);
            } else {
                return yup.string().notRequired();
            }
        }),
        catCustomerMarketingId: yup
            .object()
            .shape({
                value: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.HOW_HEARD_ABOUT_US),
                label: yup.string(),
            })
            .required()
            .typeError(NEW_CLIENT_VALIDATION_MSSGS.HOW_HEARD_ABOUT_US),
        other: yup.string().optional(),
        address: yup.object().required(),
    },

    [
        ['coownerFirstName0', 'coownerFirstName0'],
        ['coownerPaternalLastname0', 'coownerPaternalLastname0'],
        ['coownerMaternalLastname0', 'coownerMaternalLastname0'],
        ['coownerPhone0', 'coownerPhone0'],
        ['coownerEmail0', 'coownerEmail0'],
        ['coownerFirstName1', 'coownerFirstName1'],
        ['coownerPaternalLastname1', 'coownerPaternalLastname1'],
        ['coownerMaternalLastname1', 'coownerMaternalLastname1'],
        ['coownerPhone1', 'coownerPhone1'],
        ['coownerEmail1', 'coownerEmail1'],
        ['coownerFirstName2', 'coownerFirstName2'],
        ['coownerPaternalLastname2', 'coownerPaternalLastname2'],
        ['coownerMaternalLastname2', 'coownerMaternalLastname2'],
        ['coownerPhone2', 'coownerPhone2'],
        ['coownerEmail2', 'coownerEmail2'],
        ['coownerFirstName3', 'coownerFirstName3'],
        ['coownerPaternalLastname3', 'coownerPaternalLastname3'],
        ['coownerMaternalLastname3', 'coownerMaternalLastname3'],
        ['coownerPhone3', 'coownerPhone3'],
        ['coownerEmail3', 'coownerEmail3'],
    ]
);

export const schemaCreateClientLevelTwo = yup.object().shape({
    typeOfReceiptId: yup
        .object()
        .shape({
            value: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.RECEIPT_TYPE_REQUIRED),
            label: yup.string(),
        })
        .required()
        .typeError(NEW_CLIENT_VALIDATION_MSSGS.RECEIPT_TYPE_REQUIRED),
    effectiveDate: yup
        .date()
        .required(NEW_CLIENT_VALIDATION_MSSGS.EFFECTIVE_DATE_REQUIRED)
        .typeError(NEW_CLIENT_VALIDATION_MSSGS.EFFECTIVE_DATE_REQUIRED),
    imageProof: yup
        .string()
        .required(NEW_CLIENT_VALIDATION_MSSGS.PROOF_IMAGE_REQUIRED)
        .test('fileType', NEW_CLIENT_VALIDATION_MSSGS.PROOF_IMAGE_TYPE, function (value) {
            const {imageFileExtension} = this.parent;
            return imageFileExtension && ['jpg', 'png'].includes(imageFileExtension.toLowerCase());
        }),
    imageFileExtension: yup
        .string()
        .required(NEW_CLIENT_VALIDATION_MSSGS.IMAGE_EXTENSION_REQUIRED)
        .oneOf(['jpg', 'png'], NEW_CLIENT_VALIDATION_MSSGS.PROOF_IMAGE_TYPE),
});

export const schemaCreateClientLevelThree = yup.object().shape({
    fingerPrint: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.FINGERPRINT_REQUIRED),
});
