import styled from '@emotion/styled';

export const TableContainer = styled.div<{
    padding?: string;
    gap?: string;
    backGround?: string;
    border?: string;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: ${({border}) => border};
    border-radius: 0.5rem;
    overflow: hidden;
    padding: ${({padding}) => padding};
    gap: ${({gap}) => gap};
    background-color: ${({backGround}) => backGround || '#FFF'};
`;

export const TableHeaderRow = styled.div<{
    borderBottom?: string;
    background?: string;
    padding?: string;
    gap?: string;
    gridColumns: string;
}>`
    display: grid;
    gap: ${({gap}) => gap};
    grid-template-columns: ${({gridColumns}) => gridColumns};
    align-items: center;
    background-color: ${({background}) => background};
    border-bottom: ${({borderBottom}) => borderBottom || '1px solid #E8E9EA'};
    padding: ${({padding}) => padding || '0.5rem 1rem'};

    @media (max-width: 670px) {
        background-color: #f5f5f5;
    }
`;

export const TableBody = styled.div<{maxHeight?: string; overflowY?: string}>`
    max-height: ${({maxHeight}) => maxHeight};
    overflow-y: ${({overflowY}) => overflowY || 'hidden'};

    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;

export const TableRow = styled.div<{
    borderBottom?: string;
    gridColumns: string;
    padding?: string;
    gap?: string;
}>`
    display: grid;
    gap: ${({gap}) => gap};
    grid-template-columns: ${({gridColumns}) => gridColumns};
    align-items: center;
    border-bottom: ${({borderBottom}) => borderBottom || '1px solid #E8E9EA'};
    padding: ${({padding}) => padding || '0.5rem 1rem'};

    &:last-child {
        border-bottom: none;
    }

    @media (max-width: 670px) {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        padding: 0.4rem 1rem;
        align-items: flex-start;
        border-radius: 0.5rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    }
`;

export const TableCell = styled.div<{
    padding?: string;
    align?: string;
    gap?: string;
    overFlow?: string;
}>`
    display: flex;
    flex-direction: row;
    gap: ${({gap}) => gap};
    padding: ${({padding}) => padding};
    align-items: ${({align}) => align || 'center'};
    overflow: ${({overFlow}) => overFlow || 'hidden'};
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 670px) {
        padding: 0.5rem 0;
        align-items: flex-start;
    }
`;

export const TableHeaderLabel = styled.span`
    font-weight: 700;
    color: #1d1e20;
    min-width: 7.5rem;
    font-size: 0.875rem;
`;
