import React, {useEffect} from 'react';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {AssignedRole} from '@/components/Users/UserProfile/RoleSettings/NewSelectRole';
import {RolesCompanyUserlist} from '@/components/Users/UserProfile/Redux/Actions/GetRoleUserAction';

const RoleSettings = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const {loading} = useSelector((state: RootState) => state.getRoleCompany);

    const userId = employeeId;

    useEffect(() => {
        if (token && userId) {
            dispatch(RolesCompanyUserlist(token, userId));
        }
    }, [token, userId, dispatch]);

    return (
        <ContainerFlex>
            {loading ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="16px">
                        <AssignedRole />
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};

export default RoleSettings;
