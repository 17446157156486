import {GET_CONTRACTS_INVETORY} from '@components/InventoryManagement/Redux/types';
import {
    ContractsInventory,
    ContractsInventoryAction,
} from '@components/InventoryManagement/Redux/interfaces';

const initialState: ContractsInventory = {
    data: {
        pageNumber: 1,
        pageSize: 20,
        totalPages: 0,
        totalItems: 0,
        actualPage: 1,
        data: {
            branchId: 0,
            branchName: '',
            contractsIcon: '',
            contracts: [],
        },
    },
    loading: false,
    error: false,
    priceRangeFilter: null,
};

const GetContractsForInventory = (
    state = initialState,
    action: ContractsInventoryAction
): ContractsInventory => {
    switch (action.type) {
        case GET_CONTRACTS_INVETORY.START:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: '',
            };
        case GET_CONTRACTS_INVETORY.SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                errorMessage: '',
            };
        case GET_CONTRACTS_INVETORY.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
            };
        case GET_CONTRACTS_INVETORY.ACTUAL_PAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    actualPage: action.actualPage || 1,
                },
            };
        default:
            return state;
    }
};
export default GetContractsForInventory;
