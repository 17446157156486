export const RESET_PASSWORD = {
    TITLE: 'Restablecer contraseña',
    TITLE_TWO: 'Información de inicio de sesión',
    INDICATION:
        'Puedes cambiar la contraseña o configurar que la contraseña se actualice determinado tiempo para seguridad.',
    TEX_PASS: 'Nueva Contraseña',
    REPEAT_PASS: 'Repetir contraseña',
    GENERATE: 'Genera automática mente',
    TEX_PIN: 'Quieres agregar un PIN? (Solo aplica para POS)',
    DATA_PIN: 'Numero de PIN',
    BTN: 'Cancelar',
    BTN_KEEP: 'Guardar',
    ACTUAL_PASS: 'Contraseña actual',
    NEW_PASS: 'Pedir actaulizacion cada',
    EDIT_PASSWORD: 'Pedir actualización cada',
    PASS: 'Contraseña',
    TEX_EDIT: 'Editar',
};

export const RESTORE_NEW_PASSWORD = {
    MESSAGE: '8 caracteres como minimo.',
    PASSWORD: 'Contraseña',
    MESSAGE_ONE: 'Debe incluir una mayúscula, una minúscula, un número y',
    MESSAGE_TWO: ' un carácter especial.',
    REPEAT_PASS: '8 caracteres como minimo',
    SAME_PASSWORD: '8 caracteres como minimo.',
    CLOSE: 'Close icon',
    DONE: 'Close icon',
};

export const VALIDA_FORM = {
    MIN: '8 caracteres como minimo.',
    REQUIRED: 'Este campo es obligatorio',
    MATCHES: 'Debe incluir una mayúscula, una minúscula, un número y un carácter ',
    ONE_OF: 'Las contraseñas deben coincidir',
    PASS: 'password',
};

export const PIN_TEX = {
    TITLE: 'PIN de acceso para terminales POS',
    DESCRIPTION:
        'Este permite un acceso rápido y seguro, asegurando que solo usuarios autorizados operen el sistema',
    INPUT: 'Numero de PIN',
    AUTO: 'Auto-generar',
};
export const DEFAULT_USER_VALUES = {
    img: '',
    name: '',
    lastNames: '',
    birthdate: '',
    phone: {
        dialCode: '+52',
        number: '',
    },
    email: '',
    cp: '',
    street: '',
    externalNumber: '',
    internalNumber: '',
    colony: '',
    municipality: '',
    state: '',
    password: '',
    repeatPassword: '',
    dayNewPassword: '',
};

export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
] as const;

export const DAYS_IN_MONTH = {
    Enero: 31,
    Febrero: 28,
    Marzo: 31,
    Abril: 30,
    Mayo: 31,
    Junio: 30,
    Julio: 31,
    Agosto: 31,
    Septiembre: 30,
    Octubre: 31,
    Noviembre: 30,
    Diciembre: 31,
};

export const TEXTS = {
    PLACEHOLDER_DAY: 'Día',
    PLACEHOLDER_MONTH: 'Mes',
};

export const PASSWORD_CONFIG = {
    currentPassword: 'Xy$K9p#2024',
    lastChangeDate: '2 - febrero',
    CHANGE_PASS: 'Redefinir contraseña',
    SEND_LINK: 'Enviar Link',
    TIMER: 'Volver a solicitar en ',
};

export const TIMER_FINISHED = 0;
export const TIMER_STARTED = 30;
