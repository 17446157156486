import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {CustomSelect} from '@Articles/CustomSelect';
import {RootState} from '@/config/store';
import {
    GroupSelect,
    ResetArticle,
    ResetBrand,
    ResetFamily,
    ResetSubfamily,
    getArticleLevel,
    getBrandLevel,
    getFamilyLevel,
    getSubfamilyLevel,
} from '@/components/Articles/Redux/Actions/SearchManagement';
import {DataNodeProps, ISearchAllCatalog, IUse} from '@/components/Articles/Redux/interfaces';
import {
    saveArticle,
    saveBrand,
    saveFamily,
    saveGroup,
    saveSubFamily,
} from '@/components/Articles/Redux/Actions/SavedLevels';
import {LevelsSelector} from '@Articles/ReactiveComponent';
import {selectedDataNode} from '@Articles/Redux/Actions/GetInheritedFeatures';
import {LEVELNAMES} from '@components/Articles/constants';

export const DinamicLevels = () => {
    const dispatch = useDispatch();
    const [group, setGroup] = useState<IUse[]>([{id: 0, name: ''}]);
    const [family, setFamily] = useState<IUse[]>([{id: 0, name: '', groupId: 0}]);
    const [subFamily, setSubFamily] = useState<IUse[]>([{id: 0, name: '', familyId: 0}]);
    const [brand, setBrand] = useState<IUse[]>([{id: 0, name: '', idSubFamily: 0}]);
    const [article, setArticle] = useState<IUse[]>([{id: 0, name: '', brandId: 0}]);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const Levels: ISearchAllCatalog = useSelector(
        (state: RootState) => state.ArticleManagementSearch
    );
    const DataNode = useSelector((state: RootState) => state.SelectedDataNode.dataNode);
    const LevelsId = useSelector((state: RootState) => state.SavedLevels);

    const updateSelectedNode = (catLevel: number, level: number) => {
        const updatedIds = DataNode.filter((item: DataNodeProps) => item.level < level);
        updatedIds.push({catLevel, level});
        dispatch(selectedDataNode(updatedIds));
    };
    const HandleGroup = (data: IUse) => {
        if (token) {
            dispatch(ResetFamily(setFamily));
            dispatch(ResetSubfamily(setSubFamily));
            dispatch(ResetBrand(setBrand));
            dispatch(ResetArticle(setArticle));
            dispatch(getFamilyLevel(token, String(data.id)));
            dispatch(saveGroup(data.id));
            updateSelectedNode(data.id, 1);
        }
    };

    const HandleFamily = (data: IUse) => {
        if (token) {
            dispatch(getSubfamilyLevel(token, String(data.id)));
            dispatch(ResetBrand(setBrand));
            dispatch(ResetArticle(setArticle));
            dispatch(saveFamily(data.id));
            updateSelectedNode(data.id, 2);
        }
    };

    const HandleSubFamily = (data: IUse) => {
        if (token) {
            dispatch(getBrandLevel(token, String(data.id)));
            dispatch(ResetBrand(setBrand));
            dispatch(ResetArticle(setArticle));
            dispatch(saveSubFamily(data.id));
            updateSelectedNode(data.id, 3);
        }
    };

    const handleBrand = (data: IUse) => {
        if (token) {
            dispatch(getArticleLevel(token, String(data.id)));
            dispatch(ResetArticle(setArticle));
            dispatch(saveBrand(data.id));
            updateSelectedNode(data.id, 4);
        }
    };

    const handleArticle = (data: IUse) => {
        if (token) {
            dispatch(saveArticle(data.id));
            updateSelectedNode(data.id, 5);
        }
    };

    useEffect(() => {
        if (token) {
            LevelsSelector(Levels, token, setGroup, setFamily, setSubFamily, setBrand, setArticle);
        }
    }, [Levels.GroupSelect, Levels.Family, Levels.Subfamily, Levels.Brand, Levels.Article]);

    useEffect(() => {
        if (token) dispatch(GroupSelect(token));
    }, []);

    useEffect(() => {
        if (token) {
            if (LevelsId.GroupId) dispatch(getFamilyLevel(token, String(LevelsId.GroupId)));
            if (LevelsId.FamilyId) dispatch(getSubfamilyLevel(token, String(LevelsId.FamilyId)));
            if (LevelsId.SubfamilyId) dispatch(getBrandLevel(token, String(LevelsId.SubfamilyId)));
            if (LevelsId.BrandId) dispatch(getArticleLevel(token, String(LevelsId.BrandId)));
        }
    }, [LevelsId, token, dispatch]);
    return (
        <ContainerFlex ColumnGap="16px" Justify="start" Align="start">
            <CustomSelect
                data={group}
                levelName={LEVELNAMES.GROUP}
                title={LEVELNAMES.ONE}
                level={1}
                handleClick={(data) => HandleGroup({...data, groupId: data.id})}
                isSelected={LevelsId.GroupId}
                id={LevelsId.GroupId}
            />
            {LevelsId.GroupId !== LEVELNAMES.ZERO && (
                <CustomSelect
                    data={family}
                    levelName={LEVELNAMES.FAMILY}
                    title={LEVELNAMES.TWO}
                    level={2}
                    handleClick={(data) => HandleFamily({...data, groupId: LevelsId.GroupId})}
                    isSelected={LevelsId.FamilyId}
                    id={LevelsId.GroupId}
                />
            )}
            {LevelsId.FamilyId !== LEVELNAMES.ZERO && (
                <CustomSelect
                    data={subFamily}
                    levelName={LEVELNAMES.SUBFAMILY}
                    title={LEVELNAMES.THREE}
                    level={3}
                    handleClick={(data) => HandleSubFamily({...data, familyId: LevelsId.FamilyId})}
                    isSelected={LevelsId.SubfamilyId}
                    id={LevelsId.FamilyId}
                />
            )}
            {LevelsId.SubfamilyId !== LEVELNAMES.ZERO && (
                <CustomSelect
                    data={brand}
                    levelName={LEVELNAMES.BRAND}
                    title={LEVELNAMES.FOUR}
                    level={4}
                    handleClick={(data) =>
                        handleBrand({...data, idSubFamily: LevelsId.SubfamilyId})
                    }
                    isSelected={LevelsId.BrandId}
                    id={LevelsId.SubfamilyId}
                />
            )}
            {LevelsId.BrandId !== LEVELNAMES.ZERO && (
                <CustomSelect
                    data={article}
                    levelName={LEVELNAMES.ARTICLE}
                    title={LEVELNAMES.FIVE}
                    level={5}
                    handleClick={(data) => handleArticle({...data, brandId: LevelsId.BrandId})}
                    isSelected={LevelsId.ArticleId}
                    id={LevelsId.BrandId}
                />
            )}
        </ContainerFlex>
    );
};
