import React, {useEffect, useRef, useState} from 'react';
import {ContainerFlex, Image, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import fox from '@/images/FoxEmail.svg';
import {Input} from '@/components/MarketLogin/Styles';
import {Controller, useForm} from 'react-hook-form';
import ButtonGeneral from '@/components/General/Atoms/Button/index';
import {EMAIL_VERIFICATION} from '@/components/MarketLogin/constants';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import iconError from '@/images/iconError.svg';
import {
    addCodeLoginUser,
    addCountLoginUser,
    resetCodeLoginUser,
} from '@/components/SignUp/Redux/Actions/ResetPassCodeAction';
import {TextModule} from '@/components/SignUp/TextModule';
import arrow from '@/images/arrow_back.svg';
import {
    backToStep,
    clearValidationData,
} from '@/components/SignUp/Redux/Actions/saveDataFormAction';
import {getEmailVerificationUsersAdmin} from '@/components/SignUp/Redux/Actions/ValidateEmailAdminAction';
import {verifyNewCodeAdmin} from '@/components/SignUp/Redux/Actions/VerifyCodeUserAction';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {SAVE_CODE_VALIDATION} from '@/components/SignUp/Redux/types';
import {VALIDECODE} from '@/components/SignUp/constants';

export const ValideCode = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [error, setErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasInvalidInput, setHasInvalidInput] = useState(false);
    const count = useSelector((state: RootState) => state.CodePasswordChange.count);
    const code = useSelector((state: RootState) => state.CodePasswordChange.code);
    const {actualStep} = useSelector((state: RootState) => state.saveDataSingUp);
    const dataUser = useSelector((state: RootState) => state.saveDataSingUp.accountData);
    const {data, loading} = useSelector((state: RootState) => state.verifyCodeAdmin);
    const [isValidating, setIsValidating] = useState(false);

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {inputs: code, names: ''},
    });

    const [timer, setTimer] = useState(() => (count ? parseInt(count, 10) : 30));
    const [showReset, setShowReset] = useState(false);
    const countdownRef = useRef<NodeJS.Timeout | null>(null);
    const inputValues = watch('inputs');

    const handleFocus = () => {
        setErrors(false);
        setErrorMessage('');
    };
    useEffect(() => {
        if (timer > 0) {
            countdownRef.current = setInterval(() => {
                setTimer((prevTimer) => {
                    const newTime = prevTimer - 1;
                    dispatch(addCountLoginUser(newTime.toString()));
                    return newTime;
                });
            }, 1000);
        } else {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
            setShowReset(true);
        }
        return () => {
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        };
    }, [timer, dispatch]);

    const onSubmit = () => {
        if (!loading) {
            const verificationCode = inputValues.join('');
            dispatch(verifyNewCodeAdmin(verificationCode));
            setIsValidating(true);
        }
    };

    const handleReset = () => {
        setTimer(30);
        setShowReset(false);
        setErrors(false);
        setErrorMessage('');
        setHasInvalidInput(false);
        reset({inputs: Array(4).fill('')});
        dispatch(getEmailVerificationUsersAdmin(dataUser));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            setHasInvalidInput(false);
            const codeload = [...code];
            codeload[index] = value;
            dispatch(addCodeLoginUser(codeload));
            if (/^[0-9]$/.test(value)) {
                const nextInput = document.getElementById(`input-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }
        } else {
            setHasInvalidInput(true);
            const codeload = [...code];
            dispatch(addCodeLoginUser(codeload));
        }
    };

    const backPreviousStep = () => {
        if (actualStep !== 1) {
            setIsValidating(false);
            setErrors(false);
            setErrorMessage('');
            setHasInvalidInput(false);
            dispatch(backToStep());
            dispatch(clearValidationData());
            dispatch(resetCodeLoginUser());
        }
    };

    useEffect(() => {
        if (!loading && data && isValidating) {
            setIsValidating(false);
            if (data.data === false) {
                setErrors(true);
                setErrorMessage(data?.message);
            } else if (data.data === true) {
                dispatch({
                    type: SAVE_CODE_VALIDATION,
                    company: null,
                });
            }
        }
    }, [data, loading, dispatch, isValidating]);

    const isButtonDisabled = () => {
        return (
            !inputValues ||
            inputValues.length !== 4 ||
            inputValues.some((value: string) => !value) ||
            hasInvalidInput
        );
    };

    const renderInput = (index: number) => (
        <Controller
            key={index}
            name={`inputs.${index}`}
            control={control}
            render={({field}) => (
                <Input
                    id={`input-${index}`}
                    type="text"
                    maxLength={1}
                    hasError={error || !!errors.inputs || hasInvalidInput}
                    {...field}
                    autoComplete="off"
                    onChange={(e) => {
                        field.onChange(e);
                        handleChange(e, index);
                    }}
                    onFocus={handleFocus}
                />
            )}
        />
    );

    return (
        <>
            <ContainerFlex FlexDir="column" Gap="24px">
                <ContainerFlex FlexDir="column">
                    {actualStep !== 1 && (
                        <ContainerFlex Justify="start">
                            <Image Cursor="pointer" src={arrow} onClick={backPreviousStep} />
                        </ContainerFlex>
                    )}

                    <ContainerFlex>
                        <Image src={fox} />
                    </ContainerFlex>
                </ContainerFlex>

                <TextModule />
                <ContainerForm
                    Gap="24px"
                    Height="auto"
                    FlexDir="column"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ContainerFlex Gap="16px" FlexDir="column">
                        <ContainerFlex Gap="16px">
                            {Array(4)
                                .fill(0)
                                .map((_, index) => renderInput(index))}
                        </ContainerFlex>

                        {(error || hasInvalidInput) && (
                            <ContainerFlex Gap="4px">
                                <Image
                                    Height="14px"
                                    Width="14px"
                                    src={iconError}
                                    alt="error-icone"
                                />
                                <Text FontFamily="Nunito" FontSize="12px" Color="#FF6357">
                                    {hasInvalidInput ? VALIDECODE.IS_NUMBER : errorMessage}
                                </Text>
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                    <ContainerFlex>
                        {loading ? (
                            <LoadingAtaskate />
                        ) : (
                            <ButtonGeneral
                                FontSize="1rem"
                                padding="8px 16px"
                                height="40px"
                                width="100%"
                                text="Verificar"
                                type="submit"
                                disabled={isButtonDisabled()}
                            />
                        )}
                    </ContainerFlex>
                </ContainerForm>

                <ContainerFlex>
                    {timer > 0 && (
                        <Text FontFamily="Nunito" FontSize="16px" Color="#2A2C2F" FontWeight="600">
                            {EMAIL_VERIFICATION.TIMER_TEXT} {timer} seg
                        </Text>
                    )}
                    {showReset && (
                        <Text
                            Cursor="pointer"
                            FontSize="16px"
                            Color="#5A5AFF"
                            FontWeight="600"
                            onClick={handleReset}
                            FontFamily="Nunito"
                        >
                            {EMAIL_VERIFICATION.REQUEST_NEW_CODE}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
