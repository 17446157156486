import {Controller, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {ContainerFlex, Text, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import CreditScore from '@components/PersonalLoans/NewLoanProduct/CreditScore';
import {DOCUMENTCLIENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {CheckboxGender} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/Fields/CheckboxGender';
import {InputAge} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/Fields/InputAge';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {IClientsDocumentsFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';
import {DocumentsTypesForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/EditingForm/DocumentsTypesForm';
import {RootState} from '@/config/store';

export const ClientsDocumentsForm: React.FC<IClientsDocumentsFormProps> = ({
    isSubmitted,
    checkboxsState,
    setCheckBoxsState,
}) => {
    const scoreSelectedData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details.creditScore
    );
    const {
        resetField,
        control,
        register,
        formState: {errors},
        trigger,
        watch,
    } = useFormContext();
    const errorMessage = errors.ageMax?.message?.toString() || '';
    const formState = watch();
    useEffect(() => {
        if (isSubmitted) {
            trigger('ageMax');
        }
    }, [formState.ageMin, formState.ageMax]);
    return (
        <>
            <ContainerFlex Justify="start" Gap="1rem" Height="max-content">
                <ContainerCellGrid FlexDir="column" Align="start" Width="max-content" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.GENDER}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="16px" Height="1.5rem">
                        <CheckboxGender
                            control={control}
                            name="genderTerms[1].isChecked"
                            label="Mujer"
                            isChecked={checkboxsState.genderWoman}
                            handleChange={() => {
                                setCheckBoxsState({
                                    ...checkboxsState,
                                    genderWoman: !checkboxsState.genderWoman,
                                });
                            }}
                        />
                        <CheckboxGender
                            control={control}
                            name="genderTerms[0].isChecked"
                            label="Hombre"
                            isChecked={checkboxsState.genderMan}
                            handleChange={() => {
                                setCheckBoxsState({
                                    ...checkboxsState,
                                    genderMan: !checkboxsState.genderMan,
                                });
                            }}
                        />
                        <CheckboxGender
                            control={control}
                            name="genderTerms[2].isChecked"
                            label="Otro género"
                            isChecked={checkboxsState.otherGender}
                            handleChange={() => {
                                setCheckBoxsState({
                                    ...checkboxsState,
                                    otherGender: !checkboxsState.otherGender,
                                });
                            }}
                        />
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Gap="0.75rem" Width="27.25rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.AGE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="0.5rem" Height="2.5rem">
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.BETWEEN}
                        </Text>
                        <InputAge register={register} name="ageMin" />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.AND}
                        </Text>
                        <InputAge register={register} name="ageMax" />
                        <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#2A2C2F">
                            {DOCUMENTCLIENTS.YEARS}
                        </Text>
                    </ContainerFlex>
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="19.063rem" Gap="0.75rem">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.SCORE}{' '}
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    <CreditScore
                        control={control}
                        resetField={resetField}
                        initialSelectedRows={scoreSelectedData}
                    />
                    <ContainerFlex Justify="start" Gap="0.5rem">
                        <Controller
                            name="reviewCreditVoul"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Checkbox
                                    {...field}
                                    checked={checkboxsState.reviewCreditVoul}
                                    onChange={(e) => {
                                        onChange(e);
                                        setCheckBoxsState({
                                            ...checkboxsState,
                                            reviewCreditVoul: !checkboxsState.reviewCreditVoul,
                                        });
                                    }}
                                />
                            )}
                        />
                        <Text Color="#2A2C2F">{DOCUMENTCLIENTS.CHECKCREDITS}</Text>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <DocumentsTypesForm control={control} register={register} />
        </>
    );
};
