import {useForm} from 'react-hook-form';
import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {schemaUser} from '@components/SignUp/validationsYup';
import {ISignUpDataUser} from '@components/SignUp/interfaces';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerImg,
    Input,
    LinkAtaskate,
    Text,
} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import ButtonGeneral from '@General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {VisibilityOn} from '@components/SignUp/Icons/VisibilityOn';
import {VisibilityOff} from '@components/SignUp/Icons/VisibilityOff';
import {
    catTypeBusiness,
    catTypePlaces,
} from '@components/SignUp/Redux/Actions/catPlaceAndBusinessAction';
import {infoCompanyBenefit} from '@components/SignUp/Redux/Actions/companyBenefitAction';
import {PERSONAL_FORM, PASSWORD_REGEX, VALIDATIONS} from '@components/SignUp/constants';
import {addEmailUser} from '@/components/SignUp/Redux/Actions/EmailAction';
import {getEmailVerificationUsersAdmin} from '@/components/SignUp/Redux/Actions/ValidateEmailAdminAction';
import {saveAccountData} from '@/components/SignUp/Redux/Actions/saveDataFormAction';
import {ErrorPassword} from '@/components/SignUp/MessagePassError';
import {ErrorPasswordRepeat} from '@/components/SignUp/MessageRepeatPassError';
import {ErrorMessagesPass} from '@/components/SignUp/MesageError';
import {validateEmailExist} from '@/components/SignUp/Redux/Actions/validateEmailAction';
import {routers} from '@/appRoute';

export const CreateAccountEmail = () => {
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const defaultValues = useSelector((state: RootState) => state.saveDataSingUp.accountData);
    const loading = useSelector((state: RootState) => state.validateEmailAdministrator.loading);
    const validateResponse = useSelector((state: RootState) => state.validateEmailAdmin.data);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        watch,
        setValue,
    } = useForm<ISignUpDataUser>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schemaUser),
    });

    const password = watch('password');
    const repeatPassword = watch('confirmPassword');
    const isFormatError = errors.email?.message?.includes(PERSONAL_FORM.MESSAGE_FORMAT);

    useEffect(() => {
        if (validateResponse?.succeeded === true) {
            dispatch(saveAccountData(watch()));
        }
    }, [validateResponse, dispatch, watch]);

    const onsubmit = async (data: ISignUpDataUser) => {
        const result = (await dispatch(validateEmailExist(data, setError))) as unknown;
        if (result) {
            dispatch(getEmailVerificationUsersAdmin(data));
            dispatch(addEmailUser(data.email));
        }
    };

    const disabledButton = () => {
        const valuesForm = watch();
        const {password, confirmPassword, names, paternalSurname, maternalSurname, email} =
            valuesForm;
        const isPasswordValid = PASSWORD_REGEX.test(password);
        const isConfirmPasswordValid = confirmPassword === password;
        const isNamesValid = names?.trim() !== '' && names !== undefined;
        const isPaternalSurnameValid =
            paternalSurname?.trim() !== '' && paternalSurname !== undefined;
        const isMaternalSurnameValid =
            maternalSurname?.trim() !== '' && maternalSurname !== undefined;
        const isEmailValid = email?.trim() !== '' && email !== undefined;

        return (
            isPasswordValid &&
            isConfirmPasswordValid &&
            isNamesValid &&
            isPaternalSurnameValid &&
            isMaternalSurnameValid &&
            isEmailValid
        );
    };

    useEffect(() => {
        setValue('confirmPassword', '');
    }, [password, setValue]);

    useEffect(() => {
        dispatch(catTypeBusiness());
        dispatch(catTypePlaces());
        dispatch(infoCompanyBenefit());
    }, []);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const routeLogin = token ? routers.LoginAdmin : '/Login';

    return (
        <>
            <ContainerCellGrid Gap="8px" FlexDir="column" Align="center" Width="34vw">
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                    {PERSONAL_FORM.TITLE}
                </Text>
                <Text Color="#2A2C2F" FontSize="1rem">
                    {PERSONAL_FORM.SUBTITLE}
                </Text>
            </ContainerCellGrid>

            <ContainerCellGrid Gap="16px" Justify="space-between" Width="34vw">
                <ContainerCellGrid Gap="4px" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        {PERSONAL_FORM.NAME}
                    </Text>
                    <Input {...register('names')} error={errors.names?.message} Cursor="text" />
                    {errors.names?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.names.message}
                        </Text>
                    )}
                </ContainerCellGrid>
            </ContainerCellGrid>

            <ContainerCellGrid Gap="16px" Justify="space-between" Width="34vw">
                <ContainerCellGrid Gap="4px" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        {PERSONAL_FORM.FATHERS_LASTNAME}
                    </Text>
                    <Input
                        {...register('paternalSurname')}
                        error={errors.paternalSurname?.message}
                        Cursor="text"
                    />
                    {errors.paternalSurname?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.paternalSurname.message}
                        </Text>
                    )}
                </ContainerCellGrid>

                <ContainerCellGrid Gap="4px" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        {PERSONAL_FORM.MOTHER_LASTNAME}
                    </Text>
                    <Input
                        {...register('maternalSurname')}
                        error={errors.maternalSurname?.message}
                        Cursor="text"
                    />
                    {errors.maternalSurname?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.maternalSurname.message}
                        </Text>
                    )}
                </ContainerCellGrid>
            </ContainerCellGrid>

            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.EMAIL}
                </Text>
                <Input
                    {...register('email')}
                    error={errors.email?.message}
                    Cursor="text"
                    autoComplete="off"
                />
                {errors.email?.message &&
                    (isFormatError ? (
                        <ContainerFlex FlexDir="column" Justify="start" Align="start">
                            <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                                {VALIDATIONS.FORMAT}
                            </Text>
                            <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                                {VALIDATIONS.FORMAT_TWO}
                            </Text>
                        </ContainerFlex>
                    ) : (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                            {errors.email.message}
                        </Text>
                    ))}
            </ContainerCellGrid>

            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.PASSWORD}
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    FontSize="1rem"
                    error={errors.password?.message}
                >
                    <input
                        type={viewPassword ? 'text' : 'password'}
                        {...register('password')}
                        autoComplete="off"
                    />
                    <ContainerImg onClick={() => setViewPassword(!viewPassword)} Cursor="pointer">
                        {viewPassword ? <VisibilityOn /> : <VisibilityOff />}
                    </ContainerImg>
                </SearchInput>
                {password?.length > 0 && (
                    <ErrorPassword
                        error={errors.password?.message ? true : false}
                        password={password}
                    />
                )}
                {errors.password?.message && password.length === 0 && (
                    <ErrorMessagesPass message={errors.password?.message} />
                )}
            </ContainerCellGrid>

            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.CONFIRMPASS}
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    FontSize="1rem"
                    error={errors.confirmPassword?.message}
                >
                    <input
                        {...register('confirmPassword')}
                        type={viewConfirmPassword ? 'text' : 'password'}
                        autoComplete="off"
                    />
                    <ContainerImg
                        onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                        Cursor="pointer"
                    >
                        {viewConfirmPassword ? <VisibilityOn /> : <VisibilityOff />}
                    </ContainerImg>
                </SearchInput>

                {repeatPassword?.length > 0 && (
                    <ErrorPasswordRepeat
                        error={errors.confirmPassword?.message ? true : false}
                        password={password}
                    />
                )}
                {errors.confirmPassword?.message && repeatPassword.length === 0 && (
                    <ErrorMessagesPass message={errors.confirmPassword?.message} />
                )}
            </ContainerCellGrid>

            {loading ? (
                <LoadingAtaskate />
            ) : (
                <ButtonGeneral
                    text={PERSONAL_FORM.BTNCONTINUE}
                    width="100%"
                    cursor="pointer"
                    clic={handleSubmit(onsubmit)}
                    disabled={!disabledButton()}
                />
            )}
            <LinkAtaskate Color="#5A5AFF" FontWeight="500" Cursor="pointer" to={routeLogin}>
                {PERSONAL_FORM.LOGIN}
            </LinkAtaskate>
        </>
    );
};
