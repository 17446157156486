import {COMMERCIAL_INVENTORY_FILTER_LABELS} from '@/components/InventoryManagement/constants';
import {
    CommercialInventoryFilter,
    PriceFilter,
    SetCommercialFilterByPriceAction,
    SetCommercialFilterAction,
} from '@/components/InventoryManagement/interfaces';
import {
    CLEAR_COMMERCIAL_FILTER_BUTTONS,
    SET_COMMERCIAL_FILTER_BY_PRICE,
    SET_COMMERCIAL_FILTER,
} from '@/components/InventoryManagement/Redux/types';

export const createFilterAction = (
    type: string,
    filter: CommercialInventoryFilter,
    excludeLabels: string[] = [],
    defaultValue: number = 0
): SetCommercialFilterAction => ({
    type,
    filter,
    excludeLabels,
    defaultValue,
});

export const setCommercialFilterByNewest = () =>
    createFilterAction(
        SET_COMMERCIAL_FILTER,
        {label: COMMERCIAL_INVENTORY_FILTER_LABELS.NEWEST, value: 0},
        [COMMERCIAL_INVENTORY_FILTER_LABELS.AGING, COMMERCIAL_INVENTORY_FILTER_LABELS.PROMOTIONS],
        1
    );

export const setCommercialFilterByAging = () =>
    createFilterAction(
        SET_COMMERCIAL_FILTER,
        {label: COMMERCIAL_INVENTORY_FILTER_LABELS.AGING, value: 0},
        [COMMERCIAL_INVENTORY_FILTER_LABELS.NEWEST, COMMERCIAL_INVENTORY_FILTER_LABELS.PROMOTIONS],
        1
    );

export const setCommercialFilterByPromotions = () =>
    createFilterAction(
        SET_COMMERCIAL_FILTER,
        {label: COMMERCIAL_INVENTORY_FILTER_LABELS.PROMOTIONS, value: 0},
        [COMMERCIAL_INVENTORY_FILTER_LABELS.AGING, COMMERCIAL_INVENTORY_FILTER_LABELS.NEWEST],
        1
    );

export const setCommercialFilterByBranch = (branchId: number) =>
    createFilterAction(
        SET_COMMERCIAL_FILTER,
        {
            label: COMMERCIAL_INVENTORY_FILTER_LABELS.BRANCH,
            value: branchId,
        },
        [COMMERCIAL_INVENTORY_FILTER_LABELS.STORAGE]
    );

export const setCommercialFilterByStorage = (storageId: number) =>
    createFilterAction(
        SET_COMMERCIAL_FILTER,
        {
            label: COMMERCIAL_INVENTORY_FILTER_LABELS.STORAGE,
            value: storageId,
        },
        [COMMERCIAL_INVENTORY_FILTER_LABELS.BRANCH]
    );

export const setCommercialFilterByState = (stateId: number) =>
    createFilterAction(SET_COMMERCIAL_FILTER, {
        label: COMMERCIAL_INVENTORY_FILTER_LABELS.STATE,
        value: stateId,
    });

export const setCommercialFilterByPrice = (
    price: PriceFilter
): SetCommercialFilterByPriceAction => ({
    type: SET_COMMERCIAL_FILTER_BY_PRICE,
    price,
});

export const clearCommercialFilterButtons = () => ({
    type: CLEAR_COMMERCIAL_FILTER_BUTTONS,
});
