import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {LocateProductsItem} from '@/components/LocateProducts/interfaces';

export const LOCATE_PRODUCTS_PAGE = {
    TITLE: 'Ubicar productos',
    ACTIONS: '',
    SEARCH_PLACEHOLDER: 'Buscar',
    SELECT_PLACEHOLDER: '',
};

export const LOCATE_PRODUCTS_TABLE = {
    HEADERS: {
        PRODUCT: 'Producto',
        ADDED: 'Agregado',
        LOCATION: 'Location',
    },
};

export const BREAD_LOCATE_PRODUCTS: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Inicio'},
    {to: '#/Inventory', label: 'Inventario'},
];

export const SORTING = {
    ASC: 'asc',
    DESC: 'desc',
};

export const dummyProducts: LocateProductsItem[] = [
    {
        id: 1,
        addedDate: '12-04-2012',
        image: 'https://images.unsplash.com/photo-1579340510942-7381b0f7c0a1',
        locations: ['E1', 'Bóveda', 'F1', 'C1'],
        pledge: 'Anillo de oro 18k con diamantes de lujo ccccc',
        category: {brand: 'Pandora', family: 'Joyeria', subFamily: 'Anillos'},
    },
    {
        id: 2,
        addedDate: '12-04-2012',
        image: 'https://images.unsplash.com/photo-1589329603983-1c7e71e53c2a',
        locations: [],
        pledge: 'Reloj Rolex de acero inoxidable',
        category: {brand: 'Rolex', family: 'Famailia con texto largo', subFamily: 'Relojes'},
    },
    {
        id: 3,
        addedDate: '12-04-2012',
        image: 'https://images.unsplash.com/photo-1564643055-82362ca31480',
        locations: ['E1', 'Bóveda', 'F1', 'C1'],
        pledge: 'Guitarra eléctrica Fender',
        category: {brand: 'Marca con texto largo', family: 'Intrumentos', subFamily: 'Guitarras'},
    },
];

export const dummyPagination = {
    pageNumber: 1,
    pageSize: 20,
    totalPages: 10,
    totalItems: 20,
};

export const SELECT_LOCATION_BUTTON = {
    DEFAULT: 'Selecciona',
    SPACER: '|',
};

export const FILTER_SELECT_OPTIONS = [
    {value: 'TODAY', label: 'Hoy'},
    {value: 'ALL', label: 'Todos'},
    {value: 'THIS_WEEK', label: 'Esta Semana'},
    {value: 'THIS_MONTH', label: 'Desde hace un mes'},
];
