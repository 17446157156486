export const BRANCH_ASSIGNMENT = {
    TITLE: 'Asignación de sucursales',
    TITLE_TWO: 'Selecciona la sucursal del usuario',
    INDICATION: 'Asigna las ubicaciones en tu empresa a las que este usuario tendrá acceso.',
    ASSIGNMENT: '[Nombre Empresa]',
    ASSIGNMENT_ONE: 'México',
    ASSIGNMENT_TWO: 'Centro',
    ASSIGNMENT_THREE: 'CDMX',
    TYPE_ASSIGNMENT: 'Grupo, sucursal o bodega',
    ELEMENT: 'elementos',
    BTN: 'Descartar',
    BTN_KEEP: 'Guardar',
    SEARCH: 'Busca un grupo o sucursal',
    NOT_DATA: 'No hay datos disponibles.',
    IS_BRANCH: 'Sucursal',
    OPEN_TABLE: 'Ocultar estructura de grupos',
};

export const DATA_TYPE_CASH = [
    {label: 'Ingreso', value: 'Ingreso'},
    {label: 'Egreso', value: 'Egreso'},
];

export const BRANCH_TABLE = {
    NAME: 'Nombre',
    TYPE: 'Tipo',
    ADDRESS: 'Dirección',
    USERS: 'Usuarios',
};

export const userProfile = 3;

export const tempBranchData = [
    {
        id: 1,
        name: 'Guadalajara',
        type: 1,
        addess: 'Av. Vallarta 1234, Colonia Americana, Guadalajara, Jalisco',
        users: 50,
    },
    {
        id: 2,
        name: 'Monterrey',
        type: 2,
        addess: 'Calle Hidalgo 567, Centro, Monterrey, Nuevo León',
        users: 75,
    },
    {
        id: 3,
        name: 'Ciudad de México',
        type: 3,
        addess: 'Paseo de la Reforma 789, Juárez, Ciudad de México',
        users: 100,
    },
    {
        id: 4,
        name: 'Cancún',
        type: 1,
        addess: 'Av. Tulum 321, Centro, Cancún, Quintana Roo',
        users: 30,
    },
    {
        id: 5,
        name: 'Puebla',
        type: 2,
        addess: 'Blvd. Hermanos Serdán 234, Colonia El Carmen, Puebla, Puebla',
        users: 40,
    },
    {
        id: 6,
        name: 'Tijuana',
        type: 3,
        addess: 'Av. Revolución 456, Zona Centro, Tijuana, Baja California',
        users: 60,
    },
    {
        id: 7,
        name: 'Mérida',
        type: 1,
        addess: 'Calle 60 789, Centro, Mérida, Yucatán',
        users: 35,
    },
    {
        id: 8,
        name: 'Chihuahua',
        type: 2,
        addess: 'Av. Universidad 321, Zona Centro, Chihuahua, Chihuahua',
        users: 45,
    },
    {
        id: 9,
        name: 'Toluca',
        type: 3,
        addess: 'Paseo Tollocan 987, San Pedro Totoltepec, Toluca, Estado de México',
        users: 25,
    },
    {
        id: 10,
        name: 'León',
        type: 1,
        addess: 'Blvd. Adolfo López Mateos 123, Centro, León, Guanajuato',
        users: 70,
    },
];
