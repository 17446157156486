import {INVENTORY_LOCATION_STORAGE} from '@components/InventoryManagement/Redux/types';
import {
    IInventoryLocationStorageListItem,
    IInventoryLocationStorageListItemType,
} from '@components/InventoryManagement/interfaces';

export const setSelectedStorageListItem = (
    selectedStorageListItem: IInventoryLocationStorageListItem | null
) => ({
    type: INVENTORY_LOCATION_STORAGE.SELECT_STORAGE,
    payload: {selectedStorageListItem},
});

export const setOpenLocationModal = (showLocationModal: boolean) => ({
    type: INVENTORY_LOCATION_STORAGE.SHOW_MODAL,
    payload: {showLocationModal},
});

export const setSelectedStorageItemType = (
    selectedStorageItemType: IInventoryLocationStorageListItemType | null
) => ({
    type: INVENTORY_LOCATION_STORAGE.SELECT_STORAGE_TYPE,
    payload: {selectedStorageItemType},
});
