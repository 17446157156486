import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import InventoryLocationStorageListItem from '@components/InventoryManagement/InventoryLocationListItem';
import {IInventoryLocationStorageListProps} from '@components/InventoryManagement/interfaces';
import {INVENTORY_LOCATION} from '@components/InventoryManagement/constants';

const InventoryLocationStorageList: React.FC<IInventoryLocationStorageListProps> = ({storage}) => {
    return (
        <ContainerFlex
            backG="#FAFAFA"
            Gap="1rem"
            FlexDir="column"
            Padding="1rem"
            Align="start"
            Justify="start"
            Radius="1rem"
        >
            <ContainerFlex Justify="start" Align="center" Gap="0.5rem" Radius="1rem">
                <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                    {storage.name}
                </Text>
                <Text FontSize="0.75rem" FontWeight="400">
                    {INVENTORY_LOCATION.SUB_TITLE_LEVEL} 0
                </Text>
            </ContainerFlex>

            <ContainerFlex FlexDir="Column" Padding="0.5rem" Radius="0.5rem" backG="#FFFFFF">
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    Gap="0.5rem"
                    Radius="1rem"
                    Height="1.25rem"
                >
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                        {INVENTORY_LOCATION.SUB_TITLE}
                    </Text>
                    <Text FontSize="0.75rem" FontWeight="400">
                        {INVENTORY_LOCATION.SUB_TITLE_LEVEL} 1
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    FlexDir="column"
                    PaddingT="0.5rem"
                    OverFlowY="auto"
                    Height="12.5rem"
                >
                    {storage.storageList.map((storageItem, index) => (
                        <InventoryLocationStorageListItem key={index} storageItem={storageItem} />
                    ))}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
export default InventoryLocationStorageList;
