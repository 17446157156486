import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image, Figure} from '@Shopify/Ecommerce/styles';
import globalQuoterBanner from '@images/HeaderPublicNow.svg';
import Add from '@images/Right.svg';
import {TITLES} from '@components/HomePublicClient/constants';
import {useNavigate} from 'react-router-dom';
import PicA from '@/images/Pic1.png';
import PicB from '@/images/Pic2.png';
import PicC from '@/images/pic3.png';
import {useDispatch, useSelector} from 'react-redux';
import {GetRouter} from '@components/AdminNavbar/Redux/Actions/loginTypeRoute';
import ExpressQuoter from '@/components/ExpressQuoter/ExpressQuoter';
import {RootState} from '@/config/store';
import {routers} from '@/appRoute';

const HomePublic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showExpressQuoter, setShowExpressQuoter] = useState<boolean>(false);
    useEffect(() => {
        dispatch(GetRouter('/login/MarketUser'));
    }, []);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const linkQuoter: string = token ? TITLES.cards_one.ctaLink : routers.LoanQuoter;
    return (
        <ContainerFlex FlexDir="column" Gap="24px" Position="relative">
            <ContainerFlex Justify="start" Align="start" Height="467px" FlexDir="column">
                <Figure>
                    <Image src={globalQuoterBanner} Width="100%" />
                </Figure>
                <ContainerFlex MarginTop="-12%" Justify="start" Align="start" PaddingL="7.5%">
                    <ContainerFlex
                        Radius="32px"
                        Border="1px solid #5A5AFF"
                        ColumnGap="8px"
                        Height="40px"
                        Width="13.31rem"
                        backG="#FFFFFF"
                        Cursor="pointer"
                        onClick={() => setShowExpressQuoter(!showExpressQuoter)}
                    >
                        <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                            {TITLES.mainBanner.cta}
                        </Text>
                        <Image src={Add} alt="arrow" Cursor="pointer" />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    MarginTop="8.4%"
                    Padding="44px"
                    ColumnGap="40px"
                    Align="end"
                    Height="222px"
                >
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        backG={`url(${PicA}) no-repeat`}
                        Width="30%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontSize="1.75rem"
                            Color="#0D166B"
                            FontWeight="700"
                            wSpace=""
                            Width="5rem"
                        >
                            {TITLES.cards_one.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="10rem">
                            {TITLES.cards_one.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate(linkQuoter)}
                        >
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_one.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        Width="30%"
                        backG={`url(${PicB}) no-repeat`}
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text FontSize="1.75rem" Color="#0D166B" FontWeight="700" Width="13rem">
                            {TITLES.cards_two.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="13rem">
                            {TITLES.cards_two.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate('/login/MarketUser')}
                        >
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_two.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        backG={`url(${PicC}) no-repeat`}
                        Width="30%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text FontSize="1.75rem" Color="#0D166B" FontWeight="700" Width="13rem">
                            {TITLES.cards_three.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="13rem">
                            {TITLES.cards_three.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate('/login/MarketUser')}
                        >
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_three.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>

            {showExpressQuoter && <ExpressQuoter closeShowModal={setShowExpressQuoter} />}
        </ContainerFlex>
    );
};

export default HomePublic;
