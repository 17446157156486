import React from 'react';
import {TableCell, TableRow} from '@/components/General/Atoms/TableList/styles';
import {CategoryItem} from '@/components/LocateProducts/CategoryItem';
import {LocateProductsItem} from '@/components/LocateProducts/interfaces';
import SelectLocationButton from '@/components/LocateProducts/SelectLocationButton';
import {ContainerFlex, Image, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';
import useFormatDate from '@/hooks/useFormatDate';

export default function LocateProductsListRow({product}: {product: LocateProductsItem}) {
    return (
        <TableRow
            borderBottom="1px solid #E8E9EA"
            gridColumns="repeat(3, minmax(5rem, 1fr))"
            gap="1rem"
        >
            <TableCell>
                <ContainerFlex Gap="0.5rem" Justify="start">
                    <Image
                        src={product.image}
                        Height="3rem"
                        Width="3rem"
                        Radius="0.25rem"
                        Border="1px solid #E8E9EA"
                        alt="article"
                    />
                    <ContainerFlex FlexDir="column" Align="start" MaxW="15.625rem">
                        <TextEllipsis
                            Color="#5A5AFF"
                            FontSize="0.875rem"
                            FontWeight="700"
                            Width="100%"
                        >
                            {product.pledge}
                        </TextEllipsis>
                        {product.category && (
                            <ContainerFlex Justify="start">
                                <CategoryItem
                                    key={product.category.family}
                                    label={product.category.family}
                                />
                                <CategoryItem
                                    key={product.category.subFamily}
                                    label={product.category.subFamily}
                                />
                                <CategoryItem
                                    key={product.category.brand}
                                    label={product.category.brand}
                                    showIcon
                                />
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </TableCell>
            <TableCell padding="0.25rem 0">
                {product.addedDate && (
                    <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                        {useFormatDate(product.addedDate)}
                    </Text>
                )}
            </TableCell>
            <TableCell padding="0.25rem 0">
                <SelectLocationButton locations={product.locations || []} />
            </TableCell>
        </TableRow>
    );
}
