import React from 'react';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import iconError from '@/images/iconError.svg';
import iconSuccessPwd from '@/images/iconSuccessPwd.svg';
import {Icon} from '@/components/MarketForgottenLogin/Operational/styles';
import {RESTORE_NEW_PASSWORD} from '@/components/MarketForgottenLogin/Operational/contants';

interface IError {
    error: boolean;
    password: string;
}
export const ErrorPasswordRepeat = ({error, password}: IError) => {
    const errorIcon = error ? iconError : iconSuccessPwd;
    const errorIconColor = error ? '#FF6357' : '#309C60';
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <Icon
                src={errorIcon}
                alt={error ? RESTORE_NEW_PASSWORD.CLOSE : RESTORE_NEW_PASSWORD.DONE}
                color={errorIconColor}
            />
            <Text
                Color={error ? '#FF6357' : '#309C60'}
                FontSize="0.7rem"
                FontFamily="Nunito"
                wSpace="normal"
                TextAlign="justify"
            >
                {error ? RESTORE_NEW_PASSWORD.REPEAT_PASS : RESTORE_NEW_PASSWORD.SAME_PASSWORD}
            </Text>
        </ContainerFlex>
    );
};
