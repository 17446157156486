import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    CREATE_REQUEST_LOAN_START,
    CREATE_REQUEST_LOAN_SUCCESS,
    CREATE_REQUEST_LOAN_ERROR,
    GET_CREDIT_LOAN_SUMMARY_START,
    GET_CREDIT_LOAN_SUMMARY_SUCCESS,
    GET_CREDIT_LOAN_SUMMARY_ERROR,
    GET_CLIENT_CREDIT_HISTORY_START,
    GET_CLIENT_CREDIT_HISTORY_SUCCESS,
    GET_CLIENT_CREDIT_HISTORY_ERROR,
    GET_PERSONAL_INFOMARTION_ID_START,
    GET_PERSONAL_INFOMARTION_ID_SUCCESS,
    GET_PERSONAL_INFOMARTION_ID_ERROR,
    DELETE_ABOUT_PERSONAL_DATA_START,
    DELETE_ABOUT_PERSONAL_DATA_SUCCESS,
    DELETE_ABOUT_PERSONAL_DATA_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {ICreateRequestLoan} from '@components/PersonalLoans/interfaces';
import {NavigateFunction} from 'react-router-dom';
import {routers} from '@/appRoute';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';
import {getPreLoadClientData} from '@/components/PersonalLoans/Redux/Actions/PreLoadPersonalDataActions';

export const createRequestStart = () => {
    return {
        type: CREATE_REQUEST_LOAN_START,
    };
};

export const createRequestSuccess = (payload: AxiosResponse) => {
    return {
        type: CREATE_REQUEST_LOAN_SUCCESS,
        payload,
    };
};

export const createRequestError = (error: AxiosError) => {
    return {
        type: CREATE_REQUEST_LOAN_ERROR,
        error,
    };
};

export const getCreditLoanSummaryStart = () => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_START,
    };
};

export const getCreditLoanSummarySuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_SUCCESS,
        payload,
    };
};

export const getCreditLoanSummaryError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_ERROR,
        error,
    };
};

export const getClientCreditHistoryStart = () => {
    return {
        type: GET_CLIENT_CREDIT_HISTORY_START,
    };
};

export const getClientCreditHistorySuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CLIENT_CREDIT_HISTORY_SUCCESS,
        payload,
    };
};

export const getClientCreditHistoryError = (error: AxiosError) => {
    return {
        type: GET_CLIENT_CREDIT_HISTORY_ERROR,
        error,
    };
};

export const getPersonalInformationIdStart = () => {
    return {
        type: GET_PERSONAL_INFOMARTION_ID_START,
    };
};

export const getPersonalInformationIdSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_PERSONAL_INFOMARTION_ID_SUCCESS,
        payload,
    };
};

export const getPersonalInformationIdError = (error: AxiosError) => {
    return {
        type: GET_PERSONAL_INFOMARTION_ID_ERROR,
        error,
    };
};

export const deleteAboutPersonalDataStart = () => {
    return {
        type: DELETE_ABOUT_PERSONAL_DATA_START,
    };
};

export const deleteAboutPersonalDataSuccess = () => {
    return {
        type: DELETE_ABOUT_PERSONAL_DATA_SUCCESS,
    };
};

export const deleteAboutPersonalDataError = (error: AxiosError) => {
    return {
        type: DELETE_ABOUT_PERSONAL_DATA_ERROR,
        error,
    };
};

export const createRequestLoan = (
    token: string,
    dataCreateRequest: ICreateRequestLoan,
    navigate: NavigateFunction
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(createRequestStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateRequestedCredit}`,
                dataCreateRequest,
                {headers: headers}
            );

            dispatch(createRequestSuccess(response));
            dispatch(getCreditLoanSummary(token, response.data.data));
            const clientHasApplicationHistoryResponse = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ClientHasApplicationHistory}`,
                {headers}
            );
            dispatch(getClientCreditHistorySuccess(clientHasApplicationHistoryResponse));
            if (clientHasApplicationHistoryResponse.data.data.hasApplicationHistory) {
                dispatch(ActualDataStepLoans(3));
                dispatch(getPersonalInformationId(token, response.data.data));
            } else {
                dispatch(ActualDataStepLoans(1));
                dispatch(getPreLoadClientData(token));
            }
            navigate(routers.PersonalLoans);
        } catch (error) {
            dispatch(createRequestError(error as AxiosError));
        }
    };
};

export const getCreditLoanSummary = (token: string, solicitudeId: number) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditLoanSummaryStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRequestedSummary}`,
                {
                    headers,
                    params: {solicitudeId},
                }
            );
            dispatch(getCreditLoanSummarySuccess(response));
            return response;
        } catch (error) {
            dispatch(getCreditLoanSummaryError(error as AxiosError));
        }
    };
};

export const getPersonalInformationId = (token: string, personaCreditApplicationNewId: number) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPersonalInformationIdStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdatePersonalDataApplication}`,
                {personaCreditApplicationNewId},
                {headers}
            );
            dispatch(getPersonalInformationIdSuccess(response));
            return response;
        } catch (error) {
            dispatch(getPersonalInformationIdError(error as AxiosError));
        }
    };
};

export const deleteAboutPersonalData = (token: string, solicitudeId: number) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(deleteAboutPersonalDataStart());
        try {
            await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteApplicationSummary}`,
                {headers, params: {solicitudeId}}
            );
            dispatch(deleteAboutPersonalDataSuccess());
        } catch (error) {
            dispatch(deleteAboutPersonalDataError(error as AxiosError));
        }
    };
};
