import {
    ConservationStateActionError,
    ConservationStateActionSuccess,
} from '@/components/InventoryManagement/interfaces';
import {
    GET_BRANCH_STORAGES,
    GET_BRANCH_STORAGES_ERROR,
    GET_BRANCH_STORAGES_SUCCESS,
} from '@/components/InventoryManagement/Redux/types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getBranchStorages = () => ({
    type: GET_BRANCH_STORAGES,
});
export const getBranchStoragesSuccess = (
    response: AxiosResponse
): ConservationStateActionSuccess => {
    return {
        type: GET_BRANCH_STORAGES_SUCCESS,
        response,
    };
};
export const getBranchStoragesError = (error: AxiosError): ConservationStateActionError => ({
    type: GET_BRANCH_STORAGES_ERROR,
    error,
});

export function fetchBranchStoragesByCompany(companyId: number, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {
        companyId,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(getBranchStorages());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchStorageByCompanyId}`,
                {headers, params}
            );
            dispatch(getBranchStoragesSuccess(response.data));
        } catch (error) {
            if (error instanceof AxiosError) {
                dispatch(getBranchStoragesError(error));
            }
        }
    };
}
