import {INITIATE_TIMER} from '@/components/RolesPermissions/Redux/Types';
import {AxiosError} from 'axios';

const initialState = {
    employeeId: 0,
};

export const ResetPasswordTimer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: number;
    }
) => {
    switch (action.type) {
        case INITIATE_TIMER:
            return {
                ...state,
                employeeId: action.payload,
            };
        default:
            return state;
    }
};
