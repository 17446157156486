import styled from '@emotion/styled';

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1.5rem;
    background-color: white;
    padding: 1.5rem;
    box-sizing: border-box;
`;
