import {
    ConservationStates,
    ConservationStatesPayload,
} from '@components/InventoryManagement/interfaces';
import {
    GET_CONSERVATION_STATES,
    GET_CONSERVATION_STATES_ERROR,
    GET_CONSERVATION_STATES_SUCCESS,
} from '@components/InventoryManagement/Redux/types';

const initialState: ConservationStates = {
    states: [],
    error: null,
    loading: false,
};

export const getConservationStates = (
    state = initialState,
    action: ConservationStatesPayload
): ConservationStates => {
    switch (action.type) {
        case GET_CONSERVATION_STATES:
            return {...state, loading: true, error: null};
        case GET_CONSERVATION_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                states: action.response.data,
            };
        case GET_CONSERVATION_STATES_ERROR:
            return {...state, loading: false, error: action.error};
        default:
            return state;
    }
};

export default getConservationStates;
