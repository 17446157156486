import React, {useState} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import Arrow from '@images/arrowDownIcon.svg';
import ArrowUp from '@images/arrowUpIcon.svg';

const ProductDescription: React.FC = () => {
    const [showMore, setShowMore] = useState(false);
    return (
        <>
            <ContainerFlex FlexDir="Column" Gap="1.5rem">
                <ContainerFlex Height="1.5rem">
                    <ContainerFlex Align="start" Justify="start" Width="70%">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1.25rem">
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_DESCRIPTION}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Align="start" Justify="end" Width="30%">
                        <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="0.875rem">
                            {INVENTORY_PRODUCT_DETAIL.EDIT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="Column" Align="start" Justify="start">
                    <Text Color="#000000" FontSize="0.875rem" FontWeight="400">
                        {INVENTORY_PRODUCT_DETAIL.PRODUCT_DESCRIPTION_VALUE_1}
                    </Text>
                    <Text Color="#000000" FontSize="0.875rem" FontWeight="400" MarginTop="1rem">
                        {INVENTORY_PRODUCT_DETAIL.PRODUCT_DESCRIPTION_VALUE_2}
                    </Text>
                    {showMore && (
                        <Text Color="#000000" FontSize="0.875rem" FontWeight="400" MarginTop="1rem">
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_DESCRIPTION_VALUE_3}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="Column"
                    Align="start"
                    Justify="start"
                    onClick={() => {
                        setShowMore(!showMore);
                    }}
                >
                    <Text Color="#5A5AFF" FontSize="1rem" FontWeight="700" Cursor="pointer">
                        {INVENTORY_PRODUCT_DETAIL.SHOW_MORE}
                        <Image src={!showMore ? Arrow : ArrowUp} alt="add" MarginLeft="0.25rem" />
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
export default ProductDescription;
