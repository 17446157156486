import React, {useState} from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {AUCTION_TITLES} from '@components/AuctionPass/GeneralScreen/constants';
import {
    mapToTreeComponent,
    ITreeComponent,
    AuctionEditProps,
} from '@components/AuctionPass/GeneralScreen/interface';
import {SearchGeneral} from '@/components/NavBarPOS/SearchComponent/SearchGeneral';
import {NewTreeComponent} from '@/components/AuctionPass/GeneralScreen/NewTreeComponent';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {
    ArticleReview,
    AuctionPass,
    IntegratedPromotions,
} from '@/components/AuctionPass/GeneralScreen/FootherModal';
import {TextSpan} from '@/components/AuctionPass/GeneralScreen/Operational/styles';

export const AuctionEditContainer = (props: AuctionEditProps) => {
    const {isOpen, closeModal, footerType = 'ArticleReview'} = props;
    const {title, description, subtitle, bottomTitle} = props;
    const [search, setSearch] = useState('');
    const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
    const [isSelectAll, setIsSelectAll] = useState(false);

    const SearchValue = (e: {target: {value: string}}) => {
        setSearch(e.target.value);
    };

    const mapping = mapToTreeComponent(
        props.treeData,
        'id',
        'children',
        ['name', 'nameGroup', 'nameFamily', 'nameSubFamily', 'nameBrand'],
        'validity',
        'percent'
    );
    const getAllTreeIds = (items: ITreeComponent[]): number[] => {
        let ids: number[] = [];
        items.forEach((item) => {
            ids.push(item.id);
            if (item.children) {
                ids = [...ids, ...getAllTreeIds(item.children)];
            }
        });
        return ids;
    };

    const handleCheckChange = (newCheckedItems: Set<number>) => {
        setCheckedItems(newCheckedItems);
        if (newCheckedItems.size !== getAllTreeIds(mapping).length) {
            setIsSelectAll(false);
        }
    };
    const handleSelectAll = () => {
        if (!isSelectAll) {
            const allIds = getAllTreeIds(mapping);
            setCheckedItems(new Set(allIds));
            setIsSelectAll(true);
        } else {
            setCheckedItems(new Set());
            setIsSelectAll(false);
        }
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    const handleClose = () => {
        setCheckedItems(new Set());
        setIsSelectAll(false);
        closeModal();
    };

    const onSubmit = () => {
        closeModal();
    };
    const renderFooter = () => {
        const footerProps = {
            bottomTitle,
            onClose: handleClose,
            onSubmit,
            isSubmitDisabled: checkedItems.size === 0,
            selectedItems: Array.from(checkedItems),
        };

        switch (footerType) {
            case 'ArticleReview':
                return <ArticleReview {...footerProps} />;
            case 'AuctionPass':
                return <AuctionPass {...footerProps} />;
            default:
                return <IntegratedPromotions {...footerProps} />;
        }
    };

    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    Align="start"
                    Justify="end"
                    Height="100vh"
                    ZIndex="5"
                    PositionRight="0"
                    onClick={handleClickOutside}
                >
                    <ContainerFlex
                        Position="relative"
                        Width="480px"
                        ColumnGap="8px"
                        Justify="start"
                        Align="start"
                        backG="#FFFFFF"
                        Padding="24px 0 0 0"
                        Radius="8px 0px 0px 8px"
                        FlexDir="column"
                        Gap="24px"
                        Border="1px solid #D4D6D8"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            Gap="8px"
                            FlexDir="column"
                            Height="102px"
                            Padding="0 24px"
                        >
                            <ContainerFlex Justify="start" Height="max-content">
                                <Text
                                    FontSize="2rem"
                                    Color="#5A5AFF"
                                    FontWeight="500"
                                    wSpace=""
                                    LHeight="1"
                                >
                                    {title}
                                </Text>
                            </ContainerFlex>

                            <TextSpan>{description}</TextSpan>
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="column"
                            Justify="start"
                            Align="start"
                            Gap="16px"
                            Padding="0 24px"
                        >
                            <ContainerFlex Justify="start" Align="start" Height="40px">
                                <Text
                                    FontSize="1.25rem"
                                    FontWeight="700"
                                    LHeight="1"
                                    wSpace=""
                                    Width="100%"
                                >
                                    {subtitle}
                                </Text>
                            </ContainerFlex>
                            <SearchGeneral
                                variant={'nav'}
                                hasQR={true}
                                Width="100%"
                                placeholder={AUCTION_TITLES.SEARCH_PLACEHOLDER}
                                value={search}
                                onChange={SearchValue}
                                handleClose={() => setSearch('')}
                            />
                            <ContainerFlex Gap="8px" FlexDir="column" Justify="start">
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    ColumnGap="8px"
                                    Height="24px"
                                >
                                    <Checkbox checked={isSelectAll} onChange={handleSelectAll} />
                                    <Text>{AUCTION_TITLES.ALL}</Text>
                                </ContainerFlex>
                                <ContainerFlex
                                    Height="305px"
                                    Justify="start"
                                    Align="start"
                                    OverFlowY="auto"
                                    OverFlowX="hidden"
                                    Gap="8px"
                                    Padding="0 5px"
                                >
                                    <NewTreeComponent
                                        data={mapping}
                                        level={0}
                                        fatherId={0}
                                        isView={false}
                                        onCheckChange={handleCheckChange}
                                        checkedItems={checkedItems}
                                    />
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                        {renderFooter()}
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
