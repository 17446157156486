export const GET_COMMERCIAL_INVENTORY = 'GET_COMMERCIAL_INVENTORY';
export const GET_COMMERCIAL_INVENTORY_SUCCESS = 'GET_COMMERCIAL_INVENTORY_SUCCESS';
export const GET_COMMERCIAL_INVENTORY_ERROR = 'GET_COMMERCIAL_INVENTORY_ERROR';
export const CHANGE_COMMERCIAL_INVENTORY_PAGE = 'CHANGE_COMMERCIAL_INVENTORY_PAGE';

export const SET_COMMERCIAL_FILTER_BY_PRICE = 'SET_COMMERCIAL_FILTER_BY_PRICE';
export const SET_COMMERCIAL_FILTER = 'SET_COMMERCIAL_FILTER';
export const CLEAR_COMMERCIAL_FILTER_BUTTONS = 'CLEAR_COMMERCIAL_FILTER_BUTTONS';

export const GET_CONSERVATION_STATES = 'GET_CONSERVATION_STATES';
export const GET_CONSERVATION_STATES_SUCCESS = 'GET_CONSERVATION_STATES_SUCCESS';
export const GET_CONSERVATION_STATES_ERROR = 'GET_CONSERVATION_STATES_ERROR';

export const GET_BRANCH_STORAGES = 'GET_BRANCH_STORAGES';
export const GET_BRANCH_STORAGES_SUCCESS = 'GET_BRANCH_STORAGES_SUCCESS';
export const GET_BRANCH_STORAGES_ERROR = 'GET_BRANCH_STORAGES_ERROR';

export const GET_INVENTORY_PRODUCTS = {
    START: 'GET_INVENTORY_PRODUCTS_START',
    SUCCESS: 'GET_INVENTORY_PRODUCTS_SUCCESS',
    ERROR: 'GET_INVENTORY_PRODUCTS_ERROR',
    ACTUAL_PAGE: 'GET_INVENTORY_PRODUCTS_ACTUAL_PAGE',
};

export const GET_CONTRACTS_INVETORY = {
    START: 'GET_CONTRACTS_INVETORY_START',
    SUCCESS: 'GET_CONTRACTS_INVETORY_SUCCESS',
    ERROR: 'GET_CONTRACTS_INVETORY_ERROR',
    ACTUAL_PAGE: 'GET_CONTRACTS_INVETORY_ACTUAL_PAGE',
};

export const GET_CONTRACT_STATUSES = {
    START: 'GET_CONTRACT_STATUSES_START',
    SUCCESS: 'GET_CONTRACT_STATUSES_SUCCESS',
    ERROR: 'GET_CONTRACT_STATUSES_ERROR',
};

export const GET_BRANCH_STORAGE_BYCOMPANY = {
    START: 'GET_BRANCH_STORAGE_BYCOMPANY_START',
    SUCCESS: 'GET_BRANCH_STORAGE_BYCOMPANY_SUCCESS',
    ERROR: 'GET_BRANCH_STORAGE_BYCOMPANY_ERROR',
};

export const INVENTORY_LOCATION_STORAGE = {
    SELECT_STORAGE: 'SELECT_STORAGE',
    SELECT_STORAGE_TYPE: 'SELECT_STORAGE_TYPE',
    SHOW_MODAL: 'SHOW_MODAL',
};
