import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import CompanyInfo from '@components/GlobalQuoter/CompanyInfo';
import LoanInfo from '@components/GlobalQuoter/LoanInfo';
import LoanListCardButtons from '@/components/GlobalQuoter/LoanListCardButtons';
import {DivisorLine} from '@components/MyAccount/styles';
import {ILoanLisCard} from '@components/GlobalQuoter/interface';

const LoanLisCard: React.FC<ILoanLisCard> = ({loan, index}) => {
    return (
        <>
            <ContainerFlex Height="129px" Justify="flex-start">
                <CompanyInfo
                    needTag={index === 0}
                    companyName={loan.companyName}
                    rating={loan.startsCount}
                    logo={loan.companyLogo}
                    companyAvatarColor={loan.companyAvatarColor}
                    labelPersonCounter={loan.labelPersonCounter}
                />
                <LoanInfo
                    maxAmount={loan.amountMax}
                    countPayments={loan.countPayments}
                    frecuencyName={loan.frecuencyName}
                    anualRate={loan.anualRate}
                />
                <LoanListCardButtons
                    companyId={loan.companyId}
                    frequencyTermCreditId={loan.frequencyTermCreditId}
                    maxWidth="215px"
                />
            </ContainerFlex>
            <DivisorLine width="100%" height="1px" />
        </>
    );
};

export default LoanLisCard;
