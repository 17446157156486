import React, {useState} from 'react';

import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {COOWNER_SELECTION} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, ContainerForm, Text, Image} from '@Shopify/Ecommerce/styles';
import {RadioButton} from '@General/Atoms/RadioButton/Radio';
import CoownerBeneficiaryRow from '@/components/Shopify/ListClient/CoownerBeneficiaryRow';
import AddPlus from '@images/add - plus.svg';

const CoownerBeneficiary = (props: IClientProofStep) => {
    const {register, setValue, getValues, resetField} = props;
    const [cotitulares, setCotitulares] = useState([true, false, false, false]);
    const [displayCotitular, setDisplayCotitular] = useState(false);

    if (getValues!('coownerId') === undefined) {
        setValue('coownerId', '2');
    }

    const handleChange = () => {
        setDisplayCotitular((displayCotitular) => !displayCotitular);
    };

    const handleAddCotitular = () => {
        const index = cotitulares.indexOf(false);
        if (index > -1) {
            const updatedCotitularesState = [...cotitulares];
            updatedCotitularesState[index] = true;
            setCotitulares(updatedCotitularesState);
        }
    };

    const handleRemoveCotitular = (position: number) => {
        const updatedCotitularesState = cotitulares.map((cotitular: boolean, index: number) =>
            index === position ? false : cotitular
        );
        setCotitulares(updatedCotitularesState);
        resetField(`coownerFirstName${position}`);
        resetField(`coownerPaternalLastname${position}`);
        resetField(`coownerMaternalLastname${position}`);
        resetField(`coownerPhone${position}`);
        resetField(`coownerEmail${position}`);
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            backG="#fff"
            Width="1233px"
            Margin="0 auto"
        >
            <Text FontWeight="500" FontSize="1.5rem" Color="#2A2C2F">
                {COOWNER_SELECTION.TITLE}
            </Text>
            <Text FontSize="1rem" Color="#2A2C2F" Display="Block">
                {COOWNER_SELECTION.WANT_TO_ADD}
            </Text>
            <ContainerFlex Justify="flex-start" MarginTop="1rem">
                <Text MarginRight="1.5rem">
                    <RadioButton
                        {...register('coownerId')}
                        value="1"
                        background="#5A5AFF"
                        BorderColor="#5A5AFF"
                        BorderColorChecked="#5A5AFF"
                        BorderColorHover="#5A5AFF"
                        onChange={(e) => {
                            setValue('coownerId', e.target.value);
                            handleChange();
                        }}
                    />
                    {COOWNER_SELECTION.YES}
                </Text>
                <Text>
                    <RadioButton
                        {...register('coownerId')}
                        value="2"
                        background="#5A5AFF"
                        BorderColor="#5A5AFF"
                        BorderColorChecked="#5A5AFF"
                        BorderColorHover="#5A5AFF"
                        onChange={(e) => {
                            setValue('coownerId', e.target.value);
                            handleChange();
                        }}
                    />
                    {COOWNER_SELECTION.NO}
                </Text>
            </ContainerFlex>
            {displayCotitular && (
                <ContainerForm
                    Align="start"
                    autoComplete="off"
                    MarginT="1rem"
                    Width="100%"
                    Justify="flex-start"
                >
                    <ContainerFlex
                        Padding=" 1.75rem 1rem"
                        backG="#FAFAFA"
                        Radius="1rem"
                        Align="start"
                    >
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1rem">
                            {cotitulares.map((cotitular, index) => {
                                return (
                                    cotitular && (
                                        <CoownerBeneficiaryRow
                                            {...props}
                                            handleRemoveCotitular={handleRemoveCotitular}
                                            index={index}
                                        />
                                    )
                                );
                            })}
                            {!cotitulares[3] && (
                                <ContainerFlex Justify="flex-start">
                                    <ContainerFlex
                                        Width="auto"
                                        onClick={handleAddCotitular}
                                        Cursor="pointer"
                                    >
                                        <Image src={AddPlus} Height="24px" Cursor="pointer" />
                                    </ContainerFlex>
                                    <ContainerFlex
                                        Width="auto"
                                        onClick={handleAddCotitular}
                                        Cursor="pointer"
                                    >
                                        <Text {...style.level} Color="#5A5AFF" Cursor="pointer">
                                            {COOWNER_SELECTION.ADD_COOWNER}
                                        </Text>
                                    </ContainerFlex>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerForm>
            )}
        </ContainerFlex>
    );
};

export default CoownerBeneficiary;
