import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ProductEdit from '@components/InventoryManagement/ProductEdit';
import {INVENTORY_PRODUCT_DETAIL} from '@components/InventoryManagement/constants';
import ProductCharacteristicsModal from '@components/InventoryManagement/ProductCharacteristicsModal';

const ProductCharacteristics: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <ContainerFlex>
                <ContainerFlex Align="start" Justify="start">
                    <Text Color="#1D1E20" FontWeight="700" FontSize="1rem">
                        {INVENTORY_PRODUCT_DETAIL.PRODUCT_CHARACTERISTICS}
                        <Text Color="#1D1E20" FontWeight="400" FontSize="1rem" MarginLeft="0.5rem">
                            {INVENTORY_PRODUCT_DETAIL.PRODUCT_CHARACTERISTICS_VALUE}
                        </Text>
                    </Text>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start">
                    <ProductEdit
                        onClickEdit={() => {
                            setShowModal(true);
                        }}
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ProductCharacteristicsModal showModal={showModal} setShowModal={setShowModal} />
        </>
    );
};
export default ProductCharacteristics;
