import React from 'react';

import {
    checkExpiredElapsed,
    PRODUCT_OPTIONS,
    INVENTORY_PLEDGE_STATUS,
    PRODUCT_CARD,
    mapInventoryProduct,
} from '@components/InventoryManagement/constants';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {useInventoryActions} from '@components/InventoryManagement/hooks/useInventoryActions';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import ButtonGeneral from '@/components/General/Atoms/Button';
import ContractInfo from '@components/InventoryManagement/ContractInfo';
import {cardButtonStyle, cardButtonTextStyle} from '@components/InventoryManagement/styles';
import {ActionMenuOption} from '@components/ActionMenu/ActionMenuOption';
import {Contract} from '@components/InventoryManagement/Redux/interfaces';
import {ContractCardProps} from '@components/InventoryManagement/interfaces';

const ContractCard: React.FC<ContractCardProps> = ({
    contract,
    inventoryContracts,
    openOption,
    handleOptionClick,
}) => {
    const {handleEndorsementDischarge, handleError} = useInventoryActions();
    const tempNullFunction = () => {
        return;
    };
    const getButtonRender = (contract: Contract) => {
        const item = mapInventoryProduct(contract);
        const buttonConfigs: Record<string, {text: string; onClick: () => void}> = {
            [INVENTORY_PLEDGE_STATUS.IN_EFFECT]: {
                text: PRODUCT_CARD.RENEW,
                onClick: () =>
                    handleEndorsementDischarge(
                        TYPE_MOVEMENT_ADD_CART.RENEWAL,
                        item,
                        {},
                        handleError
                    ),
            },
            [INVENTORY_PLEDGE_STATUS.EXPIRED]: {
                text:
                    checkExpiredElapsed(contract.expires) <= 7
                        ? PRODUCT_CARD.REACTIVATE
                        : PRODUCT_CARD.DETAILS,
                onClick:
                    checkExpiredElapsed(contract.expires) <= 7
                        ? () => tempNullFunction()
                        : () => tempNullFunction(),
            },
        };
        return (
            buttonConfigs[contract.contractStatus] || {
                text: PRODUCT_CARD.DETAILS,
                onClick: () => tempNullFunction(),
            }
        );
    };

    const getItemMenuOptions = (contract: Contract) => [
        {
            id: 'extension',
            name: PRODUCT_OPTIONS.EXTEND,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    mapInventoryProduct(contract),
                    {extend: true},
                    handleError
                ),
        },
        {
            id: 'passToCapital',
            name: PRODUCT_OPTIONS.PASS_TO_CAPITAL,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                    mapInventoryProduct(contract),
                    {addPay: true},
                    handleError
                ),
        },
        {
            id: 'discharge',
            name: PRODUCT_OPTIONS.DISCHARGE,
            action: () =>
                handleEndorsementDischarge(
                    TYPE_MOVEMENT_ADD_CART.DISCHARGE,
                    mapInventoryProduct(contract),
                    {},
                    handleError
                ),
        },
    ];
    const buttonData = getButtonRender(contract);

    return (
        <ContainerFlex
            FlexDir="Column"
            MaxW="16.875rem"
            backG="#FFFFFF"
            Radius="1.25rem"
            Justify="space-between"
            Height="28.313rem"
        >
            <ContainerFlex
                Align="start"
                Justify="start"
                Height="0rem"
                Position="relative"
                PositionTop="1rem"
                PositionLeft="1rem"
            >
                <Checkbox />
            </ContainerFlex>
            <ContainerFlex Align="start" Justify="center" Height="11.25rem">
                <Image
                    src={inventoryContracts.contractsIcon}
                    Height="11.25rem"
                    Width="11.25rem"
                    BorderTLRadius="1.25rem"
                    BorderTRRadius="1.25rem"
                />
            </ContainerFlex>
            <ContractInfo pledge={inventoryContracts} item={contract} />
            <ContainerFlex
                Align="center"
                Justify="center"
                Padding="0rem 1rem 1.5rem 1rem"
                Height="4rem"
                Gap="1rem"
            >
                <ButtonGeneral
                    {...cardButtonStyle}
                    clic={buttonData.onClick}
                    text={<Text {...cardButtonTextStyle}>{buttonData.text}</Text>}
                />
                {contract.contractStatus === INVENTORY_PLEDGE_STATUS.IN_EFFECT && (
                    <ContainerFlex Cursor="pointer" Height="2rem" Width="2rem">
                        <ActionMenuOption
                            id={contract.contractNumber}
                            menuOptions={getItemMenuOptions(contract)}
                            isOpen={openOption === contract}
                            onOptionClick={() => handleOptionClick(contract)}
                        />
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ContractCard;
