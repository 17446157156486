import React, {useCallback, useState} from 'react';
import {LOCATE_PRODUCTS_TABLE, SORTING} from '@/components/LocateProducts/constants';
import {LocateProductListProps} from '@/components/LocateProducts/interfaces';
import {Image} from '@/components/Shopify/Ecommerce/styles';
import sortIcon from '@images/ListSortArrows.svg';
import sortAscIcon from '@images/ListSortArrowsAsc.svg';
import sortDescIcon from '@images/ListSortArrowsDesc.svg';
import {TableContainer, TableHeaderRow, TableBody} from '@General/Atoms/TableList/styles';
import ListColumnHeader from '@/components/InventoryManagement/ListColumnHeader';
import LocateProductsListRow from '@/components/LocateProducts/LocateProductsListRow';

export default function LocateProductsTable({products}: LocateProductListProps) {
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState('');

    const handleSort = useCallback(
        (column: string) => {
            const newDirection =
                sortColumn === column && sortDirection === SORTING.ASC ? SORTING.DESC : SORTING.ASC;
            setSortColumn(column);
            setSortDirection(newDirection);
        },
        [sortColumn, sortDirection]
    );

    const renderColSortIcon = useCallback(
        (column: string) => {
            const isActive = sortColumn === column;
            const iconMap = {
                [SORTING.ASC]: sortAscIcon,
                [SORTING.DESC]: sortDescIcon,
            };
            const icon = isActive ? iconMap[sortDirection] : sortIcon;

            return (
                <Image
                    src={icon}
                    alt={`sort-${isActive ? sortDirection : ''}`}
                    Width="0.875rem"
                    Height="0.875rem"
                    Cursor="pointer"
                    onClick={() => handleSort(column)}
                />
            );
        },
        [sortColumn, sortDirection, handleSort]
    );

    return (
        <TableContainer>
            <TableHeaderRow
                borderBottom="1px solid #E8E9EA"
                gridColumns="repeat(3, minmax(5rem, 1fr))"
                gap="1rem"
            >
                <ListColumnHeader label={LOCATE_PRODUCTS_TABLE.HEADERS.PRODUCT} />
                <ListColumnHeader
                    label={LOCATE_PRODUCTS_TABLE.HEADERS.ADDED}
                    renderSortIcon={() => renderColSortIcon(LOCATE_PRODUCTS_TABLE.HEADERS.ADDED)}
                />
                <ListColumnHeader label={LOCATE_PRODUCTS_TABLE.HEADERS.LOCATION} />
            </TableHeaderRow>
            <TableBody overflowY="scroll">
                {products &&
                    products.map((product) => (
                        <LocateProductsListRow key={product.id} product={product} />
                    ))}
            </TableBody>
        </TableContainer>
    );
}
