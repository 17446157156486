import {FieldValues, FormProvider, useForm} from 'react-hook-form';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {
    defaultValuesNewLoanProduct,
    NEW_LOAN_PRODUCT,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import React, {useEffect, useRef, useState} from 'react';
import {LoanProductDetails} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/LoanProductDetails';
import {NavbarLoans} from '@components/PersonalLoans/NewLoanProduct/NavbarLoans';
import {PaymentFrequency} from '@components/PersonalLoans/NewLoanProduct/PaymentFrequency/PaymentFrequency';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RefsTypes} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {schemaNewLoanProduct} from '@components/PersonalLoans/NewLoanProduct/schemaNewLoanProduct';
import {yupResolver} from '@hookform/resolvers/yup';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {PaymentMethods} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/PaymentMethods';
import {ChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ChargesAndPenalties';
import {CancelProductModal} from '@components/PersonalLoans/NewLoanProduct/ui/CancelProductModal';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {LoanProductPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/LoanProductPreview';
import {PreviewAlert} from '@components/PersonalLoans/NewLoanProduct/ui/PreviewAlert';
import {DocumentsClients} from '@components/PersonalLoans/NewLoanProduct/DocumentsClients';
import {Disbursement} from '@components/PersonalLoans/NewLoanProduct/Disbursement';
import {
    getDocumentsPredefinedOptions,
    getDisbursementOptions,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';
import {getCatPaymentTypes} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetCatPaymentTypesActions';
import {getChargeAndPenaltiesDefault} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetChargeAndPenaltiesDefaultActions';
import {getTypeChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetTypeChargeAndPenaltiesActions';
import {getAmountTypeChargeAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetTypeAmountChargePenaltiesActions';
import {getCatConditionsTerms} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetCatConditionsTermsActions';
import {GetPersonalCreditTerm} from '@/components/LoansProducts/interfaces';
import {
    IGenderTerms,
    INewLoanSubmittedDataReducer,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {resetFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {resetFrequencyPaymentsReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';
import {setIsEditingLoan} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/isEditingLoanStateActions';
import {EditAlert} from '@components/PersonalLoans/NewLoanProduct/ui/EditAlert';
import {resetChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {SelectGetAllGenderPersonalLoan} from '@components/PersonalLoans/Redux/Actions/GetAllGenderPersonalLoan';

export const NewLoanProduct = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const {chargesAndPenalties} = useSelector(
        (state: RootState) => state.setChargesAndPenaltiesTable
    );
    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaNewLoanProduct),
        defaultValues: defaultValuesNewLoanProduct,
    });
    const [navbarSelected, setNavbarSelected] = useState<string>('Detalle');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [showLoanProductPreview, setShowLoanProductPreview] = useState<boolean>(false);
    const [showCancelProductModal, setShowCancelProductModal] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const refs: Record<RefsTypes, React.MutableRefObject<HTMLDivElement | null>> = {
        detailsRef: useRef<HTMLDivElement | null>(null),
        frequencyRef: useRef<HTMLDivElement | null>(null),
        clientAndDocumentsRef: useRef<HTMLDivElement | null>(null),
        disbursementsRef: useRef<HTMLDivElement | null>(null),
        paymentMethodRef: useRef<HTMLDivElement | null>(null),
        chargesRef: useRef<HTMLDivElement | null>(null),
    };
    const itemsBreadcrumb = [
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_1, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_1},
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_2, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_2},
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_3, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_3},
    ];
    const onSubmit = (data: FieldValues) => {
        const orderGenderArray = data.genderTerms.sort(
            (a: IGenderTerms, b: IGenderTerms) => a.genderId - b.genderId
        );
        dispatch(
            saveNewLoanSubmittedData({
                details: {
                    termName: data.termName,
                    description: data.description,
                    minAmount: data.minAmount,
                    maxAmount: data.maxAmount,
                    dateDueInit: data.dateDueInit,
                    dateDueEnd: data.dateDueEnd,
                    porcentAnualRate: data.porcentAnualRate,
                    periodMin: data.periodMin,
                    periodMax: data.periodMax,
                    requestChannel: data.requestChannel,
                    countLimitLoans: data.countLimitLoans,
                    frequencyTerm: data.frequencyTerm,
                    genderTerms: orderGenderArray,
                    ageMin: data.ageMin,
                    ageMax: data.ageMax,
                    creditScore: data.creditScore,
                    reviewCreditVoul: data.reviewCreditVoul,
                    documents: data.documents,
                    disbursements: data.disbursements,
                    typePaymentsIns: data.typePaymentsIns,
                    chargesPenalties: chargesAndPenalties,
                },
                companyId: companyId,
            })
        );
        setShowLoanProductPreview(!showLoanProductPreview);
        setNavbarSelected('Detalle');
        if (containerRef.current) return containerRef.current.scrollTo(0, 0);
    };
    const mapItemToDefaultTerm = (item: GetPersonalCreditTerm): INewLoanSubmittedDataReducer => {
        return {
            details: {
                termId: item.termId || 0,
                termName: item.termName || '',
                description: item.description || '',
                minAmount: item.minAmount || 0,
                maxAmount: item.maxAmount || 0,
                dateDueInit: item.dateDue || null,
                dateDueEnd: null,
                porcentAnualRate: item.porcentAnualRate || 0,
                periodMin: {
                    label: item.periodMin + NEW_LOAN_PRODUCT.MONTH,
                    value: item.periodMin || 0,
                },
                periodMax: {
                    label: item.periodMax > 0 ? item.periodMax + NEW_LOAN_PRODUCT.MONTH : '',
                    value: item.periodMax || 0,
                },
                requestChannel: [{label: item.requestChannelName, value: item.requestChannel || 0}],
                countLimitLoans: item.countLimitLoans || 0,
                frequencyTerm:
                    item.frequencyTerm?.map((freq) => ({
                        frecuancyId: freq.frecuancyId || 0,
                        frecuncyName: freq.frecuencyName || '',
                        countPaymentMin: freq.countFrecuancyMin || 0,
                        countPaymentMax: freq.countFrecuancyMax || 0,
                        isChecked: freq.isChecked || false,
                    })) || [],
                genderTerms:
                    item.genderTerms?.map((gender) => ({
                        genderId: gender.genderId || 0,
                        genderName: gender.genderName || '',
                        isChecked: gender.isChecked || false,
                    })) || [],
                ageMin: item.ageMin ? String(item.ageMin) : '',
                ageMax: item.ageMax ? String(item.ageMax) : '',
                creditScore: item.arrScoreTerm ? JSON.parse(item.arrScoreTerm) : [],
                reviewCreditVoul: item.reviewCreditVoul || false,
                documents:
                    item.documents?.map((doc) => ({
                        id: doc.documentId || 0,
                        documentId: doc.documentId || 0,
                        docName: doc.docName || '',
                        docDescription: doc.docDescription || '',
                        seniorityJob: doc.seniorityJob || '',
                        seniorityJobTime: {label: '', value: 0},
                        isRequired: doc.isRequired || false,
                        isChecked: doc.isChecked || false,
                    })) || [],
                disbursements:
                    item.disbursements?.map((disbursement) => ({
                        disbursementId: disbursement.disbursementsId || 0,
                        disbursementName: disbursement.disbursementDescription || '',
                        disbursementDetails: '',
                        isChecked: disbursement.isChecked || false,
                    })) || [],
                typePaymentsIns:
                    item.typePaymentsIns?.map((typePayment) => ({
                        typePaymentId: typePayment.typePaymentId || 0,
                        typePaymentName: typePayment.typePaymentName || '',
                        typePaymentDescription: typePayment.typePaymentDescription || '',
                        isChecked: typePayment.isChecked || false,
                    })) || [],
                chargesPenalties:
                    item.chargesPenalties?.map((charge) => ({
                        id: charge.chargesPenaltiesId || 0,
                        typeCharge: {
                            typeChargeId: charge.typeChargeId || 0,
                            label: charge.typeCharge || '',
                        },
                        chargeName: charge.chargeName || '',
                        chargeAmount: {
                            typeChargeAmountId: charge.typeChargeAmountId || 0,
                            label: charge.typeChargeAmount || '',
                            amount: charge.chargeAmount || 0,
                        },
                        condition: {
                            conditionId: charge.conditionId || 0,
                            label: charge.condition || '',
                        },
                        eventType: {
                            eventTypeId: charge.eventTypeId || 0,
                            label: charge.eventType || '',
                        },
                        isChecked: charge.isChecked || false,
                        isShowingEditButton: false,
                    })) || [],
            },
            companyId: companyId,
        };
    };
    const DataLoans = useSelector((state: RootState) => state.GetTermPersonalLoan.dataLoans);
    useEffect(() => {
        if (DataLoans && DataLoans?.termId > NEW_LOAN_PRODUCT.ZERO) {
            dispatch(saveNewLoanSubmittedData(mapItemToDefaultTerm(DataLoans)));
            setShowLoanProductPreview(true);
            dispatch(setIsEditingLoan(true));
        }
    }, [DataLoans]);
    const {handleSubmit} = formMethods;
    useEffect(() => {
        if (!token) return;
        dispatch(resetChargesAndPenalties());
        dispatch(getDocumentsPredefinedOptions(token));
        dispatch(getDisbursementOptions(token));
        dispatch(getCatPaymentTypes(token));
        dispatch(getChargeAndPenaltiesDefault(token));
        dispatch(getTypeChargesAndPenalties(token));
        dispatch(getAmountTypeChargeAndPenalties(token));
        dispatch(getCatConditionsTerms(token));
        dispatch(SelectGetAllGenderPersonalLoan(token));
        dispatch(resetFrequencyPaymentTable());
        dispatch(resetFrequencyPaymentsReducer());
    }, [dispatch, token]);
    const isEditingLoan: boolean = useSelector((state: RootState) => state.setIsEditingLoanReducer);
    return (
        <>
            <ContainerFlex
                Padding="1.5rem"
                Align="start"
                Justify="start"
                FlexDir="column"
                Gap="1.5rem"
                OverFlow="scroll"
                ref={containerRef}
                Position="relative"
            >
                <ContainerFlex Gap="1rem" FlexDir="column" Align="start" Height="max-content">
                    <Breadcrumb items={itemsBreadcrumb} />
                    <Text FontSize="1.75rem" FontWeight="700" Color="#1D1E20">
                        {NEW_LOAN_PRODUCT.TITLE}
                    </Text>
                </ContainerFlex>

                <NavbarLoans
                    containerRef={containerRef}
                    refs={refs}
                    navbarSelected={navbarSelected}
                    setNavbarSelected={setNavbarSelected}
                />
                {showLoanProductPreview && !isEditingLoan && <PreviewAlert />}
                {showLoanProductPreview &&
                    isEditingLoan &&
                    DataLoans &&
                    DataLoans?.isEdit === false && <EditAlert />}
                {showLoanProductPreview ? (
                    <LoanProductPreview
                        refs={refs}
                        showCancelProductModal={showCancelProductModal}
                        setShowCancelProductModal={setShowCancelProductModal}
                    />
                ) : (
                    <FormProvider {...formMethods}>
                        <ContainerForm
                            Align="start"
                            Justify="start"
                            FlexDir="column"
                            Gap="1.5rem"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <LoanProductDetails
                                detailsRef={refs.detailsRef}
                                isSubmitted={isSubmitted}
                            />
                            <PaymentFrequency
                                frequencyRef={refs.frequencyRef}
                                isSubmitted={isSubmitted}
                            />
                            <DocumentsClients
                                clientAndDocumentsRef={refs.clientAndDocumentsRef}
                                isSubmitted={isSubmitted}
                            />
                            <Disbursement
                                disbursementsRef={refs.disbursementsRef}
                                isSubmitted={isSubmitted}
                            />
                            <PaymentMethods
                                paymentMethodRef={refs.paymentMethodRef}
                                isSubmitted={isSubmitted}
                            />
                            <ChargesAndPenalties chargesRef={refs.chargesRef} />
                            <ContainerFlex
                                Radius="1rem"
                                backG="#FFF"
                                Border="1px solid #E8E9EA"
                                Padding="2.5rem"
                                Gap="1rem"
                            >
                                <ButtonGenerals
                                    SecondaryButton="#FFFFFF"
                                    Width="147px"
                                    type="button"
                                    onClick={() =>
                                        setShowCancelProductModal(!showCancelProductModal)
                                    }
                                >
                                    {NEW_LOAN_PRODUCT.BTN_CANCEL}
                                </ButtonGenerals>
                                <ButtonGenerals type="submit" onClick={() => setIsSubmitted(true)}>
                                    {NEW_LOAN_PRODUCT.BTN_CREATE_PRODUCT}
                                </ButtonGenerals>
                            </ContainerFlex>
                        </ContainerForm>
                    </FormProvider>
                )}
                {showCancelProductModal && (
                    <CancelProductModal
                        showCancelProductModal={showCancelProductModal}
                        setShowCancelProductModal={setShowCancelProductModal}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
