import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {HeadTablePurityLab} from '@/components/Foundry/Purity/HeadTablePurityLab';
import {getPurityLab} from '@ActionsPurity/PurityActions';
import {RowPurityLab} from '@Purity/RowPurityLab';

export function PurityLab(): JSX.Element {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    const params = useParams<{id: string}>();
    useEffect(() => {
        const getTablePurityLab = () => dispatch(getPurityLab(String(params.id), token));
        getTablePurityLab();
    }, [dispatch]);

    const puritylab = useSelector((state: RootState) => {
        return state.purity.puritylab;
    });

    return (
        <div className="container-primary">
            <div className="pt-2 pb-2 flex align-items-center">
                <span className="child-breadcrumb">Fundiciones</span>
                <span className="pt-2">
                    <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                </span>
                <span className="principal-breadcrumb">Pureza</span>
            </div>
            <table className="table">
                <HeadTablePurityLab />
                <tbody>
                    {puritylab &&
                        puritylab.lingotes.map(
                            (
                                assignments: JSX.IntrinsicAttributes & {
                                    lingote: number;
                                    kilatajes: string;
                                    purezaPrestalana: number;
                                    purezaFundidora: number;
                                    purezaTerceria: number;
                                    gramosProveedor: number;
                                    gramosOroFino: number;
                                    onzasOroFino: number;
                                    purezaPromedio: number;
                                    purezaSeleccionada: string;
                                    costo: number;
                                    subTotal: number;
                                    comision: number;
                                    total: number;
                                    ganancia: number;
                                }
                            ) => (
                                <RowPurityLab
                                    idingot={params.id}
                                    key={assignments.lingote}
                                    {...assignments}
                                />
                            )
                        )}
                </tbody>
            </table>
        </div>
    );
}
