import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import Quoter from '@/components/CreditQuoter/Quoter';
import RequirementsQuoter from '@/components/CreditQuoter/RequirementsQuoter';
import DetailsQuoter from '@/components/CreditQuoter/DetailsQuoter';
import BannerQuoter from '@/components/CreditQuoter/BannerQuoter';
import CompanyLoansQuoter from '@/components/CreditQuoter/CompanyLoansQuoter';
import {ICompaniesOffert} from '@/components/GlobalQuoter/interface';
import {fetchCompanyOfferSelected} from '@components/CreditQuoter/Redux/Actions/CompanyOfferSelected';
import {DEFAULT_COMPANY_OFFER} from '@components/CreditQuoter/constants';
import {GetQuoterCompaniesLoansAditionalService} from '@components/CreditQuoter/Redux/Actions/GetQuoterCompaniesLoansAditionalInf';
import {IGetConfirmationStepReducerInitialState} from '@components/PersonalLoans/interfaces';

export const CreditQuoter = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {state} = useLocation();
    const {frequencyTermCreditId, companyId} = state;
    const dispatch = useDispatch();
    const {parsedAmount} = useSelector((state: RootState) => state.globalQuoterFormReducer);
    const {quoterCompleteList} = useSelector((state: RootState) => state.globalQuoterFilterRedcuer);
    const {data} = useSelector((state: RootState) => state.CompanyOfferSelected);
    const {dataList} = useSelector(
        (state: RootState) =>
            state.GetConfirmationStepReducer as IGetConfirmationStepReducerInitialState
    );
    const additionalOffer = dataList?.find(
        (offer) => offer.frequencyTermCreditId === frequencyTermCreditId
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (
            (quoterCompleteList && quoterCompleteList?.length > 0) ||
            (dataList && dataList?.length > 0)
        ) {
            const offer =
                quoterCompleteList.find(
                    (offer: ICompaniesOffert) =>
                        offer.frequencyTermCreditId === frequencyTermCreditId
                ) ||
                additionalOffer ||
                DEFAULT_COMPANY_OFFER;
            if (offer)
                dispatch(
                    GetQuoterCompaniesLoansAditionalService(
                        {termPersonalLoanId: offer.termPersonalLoanId},
                        token
                    )
                );
            dispatch(fetchCompanyOfferSelected(offer));
        }
    }, [quoterCompleteList, dataList, token]);
    return (
        <ContainerFlex FlexDir="column" Height="100%" Justify="start" Align="start">
            <Quoter
                companyOffer={data}
                parsedAmount={additionalOffer?.amountMax || Number(parsedAmount)}
                companyId={Number(companyId)}
                frequencyTermCreditId={Number(frequencyTermCreditId)}
            />
            <RequirementsQuoter />
            <DetailsQuoter />
            <BannerQuoter />
            <CompanyLoansQuoter companyId={companyId} />
        </ContainerFlex>
    );
};
