import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IArticleVisualizationName, FeatureUnified} from '@Articles/interface';
import {ARTICLE_VISUALIZATION} from '@Articles/constants';
import {articleNameLabelStyle, NameSampleText} from '@Articles/style';
import {ICharacteristics} from '@components/Articles/Redux/interfaces';
import {useNormalizedFeatures} from '@components/Articles/FieldDinamics/hooks/useNormalizedFeatures';
import CharacteristicPill from '@components/Articles/FieldDinamics/CharacteristicPill';
import DropdownFeatureList from '@components/Articles/FieldDinamics/DropdownFeatureList';

const ArticleVisualizationName = ({watch, hereditaryFeatures}: IArticleVisualizationName) => {
    const [addedCharacteristics, setAddedCharacteristics] = useState<FeatureUnified[]>([]);

    const DinamicFeature = useSelector((state: RootState) => {
        return state.SaveNewDynamicFeatureReducer;
    });
    const DataUpdate: ICharacteristics[] | undefined = useSelector(
        (state: RootState) => state.GetNewCatalogId.dataCatalog?.dinamicsCharacteristics
    );

    const normalizedFeatures = useNormalizedFeatures(
        hereditaryFeatures,
        DinamicFeature,
        DataUpdate
    );

    const articleName = watch('name');

    const toggleCharacteristic = (feature: FeatureUnified) => {
        setAddedCharacteristics((prev) => {
            const exists = prev.some((f) => f.id === feature.id);
            if (exists) {
                return prev.filter((f) => f.id !== feature.id);
            } else {
                return [...prev, feature];
            }
        });
    };

    const isChecked = (feature: FeatureUnified) => {
        return addedCharacteristics.some((f) => f.id === feature.id);
    };

    const commaSeparator = (
        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400" LetterSpacing="0.019rem">
            ,
        </Text>
    );

    if (!articleName) return null;

    return (
        <>
            <ContainerFlex
                Justify="start"
                Align="flex-start"
                FlexDir="column"
                Padding="1rem"
                Gap="1rem"
                Radius="1rem"
                backG="#FFF"
            >
                <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="500">
                    {ARTICLE_VISUALIZATION.TITLE}
                </Text>
                <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                    {ARTICLE_VISUALIZATION.SUBTITLE}
                </Text>
                <ContainerFlex Justify="start" Align="flex-start">
                    <ContainerFlex Justify="start" Align="center" Gap="0.5rem" FlexWrap="wrap">
                        <Text {...articleNameLabelStyle} FontWeight="700">
                            {articleName}
                        </Text>
                        {commaSeparator}
                        {addedCharacteristics.map((feature, index) => (
                            <CharacteristicPill
                                key={index}
                                feature={feature}
                                onRemove={toggleCharacteristic}
                            />
                        ))}
                        <DropdownFeatureList
                            features={normalizedFeatures}
                            onToggle={toggleCharacteristic}
                            isChecked={isChecked}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                {addedCharacteristics.length > 0 && (
                    <ContainerFlex FlexDir="column" Justify="start" Align="flex-start">
                        <Text
                            {...articleNameLabelStyle}
                            FontWeight="700"
                            Height="2.5rem"
                            Padding="0.5rem 0"
                        >
                            {ARTICLE_VISUALIZATION.SAMPLE_NAME}
                        </Text>
                        <ContainerFlex
                            Padding="0 1rem"
                            Align="flex-start"
                            Radius="0.5rem"
                            backG="#F0F0FF"
                            Justify="flex-start"
                            FlexWrap="wrap"
                        >
                            <Text
                                {...articleNameLabelStyle}
                                FontWeight="700"
                                Height="2.5rem"
                                Padding="0.5rem 0"
                            >
                                {articleName}
                                {commaSeparator}
                            </Text>
                            {addedCharacteristics.map((feature, index) => (
                                <React.Fragment key={index}>
                                    <NameSampleText>
                                        <Text
                                            {...articleNameLabelStyle}
                                            FontWeight="700"
                                            Height="2rem"
                                        >
                                            {feature.name}
                                            {feature.valuename && <>: {feature.valuename}</>}
                                            {index < addedCharacteristics.length - 1 &&
                                                commaSeparator}
                                        </Text>
                                    </NameSampleText>
                                </React.Fragment>
                            ))}
                        </ContainerFlex>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </>
    );
};

export default ArticleVisualizationName;
