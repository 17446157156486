import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import infoBlue from '@/images/Info_blue.svg';

export interface InfoBannerProps {
    message: string;
}

const InfoBanner = ({message}: InfoBannerProps) => {
    return (
        <ContainerFlex
            Padding="16px"
            Radius="8px"
            backG="#DBF4FF"
            Gap="8px"
            Justify="flex-start"
            Align="center"
            Margin="8px 0 0 0"
        >
            <Image src={infoBlue} />
            <Text Color="#2A2C2F" FontFamily="Nunito" LetterSpacing="0.3px">
                {message}
            </Text>
        </ContainerFlex>
    );
};

export default InfoBanner;
