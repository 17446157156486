import axios from 'axios';
import {
    VERIFY_CODE_NEW_REQUEST,
    VERIFY_CODE_NEW_SUCCESS,
    VERIFY_CODE_NEW_ERROR,
} from '@components/MarketLogin/redux/types/types';
import process from 'process';

import {URL} from '@config/constants/index';

export const PostNewVerifyCodeResquest = () => {
    return {
        type: VERIFY_CODE_NEW_REQUEST,
    };
};
export const PostNewVeryfyCodeSuccess = (data) => {
    return {
        type: VERIFY_CODE_NEW_SUCCESS,
        payload: data,
    };
};

export const PostNewVeryfyCodeFailure = (err) => {
    return {
        type: VERIFY_CODE_NEW_ERROR,
        payload: err,
    };
};

export const PostNewCode = (token, clientID) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(PostNewVerifyCodeResquest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SendCodeUserClient}`,
                {clientUserId: clientID},
                {headers}
            );
            dispatch(PostNewVeryfyCodeSuccess(response));
        } catch (error) {
            dispatch(PostNewVeryfyCodeFailure(error));
        }
    };
};
