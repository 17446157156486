import React, {useState} from 'react';
import {ClickAwayListener} from '@mui/material';

import {Text, Input} from '@EcommerceShopify/styles';
import {
    FIXED_PRICES_RANGES,
    FIXED_PRICES_RANGES_VALUES,
    INVENTORY_PAGE,
    OPTION_TEXT,
} from '@components/InventoryManagement/constants';
import PriceRangeFilter from '@components/InventoryManagement/PriceRangeFilter';
import {
    renewalMenuStyles,
    Dropdown,
    Container,
    DropdownContainer,
} from '@components/InventoryManagement/styles';
import {useDispatch} from 'react-redux';
import {setCommercialFilterByPrice} from '@/components/InventoryManagement/Redux/Actions/commercialInventoryFilters';
import {PriceFilter} from '@/components/InventoryManagement/interfaces';
import {changeCommercialInventoryPage} from '@/components/InventoryManagement/Redux/Actions/getCommercialInventory';

const CommercialPriceInputDropdown = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const dispatch = useDispatch();

    const handleInputClick = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleOptionClick = (option: string) => {
        let price: PriceFilter | undefined = FIXED_PRICES_RANGES_VALUES[option];
        if (!price) {
            const arrNumbers = option.match(/[\d,]+(?:\.\d+)?/g);
            const values = arrNumbers?.map((num) => parseFloat(num.replace(/,/g, ''))) ?? [0];
            price = {
                minPrice: option.includes(OPTION_TEXT.FROM) ? values[0] : 0,
                maxPrice: option.includes(OPTION_TEXT.TO) ? values[0] : 1_000_000,
            };
            if (values.length === 2) {
                price.minPrice = values[0];
                price.maxPrice = values[1];
            }
        }
        dispatch(setCommercialFilterByPrice(price));
        dispatch(changeCommercialInventoryPage(1));
        setSelectedOption(option);
        setIsDropdownOpen(false);
    };

    return (
        <Container>
            <Input
                type="text"
                readOnly
                value={selectedOption || ''}
                placeholder={INVENTORY_PAGE.PRICE}
                onClick={handleInputClick}
            />
            <DropdownContainer>
                {isDropdownOpen && (
                    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
                        <Dropdown>
                            {FIXED_PRICES_RANGES.map((range) => (
                                <Text
                                    key={range}
                                    {...renewalMenuStyles}
                                    onClick={() => handleOptionClick(range)}
                                >
                                    {range}
                                </Text>
                            ))}
                            <PriceRangeFilter onSelect={handleOptionClick} />
                        </Dropdown>
                    </ClickAwayListener>
                )}
            </DropdownContainer>
        </Container>
    );
};

export default CommercialPriceInputDropdown;
