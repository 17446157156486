import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    VALIDATE_LOANS_DATA_ERROR,
    VALIDATE_LOANS_DATA_START,
    VALIDATE_LOANS_DATA_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

export const fetchValidateDataStart = () => {
    return {
        type: VALIDATE_LOANS_DATA_START,
    };
};
export const fetchValidateDataSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_LOANS_DATA_SUCCESS,
        payload: result,
    };
};

export const fetchValidateDataError = (error: AxiosError) => {
    return {
        type: VALIDATE_LOANS_DATA_ERROR,
        error: error,
    };
};

export function GetValidateLoanData(
    loanId: number,
    token: string,
    setIsValid: (data: boolean) => void
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const startTime = Date.now();
    const delay = 3000;
    return async (dispatch: AppDispatch) => {
        dispatch(fetchValidateDataStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetValidateLoan.replace(
                    '{loanId}',
                    String(loanId)
                )}
            `,
                {headers}
            );
            dispatch(fetchValidateDataSuccess(response));
            setIsValid(true);
            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(0, delay - elapsedTime);

            if (remainingTime > 0) {
                await new Promise((resolve) => setTimeout(resolve, remainingTime));
            }
            dispatch(ActualDataStepLoans(6));
        } catch (error) {
            dispatch(fetchValidateDataError(error as AxiosError));
        }
    };
}
