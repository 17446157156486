import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import AtaskateLogo from '@images/AtaskateLogo.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {ProgressBar} from '@components/SignUp/ProgressBar';
import {YourCompany} from '@components/SignUp/YourCompany';
import {TypeSale} from '@components/SignUp/TypeSale';
import {SelectPlan} from '@components/SignUp/SelectPlan';
import {LoadingScreen} from '@components/SignUp/LoadingScreen';
import {backToStep, resetAccountData} from '@components/SignUp/Redux/Actions/saveDataFormAction';
import {PERSONAL_FORM, PROGRESSITEMS} from '@components/SignUp/constants';
import {ValideCode} from '@/components/SignUp/ValideEmailCode';
import {CreateAccountEmail} from '@/components/SignUp/CreateAccountEmail';
import arrow from '@images/arrow_back.svg';
import {resetCodeLoginUser} from '@/components/SignUp/Redux/Actions/ResetPassCodeAction';

export const SignUp = () => {
    const dispatch = useDispatch();

    const {actualStep, loading} = useSelector((state: RootState) => state.saveDataSingUp);

    const ViewActualStep = () => {
        const viewStep: {[key: number]: React.JSX.Element} = {
            1: <CreateAccountEmail />,
            2: <ValideCode />,
            3: <YourCompany />,
            4: <TypeSale />,
            5: <SelectPlan />,
        };
        return viewStep[actualStep];
    };

    const progressStepMap = new Map<number, number>([
        [1, 1],
        [2, 1],
        [3, 2],
        [4, 3],
        [5, 4],
    ]);

    const getProgressStep = (currentStep: number): number => {
        return progressStepMap.get(currentStep) ?? 1;
    };
    const backPreviousStep = () => {
        if (actualStep !== 1) {
            dispatch(backToStep());
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetAccountData());
            dispatch(resetCodeLoginUser());
        };
    }, []);

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <ContainerFlex
                    backG="#E5E5FF"
                    FlexDir="column"
                    Gap="65px"
                    Padding="40px"
                    Height="100vh"
                    OverFlow="auto"
                    MinH="fit-content"
                    Justify="flex-start"
                >
                    <ContainerFlex
                        Display="grid"
                        Justify="center"
                        Align="center"
                        Height="auto"
                        Position="relative"
                    >
                        {actualStep !== 1 && actualStep !== 2 && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                textColor="#5A5AFF"
                                Cursor="pointer"
                                hBackG="none"
                                border="none"
                                backGround="transparent"
                                hText="#0D166B"
                                cursor="pointer"
                                padding="0"
                                height="auto"
                                clic={backPreviousStep}
                                width="4.5rem"
                                position="absolute"
                                text={
                                    <>
                                        <Image src={arrow} alt="return-icone" />
                                        <Text FontSize="1rem" FontWeight="500" Cursor="pointer">
                                            {PERSONAL_FORM.RETURN_PAGE}
                                        </Text>
                                    </>
                                }
                            />
                        )}
                        <ContainerImg>
                            <Image src={AtaskateLogo} alt="ataskate-logo" Width="204px" />
                        </ContainerImg>
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Height="max-content" Gap="24px">
                        <ContainerFlex
                            Display="grid"
                            Width="auto"
                            Height="auto"
                            Radius="24px"
                            backG="#FFF"
                            BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                            Padding="32px"
                            Gap="24px"
                            GridRows="repeat(auto-fit)"
                            JustifyItems="center"
                        >
                            <ProgressBar
                                progressItems={PROGRESSITEMS}
                                actualStep={getProgressStep(actualStep)}
                            />
                            <ViewActualStep />
                        </ContainerFlex>
                        <ContainerFlex Gap="4px">
                            <Text
                                onClick={() => {
                                    window.location.href = `https://www.multiapoyo.com.mx/pages/legales`;
                                }}
                                Color="#5A5AFF"
                                FontFamily="nunito"
                                FontSize="0.75rem"
                                FontWeight="550"
                                Cursor="pointer"
                            >
                                {PERSONAL_FORM.TERM}
                            </Text>
                            <Text
                                Color="#5A5AFF"
                                FontFamily="nunito"
                                FontSize="0.75rem"
                                FontWeight="550"
                            >
                                {PERSONAL_FORM.AND}
                            </Text>

                            <Text
                                onClick={() => {
                                    window.location.href = `https://www.multiapoyo.com.mx/pages/aviso-de-privacidad`;
                                }}
                                Color="#5A5AFF"
                                FontFamily="nunito"
                                FontSize="0.75rem"
                                FontWeight="550"
                                Cursor="pointer"
                            >
                                {PERSONAL_FORM.CONDITIONS}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};
