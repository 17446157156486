import React from 'react';

import {FeatureUnified} from '@Articles/interface';
import {AddedPill} from '@Articles/style';
import {Image, Text} from '@Shopify/Ecommerce/styles';
import removeIcon from '@images/closeDark.svg';

const CharacteristicPill = ({
    feature,
    onRemove,
}: {
    feature: FeatureUnified;
    onRemove: (feature: FeatureUnified) => void;
}) => (
    <AddedPill>
        <Text
            Color="#2A2C2F"
            FontSize="1rem"
            FontWeight="700"
            LetterSpacing="0.019rem"
            wSpace="nowrap"
        >
            {feature.name}
        </Text>
        <Image
            src={removeIcon}
            alt="remove"
            Width="1.25rem"
            Height="1.25rem"
            Cursor="pointer"
            onClick={() => onRemove(feature)}
        />
    </AddedPill>
);

export default CharacteristicPill;
