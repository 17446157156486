import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {ContainerFlex, Image, Text, TextEllipsis} from '@/components/Shopify/Ecommerce/styles';
import {DOCUMENTCLIENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import checkDisbursementIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkDisbursementIcon.svg';
import {RootState} from '@/config/store';

export const ClientData = () => {
    const documentClientData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details
    );
    const genderTerms = documentClientData.genderTerms.filter((item) => item.isChecked);
    const creditScore = documentClientData.creditScore;
    const [showRestElements, setShowRestElements] = useState<boolean>(false);
    return (
        <>
            <ContainerFlex Gap="1rem" Justify="start" Align="start">
                <ContainerFlex Gap="12px" Justify="start" Align="start" FlexDir="column">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.GENDER}&nbsp;
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    {genderTerms.length > 0 ? (
                        <ContainerFlex Align="start" Justify="start" Gap="8px" Width="max-content">
                            {genderTerms.map((item, index) => (
                                <ContainerFlex
                                    Width="max-content"
                                    Height="max-content"
                                    Gap="8px"
                                    key={index}
                                >
                                    <Image src={checkDisbursementIcon} Width="24px" Height="24px" />
                                    <Text Color="#2A2C2F">{item.genderName}</Text>
                                </ContainerFlex>
                            ))}
                        </ContainerFlex>
                    ) : (
                        <Text Color="#2A2C2F" Height="44px">
                            -
                        </Text>
                    )}
                </ContainerFlex>

                <ContainerFlex Gap="0.5rem" Justify="start" Align="start" FlexDir="column">
                    <Text
                        oFlow="normal"
                        wSpace="pre"
                        FontWeight="700"
                        FontSize="1rem"
                        Color="#1D1E20"
                    >
                        {DOCUMENTCLIENTS.AGE}&nbsp;
                        <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                            {DOCUMENTCLIENTS.OPTIONAL}
                        </Text>
                    </Text>
                    {documentClientData.ageMin && documentClientData.ageMax ? (
                        <ContainerFlex Justify="start" Align="start" Gap="0.5rem">
                            <Text
                                oFlow="normal"
                                wSpace="pre"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Width="35px"
                                Height="40px"
                                Padding="4px 0px"
                            >
                                {DOCUMENTCLIENTS.BETWEEN}
                            </Text>
                            <Text
                                oFlow="normal"
                                wSpace="pre"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Height="40px"
                                Padding="0.5rem 1rem"
                            >
                                {documentClientData.ageMin}
                            </Text>
                            <Text
                                oFlow="normal"
                                wSpace="pre"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Width="10px"
                                Height="40px"
                                Padding="4px 0px"
                            >
                                {DOCUMENTCLIENTS.AND}
                            </Text>
                            <Text
                                oFlow="normal"
                                wSpace="pre"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Height="40px"
                                Padding="0.5rem 1rem"
                            >
                                {documentClientData.ageMax}
                            </Text>
                            <Text
                                oFlow="normal"
                                wSpace="pre"
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                Width="max-content"
                                Height="40px"
                                Padding="0.5rem 1rem"
                            >
                                {DOCUMENTCLIENTS.YEARS}
                            </Text>
                        </ContainerFlex>
                    ) : (
                        <Text Color="#2A2C2F" Height="40px">
                            -
                        </Text>
                    )}
                </ContainerFlex>

                <ContainerFlex Gap="0.5rem" Justify="start" Align="start" FlexDir="column">
                    <ContainerFlex Gap="0.25rem" Justify="start" Align="start" FlexDir="column">
                        <Text
                            oFlow="normal"
                            wSpace="pre"
                            FontWeight="700"
                            FontSize="1rem"
                            Color="#1D1E20"
                        >
                            {DOCUMENTCLIENTS.SCORE}
                        </Text>
                        {creditScore && creditScore.length > 0 ? (
                            <ContainerFlex
                                Gap="0.5rem"
                                Padding="0.5rem 0px"
                                Justify="start"
                                Align="start"
                            >
                                {creditScore.slice(0, 2).map((item, index) => (
                                    <Text
                                        Color="#2A2C2F"
                                        FontWeight="700"
                                        Height="32px"
                                        Padding="0.25rem 0.5rem"
                                        bGround="#F4F5F5"
                                        BorderRadius="0.5rem"
                                        key={index}
                                    >
                                        {item.name}
                                    </Text>
                                ))}
                                {creditScore.length > 2 && (
                                    <ContainerFlex
                                        Position="relative"
                                        FlexDir="column"
                                        Justify="start"
                                        Width="36px"
                                        Height="32px"
                                    >
                                        <Text
                                            Color="#5A5AFF"
                                            FontWeight="700"
                                            Width="36px"
                                            MinH="32px"
                                            Padding="0.25rem 0.5rem"
                                            bGround="#F4F5F5"
                                            BorderRadius="0.5rem"
                                            MarginBottom="8px"
                                            Cursor="pointer"
                                            onMouseEnter={() => setShowRestElements(true)}
                                            onMouseLeave={() => setShowRestElements(false)}
                                        >
                                            {`+${creditScore.length - 2}`}
                                        </Text>

                                        {showRestElements && (
                                            <ContainerFlex
                                                Position="relative"
                                                PositionTop="8px"
                                                PositionRight="8px"
                                                Align="start"
                                                FlexDir="column"
                                                Height="max-content"
                                                Width="139px"
                                                backG="#FFF"
                                                Radius="0.5rem"
                                                Border="1px solid #F3F3F3"
                                                BoxShadow="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                                            >
                                                {creditScore.slice(2).map((item, index) => (
                                                    <TextEllipsis
                                                        Padding="0.5rem"
                                                        Color="#2A2C2F"
                                                        Width="122px"
                                                        key={index}
                                                    >
                                                        {item.name}
                                                    </TextEllipsis>
                                                ))}
                                            </ContainerFlex>
                                        )}
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                        ) : (
                            <Text Color="#2A2C2F" Height="32px">
                                -
                            </Text>
                        )}
                    </ContainerFlex>
                    {documentClientData.reviewCreditVoul && (
                        <ContainerFlex Gap="0.5rem" Justify="start" Align="start">
                            <Image src={checkDisbursementIcon} Width="24px" Height="24px" />
                            <Text Color="#2A2C2F">{DOCUMENTCLIENTS.CHECKCREDITS}</Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
