import {useDispatch} from 'react-redux';

import useCheckPawnsOnCart from '@/hooks/useCheckPawnsOnCart';
import {addDischargeOrEndorsement} from '@Shopify/ClientProfile/Redux/Action/AddDischargeEndorsement';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';
import {InventoryPledge} from '@components/InventoryManagement/Redux/interfaces';
import useToast from '@/hooks/useToast';
import {useInventorySelectors} from '@components/InventoryManagement/hooks/useInventorySelectors';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';

export const useInventoryActions = () => {
    const dispatch = useDispatch();
    const {showToast} = useToast();
    const {checkCartAndPawns} = useCheckPawnsOnCart();
    const {cartShoppingId, branchId, token} = useInventorySelectors();

    const handleEndorsementDischarge = async (
        typeMovement: number,
        item: InventoryPledge,
        options: {extend?: boolean; addPay?: boolean},
        handleError: (message: string) => void
    ) => {
        const {hasPawns, message} = await checkCartAndPawns();
        if (hasPawns && message) {
            handleError(message);
            return;
        }
        const onError = (error: string) => {
            dispatch(setIsCartOpen(true));
            handleError(error);
        };

        const {extend = false, addPay = false} = options;
        const payment =
            typeMovement === TYPE_MOVEMENT_ADD_CART.RENEWAL
                ? item.datCarShopInventory.renewal
                : item.datCarShopInventory.performance;

        const discharge = {
            carShoppingId: cartShoppingId,
            typeMovement,
            branchId,
            pawnId: item.contractNumber,
            paymentAmount: payment,
            iva: item.datCarShopInventory.iva,
            moratoriumAmount: item.datCarShopInventory.moratoriumAmount,
            amountStorage: item.datCarShopInventory.amountStorage,
            amountInterest: item.datCarShopInventory.amountInterest,
            insuranceAmount: item.datCarShopInventory.insuranceAmount,
            amountReposition: item.datCarShopInventory.amountReposition,
            amountRepositionIVA: item.datCarShopInventory.amountRepositionIVA,
            amountDiscountInterest: item.datCarShopInventory.amountDiscountInterest,
            amountDiscountInterestIVA: item.datCarShopInventory.amountDiscountInterestIVA,
            maximumInterest: item.datCarShopInventory.maximumInterest,
        };

        await dispatch(
            addDischargeOrEndorsement(
                discharge,
                token,
                () => dispatch(setIsCartOpen(true)),
                onError,
                {extend, addPay}
            )
        );
    };

    const handleError = (message: string) => {
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    return {handleEndorsementDischarge, handleError};
};
