import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
    ContainerCellGrid,
    ContainerFlex,
    Text,
    Image,
    ContainerForm,
} from '@components/Shopify/Ecommerce/styles';
import {
    BUTTONS,
    DEFAULT_USER_VALUES,
    TEXT_NEW_USER,
} from '@components/NewUserEmployee/Operational/constants';
import {PersonalInformation} from '@components/NewUserEmployee/PersonalInformation';
import {AdressUser} from '@components/NewUserEmployee/AdressUser';
import {PassAcess} from '@components/NewUserEmployee/AccessPass';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {AssignedRole} from '@components/NewUserEmployee/AssignedRole';
import {DataPersonalUsers, IRolItem} from '@components/NewUserEmployee/Operational/interface';
import {useForm} from 'react-hook-form';
import user from '@components/NewUserEmployee/Operational/img/default.jpg';
import edit from '@/images/PencilIcon.svg';
import ImgInputUpload from '@components/NewUserEmployee/UploadImg';
import {EditableAvatar} from '@components/NewUserEmployee/ImageEdit';
import acess from '@/images/accessUserIcon.svg';
import role from '@/images/rolUserIcon.svg';
import phone from '@/images/phoneUserIcone.svg';
import email from '@/images/emailUserIcone.svg';
import {InfoUser} from '@components/NewUserEmployee/Operational/ValidationYup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {AppDispatch, RootState} from '@/config/store';
import {getLevelTreeBranch} from '@components/NewUserEmployee/Redux/Actions/GetLevelTreeBranchActions';
import {createUserCommand} from '@components/NewUserEmployee/Redux/Actions/CreateUserCommandActions';
import {formatDateData} from '@/hooks/currentFormatUtils';
import ModalRoles from '@components/NewUserEmployee/MoldaRoles';
import {closeRoleModal} from '@/components/Users/UserProfile/Redux/Actions/GetAllRoleAction';
import {UserBranchAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignment';

const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Mi empresa'},
    {to: '#/Users', label: 'Usuarios'},
    {to: '#/newUser', label: 'Nuevo usuario'},
];
export const NewEmployeeUser = () => {
    const selectedRole = useSelector((state: RootState) => state.getRoleAllUser.selectedRole);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [errorBranches, setErrorBranches] = useState(false);
    const [searchItem, setSearch] = useState('');
    const [checkedStorages, setCheckedStorages] = useState<number[]>([]);
    const [checkedOptions, setCheckedOptions] = useState<Set<number>>(new Set());
    const [profileImage, setProfileImage] = useState<string>(user);

    const navigate = useNavigate();
    const [roles, setRoles] = useState<IRolItem[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const CreateUser = useSelector((state: RootState) => state.createUserCommandReducer.loading);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const {control, setValue, formState, handleSubmit, reset, watch, register} =
        useForm<DataPersonalUsers>({
            resolver: yupResolver(InfoUser),
            mode: 'onChange',
            defaultValues: DEFAULT_USER_VALUES,
        });

    const formProps = {
        control,
        setValue,
        formState,
        reset,
        watch,
    };

    const handleModal = (isOpen: boolean): void => {
        setIsModalOpen(isOpen);
    };
    const handleSaveImage = (editedImage: string) => {
        setProfileImage(editedImage);
        setValue('img', editedImage);
    };
    const handleCancel = () => {
        reset();
    };
    const getSelectedIds = (): number[] => {
        return roles.filter((role) => role.isSelected).map((role) => role.id);
    };
    const selectedIds = getSelectedIds();
    useEffect(() => {
        setValue('img', profileImage);
    }, [profileImage, setValue]);

    const onSubmit = (data: DataPersonalUsers) => {
        if (token && companyId) {
            const CreateUserData = {
                companyId: companyId,
                image: profileImage,
                names: data.name,
                paternalSurname: data.parental_surnames,
                maternalSurname: data.maternal_surname,
                birthdate: formatDateData(data.birthdate),
                email: data.email,
                phoneNumber: data.phone.number,
                password: data.password,
                roles: selectedIds,
                postCode: data.cp,
                address: data.street,
                outsideNumber: data.externalNumber,
                insideNumber: data.internalNumber ? String(data.internalNumber) : '',
                colonyId: data.colony.value,
                branches: Array.from(checkedOptions),
                Storages: checkedStorages,
                numberPeriods: Number(data.numberPeriods),
                catPasswordUpdateId: data.catPasswordUpdateId.value,
            };
            dispatch(
                createUserCommand(
                    token,
                    companyId,
                    CreateUserData,
                    reset,
                    navigate,
                    setErrorBranches
                )
            );
        }
    };
    const ResetAll = () => {
        if (token && companyId) {
            dispatch(getLevelTreeBranch(token, companyId));
            setSearch('');
        }
    };
    useEffect(() => {
        if (token && companyId) {
            dispatch(getLevelTreeBranch(token, companyId));
        }
    }, []);

    useEffect(() => {
        if (Array.from(checkedOptions).length > 0) {
            setErrorBranches(false);
        }
    }, [Array.from(checkedOptions)]);

    useEffect(() => {
        if (searchItem.length === 2) {
            ResetAll();
        }
    }, [searchItem]);
    return (
        <ContainerForm Height="none" onSubmit={handleSubmit(onSubmit)}>
            <ContainerFlex Padding="24px" FlexDir="column" Height="" Gap="16px" Align="start">
                <Breadcrumb items={itemsBreadcrumb} />
                <ContainerFlex Height="max-content" Align="start" Justify="start">
                    <Text FontSize="1.5rem" FontWeight="700">
                        {TEXT_NEW_USER.TITLE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Height="max-content" Radius="16px" Border=" 1px solid #E8E9EA">
                    <ContainerCellGrid Padding="24px" Gap="32px">
                        <EditableAvatar
                            src={profileImage || user}
                            onEditClick={() => handleModal(true)}
                            editIcon={edit}
                        />
                        <ContainerFlex FlexDir="column">
                            <ContainerFlex Justify="start">
                                <Text FontSize="1.5rem" FontWeight="700">
                                    {TEXT_NEW_USER.NAME_USER}
                                </Text>
                            </ContainerFlex>

                            <ContainerCellGrid>
                                <ContainerFlex Justify="start" Gap="4px">
                                    <Image src={acess} alt="location-icone" />
                                    <Text FontSize="0.875rem">{TEXT_NEW_USER.ACCESS}</Text>
                                    <Text Color="#71767D">{TEXT_NEW_USER.UNASSIGNED}</Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="center" Gap="4px">
                                    <Image src={role} alt="rol-icone" />
                                    <Text FontSize=" 0.875rem">{TEXT_NEW_USER.ROL}</Text>
                                    <Text Color="#71767D">{TEXT_NEW_USER.UNASSIGNED}</Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="center">
                                    <Image src={phone} alt="phone-icone" />
                                    <Text>{TEXT_NEW_USER.PHONE}</Text>
                                </ContainerFlex>
                                <ContainerFlex Justify="end">
                                    <Image src={email} alt="email-icone" />
                                    <Text>{TEXT_NEW_USER.MAIL}</Text>
                                </ContainerFlex>
                            </ContainerCellGrid>
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
                <PersonalInformation {...formProps} />
                <AdressUser {...formProps} />
                <PassAcess {...formProps} register={register} />
                <ContainerFlex
                    Padding="24px"
                    Height="max-content"
                    Radius="16px"
                    Border=" 1px solid #E8E9EA"
                >
                    <ContainerFlex Justify="space-between">
                        <Text FontSize="1.5rem" FontWeight="600">
                            {TEXT_NEW_USER.PIN}
                        </Text>
                        <GreenSwitch />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Padding="24px"
                    Height="max-content"
                    Radius="16px"
                    Border={errorBranches ? '1px solid red' : '1px solid #E8E9EA'}
                >
                    <UserBranchAssignment
                        checkedOptions={checkedOptions}
                        setCheckedOptions={setCheckedOptions}
                        checkedStorages={checkedStorages}
                        setCheckedStorages={setCheckedStorages}
                        isCreate={true}
                    />
                </ContainerFlex>
                <AssignedRole roles={roles} setRoles={setRoles} />
                <ContainerFlex Gap="24px" Border="1px solid #E8E9EA" Radius="16px" Padding="24px">
                    <ButtonGenerals type="button" SecondaryButton="#FFFFFF" onClick={handleCancel}>
                        {BUTTONS.CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals type="submit" disabled={CreateUser}>
                        {' '}
                        {BUTTONS.KEEP}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
            {isModalOpen && (
                <ImgInputUpload
                    isOpen={isModalOpen}
                    closeModal={() => handleModal(false)}
                    onSave={handleSaveImage}
                    currentImage={profileImage || undefined}
                />
            )}

            {isModalOpen && (
                <ModalRoles
                    isOpen={isModalOpen}
                    closeModal={() => dispatch(closeRoleModal())}
                    selectedRole={selectedRole}
                />
            )}
        </ContainerForm>
    );
};
