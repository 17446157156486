import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    BranchItem,
    BranchOption,
    SearchItem,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {putTreeNewLevel} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/PutLevelTreeNewAction';

export const useBranchTree = (
    checkedOptions: Set<number>,
    setCheckedOptions: React.Dispatch<React.SetStateAction<Set<number>>>,
    setCheckedStorages: React.Dispatch<React.SetStateAction<number[]>>,
    checkedStorages: number[],
    isCreate: boolean
) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.ResetPasswordTimer.employeeId);
    const [selectedOptions, setSelectedOptions] = useState<BranchOption[]>([]);
    const search = useSelector(
        (state: RootState) => state.SearchLevelBranchUser.userData?.data as SearchItem[] | undefined
    );
    const TreeBranchsData: BranchOption[] = useSelector(
        (state: RootState) => state.getLevelTreeBranchReducer.data
    );

    const [columns, setColumns] = useState<BranchOption[][]>([]);

    const treeUser = useSelector(
        (state: RootState) => state.GetBrancheTreeUser.userData as number[]
    );
    const userStorages = useSelector((state: RootState) => state.GetStorages.storages);

    useEffect(() => {
        if (treeUser && !isCreate) {
            setCheckedOptions(new Set(treeUser));
        }

        if (userStorages && !isCreate) {
            setCheckedStorages(userStorages);
        }
    }, [treeUser, userStorages]);

    useEffect(() => {
        if (TreeBranchsData[0] && TreeBranchsData[0].nextLevel) {
            setColumns([TreeBranchsData[0].nextLevel]);
        }
    }, [TreeBranchsData]);

    const getAllChildIds = (option: BranchOption): {branches: number[]; storages: number[]} => {
        const nextLevelIds = option.nextLevel
            ? option.nextLevel.map(getAllChildIds).reduce(
                  (acc, child) => {
                      acc.branches.push(...child.branches);
                      if (acc.storages) {
                          acc.storages.push(...child.storages);
                      }
                      return acc;
                  },
                  {branches: [], storages: []}
              )
            : {branches: [], storages: []};

        const branchIds = option.branches
            .filter((branch) => branch.branchType === 'Branch')
            .map((branch) => branch.idBranch);
        const storageIds = option.branches
            .filter((branch) => branch.branchType === 'Storage')
            .map((branch) => branch.idBranch);
        return {
            branches: [...nextLevelIds.branches, ...branchIds],
            storages: [option.idBranch, ...nextLevelIds.storages, ...storageIds],
        };
    };

    const isParentChecked = (option: BranchOption): boolean => {
        const childIds = getAllChildIds(option).branches;
        const childStorages = getAllChildIds(option).storages;
        if (childIds.length && childStorages.length === 0) return false;

        return (
            childIds.some((id) => checkedOptions.has(id)) ||
            childStorages.some((id) => checkedStorages.includes(id))
        );
    };

    const getNextLevel = (search: SearchItem[], columnIndex: number) => {
        const nextLevelIndex = columnIndex + 1;
        return search.map((item: SearchItem) => ({
            idBranch: 0,
            branches: [],
            idLevel: Number(item.ids.split(',')[nextLevelIndex]),
            levelName: item.title.split(' > ')[nextLevelIndex],
            typeLocation: item.typeLocation,
        }));
    };

    const getNextItems = (option: BranchOption) => [...(option.nextLevel || [])];

    const handleSelect = (
        option: BranchOption,
        columnIndex: number,
        setBranchData: React.Dispatch<React.SetStateAction<BranchItem[]>>
    ) => {
        const newSelectedOptions = [...selectedOptions.slice(0, columnIndex), option];
        setSelectedOptions(newSelectedOptions);

        const baseColumns = columns.slice(0, columnIndex + 1);
        const nextItems = getNextItems(option);

        const hasMoreSearchLevels = () => {
            return search?.length && columnIndex < search[0].ids.split(',').length - 1;
        };

        const nextColumnStrategies = {
            hasNextItems: () => nextItems,
            hasSearchLevels: () => (search ? getNextLevel(search, columnIndex) : []),
            default: () => [],
        };

        const getNextColumns = () => {
            if (nextItems.length > 0) return nextColumnStrategies.hasNextItems();
            if (hasMoreSearchLevels()) return nextColumnStrategies.hasSearchLevels();
            return nextColumnStrategies.default();
        };

        const nextColumns = getNextColumns();
        setColumns(nextColumns.length > 0 ? [...baseColumns, nextColumns] : baseColumns);

        if (option.branches) {
            setBranchData(option.branches);
        }
    };

    const handleCheckboxChange = (option: BranchOption) => {
        const childIds = getAllChildIds(option).branches;
        const checkboxActions = new Map([
            [
                true,
                (ids: number[], set: Set<number>) => {
                    ids.forEach((id) => set.delete(id));
                    return set;
                },
            ],
            [
                false,
                (ids: number[], set: Set<number>) => {
                    ids.forEach((id) => {
                        if (id) set.add(id);
                    });
                    return set;
                },
            ],
        ]);

        const isCurrentlyChecked = isParentChecked(option);
        const storageIds = getAllChildIds(option).storages.filter((id) => id !== undefined);
        const newCheckedStorages = isCurrentlyChecked
            ? checkedStorages.filter((id) => !storageIds.includes(id))
            : [...checkedStorages, ...storageIds];

        const newCheckedOptions = new Set(checkedOptions);
        const allIds = [...childIds];
        const updatedCheckedOptions =
            checkboxActions.get(isCurrentlyChecked)?.(allIds, newCheckedOptions) ??
            newCheckedOptions;

        const updatedCheckedBranches = Array.from(updatedCheckedOptions);

        setCheckedStorages(newCheckedStorages);
        setCheckedOptions(updatedCheckedOptions);

        if (!isCreate) {
            dispatch(
                putTreeNewLevel(token, {
                    employeeId,
                    branches: updatedCheckedBranches,
                    storages: newCheckedStorages,
                })
            );
        }
    };

    return {
        columns,
        selectedOptions,
        handleSelect,
        handleCheckboxChange,
        isParentChecked,
        setColumns,
        setSelectedOptions,
    };
};
