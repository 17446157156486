import {COMPANY_OFFER_SELECTED} from '@components/CreditQuoter/Redux/Types/Types';
import {ICompaniesOffert} from '@/components/GlobalQuoter/interface';
import {IAdditionalOffer} from '@/components/PersonalLoans/interfaces';

export const fetchCompanyOfferSelected = (result: ICompaniesOffert | IAdditionalOffer) => {
    return {
        type: COMPANY_OFFER_SELECTED,
        data: result,
    };
};
