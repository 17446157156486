import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ButtonGeneral from '@General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {PRODUCT_CHARACTERISTICS} from '@components/InventoryManagement/constants';
import {ProductConditionProps} from '@components/InventoryManagement/interfaces';
import {productConditionSchema} from '@/components/InventoryManagement/yupProduct';

const ProductCharacteristicsModal: React.FC<ProductConditionProps> = ({
    showModal,
    setShowModal,
}) => {
    const {
        handleSubmit,
        register,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            characteristic: '',
        },
        resolver: yupResolver(productConditionSchema),
    });

    const closeModal = () => {
        setShowModal(false);
        reset();
    };

    const onSubmit = async (values: FieldValues) => {
        setShowModal(false);
        reset();
    };

    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => setShowModal(true)}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerFlex
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                    {PRODUCT_CHARACTERISTICS.TITLE}
                </Text>

                {PRODUCT_CHARACTERISTICS.RADIO_OPTIONS.map((option, index) => (
                    <ContainerFlex
                        Cursor="pointer"
                        key={index}
                        Justify="start"
                        Gap="1rem"
                        Height="1.5rem"
                        onClick={() => {}}
                    >
                        <InputColor
                            {...register('characteristic')}
                            type="radio"
                            value={option.value}
                        />
                        <Text Cursor="pointer" Color="#54575C">
                            {option.label}
                        </Text>
                    </ContainerFlex>
                ))}
                <Text Color="#ef4f55" FontWeight="400" FontSize="12px">
                    {errors?.characteristic?.message}
                </Text>
                <ContainerFlex Align="end" Gap="1.5rem">
                    <ButtonGeneral
                        width="100%"
                        text={PRODUCT_CHARACTERISTICS.CANCEL_BUTTON}
                        clic={() => closeModal()}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="2.5rem"
                        cursor="pointer"
                        border="1px solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        width="100%"
                        text={PRODUCT_CHARACTERISTICS.SAVE_BUTTON}
                        clic={handleSubmit(onSubmit)}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
export default ProductCharacteristicsModal;
