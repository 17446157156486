import React from 'react';
import Select, {components, DropdownIndicatorProps, GroupBase, Props} from 'react-select';
import {ContainerImg, Image} from '@Shopify/Ecommerce/styles';
import {StylesConfig} from 'react-select';
import ArrowSmall from '@images/chevronBottom.svg';
import Calendar from '@images/calendar.svg';

import ArrowSelectDisabled from '@images/ArrowSelectDisabled.svg';

interface SelectInputProps {
    hasError?: boolean | null;
    borderRadius?: string;
    border?: string;
    background?: string;
    width?: string;
    height?: string;
    isDate?: boolean;
    maxH?: string;
}

function SelectGeneral<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>({
    hasError,
    borderRadius = '32px',
    border = '1px solid #E8E9EA',
    background = 'F4F5F5',
    width = '100%',
    height = '40px',
    isDate = false,
    maxH,
    ...props
}: Props<Option, IsMulti, Group> & SelectInputProps) {
    const DropdownIndicator = (props: DropdownIndicatorProps<Option, IsMulti, Group>) => {
        return (
            <components.DropdownIndicator {...props}>
                <ContainerImg Width="24px" Height="24px">
                    {isDate && <Image src={Calendar} alt="icon-calendar" />}
                    {!isDate && (
                        <Image
                            src={props.isDisabled ? ArrowSelectDisabled : ArrowSmall}
                            alt="icon-arrow"
                        />
                    )}
                </ContainerImg>
            </components.DropdownIndicator>
        );
    };

    const selectStyles = (isDate: boolean): StylesConfig<Option, IsMulti, Group> => ({
        container: (styles) => ({
            ...styles,
            width: width,
            height: height,
            cursor: 'pointer',
        }),
        control: (styles, state) => ({
            ...styles,
            maxHeight: maxH,
            border: hasError ? '1px solid #A82424' : border,
            backgroundColor: state.isDisabled ? '#F4F5F5' : background,
            borderRadius: borderRadius,
            boxShadow: 'none',
            padding: '0px 16px',
            ':hover': {
                borderColor: hasError ? '#A82424' : '#5A5AFF',
            },
        }),
        valueContainer: (styles) => ({...styles, padding: '0'}),
        singleValue: (styles, state) => ({
            ...styles,
            color: state.isDisabled ? '#A1A5AA' : '#2A2C2F',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: '#A1A5AA',
            fontWeight: '400',
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: 'none',
        }),
        dropdownIndicator: (styles, state) => ({
            ...styles,
            padding: '0',
            ...(!isDate && {
                transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out',
            }),
        }),
        menu: (styles) => ({
            ...styles,
            border: '1px solid #F3F3F3',
            boxShadow: '0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)',
            borderRadius: '8px',
            margin: '2px 0',
        }),
        menuList: (styles) => ({
            ...styles,
            padding: '0',
            borderRadius: '8px',
            '::-webkit-scrollbar': {
                width: '0',
            },
        }),
        option: (styles, state) => ({
            ...styles,
            fontWeight: '400',
            backgroundColor: state.isSelected ? '#FAFAFF' : '',
            borderLeft: state.isSelected ? '2px solid #5A5AFF' : '2px solid #FFF',
            color: state.isSelected ? '#5A5AFF' : '#2A2C2F',
            borderBottom: '1px solid #E8E9EA',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: '#F0F0FF',
                borderLeftColor: '#5A5AFF',
            },
        }),
    });

    return (
        <Select
            {...props}
            placeholder={props.placeholder ? props.placeholder : 'Selecciona'}
            styles={selectStyles(isDate)}
            isSearchable={false}
            components={{DropdownIndicator}}
        />
    );
}

export default SelectGeneral;
